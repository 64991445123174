import { useEffect, useState } from "react";
import * as Config from "../../../api/Config";
import Http from "../../../layouts/Http";
import { useTranslation } from "react-i18next";
const useEffectSettingProductRating = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [initProductRating, setInitProductRating] = useState({
        display_rating: "before",
        display_number: true,
        show_rating_when_empty: true,
        rating_icon_color: "#FFB400",
        rating_text_color: "#74788D",
        number_text_color: "#3D4153",
        label_ratings: "ratings",
        label_rating: "rating",
        country_language: "en",
        display_rating_collection: "before",
        display_number_collection: true,
        show_collection_rating_when_empty: true,
        font_size: {
            product_page: {
                star_size: 16,
                text_star: 12,
                text_rating: 12,
            },
            collection_page: {
                star_size: 16,
                text_star: 10,
            },
        },
    });

    const [productRating, setProductRating] = useState(initProductRating);
    const { t } = useTranslation("message");

    const [validateInput, setValidateInput] = useState({
        label_ratings: "",
        label_rating: "",
    });

    const handleChangeInput = (value, item) => {
        setProductRating({
            ...productRating,
            [item]: value,
        });
        if (value === "") {
            setValidateInput({
                ...validateInput,
                [item]: t("validate.empty_input", { ns: "message" }),
            });
        } else {
            setValidateInput({
                ...validateInput,
                [item]: "",
            });
        }
    };

    const handleGetProductRating = () => {
        setIsLoading(true);
        Http.get(`${Config.API_URL}/settings/widget/product-rating`)
            .then((response) => {
                if (response.data.status) {
                    const data = {
                        ...initProductRating,
                        ...response.data.data,
                        show_rating_when_empty: response.data.data
                            .show_rating_when_empty
                            ? true
                            : false,
                        show_collection_rating_when_empty: response.data.data
                            .show_collection_rating_when_empty
                            ? true
                            : false,
                    };
                    setInitProductRating(data);
                    setProductRating(data);
                } else {
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                }
            })
            .catch((e) => {
                window.shopify.toast.show(
                    t("toast.server_error", { ns: "message" }),
                    { duration: 3000 }
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSaveProductRating = () => {
        if (
            validateInput.label_ratings === "" &&
            validateInput.label_rating === ""
        ) {
            Http.post(
                `${Config.API_URL}/settings/widget/product-rating`,
                productRating
            )
                .then((response) => {
                    if (response.status === 200) {
                        setInitProductRating(productRating);
                        window.shopify.toast.show(
                            t("toast.saved", { ns: "message" }),
                            { duration: 3000 }
                        );
                        setInitProductRating(productRating);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                });
        }
    };
    useEffect(() => {
        handleGetProductRating();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        productRating,
        setProductRating,
        initProductRating,
        handleSaveProductRating,
        handleChangeInput,
        validateInput,
    };
};

export default useEffectSettingProductRating;
