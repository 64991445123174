import {
    Box,
    Icon,
    Collapsible,
    BlockStack,
    InlineGrid,
    InlineStack,
    RadioButton,
    Text,
    Button,
    Divider,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import ColorPicker from "../../../../../components/Widget/ColorPicker";
import ColorModeOptionSkeleton from "../../../../../components/Widget/ColorModeOptionSkeleton";
import { useCallback, useState } from "react";
import CustomModalMessage from "../../../../../components/CustomModalMessage";

const defaultColor = {
    light: {
        primary_color: {
            color: "#3D4153",
        },
        color_customize: {
            headline: "#3E4152",
            navigator_button: "#74788D",
            navigator_arrow: "#ffffff",
            page_indicator: "#3D4153",
            content: "#74788D",
            customer_name: "#292A32",
            verified_tag: "#A7ABC3",
            star: "#FFB400",
            divider: "#D3D5E1",
            stroke: "#D3D5E0",
            card_background: "#FFFFFF",
            widget_background: "#FFFFFF",
        },
    },
    dark: {
        primary_color: {
            color: "#F4F4F4",
        },
        color_customize: {
            headline: "#FFFFFF",
            navigator_button: "#74788D",
            navigator_arrow: "#ffffff",
            page_indicator: "#3D4153",
            content: "#74788D",
            customer_name: "#292A32",
            verified_tag: "#A7ABC3",
            star: "#FFB400",
            divider: "#D3D5E1",
            stroke: "#D3D5E0",
            card_background: "#FFFFFF",
            widget_background: "#2C2C2C",
        },
    },
    custom: {
        primary_color: {
            color: "#3D4153",
        },
        color_customize: {
            headline: "#3E4152",
            navigator_button: "#74788D",
            navigator_arrow: "#ffffff",
            page_indicator: "#3D4153",
            content: "#74788D",
            customer_name: "#292A32",
            verified_tag: "#A7ABC3",
            star: "#FFB400",
            divider: "#D3D5E1",
            stroke: "#D3D5E0",
            card_background: "#FFFFFF",
            widget_background: "#FFFFFF",
        },
    },
};

const ColorSchema = ({
    t,
    open,
    handleToggle,
    carouselDisplay,
    setCarouselDisplay,
    setSessionColor,
    disabled,
}) => {
    const [showModal, setShowModal] = useState(false);

    const handleSelectColorMode = (mode) => {
        document.getElementById(mode).click();
    };

    const handleSelectedColor = (color, item) => {
        if (item) {
            setCarouselDisplay({
                ...carouselDisplay,
                color_customize: {
                    ...carouselDisplay.color_customize,
                    [item]: color,
                },
            });
            setSessionColor((prevState) => ({
                ...prevState,
                [carouselDisplay.color_mode]: {
                    ...prevState[carouselDisplay.color_mode],
                    color_customize: {
                        ...prevState[carouselDisplay.color_mode]
                            .color_customize,
                        [item]: color,
                    },
                },
            }));
        }
    };

    const handleResetToDefaultConfirm = useCallback(() => {
        setCarouselDisplay({
            ...carouselDisplay,
            primary_color:
                defaultColor[carouselDisplay.color_mode].primary_color,
            color_customize: {
                ...carouselDisplay.color_customize,
                ...defaultColor[carouselDisplay.color_mode].color_customize,
            },
        });
        setSessionColor((prevState) => ({
            ...prevState,
            [carouselDisplay.color_mode]: {
                primary_color:
                    defaultColor[carouselDisplay.color_mode].primary_color
                        .color,
                color_customize: {
                    ...prevState[carouselDisplay.color_mode].color_customize,
                    ...defaultColor[carouselDisplay.color_mode].color_customize,
                },
            },
        }));

        setShowModal(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselDisplay.color_mode]);

    return (
        <div className="color-schema">
            <Box padding={0}>
                <div
                    className="widget-setting-collape--title "
                    onClick={() => handleToggle("colorSchema")}
                >
                    <h5
                        className="setting-group--title"
                        style={{ fontWeight: 700 }}
                    >
                        {t("review_testimonial.color_schemes.title", {
                            ns: "widget",
                        })}
                    </h5>
                    {open.colorSchema ? (
                        <Icon source={ChevronDownIcon} />
                    ) : (
                        <Icon source={ChevronRightIcon} />
                    )}
                </div>
                <Collapsible
                    open={open.colorSchema}
                    id="basic-collapsible"
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                >
                    <Box paddingBlock={400}>
                        <InlineStack gap={600} align="center">
                            <BlockStack gap={100} inlineAlign="center">
                                <div
                                    className="color-mode-option--field"
                                    onClick={() => {
                                        if (disabled) return;
                                        handleSelectColorMode("light");
                                    }}
                                >
                                    <ColorModeOptionSkeleton
                                        backgroundColor={"#F7F7F7"}
                                        primaryColor={"#D9D9D9"}
                                        secondaryColor={"#D9D9D9"}
                                    />
                                </div>
                                <RadioButton
                                    name="choose-mode"
                                    id="light"
                                    onChange={() =>
                                        setCarouselDisplay({
                                            ...carouselDisplay,
                                            color_mode: "light",
                                        })
                                    }
                                    label={t("light", {
                                        ns: "translation",
                                    })}
                                    checked={
                                        carouselDisplay.color_mode === "light"
                                    }
                                    disabled={disabled}
                                />
                            </BlockStack>
                            <BlockStack gap={100} inlineAlign="center">
                                <div
                                    className="color-mode-option--field"
                                    onClick={() => {
                                        if (disabled) return;
                                        handleSelectColorMode("dark");
                                    }}
                                >
                                    <ColorModeOptionSkeleton
                                        backgroundColor={"#303030"}
                                        primaryColor={"#D9D9D9"}
                                        secondaryColor={"#D9D9D9"}
                                    />
                                </div>
                                <RadioButton
                                    name="choose-mode"
                                    id="dark"
                                    onChange={() =>
                                        setCarouselDisplay({
                                            ...carouselDisplay,
                                            color_mode: "dark",
                                        })
                                    }
                                    label={t("dark", {
                                        ns: "translation",
                                    })}
                                    checked={
                                        carouselDisplay.color_mode === "dark"
                                    }
                                    disabled={disabled}
                                />
                            </BlockStack>
                            <BlockStack gap={100} inlineAlign="center">
                                <div
                                    className="color-mode-option--field"
                                    onClick={() => {
                                        if (disabled) return;
                                        handleSelectColorMode("custom");
                                    }}
                                >
                                    <ColorModeOptionSkeleton
                                        backgroundColor={"#EAF4FF"}
                                        primaryColor={"#CAE6FF"}
                                        secondaryColor={"#51C0FF"}
                                    />
                                </div>
                                <RadioButton
                                    name="choose-mode"
                                    id="custom"
                                    onChange={() =>
                                        setCarouselDisplay({
                                            ...carouselDisplay,
                                            color_mode: "custom",
                                        })
                                    }
                                    label={t("custom", {
                                        ns: "translation",
                                    })}
                                    checked={
                                        carouselDisplay.color_mode === "custom"
                                    }
                                    disabled={disabled}
                                />
                            </BlockStack>
                            <div className="mobile-hidden">
                                <Divider />
                            </div>
                        </InlineStack>
                    </Box>
                    <Collapsible
                        open={true}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                    >
                        <Box paddingBlockStart={200}>
                            <div className="color-picker--detail">
                                <BlockStack gap={{ xl: 400, lg: 200 }}>
                                    <InlineGrid
                                        columns={{
                                            xs: "1fr",
                                            sm: "5fr 5fr",
                                            md: "5fr 5fr",
                                            lg: "1fr",
                                            xl: "5fr 5fr",
                                        }}
                                        gap={300}
                                    >
                                        <ColorPicker
                                            color={
                                                carouselDisplay.color_customize
                                                    .widget_background
                                            }
                                            label={t(
                                                "review_testimonial.color_schemes.widget_background",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"widget_background"}
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />
                                        <ColorPicker
                                            label={t(
                                                "review_testimonial.color_schemes.headline",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"headline"}
                                            color={
                                                carouselDisplay.color_customize
                                                    .headline
                                            }
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />
                                    </InlineGrid>

                                    <InlineGrid
                                        columns={{
                                            xs: "1fr",
                                            sm: "5fr 5fr",
                                            md: "5fr 5fr",
                                            lg: "1fr",
                                            xl: "5fr 5fr",
                                        }}
                                        gap={200}
                                        blockAlign="center"
                                    >
                                        <ColorPicker
                                            color={
                                                carouselDisplay.color_customize
                                                    .content
                                            }
                                            label={t(
                                                "review_testimonial.color_schemes.content",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"content"}
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />

                                        <ColorPicker
                                            color={
                                                carouselDisplay.color_customize
                                                    .customer_name
                                            }
                                            label={t(
                                                "review_testimonial.color_schemes.customer_name",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"customer_name"}
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />
                                    </InlineGrid>
                                    <InlineGrid
                                        columns={{
                                            xs: "1fr",
                                            sm: "5fr 5fr",
                                            md: "5fr 5fr",
                                            lg: "1fr",
                                            xl: "5fr 5fr",
                                        }}
                                        gap={200}
                                    >
                                        <ColorPicker
                                            color={
                                                carouselDisplay.color_customize
                                                    .star
                                            }
                                            label={t(
                                                "review_testimonial.color_schemes.star",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"star"}
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />
                                        <ColorPicker
                                            label={t(
                                                "review_testimonial.color_schemes.navigator_button",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"navigator_button"}
                                            color={
                                                carouselDisplay.color_customize
                                                    .navigator_button
                                            }
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />
                                    </InlineGrid>
                                    <InlineGrid
                                        columns={{
                                            xs: "1fr",
                                            sm: "5fr 5fr",
                                            md: "5fr 5fr",
                                            lg: "1fr",
                                            xl: "5fr 5fr",
                                        }}
                                        gap={200}
                                        blockAlign="center"
                                    >
                                        <ColorPicker
                                            color={
                                                carouselDisplay.color_customize
                                                    .navigator_arrow
                                            }
                                            label={t(
                                                "review_testimonial.color_schemes.navigator_arrow",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"navigator_arrow"}
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />
                                        <ColorPicker
                                            color={
                                                carouselDisplay.color_customize
                                                    .stroke
                                            }
                                            label={t(
                                                "review_testimonial.color_schemes.border",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"stroke"}
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />
                                    </InlineGrid>
                                    <InlineGrid
                                        columns={{
                                            xs: "1fr",
                                            sm: "5fr 5fr",
                                            md: "5fr 5fr",
                                            lg: "1fr",
                                            xl: "5fr 5fr",
                                        }}
                                        gap={200}
                                        blockAlign="center"
                                    >
                                        <ColorPicker
                                            color={
                                                carouselDisplay.color_customize
                                                    .card_background
                                            }
                                            label={t(
                                                "review_testimonial.color_schemes.card_background",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            item={"card_background"}
                                            handleSelectedColor={
                                                handleSelectedColor
                                            }
                                            disabled={disabled}
                                        />
                                    </InlineGrid>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            padding: "20px 0 26px",
                                        }}
                                    >
                                        <Button
                                            onClick={()=>setShowModal(true)}
                                            disabled={disabled}
                                        >
                                            {t("reset_to_default", {
                                                ns: "translation",
                                            })}
                                        </Button>
                                    </div>
                                </BlockStack>
                            </div>
                        </Box>
                    </Collapsible>
                </Collapsible>
            </Box>
            {showModal && (
                <>
                    <CustomModalMessage
                        title={t(
                            "review_display.color_schema.reset_to_default_title",
                            { ns: "widget" }
                        )}
                        content={t(
                            "review_display.color_schema.reset_to_default_content",
                            { ns: "widget" }
                        )}
                        closeModalAction={() => setShowModal(false)}
                        primaryButton={{
                            content: t("reset_to_default", {
                                ns: "translation",
                            }),
                            action: handleResetToDefaultConfirm,
                        }}
                        secondaryButton={{
                            content: t("cancel", { ns: "translation" }),
                            action: ()=>setShowModal(false),
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default ColorSchema;
