import { useEffect, useState } from "react";
import Http from "../../layouts/Http";
import * as Config from "../../api/Config";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as ActionTypes from "../../store/action-types";

const useEffectDashboard = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(["message"]);
    const shopInfo = useSelector((state) => state.Auth.shopInfo);
    const [loading, setLoading] = useState(false);
    const [dashboard, setDashboard] = useState({
        total_review: 0,
        avg_review: 0.0,
        pending_review: 0,
        approved_review: 0,
        rejected_review: 0,
        total_mail: 0,
        success_mail: 0,
        scheduled_mail: 0,
        canceled_mail: 0,
        prev_period: {
            total_review: 0,
            avg_review: 0,
            pending_review: 0,
            approved_review: 0,
            rejected_review: 0,
            total_mail: 0,
            success_mail: 0,
            scheduled_mail: 0,
            canceled_mail: 0,
        },
    });

    const [dashboardAction, setDashboardAction] = useState({
        close_vote: window.localStorage.getItem("trustshop_rate_us_popup"),
        date: "all_time",
        
    });

    const [addScriptButton, setAddScriptButton] = useState({
        app_embed: {
            loadLink: false,
            verifyLoading: false,
            status: true,
            verify: false,
            timeout: false,
            checkByTabSuccess: false,
            value: t("add_app_embedded", { ns: "translation" }),
        },
        widget_box: {
            loadLink: false,
            verifyLoading: false,
            status: true,
            verify: false,
            timeout: false,
            checkByTabSuccess: false,
            value: t("add_widget", { ns: "translation" }),
        },
    });

    const getDashboard = () => {
        setLoading(true);
        Http.get(`${Config.API_URL}/app/dashboard`)
            .then((res) => {
                if (res.status === 200) {
                    setDashboard(res.data.data);
                    setLoading(false);
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
                window.shopify.toast.show(t("toast.server_error"), {
                    duration: 3000,
                });
            });
    };


    const handleAddBlockV1 = () => {
        window.open(
            `https://${shopInfo.domain}/admin/themes/current/?key=templates%2Fproduct.liquid`,
            "_blank"
        );
    };

    const handleRecheckByTabVisible = (block) => {
        const script = block === "app-embed" ? "app_embed" : "widget_box";
        Http.get(
            `${Config.API_URL}/app/theme-app-extension/check?appExtensionId=${Config.THEME_APP_EXTENSION_ID}&addAppBlockId=${block}`
        )
            .then((res) => {
                if (res.data.status) {
                    setAddScriptButton({
                        ...addScriptButton,
                        [script]: {
                            ...addScriptButton[script],
                            verifyLoading: false,
                            status: true,
                            verify: true,
                            timeout: false,
                            value: t(
                                `${
                                    script === "app_embed" ? "step_2" : "step_3"
                                }.quick_guide.success_btn`,
                                {
                                    ns: "onboarding",
                                }
                            ),
                            checkByTabSuccess: true,
                        },
                    });

                    dispatch({
                        type: ActionTypes.UPDATE_SHOP_INFO,
                        payload: {
                            widget: {
                                ...shopInfo.widget,
                                widget_box: res.data.status,
                            },
                        },
                    });

                    if (script === "app_embed") {
                        dispatch({
                            type: ActionTypes.UPDATE_SHOP_INFO,
                            payload: {
                                widget: {
                                    ...shopInfo.widget,
                                    app_embed: res.data.status,
                                },
                            },
                        });
                        window.shopify.toast.show(
                            t(`toast.onboarding.enable_app_embed`),
                            {
                                ns: "message",
                            }
                        );
                    } else {
                        window.shopify.toast.show(
                            t("toast.onboarding.add_app_block_success"),
                            { ns: "message" }
                        );
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleAddScript = (block) => {
        const url =
            block === "step_2"
                ? `https://${shopInfo.domain}/admin/themes/current/editor?context=apps&activateAppId=${Config.THEME_APP_EXTENSION_ID}/review-embed`
                : `https://${shopInfo.domain}/admin/themes/current/editor?template=product&addAppBlockId=${Config.THEME_APP_EXTENSION_ID}/widget-box&target=newAppsSection`;
        let count = 0;
        const script = block === "step_2" ? "app_embed" : "widget_box";
        const buttonText =
            block === "step_2" ? "add_app_embedded" : "add_widget";
        const handleCheck = () => {
            if (addScriptButton[script].checkByTabSuccess) {
                clearInterval(addScriptButton[script].interval);
                return;
            } else {
                setAddScriptButton({
                    ...addScriptButton,
                    [script]: {
                        ...addScriptButton[script],
                        value: t("verifying", { ns: "translation" }),
                        verifyLoading: true,
                        timeout: false,
                    },
                });
                count += 1;
                Http.get(
                    `${
                        Config.API_URL
                    }/app/theme-app-extension/check?appExtensionId=${
                        Config.THEME_APP_EXTENSION_ID
                    }&addAppBlockId=${
                        block === "step_2" ? "app-embed" : "widget-box"
                    }`
                )
                    .then((res) => {
                        if (res.data.status) {
                            setAddScriptButton({
                                ...addScriptButton,
                                [script]: {
                                    ...addScriptButton[script],
                                    verifyLoading: false,
                                    status: true,
                                    verify: true,
                                    timeout: false,
                                    value: t(
                                        `${block}.quick_guide.success_btn`,
                                        {
                                            ns: "onboarding",
                                        }
                                    ),
                                },
                            });
                            dispatch({
                                type: ActionTypes.UPDATE_SHOP_INFO,
                                payload: {
                                    widget: {
                                        ...shopInfo.widget,
                                        widget_box: res.data.status,
                                    },
                                },
                            });
                            if (script === "app_embed") {
                                dispatch({
                                    type: ActionTypes.UPDATE_SHOP_INFO,
                                    payload: {
                                        widget: {
                                            ...shopInfo.widget,
                                            app_embed: res.data.status,
                                        },
                                    },
                                });
                                window.shopify.toast.show(
                                    t(`toast.onboarding.enable_app_embed`),
                                    {
                                        ns: "message",
                                    }
                                );
                            } else {
                                window.shopify.toast.show(
                                    t("toast.onboarding.add_app_block_success"),
                                    { ns: "message" }
                                );
                            }
                            clearInterval(verify);
                        }
                    })
                    .catch((e) => {
                        clearInterval(verify);
                        console.log(e);
                        document.getElementById("modal-error").show();
                        setAddScriptButton({
                            ...addScriptButton,
                            [script]: {
                                ...addScriptButton[script],
                                verifyLoading: false,
                                status: false,
                                timeout: false,
                                value: t(`${block}.quick_guide.recheck_btn`, {
                                    ns: "onboarding",
                                }),
                            },
                        });
                    });
                if (count >= 5) {
                    clearInterval(verify);
                    count = 0;
                    setAddScriptButton({
                        ...addScriptButton,
                        [script]: {
                            ...addScriptButton[block],
                            verifyLoading: false,
                            status: true,
                            timeout: true,
                            value: t(`${buttonText}`, {
                                ns: "translation",
                            }),
                        },
                    });
                }
            }
        };
        window.open(url, "_blank");
        setTimeout(() => {
            handleCheck();
        }, 1000);

        const verify = setInterval(() => {
            handleCheck();
        }, 5000);
    };

    const handleAddBlockManual = (type, block, theme) => {
        Http.post(`${Config.API_URL}/app/widget/add`, {
            block: block,
        })
            .then((res) => {
                if (res.data.status) {
                    if (type === "widget-box") {
                        dispatch({
                            type: ActionTypes.UPDATE_SHOP_INFO,
                            payload: {
                                widget: {
                                    ...shopInfo.widget,
                                    widget_box: res.data.status,
                                },
                            },
                        });
                    } else {
                        dispatch({
                            type: ActionTypes.UPDATE_SHOP_INFO,
                            payload: {
                                widget: {
                                    ...shopInfo.widget,
                                    widget_box: res.data.status,
                                },
                            },
                        });
                    }
                    if (type === "app-embed") {
                        dispatch({
                            type: ActionTypes.UPDATE_SHOP_INFO,
                            payload: {
                                widget: {
                                    ...shopInfo.widget,
                                    app_embed: res.data.status,
                                },
                            },
                        });
                    }

                    window.shopify.toast.show(
                        t("toast.add_widget_v1_confirm"),
                        { ns: "message" }
                    );
                }
            })
            .catch((e) => {
                console.log(e);
                window.shopify.toast.show(t("toast.server_error"), {
                    ns: "message",
                });
            });
    };

    useEffect(() => {
        getDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        dashboard,
        dashboardAction,
        setDashboardAction,
        loading,
        addScriptButton,
        handleAddScript,
        handleRecheckByTabVisible,
        // currentTheme,
        shopInfo,
        handleAddBlockV1,
        handleAddBlockManual,
    };
};

export default useEffectDashboard;
