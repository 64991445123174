import { BlockStack, InlineStack, Checkbox } from "@shopify/polaris";
import { useEffect, useState } from "react";
const Summary = ({ t, reviewDisplay, setReviewDisplay, disabled }) => {
    const [allSummary, setAllSummary] = useState(true);
    useEffect(()=>{
        setReviewDisplay({
            ...reviewDisplay,
            section_show:{
                ...reviewDisplay.section_show,
                summary_box:allSummary,
                summary_star_icon: allSummary,
                summary_number_of_reviews: allSummary,
            }
        })
    },[allSummary])
    return (
        <div className="setting-component">
            <BlockStack gap={300}>
                <BlockStack gap={200}>
                    <Checkbox
                        label={
                            <p className={`component-title `}>
                                {t(
                                    "review_display.information_display.summary",
                                    {
                                        ns: "widget",
                                    }
                                )}
                            </p>
                        }
                        checked={allSummary}
                        onChange={(value)=>setAllSummary(value)}
                        disabled={disabled}
                    />

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="summary_box"
                            checked={reviewDisplay.section_show.summary_box}
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_box: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show.summary_box
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t("review_onepage.review_display.summary_box", {ns: "widget"})}
                                </p>
                            }
                            disabled={disabled}
                        />
                    </InlineStack>

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="summary_star_icon"
                            checked={
                                reviewDisplay.section_show.summary_star_icon
                            }
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_star_icon: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .summary_star_icon
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.color_schema.star_icon",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                            disabled={disabled}
                        />
                    </InlineStack>

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="number_of_review"
                            checked={
                                reviewDisplay.section_show
                                    .summary_number_of_reviews
                            }
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_number_of_reviews: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .summary_number_of_reviews
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.number_of_review",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                            disabled={disabled}
                        />
                    </InlineStack>
                </BlockStack>
                <BlockStack gap={200}>
                    <p className={`component-title `}>
                        {t("review_display.information_display.image_gallery", {
                            ns: "widget",
                        })}
                    </p>

                    <Checkbox
                        type="checkbox"
                        id="show_review_media"
                        checked={reviewDisplay.section_show.show_review_media}
                        onChange={(value) => {
                            setReviewDisplay({
                                ...reviewDisplay,
                                section_show: {
                                    ...reviewDisplay.section_show,
                                    show_review_media: value,
                                },
                            });
                        }}
                        label={
                            <p
                                className={`component-item-label  ${
                                    reviewDisplay.section_show.show_review_media
                                        ? ""
                                        : "subdued-text"
                                }`}
                            >
                                {t(
                                    "review_display.information_display.show_review_media",
                                    {
                                        ns: "widget",
                                    }
                                )}
                            </p>
                        }
                        disabled={disabled}
                    />

                    {reviewDisplay.section_show.show_review_media && (
                        <Checkbox
                            type="checkbox"
                            id="image_galley_star_rating"
                            checked={
                                reviewDisplay.section_show
                                    .image_galley_star_rating
                            }
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        image_galley_star_rating: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .image_galley_star_rating
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.star_rating",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                            disabled={disabled}
                        />
                    )}
                </BlockStack>
            </BlockStack>
        </div>
    );
};

export default Summary;
