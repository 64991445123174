import {
    BlockStack,
    Box,
    Button,
    Checkbox,
    Collapsible,
    Icon,
    InlineGrid,
    InlineStack,
    RadioButton,
    TextField,
    Tooltip,
    Text,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import {
    AlertCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    InfoIcon,
    XIcon,
} from "@shopify/polaris-icons";
import ModalChooseManual from "./ModalChooseManual.js";
import ColorSchema from "./ColorPicker/ColorSchema.js";
import AddAppBlockSnippet from "../../../../components/Widget/AddAppBlockSnippet.js";
import { useNavigate } from "react-router-dom";
import FontSettingComponent from "../../../../components/Widget/FontSettingComponent.js";
import listFunction from "../../../../config/pricingListFunction.js";
import WidgetLimitNotice from "../../../../components/Pricing/WidgetLimitNotice.js";

const SettingCarousel = ({
    t,
    carouselDisplay,
    setCarouselDisplay,
    getListReview,
    open,
    setOpen,
    reviews,
    initCarouselDisplay,
    setSessionColor,
    filter,
    setFilter,
    isLoadReview,
    validTime,
    handleCheckValidTime,
    shopInfo,
}) => {
    const [active, setActive] = useState(false);
    const navigate = useNavigate();
    const [mode, setMode] = useState(
        carouselDisplay.mode === "auto" ? false : true
    );
    const [slideMovement, setSlideMovement] = useState(
        carouselDisplay.slide_movement === "auto" ? false : true
    );

    useEffect(() => {
        if (active) {
            getListReview();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    const handleToggle = (name) => {
        setOpen({
            ...open,
            [name]: !open[name],
        });
    };

    const handleShowModal = useCallback(() => {
        setActive(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setActive(false);
    }, []);

    const handleCheckMode = () => {
        setMode((prevMode) => !prevMode);
        setCarouselDisplay({
            ...carouselDisplay,
            mode: mode ? "manual" : "auto",
        });
    };

    const handleCheckSlideMovement = () => {
        setSlideMovement((prevSlideMovement) => !prevSlideMovement);
        setCarouselDisplay({
            ...carouselDisplay,
            slide_movement: slideMovement ? "auto" : "manual",
        });
    };
    return (
        <div
            style={{
                height: "100%",
                maxHeight: "1080px",
                paddingBottom: "20px",
                overflow: "scroll",
                scrollbarWidth: "none",
                borderRight: "1px solid #ebecee",
                backgroundColor: "#FFFFFF"
            }}
            className={`widget-setting--field `}
        >
            <div>
                <Box paddingInlineEnd={400}>
                    {shopInfo?.current_plan === "free" && (
                        <WidgetLimitNotice
                            content={t("limit_function.widget_limit", {
                                ns: "widget",
                            })}
                            btnContent={t("upgrade_plan", {
                                ns: "translation",
                            })}
                        />
                    )}
                </Box>
                <div
                    className={`${
                        !listFunction?.review_testimonial[
                            shopInfo?.current_plan
                        ]
                            ? "limit-function"
                            : ""
                    }`}
                >
                    <Box paddingInline={400} paddingBlockEnd={400}>
                        <Box>
                            <AddAppBlockSnippet
                                t={t}
                                block={"testimonial"}
                                collection={false}
                                disabled={
                                    !listFunction.review_testimonial[
                                        shopInfo?.current_plan
                                    ]
                                }
                            />
                        </Box>
                        <div
                            className="widget-setting-collape--title"
                            onClick={() => handleToggle("choose_review")}
                        >
                            <h5
                                className="setting-group--title"
                                style={{ fontWeight: 700 }}
                            >
                                {t("review_testimonial.choose_review.title", {
                                    ns: "widget",
                                })}
                            </h5>
                            {!open.choose_review ? (
                                <Icon source={ChevronRightIcon} />
                            ) : (
                                <Icon source={ChevronDownIcon} />
                            )}
                        </div>
                        <Collapsible
                            open={open.choose_review}
                            id="basic-collapsible"
                            transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                        >
                            <div className="review-carousel-setting--item">
                                <InlineGrid gap={300} columns={1}>
                                    <div>
                                        <BlockStack gap={200}>
                                            {carouselDisplay.review_ids.length <
                                                3 ||
                                            carouselDisplay.number_of_reviews <
                                                3 ? (
                                                <div className="warning-zone">
                                                    <div className="warning-icon">
                                                        <svg
                                                            width="28"
                                                            height="28"
                                                            viewBox="0 0 28 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <rect
                                                                width="28"
                                                                height="28"
                                                                rx="8"
                                                                fill="#FFB800"
                                                            />
                                                            <path
                                                                d="M14 10.75C14.4142 10.75 14.75 11.0858 14.75 11.5V15C14.75 15.4142 14.4142 15.75 14 15.75C13.5858 15.75 13.25 15.4142 13.25 15V11.5C13.25 11.0858 13.5858 10.75 14 10.75Z"
                                                                fill="#251A00"
                                                            />
                                                            <path
                                                                d="M15 17.5C15 18.0523 14.5523 18.5 14 18.5C13.4477 18.5 13 18.0523 13 17.5C13 16.9477 13.4477 16.5 14 16.5C14.5523 16.5 15 16.9477 15 17.5Z"
                                                                fill="#251A00"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14.0001 7.5C12.9547 7.5 12.2162 8.20232 11.8477 8.94672C11.6557 9.33448 10.7599 11.0441 9.84308 12.7935L9.81534 12.8465C8.92293 14.5493 8.01717 16.2777 7.80719 16.702C7.43513 17.4537 7.32934 18.4519 7.89956 19.316C8.46914 20.1791 9.44225 20.5 10.3636 20.5L17.6364 20.5C18.5577 20.5 19.5309 20.1791 20.1005 19.316C20.6707 18.4519 20.5649 17.4537 20.1928 16.702C19.9832 16.2784 19.08 14.555 18.189 12.8548L18.1569 12.7935C17.24 11.044 16.3443 9.33454 16.1525 8.94693C15.784 8.20242 15.0455 7.5 14.0001 7.5ZM13.1919 9.61225C13.5961 8.79592 14.4041 8.79592 14.8081 9.61225C15.0102 10.0206 15.9201 11.7569 16.8297 13.4924C17.7383 15.2262 18.6466 16.9594 18.8485 17.3673C19.2525 18.1837 18.8485 19 17.6364 19L10.3636 19C9.15153 19 8.74749 18.1837 9.15153 17.3673C9.35379 16.9587 10.2647 15.2205 11.1748 13.4838C12.0829 11.7511 12.9901 10.0199 13.1919 9.61225Z"
                                                                fill="#251A00"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="warning-text">
                                                        {t(
                                                            "review_testimonial.choose_review.warning",
                                                            {
                                                                ns: "widget",
                                                            }
                                                        )}
                                                        <span>. Setup </span>
                                                        <a
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (
                                                                    !listFunction
                                                                        .review_testimonial[
                                                                        shopInfo
                                                                            ?.current_plan
                                                                    ]
                                                                )
                                                                    return;
                                                                navigate(
                                                                    "/email-review/campaign"
                                                                );
                                                            }}
                                                        >
                                                            {t(
                                                                "review_testimonial.choose_review.warning_2",
                                                                {
                                                                    ns: "widget",
                                                                }
                                                            )}
                                                        </a>
                                                        <span> or </span>
                                                        <a
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (
                                                                    !listFunction
                                                                        .review_testimonial[
                                                                        shopInfo
                                                                            ?.current_plan
                                                                    ]
                                                                )
                                                                    return;
                                                                navigate(
                                                                    "/review-management"
                                                                );
                                                            }}
                                                        >
                                                            {t(
                                                                "review_testimonial.choose_review.warning_3",
                                                                {
                                                                    ns: "widget",
                                                                }
                                                            )}
                                                        </a>
                                                    </div>
                                                    <div className="warning-close">
                                                        <p
                                                            onClick={() => {
                                                                document.querySelector(
                                                                    ".warning-zone"
                                                                ).style.display =
                                                                    "none";
                                                            }}
                                                        >
                                                            <Icon
                                                                source={XIcon}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <InlineStack
                                                gap={200}
                                                blockAlign="center"
                                                wrap={false}
                                            >
                                                <RadioButton
                                                    value={mode}
                                                    label={
                                                        <>
                                                            <InlineStack
                                                                align="start"
                                                                gap={100}
                                                                wrap={false}
                                                            >
                                                                <p>
                                                                    {t(
                                                                        "review_testimonial.choose_review.auto_new_5",
                                                                        {
                                                                            ns: "widget",
                                                                        }
                                                                    )}
                                                                </p>
                                                            </InlineStack>
                                                        </>
                                                    }
                                                    checked={
                                                        carouselDisplay.mode ===
                                                        "auto"
                                                    }
                                                    onChange={(checked) => {
                                                        if (checked) {
                                                            handleCheckMode(
                                                                "auto"
                                                            );
                                                            setCarouselDisplay({
                                                                ...carouselDisplay,
                                                                mode: "auto",
                                                            });
                                                        }
                                                    }}
                                                    disabled={
                                                        !listFunction
                                                            .review_testimonial[
                                                            shopInfo
                                                                ?.current_plan
                                                        ]
                                                    }
                                                />
                                                <Tooltip
                                                    width="medium"
                                                    content={t(
                                                        "review_testimonial.choose_review.auto_helptext",
                                                        {
                                                            ns: "widget",
                                                        }
                                                    )}
                                                >
                                                    <Icon source={InfoIcon} />
                                                </Tooltip>
                                            </InlineStack>

                                            <BlockStack>
                                                <TextField
                                                    type="number"
                                                    label={t(
                                                        "review_testimonial.choose_review.number_of_reviews",
                                                        {
                                                            ns: "widget",
                                                        }
                                                    )}
                                                    min={0}
                                                    value={
                                                        carouselDisplay
                                                            .review_ids.length >
                                                        0
                                                            ? carouselDisplay
                                                                  .review_ids
                                                                  .length
                                                            : carouselDisplay.number_of_reviews
                                                    }
                                                    disabled={
                                                        carouselDisplay.mode ===
                                                            "manual" ||
                                                        !listFunction
                                                            .review_testimonial[
                                                            shopInfo
                                                                ?.current_plan
                                                        ]
                                                    }
                                                    onChange={(value) => {
                                                        setCarouselDisplay({
                                                            ...carouselDisplay,
                                                            number_of_reviews:
                                                                value,
                                                        });
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </BlockStack>
                                            <InlineStack
                                                gap={100}
                                                blockAlign="center"
                                            >
                                                <RadioButton
                                                    value={mode}
                                                    label={t(
                                                        "review_testimonial.choose_review.choose_manually",
                                                        {
                                                            ns: "widget",
                                                        }
                                                    )}
                                                    checked={
                                                        carouselDisplay.mode ===
                                                        "manual"
                                                    }
                                                    onChange={(checked) => {
                                                        if (checked) {
                                                            handleCheckMode(
                                                                "manual"
                                                            );
                                                            setCarouselDisplay({
                                                                ...carouselDisplay,
                                                                mode: "manual",
                                                            });
                                                        }
                                                    }}
                                                    disabled={
                                                        !listFunction
                                                            .review_testimonial[
                                                            shopInfo
                                                                ?.current_plan
                                                        ]
                                                    }
                                                />
                                                <Button
                                                    onClick={() => {
                                                        if (
                                                            !listFunction
                                                                .review_testimonial[
                                                                shopInfo
                                                                    ?.current_plan
                                                            ]
                                                        )
                                                            return;
                                                        setMode(!mode);
                                                        handleShowModal();
                                                        setCarouselDisplay({
                                                            ...carouselDisplay,
                                                            mode: "manual",
                                                        });
                                                    }}
                                                    variant="plain"
                                                    disabled={
                                                        !listFunction
                                                            .review_testimonial[
                                                            shopInfo
                                                                ?.current_plan
                                                        ]
                                                    }
                                                >
                                                    {t(
                                                        "review_testimonial.choose_review.choose_manually_button",
                                                        {
                                                            ns: "widget",
                                                        }
                                                    )}
                                                </Button>
                                                {active &&
                                                listFunction.review_testimonial[
                                                    shopInfo?.current_plan
                                                ] ? (
                                                    <ModalChooseManual
                                                        t={t}
                                                        active={active}
                                                        reviews={reviews}
                                                        handleCloseModal={
                                                            handleCloseModal
                                                        }
                                                        carouselDisplay={
                                                            carouselDisplay
                                                        }
                                                        setCarouselDisplay={
                                                            setCarouselDisplay
                                                        }
                                                        getListReview={
                                                            getListReview
                                                        }
                                                        filter={filter}
                                                        setFilter={setFilter}
                                                        isLoadReview={
                                                            isLoadReview
                                                        }
                                                    />
                                                ) : null}
                                            </InlineStack>
                                            <Text>
                                                {`${
                                                    carouselDisplay.review_ids
                                                        .length
                                                } 
                                                                review ${
                                                                    carouselDisplay
                                                                        .review_ids
                                                                        .length >=
                                                                    2
                                                                        ? "s"
                                                                        : ""
                                                                }
                                                                ${t(
                                                                    "review_testimonial.choose_review.selected",
                                                                    {
                                                                        ns: "widget",
                                                                    }
                                                                )}
                                                                `}
                                            </Text>
                                        </BlockStack>
                                    </div>
                                </InlineGrid>
                            </div>
                        </Collapsible>

                        <div
                            className="widget-setting-collape--title"
                            onClick={() => handleToggle("title")}
                            style={{ paddingRight: 0 }}
                        >
                            <h5
                                className="setting-group--title"
                                style={{ fontWeight: 700 }}
                            >
                                {t("review_testimonial.title.title", {
                                    ns: "widget",
                                })}
                            </h5>
                            {!open.title ? (
                                <Icon source={ChevronRightIcon} />
                            ) : (
                                <Icon source={ChevronDownIcon} />
                            )}
                        </div>
                        <Collapsible
                            open={open.title}
                            id="basic-collapsible"
                            transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                        >
                            <div className="review-carousel-setting--item">
                                <InlineGrid gap={300} columns={1}>
                                    <BlockStack gap={200}>
                                        <Checkbox
                                            type="checkbox"
                                            checked={
                                                carouselDisplay.title_label
                                                    .is_show !== null
                                                    ? carouselDisplay
                                                          .title_label.is_show
                                                    : true
                                            }
                                            label={t(
                                                "review_testimonial.title.title",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            onChange={(checked) => {
                                                setCarouselDisplay({
                                                    ...carouselDisplay,
                                                    title_label: {
                                                        ...carouselDisplay.title_label,
                                                        is_show: checked,
                                                    },
                                                });
                                            }}
                                            disabled={
                                                !listFunction
                                                    .review_testimonial[
                                                    shopInfo?.current_plan
                                                ]
                                            }
                                        />

                                        <InlineStack
                                            gap={200}
                                            align="space-between"
                                            blockAlign="center"
                                            wrap={false}
                                        >
                                            <Text>
                                                {t("font_size", {
                                                    ns: "translation",
                                                })}{" "}
                                                (
                                                {t("pixels", {
                                                    ns: "translation",
                                                })}
                                                )
                                            </Text>
                                            <Box width="70px">
                                                <TextField
                                                    value={
                                                        carouselDisplay
                                                            .title_label.style
                                                            .font_size
                                                    }
                                                    type="number"
                                                    onChange={(value) => {
                                                        setCarouselDisplay({
                                                            ...carouselDisplay,
                                                            title_label: {
                                                                ...carouselDisplay.title_label,
                                                                style: {
                                                                    ...carouselDisplay
                                                                        .title_label
                                                                        .style,
                                                                    font_size:
                                                                        value,
                                                                },
                                                            },
                                                        });
                                                    }}
                                                    disabled={
                                                        !carouselDisplay
                                                            .title_label
                                                            .is_show ||
                                                        !listFunction
                                                            .review_testimonial[
                                                            shopInfo
                                                                ?.current_plan
                                                        ]
                                                    }
                                                />
                                            </Box>
                                        </InlineStack>

                                        <BlockStack justify="space-between">
                                            <TextField
                                                value={
                                                    carouselDisplay.title_label
                                                        .label
                                                }
                                                label={
                                                    <InlineStack
                                                        gap={200}
                                                        wrap={false}
                                                        blockAlign="center"
                                                        align="center"
                                                    >
                                                        <Text>
                                                            {t(
                                                                "review_testimonial.title.title_label",
                                                                {
                                                                    ns: "widget",
                                                                }
                                                            )}
                                                        </Text>
                                                        <Box
                                                            borderRadius="050"
                                                            background="bg-surface-active"
                                                            padding={100}
                                                        >
                                                            <FontSettingComponent
                                                                style={
                                                                    carouselDisplay
                                                                        .title_label
                                                                        .style
                                                                }
                                                                onChange={(
                                                                    key,
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        !listFunction
                                                                            .review_testimonial[
                                                                            shopInfo
                                                                                ?.current_plan
                                                                        ]
                                                                    )
                                                                        return;
                                                                    setCarouselDisplay(
                                                                        {
                                                                            ...carouselDisplay,
                                                                            title_label:
                                                                                {
                                                                                    ...carouselDisplay.title_label,
                                                                                    style: {
                                                                                        ...carouselDisplay
                                                                                            .title_label
                                                                                            .style,
                                                                                        [key]: value,
                                                                                    },
                                                                                },
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </InlineStack>
                                                }
                                                onChange={(value) => {
                                                    setCarouselDisplay({
                                                        ...carouselDisplay,
                                                        title_label: {
                                                            ...carouselDisplay.title_label,
                                                            label: value,
                                                        },
                                                    });
                                                }}
                                                disabled={
                                                    carouselDisplay.title_label
                                                        .is_show === false ||
                                                    !listFunction
                                                        .review_testimonial[
                                                        shopInfo?.current_plan
                                                    ]
                                                }
                                            />
                                        </BlockStack>
                                    </BlockStack>

                                    <BlockStack gap={200}>
                                        <Checkbox
                                            type="checkbox"
                                            checked={
                                                carouselDisplay.subtitle_label
                                                    .is_show
                                            }
                                            label={t("subtitle", {
                                                ns: "translation",
                                            })}
                                            onChange={(checked) => {
                                                setCarouselDisplay({
                                                    ...carouselDisplay,
                                                    subtitle_label: {
                                                        ...carouselDisplay.subtitle_label,
                                                        is_show: checked,
                                                    },
                                                });
                                            }}
                                            disabled={
                                                !listFunction
                                                    .review_testimonial[
                                                    shopInfo?.current_plan
                                                ]
                                            }
                                        />

                                        <InlineStack
                                            gap={200}
                                            align="space-between"
                                            blockAlign="center"
                                            wrap={false}
                                        >
                                            <Text>
                                                {t("font_size", {
                                                    ns: "translation",
                                                })}{" "}
                                                (
                                                {t("pixels", {
                                                    ns: "translation",
                                                })}
                                                )
                                            </Text>
                                            <Box width="70px">
                                                <TextField
                                                    value={
                                                        carouselDisplay
                                                            .subtitle_label
                                                            .style.font_size
                                                    }
                                                    type="number"
                                                    onChange={(value) => {
                                                        setCarouselDisplay({
                                                            ...carouselDisplay,
                                                            subtitle_label: {
                                                                ...carouselDisplay.subtitle_label,
                                                                style: {
                                                                    ...carouselDisplay
                                                                        .subtitle_label
                                                                        .style,
                                                                    font_size:
                                                                        value,
                                                                },
                                                            },
                                                        });
                                                    }}
                                                    disabled={
                                                        !carouselDisplay
                                                            .subtitle_label
                                                            .is_show ||
                                                        !listFunction
                                                            .review_testimonial[
                                                            shopInfo
                                                                ?.current_plan
                                                        ]
                                                    }
                                                />
                                            </Box>
                                        </InlineStack>

                                        <BlockStack justify="space-between">
                                            <TextField
                                                value={
                                                    carouselDisplay
                                                        .subtitle_label.label
                                                }
                                                label={
                                                    <InlineStack
                                                        gap={200}
                                                        wrap={false}
                                                    >
                                                        <Text>
                                                            {t(
                                                                "subtitle_label",
                                                                {
                                                                    ns: "translation",
                                                                }
                                                            )}
                                                        </Text>
                                                        <Box
                                                            borderRadius="050"
                                                            background="bg-surface-active"
                                                            padding={100}
                                                        >
                                                            <FontSettingComponent
                                                                style={
                                                                    carouselDisplay
                                                                        .subtitle_label
                                                                        .style
                                                                }
                                                                onChange={(
                                                                    key,
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        !listFunction
                                                                            .review_testimonial[
                                                                            shopInfo
                                                                                ?.current_plan
                                                                        ]
                                                                    )
                                                                        return;
                                                                    setCarouselDisplay(
                                                                        {
                                                                            ...carouselDisplay,
                                                                            subtitle_label:
                                                                                {
                                                                                    ...carouselDisplay.subtitle_label,
                                                                                    style: {
                                                                                        ...carouselDisplay
                                                                                            .subtitle_label
                                                                                            .style,
                                                                                        [key]: value,
                                                                                    },
                                                                                },
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </InlineStack>
                                                }
                                                onChange={(value) => {
                                                    setCarouselDisplay({
                                                        ...carouselDisplay,
                                                        subtitle_label: {
                                                            ...carouselDisplay.subtitle_label,
                                                            label: value,
                                                        },
                                                    });
                                                }}
                                                disabled={
                                                    carouselDisplay
                                                        .subtitle_label
                                                        .is_show === false ||
                                                    !listFunction
                                                        .review_testimonial[
                                                        shopInfo?.current_plan
                                                    ]
                                                }
                                            />
                                        </BlockStack>
                                    </BlockStack>
                                </InlineGrid>
                            </div>
                        </Collapsible>
                        <div
                            className="widget-setting-collape--title"
                            onClick={() => handleToggle("card_content")}
                            style={{ paddingRight: 0 }}
                        >
                            <h5
                                className="setting-group--title"
                                style={{ fontWeight: 700 }}
                            >
                                {t("review_testimonial.card_content.title", {
                                    ns: "widget",
                                })}
                            </h5>
                            {!open.card_content ? (
                                <Icon source={ChevronRightIcon} />
                            ) : (
                                <Icon source={ChevronDownIcon} />
                            )}
                        </div>
                        <Collapsible
                            open={open.card_content}
                            id="basic-collapsible"
                            transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                        >
                            <div className="review-carousel-setting--item">
                                <InlineGrid
                                    gap={300}
                                    columns={{
                                        xs: "1",
                                        sm: "1",
                                        md: "1",
                                        lg: "1",
                                        xl: "1",
                                    }}
                                >
                                    <InlineStack
                                        justify="space-between"
                                        gap={500}
                                    >
                                        <Checkbox
                                            label={t(
                                                "review_testimonial.card_content.star_rating",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            checked={
                                                carouselDisplay.display_star_rating !==
                                                null
                                                    ? carouselDisplay.display_star_rating
                                                    : initCarouselDisplay.display_star_rating
                                            }
                                            onChange={(checked) => {
                                                setCarouselDisplay({
                                                    ...carouselDisplay,
                                                    display_star_rating:
                                                        checked,
                                                });
                                            }}
                                            disabled={
                                                !listFunction
                                                    .review_testimonial[
                                                    shopInfo?.current_plan
                                                ]
                                            }
                                        />
                                    </InlineStack>
                                </InlineGrid>
                            </div>
                        </Collapsible>

                        <div
                            className="widget-setting-collape--title"
                            onClick={() => handleToggle("movement")}
                            style={{ paddingRight: 0 }}
                        >
                            <h5
                                className="setting-group--title"
                                style={{ fontWeight: 700 }}
                            >
                                {t("review_testimonial.movement.title", {
                                    ns: "widget",
                                })}
                            </h5>
                            {!open.movement ? (
                                <Icon source={ChevronRightIcon} />
                            ) : (
                                <Icon source={ChevronDownIcon} />
                            )}
                        </div>
                        <Collapsible
                            open={open.movement}
                            id="basic-collapsible"
                            transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                        >
                            <div className="review-carousel-setting--item">
                                <InlineGrid
                                    gap={300}
                                    columns={{
                                        xs: "1",
                                        sm: "1",
                                        md: "1",
                                        lg: "1",
                                        xl: "1",
                                    }}
                                >
                                    <BlockStack gap={200}>
                                        <RadioButton
                                            label={t(
                                                "review_testimonial.movement.auto_movement",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            value={slideMovement}
                                            checked={
                                                carouselDisplay.slide_movement ===
                                                "auto"
                                            }
                                            onChange={(checked) => {
                                                if (checked) {
                                                    handleCheckSlideMovement(
                                                        "auto"
                                                    );
                                                    setCarouselDisplay({
                                                        ...carouselDisplay,
                                                        slide_movement: "auto",
                                                    });
                                                }
                                            }}
                                            disabled={
                                                !listFunction
                                                    .review_testimonial[
                                                    shopInfo?.current_plan
                                                ]
                                            }
                                        />
                                        <TextField
                                            type="number"
                                            label={t(
                                                "review_testimonial.movement.time_between_reviews",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            min={1}
                                            max={30}
                                            value={
                                                carouselDisplay.time_between_slide
                                            }
                                            disabled={
                                                carouselDisplay.slide_movement ===
                                                    "arrow" ||
                                                !listFunction
                                                    .review_testimonial[
                                                    shopInfo?.current_plan
                                                ]
                                            }
                                            onChange={(value) => {
                                                setCarouselDisplay({
                                                    ...carouselDisplay,
                                                    time_between_slide: value,
                                                });
                                                handleCheckValidTime(value);
                                            }}
                                            autoComplete="off"
                                            fullWidth
                                            error={validTime}
                                        />
                                        {validTime && (
                                            <span
                                                className="Polaris-InlineError"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginTop: "-15px",
                                                }}
                                            >
                                                <span>
                                                    <Icon tone="critical" />
                                                </span>
                                                {t(
                                                    "review_testimonial.movement.time_between_reviews_error",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </span>
                                        )}
                                        <Checkbox
                                            label={t(
                                                "review_testimonial.movement.show_arrow",
                                                { ns: "widget" }
                                            )}
                                            checked={
                                                carouselDisplay.show_navigator_button
                                            }
                                            onChange={(value) => {
                                                setCarouselDisplay({
                                                    ...carouselDisplay,
                                                    show_navigator_button:
                                                        value,
                                                });
                                            }}
                                            disabled={
                                                carouselDisplay.slide_movement !==
                                                    "auto" ||
                                                !listFunction
                                                    .review_testimonial[
                                                    shopInfo?.current_plan
                                                ]
                                            }
                                        />

                                        <Checkbox
                                            label={
                                                <InlineStack
                                                    wrap={false}
                                                    gap={200}
                                                >
                                                    <Text>
                                                        {t(
                                                            "review_testimonial.movement.pause_carousel_when_hover",
                                                            { ns: "widget" }
                                                        )}
                                                    </Text>
                                                    <Tooltip
                                                        width="default"
                                                        content={t(
                                                            "review_testimonial.movement.pause_carousel_when_hover_helptext",
                                                            { ns: "widget" }
                                                        )}
                                                    >
                                                        <Icon
                                                            source={
                                                                AlertCircleIcon
                                                            }
                                                        />
                                                    </Tooltip>
                                                </InlineStack>
                                            }
                                            checked={
                                                carouselDisplay.pause_when_hover
                                            }
                                            onChange={(value) => {
                                                setCarouselDisplay({
                                                    ...carouselDisplay,
                                                    pause_when_hover: value,
                                                });
                                            }}
                                            disabled={
                                                carouselDisplay.slide_movement !==
                                                    "auto" ||
                                                !listFunction
                                                    .review_testimonial[
                                                    shopInfo?.current_plan
                                                ]
                                            }
                                        />
                                        <RadioButton
                                            label={t(
                                                "review_testimonial.movement.arrow_navigation",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                            value={slideMovement}
                                            checked={
                                                carouselDisplay.slide_movement ===
                                                "arrow"
                                            }
                                            onChange={(checked) => {
                                                if (checked) {
                                                    handleCheckSlideMovement(
                                                        "arrow"
                                                    );
                                                    setCarouselDisplay({
                                                        ...carouselDisplay,
                                                        slide_movement: "arrow",
                                                        show_navigator_button: true,
                                                    });
                                                }
                                            }}
                                            disabled={
                                                !listFunction
                                                    .review_testimonial[
                                                    shopInfo?.current_plan
                                                ]
                                            }
                                        />
                                    </BlockStack>
                                </InlineGrid>
                            </div>
                        </Collapsible>
                        <ColorSchema
                            t={t}
                            open={open}
                            handleToggle={handleToggle}
                            carouselDisplay={carouselDisplay}
                            setCarouselDisplay={setCarouselDisplay}
                            initCarouselDisplay={initCarouselDisplay}
                            setSessionColor={setSessionColor}
                            disabled={
                                !listFunction.review_testimonial[
                                    shopInfo?.current_plan
                                ]
                            }
                        />
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default SettingCarousel;
