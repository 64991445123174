import { InlineStack } from "@shopify/polaris";

const OnePageFontSettingComponent = ({ style, onChange }) => {
    return (
        <InlineStack gap={100} blockAlign="center" wrap={false} align="center">
            <p
                style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    padding: "3px",
                    borderRadius: "2px",
                    backgroundColor: style.includes("bold")
                        ? "var(--p-color-bg-fill)"
                        : "",
                    width: "16px",
                    height: "16px",
                    lineHeight: "100%",
                    cursor: "pointer",
                    textAlign: "center",
                }}
                onClick={() => {
                    onChange("bold");
                }}
            >
                B
            </p>
            <p
                style={{
                    fontSize: "10px",
                    fontStyle: "italic",
                    padding: "3px",
                    borderRadius: "2px",
                    backgroundColor: style.includes("italic")
                        ? "var(--p-color-bg-fill)"
                        : "",
                    width: "16px",
                    height: "16px",
                    lineHeight: "100%",
                    cursor: "pointer",
                    textAlign: "center",
                }}
                onClick={() => {
                    onChange(
                        "italic"
                    );
                }}
            >
                I
            </p>
            <p
                style={{
                    fontSize: "10px",
                    textDecoration: "underline",
                    padding: "3px",
                    borderRadius: "2px",
                    backgroundColor: style.includes("underline")
                        ? "var(--p-color-bg-fill)"
                        : "",
                    width: "16px",
                    height: "16px",
                    lineHeight: "100%",
                    cursor: "pointer",
                    textAlign: "center",
                }}
                onClick={() => {
                    onChange(
                        "underline"
                    );
                }}
            >
                U
            </p>
        </InlineStack>
    );
};

export default OnePageFontSettingComponent;
