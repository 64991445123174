const listFunction = {
    email_quota: {
        label: "email_quota",
        free: 50,
        basic: 200,
        pro: 1500,
        unlimited: "unlimited",
    },
    discount_voucher: {
        label: "discount_voucher",
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },
    customize_widget: {
        label: "customize_widget",
        free: "basic",
        basic: "fully",
        pro: "fully",
        unlimited: "fully",
        detail: {
            widgetBox: {
                label: "Widget box (default)",
                choose_layout: {
                    label: "choose_layout",
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                summary_box: {
                    label: "summary_box",
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                color_scheme: {
                    label: "color_scheme",
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                information_display: {
                    label: "information_display",
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                content: {
                    label: "content",
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            widgetBoxNoReview: {
                label: "Widget box (no reviews)",
                content: {
                    label: "content",
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                color_scheme: {
                    label: "color_scheme",
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            modalReviewDetail: {
                label: "Modal Review Detail",
                all_settings: {
                    label: "all_settings",
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            modal_all_image: {
                label: "modal_all_image",
                all_settings: {
                    label: "all_settings",
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            formWriteReview: {
                label: "Form Write Review",
                review_with_video: {
                    label: "review_with_video",
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
        },
    },
    upload_video: {
        label: "upload_video",
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },
    review_testimonial: {
        label: "review_testimonial",
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },

    review_page: {
        label: "review_page",
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },
    google_shopping: {
        label: "google_shopping",
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },
    global_display: {
        label: "global_display",
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },
    group_product:{
        label: "group_product",
        free: false,
        basic: true,
        pro: true,
        unlimited: true
    },
    review_translation: {
        label: "review_translation",
        free: false,
        basic: false,
        pro: "coming_soon",
        unlimited: "coming_soon",
    },
    ai_functionality: {
        label: "ai_functionality",
        free: false,
        basic: false,
        pro: "coming_soon",
        unlimited: "coming_soon",
    },
    insights_and_segmentation: {
        label: "insights_and_segmentation",
        free: false,
        basic: false,
        pro: "coming_soon",
        unlimited: "coming_soon",
    },
};

export default listFunction;
