const Header = ({ device, reviewDisplay, starIcon, shopInfo }) => {
    return (
        <>
            <div
                className={`widget-header header-${device} style-${reviewDisplay.layout_summary_box}`}
            >
                <div
                    className={`${
                        reviewDisplay.layout_summary_box === "left"
                            ? "header--left"
                            : " "
                    } ${
                        shopInfo?.element_corners === "square"
                            ? "element_corners--square"
                            : ""
                    } summary-box`}
                >
                    <div
                        className={`summarybox-left`}
                        style={{
                            border: !reviewDisplay.section_show.summary_divider
                                ? "none"
                                : "",
                        }}
                    >
                        <div className="overview">
                            <p className="avg-rating">
                                4.5{" "}
                                {reviewDisplay.section_show
                                    .summary_star_icon && (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: starIcon,
                                        }}
                                        className="star-icon--box star-icon"
                                    ></span>
                                )}
                            </p>
                            {reviewDisplay.section_show
                                .summary_number_of_reviews && (
                                <p className="total-review">
                                    273{" "}
                                    {reviewDisplay.label_content.text_reviews}
                                </p>
                            )}
                        </div>
                        <div className="score-list">
                            {[5, 4, 3, 2, 1].map((item, index) => (
                                <div
                                    className="score-item"
                                    key={`score-item--${index}`}
                                >
                                    <span
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            flexWrap: "nowrap",
                                            gap: "2px",
                                            width: "23px",
                                            flexShrink: 0,
                                        }}
                                    >
                                        {item}{" "}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: starIcon,
                                            }}
                                            className="star-icon--box star-icon"
                                        ></span>
                                    </span>
                                    <div className="score-bar">
                                        <div
                                            style={{
                                                height: "100%",
                                                width: `${item * 10}%`,
                                                borderRadius: "5px",
                                                backgroundColor:
                                                    "var(--rating_process_color) ",
                                            }}
                                        ></div>
                                    </div>
                                    {reviewDisplay.section_show
                                        .number_of_reviews ? (
                                        <span
                                            style={{
                                                flexShrink: 0,
                                                width: "28px",
                                            }}
                                        >
                                            {item * 10}%
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {(reviewDisplay.section_show.write_a_review_button ||
                        reviewDisplay.section_show.summary_divider ||
                        reviewDisplay.section_show.summary_text_1 ||
                        reviewDisplay.section_show.summary_text_2) && (
                        <div className="summarybox-right">
                            <div
                                className="write-review-btn--container"
                                style={{
                                    border: !reviewDisplay.section_show
                                        .summary_divider
                                        ? "none"
                                        : "",
                                }}
                            >
                                {reviewDisplay.section_show.summary_text_1 && (
                                    <p className="title">
                                        {
                                            reviewDisplay.label_content
                                                .text_review_this_product
                                        }
                                    </p>
                                )}
                                {reviewDisplay.section_show.summary_text_2 && (
                                    <p className="sub-title">
                                        {
                                            reviewDisplay.label_content
                                                .text_share_your_thoughts
                                        }
                                    </p>
                                )}
                                {reviewDisplay.section_show
                                    .write_a_review_button && (
                                    <button
                                        className={`write-review-btn ${
                                            shopInfo?.button_corner_radius
                                                ? "button_corner_radius"
                                                : ""
                                        }`}
                                        style={{ border: "none" }}
                                    >
                                        {
                                            reviewDisplay.label_content
                                                .btn_write_a_review
                                        }
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Header;
