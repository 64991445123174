const GenerateAvt = ({
    reviewerAvtType = "colorful",
    showAvt = true,
    shortName = "CN",
    singleColor = "#36A4E2",
    imgSrc,
}) => {
    return (
        <>
            {reviewerAvtType === "single_color" ? (
                <div
                    className={`review-item--avt ${
                        showAvt ? "" : "hidden-element"
                    }`}
                    style={{
                        backgroundColor: `${
                            singleColor ? singleColor : "#36A4E2"
                        }`,
                    }}
                >
                    <p>{shortName}</p>
                </div>
            ) : reviewerAvtType === "gravatar" ? (
                <div
                    className={`review-item--avt ${
                        showAvt ? "" : "hidden-element"
                    }`}
                    style={{
                        backgroundColor: `#E5E5E5`,
                    }}
                >
                    <img src={imgSrc} alt="gvtar-demo" />
                </div>
            ) : (
                <div
                    className={`review-item--avt ${
                        showAvt ? "" : "hidden-element"
                    }`}
                    style={{ backgroundColor: "#f0f086" }}
                >
                    <p>{shortName}</p>
                </div>
            )}
        </>
    );
};

export default GenerateAvt;
