import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    Box,
    InlineStack,
    Text,
    Button,
    TextField,
    BlockStack,
    Checkbox,
} from "@shopify/polaris";
const ModalNotGood = ({ t, handleShowModalNotGood }) => {
    const [value, setValue] = useState({
        option_1: {
            checked: false,
            content: "",
        },
        option_2: false,
        option_3: {
            checked: false,
            content: "",
        },
    });

    const [message, setMessage] = useState("");

    const handleInput = useCallback(
        (key, input) => {
            setValue({
                ...value,
                [key]: {
                    ...value[key],
                    content: input,
                },
            });
        },
        [value]
    );

    const handleSend = useCallback(() => {
        handleShowModalNotGood(false);
        window.$crisp.push(["do", "chat:open"]);
        window.$crisp.push(["set", "message:text", [`${message}`]]);
    }, [message]);
    useEffect(() => {
        let handleMessage = "I'm not satisfied with TrustShop app";
        if (
            value.option_1.checked ||
            value.option_2.checked ||
            value.option_3.checked
        ) {
            handleMessage += " because:";
            if (value.option_1.checked) {
                handleMessage += "\n - I felt hard to use the app";
                if (value.option_1.content && value.option_1.content !== "") {
                    handleMessage += `when: ${value.option_1.content}`;
                }
            }
            if (value.option_2) {
                handleMessage += `\n - I'm not satisfied with support`;
            }
            if (value.option_3.checked) {
                handleMessage += `\n - App should have more features`;
                if (value.option_3.content && value.option_3.content !== "") {
                    handleMessage += `, such as: ${value.option_3.content}`;
                }
            }
        }
        setMessage(handleMessage);
    }, [value]);
    return (
        <div id="not-good--modal">
            <div id="not-good-modal--wrap">
                <Card padding={0}>
                    <Box padding={400} background="bg">
                        <InlineStack
                            gap={200}
                            wrap="false"
                            align="space-between"
                            blockAlign="center"
                        >
                            <Text variant="headingMd">
                                {t("popup.modal_rate.title", {
                                    ns: "dashboard",
                                })}
                            </Text>
                            <Button
                                variant="plain"
                                onClick={() => handleShowModalNotGood(false)}
                            >
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.96967 11.0303C10.2626 11.3232 10.7374 11.3232 11.0303 11.0303C11.3232 10.7374 11.3232 10.2626 11.0303 9.96967L7.06066 6L11.0303 2.03033C11.3232 1.73744 11.3232 1.26256 11.0303 0.96967C10.7374 0.676777 10.2626 0.676777 9.96967 0.96967L6 4.93934L2.03033 0.96967C1.73744 0.676777 1.26256 0.676777 0.96967 0.96967C0.676777 1.26256 0.676777 1.73744 0.96967 2.03033L4.93934 6L0.96967 9.96967C0.676777 10.2626 0.676777 10.7374 0.96967 11.0303C1.26256 11.3232 1.73744 11.3232 2.03033 11.0303L6 7.06066L9.96967 11.0303Z"
                                        fill="#4A4A4A"
                                    />
                                </svg>
                            </Button>
                        </InlineStack>
                    </Box>
                    <Box padding={400}>
                        <BlockStack gap={300}>
                            <BlockStack gap={100}>
                                <Checkbox
                                    label={t(
                                        "popup.modal_rate.option_1.title",
                                        {
                                            ns: "dashboard",
                                        }
                                    )}
                                    checked={value.option_1.checked}
                                    onChange={() =>
                                        setValue({
                                            ...value,
                                            option_1: {
                                                ...value.option_1,
                                                checked:
                                                    !value.option_1.checked,
                                            },
                                        })
                                    }
                                />
                                {value.option_1.checked && (
                                    <TextField
                                        label={t(
                                            "popup.modal_rate.option_1.content",
                                            { ns: "dashboard" }
                                        )}
                                        value={value.option_1.content}
                                        onChange={(value) => {
                                            handleInput("option_1", value);
                                        }}
                                    />
                                )}
                            </BlockStack>
                            <Checkbox
                                label={t("popup.modal_rate.option_2", {
                                    ns: "dashboard",
                                })}
                                checked={value.option_2}
                                onChange={() => {
                                    setValue({
                                        ...value,
                                        option_2: !value.option_2,
                                    });
                                }}
                            />
                            <BlockStack gap={100}>
                                <Checkbox
                                    label={t(
                                        "popup.modal_rate.option_3.title",
                                        {
                                            ns: "dashboard",
                                        }
                                    )}
                                    checked={value.option_3.checked}
                                    onChange={() => {
                                        setValue({
                                            ...value,
                                            option_3: {
                                                ...value.option_3,
                                                checked:
                                                    !value.option_3.checked,
                                            },
                                        });
                                    }}
                                />
                                {value.option_3.checked && (
                                    <TextField
                                        label={t(
                                            "popup.modal_rate.option_3.content",
                                            {
                                                ns: "dashboard",
                                            }
                                        )}
                                        value={value.option_3.content}
                                        onChange={(value) => {
                                            handleInput("option_3", value);
                                        }}
                                    />
                                )}
                            </BlockStack>
                        </BlockStack>
                    </Box>
                    <Box padding={400} background="bg">
                        <InlineStack align="end" blockAlign="center">
                            <Button variant="primary" onClick={handleSend}>
                                {t("send", { ns: "translation" })}
                            </Button>
                        </InlineStack>
                    </Box>
                </Card>
            </div>
        </div>
    );
};

export default ModalNotGood;
