import {
    Box,
    Icon,
    Collapsible,
    BlockStack,
    InlineGrid,
    InlineStack,
    RadioButton,
    Text,
    Button,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import ColorPicker from "../../../../../../components/Widget/ColorPicker";
import ColorModeOptionSkeleton from "../../../../../../components/Widget/ColorModeOptionSkeleton";
import { useCallback, useState } from "react";
import CustomModalMessage from "../../../../../../components/CustomModalMessage";

const defaultColor = {
    light: {
        primary_color: {
            color: "#3D4153",
        },
        color_customize: {
            primary_color: "#3D4153",
            secondary_color: "#FFFFFF",
            title_text_color: "#3D4153",
            all_photos_button_color: "#005BD3",
            primary_text_color: "#1A1A1A",
            secondary_text_color: "#74788D",
            star_icon_color: "#FFB400",
            line_color: "#1D1D1D",
            filter_sort_background: "#F2F2F6",
            filter_sort_text: "#3D4153",
            rating_process_color: "#FFB400",
            rating_process_empty_color: "#E7E7E7",
            user_name_color: "#303030",
            load_more_color: "#3D4153",
            bg_base_color: "#FFFFFF",
            reply_title_color: "#3D4153",
            reply_content_color: "#3D4153",
            reply_background_color: "#F6F6F6",
        },
    },
    dark: {
        primary_color: {
            color: "#F4F4F4",
        },
        color_customize: {
            primary_color: "#F4F4F4",
            secondary_color: "#2E2E2E",
            title_text_color: "#F8F8F8",
            all_photos_button_color: "#3FA0E5",
            primary_text_color: "#E7E7E7",
            secondary_text_color: "#A7ABC3",
            star_icon_color: "#FFB400",
            line_color: "#E3E3E3",
            filter_sort_background: "#E7E7E7",
            filter_sort_text: "#3D4153",
            rating_process_color: "#D8D8D8",
            rating_process_empty_color: "#4F4F4F",
            user_name_color: "#E7E7E7",
            load_more_color: "#EBEBEB",
            bg_base_color: "#2E2E2E",
            reply_title_color: "#FFFFFF",
            reply_content_color: "#FFFFFF",
            reply_background_color: "#3A3A3A",
        },
    },
    custom: {
        primary_color: {
            color: "#3D4153",
        },
        color_customize: {
            primary_color: "#3D4153",
            secondary_color: "#FFFFFF",
            title_text_color: "#3D4153",
            all_photos_button_color: "#005BD3",
            primary_text_color: "#1A1A1A",
            secondary_text_color: "#74788D",
            star_icon_color: "#FFB400",
            line_color: "#1D1D1D",
            filter_sort_background: "#F2F2F6",
            filter_sort_text: "#3D4153",
            rating_process_color: "#FFB400",
            rating_process_empty_color: "#E7E7E7",
            user_name_color: "#303030",
            load_more_color: "#3D4153",
            bg_base_color: "#FFFFFF",
            reply_title_color: "#3D4153",
            reply_content_color: "#3D4153",
            reply_background_color: "#F6F6F6",
        },
    },
};

const ColorSchema = ({
    t,
    open,
    handleToggle,
    reviewDisplay,
    setReviewDisplay,
    initReviewDisplay,
    setSessionColor,
}) => {
    const [openCustomColor, setOpenCustomColor] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleSelectColorMode = (mode) => {
        document.getElementById(mode).click();
    };

    const handleSelectedColor = (color, item) => {
        if (item) {
            setReviewDisplay({
                ...reviewDisplay,
                color_customize: {
                    ...reviewDisplay.color_customize,
                    [item]: color,
                },
            });
            setSessionColor((prevState) => ({
                ...prevState,
                [reviewDisplay.color_mode]: {
                    ...prevState[reviewDisplay.color_mode],
                    color_customize: {
                        ...prevState[reviewDisplay.color_mode].color_customize,
                        [item]: color,
                    },
                },
            }));
        }
    };


    const handleResetToDefaultConfirm = useCallback(() => {
        setReviewDisplay({
            ...reviewDisplay,
            primary_color: defaultColor[reviewDisplay.color_mode].primary_color,
            color_customize: {
                ...reviewDisplay.color_customize,
                ...defaultColor[reviewDisplay.color_mode].color_customize,
            },
        });
        setSessionColor((prevState) => ({
            ...prevState,
            [reviewDisplay.color_mode]: {
                primary_color:
                    defaultColor[reviewDisplay.color_mode].primary_color.color,
                color_customize: {
                    ...prevState[reviewDisplay.color_mode].color_customize,
                    ...defaultColor[reviewDisplay.color_mode].color_customize,
                },
            },
        }));

        setShowModal(false);
    }, [reviewDisplay.color_mode]);

    const handleCancelResetToDefault = () => {
        setShowModal(false);
    };

    return (
        <div className="color-schema">
            <Box padding={0}>
                <div
                    className="widget-setting-collape--title color-setting"
                    onClick={() => handleToggle("colorSchema")}
                >
                    <h5 className="setting-group--title">
                        {t("review_display.color_schema.title", {
                            ns: "widget",
                        })}
                    </h5>
                    {open.colorSchema ? (
                        <Icon source={ChevronDownIcon} />
                    ) : (
                        <Icon source={ChevronRightIcon} />
                    )}
                </div>
                <Collapsible
                    open={open.colorSchema}
                    id="basic-collapsible"
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                >
                    <Box paddingBlock={400}>
                        <InlineStack gap={600} align="center">
                            <BlockStack gap={100} inlineAlign="center">
                                <div
                                    className="color-mode-option--field"
                                    onClick={() =>
                                        handleSelectColorMode("light")
                                    }
                                >
                                    <ColorModeOptionSkeleton
                                        backgroundColor={"#F7F7F7"}
                                        primaryColor={"#D9D9D9"}
                                        secondaryColor={"#D9D9D9"}
                                    />
                                </div>
                                <RadioButton
                                    name="choose-mode"
                                    id="light"
                                    onChange={() =>
                                        setReviewDisplay({
                                            ...reviewDisplay,
                                            color_mode: "light",
                                        })
                                    }
                                    label={t("light", {
                                        ns: "translation",
                                    })}
                                    checked={
                                        reviewDisplay.color_mode === "light"
                                    }
                                />
                            </BlockStack>
                            <BlockStack gap={100} inlineAlign="center">
                                <div
                                    className="color-mode-option--field"
                                    onClick={() =>
                                        handleSelectColorMode("dark")
                                    }
                                >
                                    <ColorModeOptionSkeleton
                                        backgroundColor={"#303030"}
                                        primaryColor={"#D9D9D9"}
                                        secondaryColor={"#D9D9D9"}
                                    />
                                </div>
                                <RadioButton
                                    name="choose-mode"
                                    id="dark"
                                    onChange={() =>
                                        setReviewDisplay({
                                            ...reviewDisplay,
                                            color_mode: "dark",
                                        })
                                    }
                                    label={t("dark", {
                                        ns: "translation",
                                    })}
                                    checked={
                                        reviewDisplay.color_mode === "dark"
                                    }
                                />
                            </BlockStack>
                            <BlockStack gap={100} inlineAlign="center">
                                <div
                                    className="color-mode-option--field"
                                    onClick={() =>
                                        handleSelectColorMode("custom")
                                    }
                                >
                                    <ColorModeOptionSkeleton
                                        backgroundColor={"#EAF4FF"}
                                        primaryColor={"#CAE6FF"}
                                        secondaryColor={"#51C0FF"}
                                    />
                                </div>
                                <RadioButton
                                    name="choose-mode"
                                    id="custom"
                                    onChange={() =>
                                        setReviewDisplay({
                                            ...reviewDisplay,
                                            color_mode: "custom",
                                        })
                                    }
                                    label={t("custom", {
                                        ns: "translation",
                                    })}
                                    checked={
                                        reviewDisplay.color_mode === "custom"
                                    }
                                />
                            </BlockStack>
                        </InlineStack>
                    </Box>

                    <div
                        onClick={() => setOpenCustomColor(!openCustomColor)}
                        style={{ cursor: "pointer" }}
                    >
                        <InlineStack gap={200} blockAlign="center">
                            <Text variant="bodySm" fontWeight="regular">
                                {t(
                                    "review_display.color_schema.custom_color_schemes",
                                    {
                                        ns: "widget",
                                    }
                                )}
                            </Text>
                            {openCustomColor ? (
                                <Icon source={ChevronDownIcon} />
                            ) : (
                                <Icon source={ChevronRightIcon} />
                            )}
                        </InlineStack>
                    </div>

                    <Collapsible
                        open={openCustomColor}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                    >
                        <Box paddingBlockStart={200}>
                            <div className="color-picker--detail">
                                <BlockStack gap={{ xl: 400, lg: 200 }}>
                                    <InlineGrid
                                        columns={{
                                            xs: "1fr",
                                            sm: "5fr 5fr",
                                            md: "5fr 5fr",
                                            lg: "1fr",
                                            xl: "5fr 5fr",
                                        }}
                                        gap={200}
                                    >
                                        {Object.keys(
                                            defaultColor?.light?.color_customize
                                        ).map((item, index) => (
                                            <ColorPicker
                                                key={`review-display-color-${item}`}
                                                color={
                                                    reviewDisplay
                                                        .color_customize[item]
                                                }
                                                label={t(
                                                    `review_display.color_schema.${item}`,
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                                item={item}
                                                handleSelectedColor={
                                                    handleSelectedColor
                                                }
                                            />
                                        ))}
                                    </InlineGrid>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            padding: "20px 0 26px",
                                        }}
                                    >
                                        <Button onClick={()=>setShowModal(true)}>
                                            {t("reset_to_default", {
                                                ns: "translation",
                                            })}
                                        </Button>
                                    </div>
                                </BlockStack>
                            </div>
                        </Box>
                    </Collapsible>
                </Collapsible>
            </Box>
            {showModal && (
                <>
                    <CustomModalMessage
                        title={t(
                            "review_display.color_schema.reset_to_default_title",
                            { ns: "widget" }
                        )}
                        content={t(
                            "review_display.color_schema.reset_to_default_content",
                            { ns: "widget" }
                        )}
                        closeModalAction={() => setShowModal(false)}
                        primaryButton={{
                            content: t("reset_to_default", {
                                ns: "translation",
                            }),
                            action: handleResetToDefaultConfirm,
                        }}
                        secondaryButton={{
                            content: t("cancel", { ns: "translation" }),
                            action: handleCancelResetToDefault,
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default ColorSchema;
