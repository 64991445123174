import { Box, Card, InlineStack, Text, Button } from "@shopify/polaris";
const CustomModalMessage = ({
    t,
    content,
    title,
    closeModalAction,
    primaryButton,
    secondaryButton,
}) => {
    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    zIndex: 9999,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: "90%",
                        maxWidth: "600px",
                    }}
                >
                    <Card padding={0}>
                        <Box padding={400} background="bg" borderBlockEndWidth="0165" borderColor="border-secondary">
                            <InlineStack
                                gap={200}
                                wrap="false"
                                align="space-between"
                                blockAlign="center"
                            >
                                <Text variant="headingMd">{title}</Text>
                                <Button variant="plain" onClick={() => closeModalAction()}>
                                    <svg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.96967 11.0303C10.2626 11.3232 10.7374 11.3232 11.0303 11.0303C11.3232 10.7374 11.3232 10.2626 11.0303 9.96967L7.06066 6L11.0303 2.03033C11.3232 1.73744 11.3232 1.26256 11.0303 0.96967C10.7374 0.676777 10.2626 0.676777 9.96967 0.96967L6 4.93934L2.03033 0.96967C1.73744 0.676777 1.26256 0.676777 0.96967 0.96967C0.676777 1.26256 0.676777 1.73744 0.96967 2.03033L4.93934 6L0.96967 9.96967C0.676777 10.2626 0.676777 10.7374 0.96967 11.0303C1.26256 11.3232 1.73744 11.3232 2.03033 11.0303L6 7.06066L9.96967 11.0303Z"
                                            fill="#4A4A4A"
                                        />
                                    </svg>
                                </Button>
                            </InlineStack>
                        </Box>
                        <Box padding={400}>{content}</Box>
                        <Box padding={400} background="bg-fill" borderBlockStartWidth="0165" borderColor="border-secondary">
                            <InlineStack
                                align="end"
                                blockAlign="center"
                                gap={300}
                            >
                                <Button
                                    variant="secondary"
                                    onClick={() => secondaryButton.action()}
                                >
                                    {secondaryButton.content ||
                                        t("cancel", {
                                            ns: "translation",
                                        })}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => primaryButton.action()}
                                >
                                    {primaryButton.content ||
                                        t("save", {
                                            ns: "translation",
                                        })}
                                </Button>
                            </InlineStack>
                        </Box>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default CustomModalMessage;
