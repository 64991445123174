import { Box, Divider, Text, BlockStack, TextField } from "@shopify/polaris";

const HeaderLabel = ({
    t,
    contentValue,
    displaySetting,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
    disabled,
}) => {
    return (
        <div className="setting-component">
            <BlockStack gap={300}>
                <Text as="h4" fontWeight="semibold">
                    {t("review_display.content.header_widget.title", {
                        ns: "widget",
                    })}
                </Text>
                <Divider borderColor="border-subdued" />
            </BlockStack>
            <Box paddingBlockStart={100} paddingInlineEnd={0}>
                <BlockStack gap={300}>
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text customer review title
                            </Text>
                        }
                        name="title"
                        value={
                            reviewDisplay.label_content.text_customer_reviews
                        }
                        onChange={(e) =>
                            handleInputChange(e, "text_customer_reviews")
                        }
                        disabled={disabled}
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text media from reviews
                            </Text>
                        }
                        name="review-submit"
                        value={
                            reviewDisplay.label_content.text_images_from_reviews
                        }
                        onChange={(e) =>
                            handleInputChange(e, "text_images_from_reviews")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text review this product
                            </Text>
                        }
                        name="text-review-this-product"
                        value={
                            reviewDisplay?.label_content
                                ?.text_review_this_product
                        }
                        onChange={(e) =>
                            handleInputChange(e, "text_review_this_product")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text share your thoughts
                            </Text>
                        }
                        name="text-share-your-thoughts"
                        value={
                            reviewDisplay?.label_content
                                ?.text_share_your_thoughts
                        }
                        onChange={(e) =>
                            handleInputChange(e, "text_share_your_thoughts")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Write review button text
                            </Text>
                        }
                        name="write-review-btn"
                        value={reviewDisplay?.label_content?.btn_write_a_review}
                        onChange={(e) =>
                            handleInputChange(e, "btn_write_a_review")
                        }
                        disabled={disabled}
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text Review with comments
                            </Text>
                        }
                        name="review_with_comments"
                        value={
                            reviewDisplay?.label_content
                                ?.text_review_with_comments
                        }
                        onChange={(e) =>
                            handleInputChange(e, "text_review_with_comments")
                        }
                        disabled={disabled}
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text all photos
                            </Text>
                        }
                        name="all-photos"
                        value={reviewDisplay?.label_content?.all_photos}
                        onChange={(e) => handleInputChange(e, "all_photos")}
                        disabled={disabled}
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text total reviews
                            </Text>
                        }
                        name="text_total_reviews"
                        value={reviewDisplay?.label_content?.text_reviews}
                        onChange={(e) => handleInputChange(e, "text_reviews")}
                        disabled={disabled}
                    />
                </BlockStack>
            </Box>
        </div>
    );
};

export default HeaderLabel;
