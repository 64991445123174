import { BlockStack, InlineStack, Checkbox } from "@shopify/polaris";
const ReviewSection = ({ t, reviewDisplay, setReviewDisplay, disabled }) => {
    return (
        <>
            <div className="setting-component">
                <BlockStack gap={300}>
                <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="product"
                            checked={reviewDisplay.section_show.product}
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        product: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show.product
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t("review_onepage.review_display.product", {ns: "widget"})}
                                </p>
                            }
                            disabled={disabled}
                        />
                    </InlineStack>
                    
                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="review_date"
                            checked={reviewDisplay.section_show.review_date}
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        review_date: value,
                                    },
                                });
                            }}
                            disabled={disabled}
                            label={
                                <p
                                    className={`component-item-label ${
                                        reviewDisplay.section_show.review_date
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.review_date",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    </InlineStack>
                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="button_helpful"
                            checked={reviewDisplay.section_show.button_helpful}
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        button_helpful: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label ${
                                        reviewDisplay.section_show
                                            .button_helpful
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.button_helpful",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                            disabled={disabled}
                        />
                    </InlineStack>
                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="button_report"
                            checked={reviewDisplay.section_show.button_report}
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        button_report: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label ${
                                        reviewDisplay.section_show.button_report
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.button_report",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                            disabled={disabled}
                        />
                    </InlineStack>
                </BlockStack>
            </div>
        </>
    );
};

export default ReviewSection;
