import {
    Box,
    Icon,
    Collapsible,
    RadioButton,
    InlineGrid,
    BlockStack,
    Checkbox,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import OnePageSkeleton from "../../../../../components/SkeletonComponent/OnePageSkeleton";
import LayoutSkeleton from "../../../../../components/SkeletonComponent/Layout";
const DisplayStyle = ({
    t,
    open,
    setOpen,
    reviewDisplay,
    setReviewDisplay,
    handleToggle,
    disabled
}) => {
    const handleSkeletonClick = (type, option) => {
        if(disabled)return
        if (type === "layout_box") {
            option === "list"
                ? document.getElementById("list").click()
                : document.getElementById("grid").click();
        } else {
            option === "left"
                ? document.getElementById("left").click()
                : document.getElementById("center").click();
        }
    };

    const handleLayoutChange = (_, newValue) => {
        setReviewDisplay({
            ...reviewDisplay,
            layout_box: newValue,
        });
    };

    const handleSummaryChange = (_, newValue) => {
        setReviewDisplay({
            ...reviewDisplay,
            layout_summary_box: newValue,
        });
    };

    return (
        <>
            <InlineGrid
                align="space-between"
                columns={{ xs: "1fr", sm: "5fr 5fr", lg: "1fr" }}
                gap={{
                    xs: 0,
                    sm: "400",
                    lg: 0,
                }}
            >
                <Box padding={0}>
                    <div
                        className="widget-setting-collape--title"
                        onClick={() => handleToggle("layout")}
                    >
                        <h5 className="setting-group--title">
                            {t("review_display.choose_layout.title", {
                                ns: "widget",
                            })}
                        </h5>
                        {open.layout ? (
                            <Icon source={ChevronDownIcon} />
                        ) : (
                            <Icon source={ChevronRightIcon} />
                        )}
                    </div>
                    <Collapsible
                        open={open.layout}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                    >
                        <Box paddingBlockStart={400}>
                            <BlockStack gap={200} columns={2}>
                                <div className="setting-layout--item">
                                    <div
                                        className="skeleton-field"
                                        onClick={() =>
                                            handleSkeletonClick(
                                                "layout_box",
                                                "list"
                                            )
                                        }
                                    >
                                        <div
                                            id="setting-widget-layout--list"
                                            className={
                                                reviewDisplay.layout_box ===
                                                "list"
                                                    ? "layout-enable"
                                                    : ""
                                            }
                                        >
                                            <LayoutSkeleton />
                                            <LayoutSkeleton />
                                        </div>
                                    </div>
                                    <RadioButton
                                        name="choose-layout"
                                        id="list"
                                        onChange={handleLayoutChange}
                                        label={t(
                                            "review_display.choose_layout.list",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                        checked={
                                            reviewDisplay.layout_box === "list"
                                        }
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="setting-layout--item">
                                    <div
                                        className="skeleton-field"
                                        onClick={() =>
                                            handleSkeletonClick(
                                                "layout_box",
                                                "grid"
                                            )
                                        }
                                    >
                                        <div
                                            id="setting-widget-layout--grid"
                                            className={
                                                reviewDisplay.layout_box ===
                                                "grid"
                                                    ? "layout-enable"
                                                    : ""
                                            }
                                        >
                                            <LayoutSkeleton />
                                            <LayoutSkeleton />
                                        </div>
                                    </div>
                                    <RadioButton
                                        name="choose-layout"
                                        id="grid"
                                        onChange={handleLayoutChange}
                                        label={t(
                                            "review_display.choose_layout.grid",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                        checked={
                                            reviewDisplay.layout_box === "grid"
                                        }
                                        disabled={disabled}
                                    />
                                </div>
                            </BlockStack>
                        </Box>
                    </Collapsible>
                </Box>
                <Box padding={0}>
                    <div
                        className="widget-setting-collape--title"
                        onClick={() => handleToggle("summary")}
                    >
                        <h5 className="setting-group--title">
                            {t("review_display.summary_box.title", {
                                ns: "widget",
                            })}
                        </h5>
                        {open.summary ? (
                            <Icon source={ChevronDownIcon} />
                        ) : (
                            <Icon source={ChevronRightIcon} />
                        )}
                    </div>
                    <Collapsible
                        open={open.summary}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                    >
                        <Box paddingBlockStart={400}>
                            <InlineGrid
                                gap={{
                                    xs: 200,
                                    sm: 400,
                                    lg: 200,
                                }}
                                columns={{
                                    xs: "1fr",
                                    sm: "1fr",
                                    lg: "1fr",
                                }}
                            >
                                <div className="setting-layout--item">
                                    <div
                                        className="skeleton-field"
                                        onClick={() =>
                                            handleSkeletonClick(
                                                "layout_summary_box",
                                                "center"
                                            )
                                        }
                                    >
                                        <OnePageSkeleton
                                            active={
                                                reviewDisplay.layout_summary_box ===
                                                "center"
                                            }
                                            type={"center"}
                                        />
                                    </div>
                                    <RadioButton
                                        name="choose-summary"
                                        id="center"
                                        onChange={handleSummaryChange}
                                        label={t(
                                            "review_display.summary_box.top",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                        checked={
                                            reviewDisplay.layout_summary_box ===
                                            "center"
                                        }
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="setting-layout--item">
                                    <div
                                        className="skeleton-field"
                                        onClick={() =>
                                            handleSkeletonClick(
                                                "layout_summary_box",
                                                "left"
                                            )
                                        }
                                    >
                                        <OnePageSkeleton
                                            active={
                                                reviewDisplay.layout_summary_box ===
                                                "left"
                                            }
                                            type={"left"}
                                        />
                                    </div>
                                    <RadioButton
                                        name="choose-summary"
                                        id="left"
                                        onChange={handleSummaryChange}
                                        label={t(
                                            "review_display.summary_box.left",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                        checked={
                                            reviewDisplay.layout_summary_box ===
                                            "left"
                                        }
                                        disabled={disabled}
                                    />
                                </div>
                            </InlineGrid>
                        </Box>
                        <Checkbox
                            label={t("border", { ns: "translation" })}
                            checked={reviewDisplay.section_show.summary_border}
                            onChange={(value) =>
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_border: value,
                                    },
                                })
                            }
                            disabled={disabled}
                        />
                    </Collapsible>
                </Box>
            </InlineGrid>
        </>
    );
};

export default DisplayStyle;
