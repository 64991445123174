import React, { useState } from "react";
import {
    Box,
    Icon,
    Divider,
    Collapsible,
    BlockStack,
    InlineStack,
    Checkbox,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import DraggableReviewFilter from "./DraggableReviewFilter";
import ReviewPerPage from "./ReviewPerPage";
import CustomerInfo from "./CustomerInfo";
import Summary from "./Summary";
const InfomationDisplay = ({
    t,
    open,
    setOpen,
    handleToggle,
    displaySetting,
    handleShowHideInfo,
    handleShowHideReviewFilter,
    multiDisplaySetting,
    handleMultiDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
    listTabFilter,
    handleQueueFilter,
    handleDragEnd,
    setMultiDisplaySetting,
}) => {
    const [error, setError] = useState({
        review_per_page_mobile: "",
        review_per_page_desktop: "",
    });

    const handleChangeReviewPerPage = (e, key) => {
        if (e.target.value < 1 || e.target.value > 10) {
            setError({
                ...error,
                [key]: t("validate.quantity_review_per_page", {
                    ns: "message",
                }),
            });
        } else {
            setError({
                ...error,
                [key]: "",
            });
            setReviewDisplay({
                ...reviewDisplay,
                [key]: e.target.value,
            });
        }
    };

    return (
        <Box padding={0}>
            <div
                className="widget-setting-collape--title"
                onClick={() => handleToggle("information")}
            >
                <h5 className="setting-group--title">
                    {t("review_display.information_display.title", {
                        ns: "widget",
                    })}
                </h5>
                {open.information ? (
                    <Icon source={ChevronDownIcon} />
                ) : (
                    <Icon source={ChevronRightIcon} />
                )}
            </div>
            <Collapsible
                open={open.information}
                id="basic-collapsible"
                transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                }}
                expandOnPrint
            >
                <Box paddingBlockStart={200}>
                    <BlockStack gap={200}>
                        <Summary
                            t={t}
                            reviewDisplay={reviewDisplay}
                            setReviewDisplay={setReviewDisplay}
                        />

                        <ReviewPerPage
                            t={t}
                            error={error}
                            handleChangeReviewPerPage={
                                handleChangeReviewPerPage
                            }
                            reviewDisplay={reviewDisplay}
                        />

                        <CustomerInfo
                            t={t}
                            multiDisplaySetting={multiDisplaySetting}
                            reviewDisplay={reviewDisplay}
                            setReviewDisplay={setReviewDisplay}
                        />

                        <div className="setting-component">
                            <BlockStack gap={300}>
                                <InlineStack
                                    align="start"
                                    gap={100}
                                    blockAlign="center"
                                >
                                    <Checkbox
                                        type="checkbox"
                                        id="review_date"
                                        checked={
                                            reviewDisplay.section_show
                                                .review_date
                                        }
                                        onChange={(value) => {
                                            setReviewDisplay({
                                                ...reviewDisplay,
                                                section_show: {
                                                    ...reviewDisplay.section_show,
                                                    review_date: value,
                                                },
                                            });
                                        }}
                                        label={
                                            <p
                                                className={`component-item-label ${
                                                    reviewDisplay.section_show
                                                        .review_date
                                                        ? ""
                                                        : "subdued-text"
                                                }`}
                                            >
                                                {t(
                                                    "review_display.information_display.review_date",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </p>
                                        }
                                    />
                                </InlineStack>
                                <InlineStack
                                    align="start"
                                    gap={100}
                                    blockAlign="center"
                                >
                                    <Checkbox
                                        type="checkbox"
                                        id="button_helpful"
                                        checked={
                                            reviewDisplay.section_show
                                                .button_helpful
                                        }
                                        onChange={(value) => {
                                            setReviewDisplay({
                                                ...reviewDisplay,
                                                section_show: {
                                                    ...reviewDisplay.section_show,
                                                    button_helpful: value,
                                                },
                                            });
                                        }}
                                        label={
                                            <p
                                                className={`component-item-label ${
                                                    reviewDisplay.section_show
                                                        .button_helpful
                                                        ? ""
                                                        : "subdued-text"
                                                }`}
                                            >
                                                {t(
                                                    "review_display.information_display.button_helpful",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </p>
                                        }
                                    />
                                </InlineStack>
                                <InlineStack
                                    align="start"
                                    gap={100}
                                    blockAlign="center"
                                >
                                    <Checkbox
                                        type="checkbox"
                                        id="button_report"
                                        checked={
                                            reviewDisplay.section_show
                                                .button_report
                                        }
                                        onChange={(value) => {
                                            setReviewDisplay({
                                                ...reviewDisplay,
                                                section_show: {
                                                    ...reviewDisplay.section_show,
                                                    button_report: value,
                                                },
                                            });
                                        }}
                                        label={
                                            <p
                                                className={`component-item-label ${
                                                    reviewDisplay.section_show
                                                        .button_report
                                                        ? ""
                                                        : "subdued-text"
                                                }`}
                                            >
                                                {t(
                                                    "review_display.information_display.button_report",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </p>
                                        }
                                    />
                                </InlineStack>
                            </BlockStack>
                        </div>

                        <div className="setting-component">
                            <BlockStack gap={300}>
                                <InlineStack
                                    align="start"
                                    gap={100}
                                    blockAlign="center"
                                >
                                    <Checkbox
                                        type="checkbox"
                                        id="mult-display-filter"
                                        checked={multiDisplaySetting.filter}
                                        onChange={(value) => {
                                            handleMultiDisplaySetting(
                                                value,
                                                "filter"
                                            );
                                        }}
                                        label={
                                            <p
                                                className={`component-title ${
                                                    multiDisplaySetting.filter
                                                        ? ""
                                                        : "subdued-text"
                                                }`}
                                            >
                                                {t(
                                                    "review_display.information_display.review_filter",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </p>
                                        }
                                    />
                                </InlineStack>
                                <Divider />
                            </BlockStack>
                            <DraggableReviewFilter
                                t={t}
                                displaySetting={displaySetting}
                                handleShowHideInfo={handleShowHideInfo}
                                handleShowHideReviewFilter={
                                    handleShowHideReviewFilter
                                }
                                reviewDisplay={reviewDisplay}
                                setReviewDisplay={setReviewDisplay}
                                listTabFilter={listTabFilter}
                                handleQueueFilter={handleQueueFilter}
                                handleDragEnd={handleDragEnd}
                            />
                        </div>
                    </BlockStack>
                </Box>
            </Collapsible>
        </Box>
    );
};

export default InfomationDisplay;
