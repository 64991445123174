import {
    ChoiceList,
    IndexFilters,
    useSetIndexFiltersMode,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";

const FilterReview = ({
    t,
    filter,
    setFilter,
    selected,
    setSelected,
    isLoadReview,
}) => {
    const { mode, setMode } = useSetIndexFiltersMode();

    const initSort =
        filter.sort_by && filter.order_by
            ? `${filter.sort_by.split("_")[0]}-${filter.order_by}`
            : "most-recent";
    const [sortSelected, setSortSelected] = useState([initSort]);
    const onHandleCancel = () => {

    };

    const handleSelectRating = (value) => {
        setFilter({
            ...filter,
            star: value,
        });
    };

    const handleSelectReview = (value) => {
        setFilter({
            ...filter,
            review_type: value[0],
        });
    };

    const handleRatingRemove = () => {
        setFilter({
            ...filter,
            star: [],
        });
    };

    const handleReviewRemove = () => {
        setFilter({
            ...filter,
            review_type: "",
        });
    };

    const handleSourceRemove = () => {
        setFilter({
            ...filter,
            source: [],
        });
    }

    const filters = [
        {
            key: "star",
            label: t("table.rating", { ns: "review-management" }),
            filter: (
                <ChoiceList
                    title={t("table.rating", { ns: "review-management" })}
                    titleHidden
                    choices={[
                        {
                            label: `1 ${t("star", { ns: "translation" })}`,
                            value: "1",
                        },
                        {
                            label: `2 ${t("stars", { ns: "translation" })}`,
                            value: "2",
                        },
                        {
                            label: `3 ${t("stars", { ns: "translation" })}`,
                            value: "3",
                        },
                        {
                            label: `4 ${t("stars", { ns: "translation" })}`,
                            value: "4",
                        },
                        {
                            label: `5 ${t("stars", { ns: "translation" })}`,
                            value: "5",
                        },
                    ]}
                    selected={filter.star}
                    onChange={handleSelectRating}
                    allowMultiple={true}
                />
            ),
            shortcut: true,
        },
        {
            key: "review_type",
            label: t("review", { ns: "translation" }),
            filter: (
                <ChoiceList
                    title={t("review", { ns: "translation" })}
                    titleHidden
                    choices={[
                        {
                            label: t("table.review_with_media", {
                                ns: "review-management",
                            }),
                            value: "with_media",
                        },
                        {
                            label: t("table.review_text_only", {
                                ns: "review-management",
                            }),
                            value: "only_text",
                        },
                    ]}
                    selected={filter.review_type}
                    onChange={handleSelectReview}
                />
            ),
            shortcut: true,
        },
      
    ];

    const appliedFilters = [];

    if (filter.star && filter.star.length > 0) {
        appliedFilters.push({
            key: "star",
            label: `${filter.star} ${t("star", { ns: "translation" })}`,
            onRemove: handleRatingRemove,
        });
    }

    if (filter.review_type && filter.review_type !== "") {
        appliedFilters.push({
            key: "review_type",
            label:
                filter.review_type === "with_media"
                    ? t("table.review_with_media", {
                        ns: "review-management",
                    })
                    : t("table.review_text_only", {
                        ns: "review-management",
                    }),
            onRemove: handleReviewRemove,
        });
    }

    if (filter.source && filter.source.length > 0) {
        appliedFilters.push({
            key: "source",
            label: `${t("source", { ns: "translation" })}: ${filter.source}`,
            onRemove: handleSourceRemove,
        });
    }

    const sortOptions = [
        {
            label: t("toolbar.most_recent", {
                ns: "review-management",
            }),
            value: "most-recent",
        },
        {
            label: t("toolbar.rating_high_low", {
                ns: "review-management",
            }),
            value: "review-desc",
        },
        {
            label: t("toolbar.rating_low_high", {
                ns: "review-management",
            }),
            value: "review-asc",
        },
        {
            label: `${t("table.product", {
                ns: "review-management",
            })} (A - Z)`,
            value: "product-asc",
        },
        {
            label: `${t("table.product", {
                ns: "review-management",
            })} (Z - A)`,
            value: "product-desc",
        },
    ];

    const handleFiltersQueryChange = (value) => {
        setFilter({
            ...filter,
            keyword: value,
        });
    };

    const handleFiltersClearAll = () => {
        setFilter({
            ...filter,
            star: [],
            review_type: "",
            source: [],
        });
    };

    const handleSort = (value) => {
        if (!value) {
            return;
        }
        const sort = value[0];
        setSortSelected(sort);
        switch (sort) {
            case "most-recent":
                setFilter({
                    ...filter,
                    sort_by: "",
                    order_by: "",
                });
                break;
            case "review-desc":
                setFilter({
                    ...filter,
                    sort_by: "review_star",
                    order_by: "desc",
                });
                break;
            case "review-asc":
                setFilter({
                    ...filter,
                    sort_by: "review_star",
                    order_by: "asc",
                });
                break;
            case "product-asc":
                setFilter({
                    ...filter,
                    sort_by: "product_title",
                    order_by: "asc",
                });
                break;
            case "product-desc":
                setFilter({
                    ...filter,
                    sort_by: "product_title",
                    order_by: "desc",
                });
                break;
            default:
                setFilter({
                    ...filter,
                    sort_by: "",
                    order_by: "",
                });
                break;
        }
    };

    useEffect(() => {
        if (
            filter.keyword ||
            (filter.star) ||
            filter.source ||
            filter.review_type
        )
            document
                .querySelector(
                    ".Polaris-Button.Polaris-Button--sizeSlim.Polaris-Button--iconOnly[aria-label='Search and filter results']"
                )
                .click();
    }, []);

    const handleSelectab = (selected) => {
        setSelected(selected);
    }

    return (
        <IndexFilters
            sortOptions={sortOptions}
            sortSelected={sortSelected}
            onSort={handleSort}
            cancelAction={{
                onAction: onHandleCancel,
                disabled: false,
                loading: isLoadReview,
            }}
            canCreateNewView={true}
            queryPlaceholder={t("toolbar.search", {
                ns: "review-management",
            })}
            mode={mode}
            setMode={setMode}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            queryValue={filter.keyword}
            onQueryClear={() =>
                setFilter({
                    ...filter,
                    keyword: "",
                })
            }
            onClearAll={handleFiltersClearAll}
            tabs={[]}
            selected={selected}
            onSelect={(value) => { handleSelectab(value) }}
            id="filter-review"
        />
    );
};

export default FilterReview;
