const Header = ({ device, reviewDisplay, starIcon, shopInfo }) => {
    return (
        <>
            <div
                className={`widget-header header-${device} style-top ${
                    reviewDisplay.layout_summary_box === "left"
                        ? "one-page-style--left"
                        : ""
                }`}
            >
                <div>
                    <div
                        className={`summary-box ${
                            shopInfo?.element_corners === "square"
                                ? "element_corners--square"
                                : ""
                        }`}
                        style={{
                            border: !reviewDisplay.section_show.summary_border
                                ? "none"
                                : "1px solid var(--line_color)",
                        }}
                    >
                        <div
                            className={`summarybox-left`}
                            style={{
                                border: "none",
                                width: "100%",
                                justifyContent: "center",
                                gap: "6%",
                            }}
                        >
                            <div className="overview">
                                <p className="avg-rating">
                                    4.5{" "}
                                    {reviewDisplay.section_show
                                        .summary_star_icon && (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: starIcon,
                                            }}
                                            className="star-icon--box star-icon"
                                        ></span>
                                    )}
                                </p>
                                {reviewDisplay.section_show
                                    .summary_number_of_reviews && (
                                    <p className="total-review">
                                        273{" "}
                                        {
                                            reviewDisplay.label_content
                                                .text_reviews
                                        }
                                    </p>
                                )}
                            </div>
                            <div
                                className="score-list"
                                style={{
                                    maxWidth: `350px`,
                                }}
                            >
                                {[5, 4, 3, 2, 1].map((item, index) => (
                                    <div
                                        className="score-item"
                                        key={`score-item--${index}`}
                                    >
                                        <span
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                flexWrap: "nowrap",
                                                gap: "2px",
                                                width: "23px",
                                                flexShrink: 0,
                                            }}
                                        >
                                            {item}{" "}
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: starIcon,
                                                }}
                                                className="star-icon--box star-icon"
                                            ></span>
                                        </span>
                                        <div className="score-bar">
                                            <div
                                                style={{
                                                    height: "100%",
                                                    width: `${item * 10}%`,
                                                    borderRadius: "5px",
                                                    backgroundColor:
                                                        "var(--rating_process_color) ",
                                                }}
                                            ></div>
                                        </div>
                                        {reviewDisplay.section_show
                                            .number_of_reviews ? (
                                            <span
                                                style={{
                                                    flexShrink: 0,
                                                    width: "28px",
                                                }}
                                            >
                                                {item * 10}%
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
