import { Box, Divider, Text, TextField, BlockStack } from "@shopify/polaris";
const ValidateMessageLabel = ({
    t,
    contentValue,
    displaySetting,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
}) => {
    return (
        <>
            <div className="setting-component">
                <BlockStack gap={3}>
                    <Text as="h4" fontWeight="semibold">
                        {t(
                            "review_display.content.validate_message_label.title",
                            {
                                ns: "widget",
                            }
                        )}
                    </Text>
                    <Divider borderColor="border-subdued" />
                </BlockStack>
                <Box paddingBlockStart={1}>
                    <BlockStack gap={3}>
                        <TextField
                            label={
                                <Text as="h4" fontWeight="medium">
                                    Required input
                                </Text>
                            }
                            name="required_input"
                            value={reviewDisplay?.label_content?.required_input}
                            onChange={(e) =>
                                handleInputChange(e, "required_input")
                            }
                        />

                        <TextField
                            label={
                                <Text as="h4" fontWeight="medium">
                                    Invalid email
                                </Text>
                            }
                            name="required_email"
                            value={reviewDisplay?.label_content?.required_email}
                            onChange={(e) =>
                                handleInputChange(e, "required_email")
                            }
                        />

                        <TextField
                            label={
                                <Text as="h4" fontWeight="medium">
                                    Media upload fail
                                </Text>
                            }
                            name="media_upload_fail"
                            value={
                                reviewDisplay?.label_content?.media_upload_fail
                            }
                            onChange={(e) =>
                                handleInputChange(e, "media_upload_fail")
                            }
                        />

                        <TextField
                            label={
                                <Text as="h4" fontWeight="medium">
                                    Media upload size {`( < 10MB)`}
                                </Text>
                            }
                            name="media_rule_size"
                            value={
                                reviewDisplay?.label_content?.media_rule_size
                            }
                            onChange={(e) =>
                                handleInputChange(e, "media_rule_size")
                            }
                        />

                        <TextField
                            label={
                                <Text as="h4" fontWeight="medium">
                                    Media upload type{" "}
                                    {`( Image: .bmp, .jpeg or .png | Video: .mp4, .mov )`}
                                </Text>
                            }
                            name="media_rule_type"
                            value={
                                reviewDisplay?.label_content?.media_rule_type
                            }
                            onChange={(e) =>
                                handleInputChange(e, "media_rule_type")
                            }
                        />

                        <TextField
                            label={
                                <Text as="h4" fontWeight="medium">
                                    Media upload duplicate
                                </Text>
                            }
                            name="media_upload_duplicate"
                            value={
                                reviewDisplay?.label_content?.media_upload_duplicate
                            }
                            onChange={(e) =>
                                handleInputChange(e, "media_upload_duplicate")
                            }
                        />
                    </BlockStack>
                </Box>
            </div>
        </>
    );
};

export default ValidateMessageLabel;
