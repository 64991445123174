import {
    Text,
    Box,
    Button,
    ButtonGroup,
    BlockStack,
    InlineStack,
} from "@shopify/polaris";
import { lazy, Suspense } from "react";
import AddAppBlockSnippet from "../../../../components/Widget/AddAppBlockSnippet";
import Default from "./Default/Default";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import listFunction from "../../../../config/pricingListFunction";
const NoReviews = lazy(() => import("./NoReviews/NoReviews"));
const DetailSetting = lazy(() => import("./Modal/DetailImage/DetailImage"));
const AllMediasSetting = lazy(() =>
    import("./Modal/AllMedias/AllMediasSetting")
);
const FormWriteReview = lazy(() =>
    import("./Modal/FormWriteReview/FormWriteReview")
);
const WidgetLimitNotice = lazy(() =>
    import("../../../../components/Pricing/WidgetLimitNotice")
);

const SettingOption = ({
    t,
    open,
    setOpen,
    multiDisplaySetting,
    displaySetting,
    contentValue,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleShowHideReviewFilter,
    handleMultiDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
    listTabFilter,
    handleQueueFilter,
    handleDragEnd,
    saveState,
    saveLoading,
    showMobileMenu,
    setShowMobileMenu,
    handleSaveReviewDisplay,
    handleDisplaySetting,
    setMultiDisplaySetting,
    initReviewDisplay,
    setSessionColor,
    tabs,
}) => {
    const shopInfo = useSelector((state) => state?.Auth?.shopInfo);
    const section = [
        <Default
            t={t}
            open={open}
            setOpen={setOpen}
            handleToggle={handleToggle}
            reviewDisplay={reviewDisplay}
            setReviewDisplay={setReviewDisplay}
            displaySetting={displaySetting}
            handleShowHideInfo={handleShowHideInfo}
            handleShowHideReviewFilter={handleShowHideReviewFilter}
            multiDisplaySetting={multiDisplaySetting}
            handleMultiDisplaySetting={handleMultiDisplaySetting}
            listTabFilter={listTabFilter}
            handleQueueFilter={handleQueueFilter}
            handleDragEnd={handleDragEnd}
            setMultiDisplaySetting={setMultiDisplaySetting}
            contentValue={contentValue}
            handleInputChange={handleInputChange}
            handleDisplaySetting={handleDisplaySetting}
            initReviewDisplay={initReviewDisplay}
            setSessionColor={setSessionColor}
            shopInfo={shopInfo}
        />,
        <Suspense fallback={<div>Loading...</div>}>
            <NoReviews
                t={t}
                reviewDisplay={reviewDisplay}
                setReviewDisplay={setReviewDisplay}
                handleInputChange={handleInputChange}
                setSessionColor={setSessionColor}
            />
        </Suspense>,
        <Suspense fallback={<div>Loading...</div>}>
            <AllMediasSetting
                t={t}
                reviewDisplay={reviewDisplay}
                setReviewDisplay={setReviewDisplay}
                handleInputChange={handleInputChange}
                setSessionColor={setSessionColor}
                disabled={
                    !listFunction?.customize_widget?.detail?.modal_all_image
                        ?.all_settings[shopInfo?.current_plan]
                }
            />
        </Suspense>,
        <Suspense fallback={<div>Loading...</div>}>
            <DetailSetting
                t={t}
                reviewDisplay={reviewDisplay}
                setReviewDisplay={setReviewDisplay}
                handleInputChange={handleInputChange}
                setSessionColor={setSessionColor}
                disabled={
                    !listFunction?.customize_widget?.detail?.modalReviewDetail
                        ?.all_settings[shopInfo?.current_plan]
                }
            />
        </Suspense>,
        <Suspense fallback={<div>Loading...</div>}>
            <FormWriteReview
                t={t}
                reviewDisplay={reviewDisplay}
                setReviewDisplay={setReviewDisplay}
                handleInputChange={handleInputChange}
                setSessionColor={setSessionColor}
                disabled={
                    !listFunction?.customize_widget?.detail?.formWriteReview
                        ?.review_with_video[shopInfo?.current_plan]
                }
                shopInfo={shopInfo}
            />
        </Suspense>,
    ];

    return (
        <>
            <Box as="div" paddingBlockEnd={400}>
                <div className="setting-widget-body--option">
                    {shopInfo?.current_plan === "free" &&
                        tabs > 1 &&
                        tabs < 4 && (
                            <Box paddingInline={400}>
                                {" "}
                                <WidgetLimitNotice
                                    content={t("limit_function.widget_limit", {
                                        ns: "widget",
                                    })}
                                    btnContent={t("upgrade_plan", {
                                        ns: "translation",
                                    })}
                                />
                            </Box>
                        )}
                    <Box paddingBlock={400}>
                        <AddAppBlockSnippet
                            t={t}
                            block={"widget"}
                            collection={false}
                        />
                    </Box>
                    <div
                        className={`${
                            shopInfo?.current_plan === "free" &&
                            tabs > 1 &&
                            tabs < 4
                                ? "limit-function"
                                : ""
                        }`}
                    >
                        <Box
                            paddingInline={400}
                            background={
                                shopInfo?.current_plan === "free" &&
                                tabs > 1 &&
                                tabs < 4
                                    ? "bg-surface-disabled"
                                    : ""
                            }
                        >
                            <BlockStack>
                                {section[tabs]}
                                {tabs > 1 ? (
                                    <Text>
                                        <Trans
                                            ns="translation"
                                            key={"adjust_helptext"}
                                        >
                                            <i>
                                                To adjust the content and title,
                                                go to the{" "}
                                                <strong>
                                                    Review display &gt; Default
                                                    tab
                                                </strong>
                                                .
                                            </i>
                                        </Trans>
                                    </Text>
                                ) : (
                                    <></>
                                )}
                            </BlockStack>
                        </Box>
                    </div>
                </div>
            </Box>
        </>
    );
};

export default SettingOption;
