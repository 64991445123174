import {
    InlineGrid,
    Box,
    Icon,
    Collapsible,
    Text,
    InlineStack,
    Checkbox,
    BlockStack,
    Tag,
} from "@shopify/polaris";
import {
    ChevronDownIcon,
    ChevronRightIcon,
    SelectIcon,
} from "@shopify/polaris-icons";
const ReviewSource = ({
    t,
    open,
    handleToggle,
    reviewDisplay,
    setReviewDisplay,
    productSelectList,
    setProductSelectList,
    disabled
}) => {
    const handleSelectProduct = async () => {
        const preSelectedProd = reviewDisplay.review_of_products
            ? reviewDisplay.review_of_products.map((item) => {
                  return { id: item };
              })
            : [];
        const selected = await window.shopify.resourcePicker({
            type: "product",
            multiple: true,
            action: "select",
            filter: {
                hidden: false,
                variants: false,
                draft: true,
                archived: true,
            },
            selectionIds: preSelectedProd,
        });

        if (selected) {
            setReviewDisplay({
                ...reviewDisplay,
                review_of_products: selected?.map((product) => product.id),
            });

            let listProd = [];
            selected?.forEach((product) => {
                listProd.push({
                    id: product.id,
                    title: product.title,
                    images: [
                        {
                            id: product.images[0].id,
                            originalSrc: product.images[0].originalSrc,
                            altText: product.images[0].altText,
                        },
                    ],
                });
            });
            setProductSelectList(listProd);
        }
    };

    const handleRemoveProduct = (id) => {
        setReviewDisplay({
            ...reviewDisplay,
            review_of_products: reviewDisplay.review_of_products.filter(
                (item) => item !== id
            ),
        });
        setProductSelectList((prev) => prev.filter(item => item.id !== id));
    };

    const productSelectedListMarkup =
        productSelectList.length > 0 ? (
            <InlineStack gap="200" align="start">
                {productSelectList.map((option) => {
                    return (
                        <Tag
                            key={`option-${option.title}`}
                            onRemove={() => {
                                if(disabled) return;
                                handleRemoveProduct(option.id);
                            }}
                        >
                            <Box paddingBlock={100}>
                                <InlineStack
                                    wrap={false}
                                    gap={200}
                                    blockAlign="center"
                                >
                                    <img
                                        src={
                                            option?.images[0]?.originalSrc +
                                            "&width=20"
                                        }
                                        alt={option.title}
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                    <Text>{option.title}</Text>
                                </InlineStack>
                            </Box>
                        </Tag>
                    );
                })}
            </InlineStack>
        ) : (
            <></>
        );
        
    return (
        <>
            <Box padding={0}>
                <div
                    className="widget-setting-collape--title"
                    onClick={() => handleToggle("reviewSource")}
                >
                    <h5 className="setting-group--title">
                        {t("review_onepage.review_source.title", {
                            ns: "widget",
                        })}
                    </h5>
                    {open.reviewSource ? (
                        <Icon source={ChevronDownIcon} />
                    ) : (
                        <Icon source={ChevronRightIcon} />
                    )}
                </div>
                <Collapsible
                    open={open.reviewSource}
                    id="basic-collapsible"
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                >
                    <Box paddingBlock={400}>
                        <BlockStack gap={200}>
                            <Text>
                            {t("review_onepage.review_source.subtitle", {
                            ns: "widget",
                        })}
                            </Text>

                            <Checkbox
                                label={t("review_onepage.review_source.all_product", {ns: "widget"})}
                                checked={reviewDisplay.show_all_reviews}
                                onChange={(value) => {
                                    setReviewDisplay({
                                        ...reviewDisplay,
                                        show_all_reviews: value,
                                    });
                                }}
                                disabled={disabled}
                            />
                            <Box>
                                <div
                                    className={`custom-input ${
                                        reviewDisplay.show_all_reviews
                                            ? "quantity-disabled"
                                            : ""
                                    }`}
                                    style={{
                                        position: "relative",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        if (!reviewDisplay.show_all_reviews && !disabled) {
                                            handleSelectProduct();
                                        }
                                    }}
                                >
                                    <InlineStack
                                        align="space-between"
                                        blockAlign="center"
                                    >
                                        <Text variant="bodyMd" tone="subdued">
                                        {t("review_onepage.review_source.select_product", {ns: "widget"})}
                                        </Text>
                                        <Icon source={SelectIcon} />
                                    </InlineStack>
                                </div>
                                {!reviewDisplay.show_all_reviews &&
                                    reviewDisplay.review_of_products.length <
                                        1 && (
                                        <Text tone="critical">
                                            {t("review_onepage.review_source.warning", {ns: "widget"})}
                                        </Text>
                                    )}
                            </Box>
                            {!reviewDisplay.show_all_reviews &&
                                productSelectedListMarkup}
                            <div
                                style={{
                                    fontStyle: "italic",
                                }}
                            >
                                <Text>
                                     {t("review_onepage.review_source.help_text", {ns: "widget"})}
                                </Text>
                            </div>
                        </BlockStack>
                    </Box>
                </Collapsible>
            </Box>
        </>
    );
};

export default ReviewSource;
