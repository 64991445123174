import {
    Box,
    InlineStack,
    Button,
    ButtonGroup,
    Icon,
    Text,
} from "@shopify/polaris";
import {
    ChevronDownIcon,
    DesktopIcon,
    ExitIcon,
    ImageIcon,
    MobileIcon,
    ViewportWideIcon,
    SlideshowIcon,
    NoteIcon,
    CheckIcon,
} from "@shopify/polaris-icons";
import { useState, Suspense } from "react";
import ReviewDisplay from "../ReviewDisplay/ReviewDisplay";
import ProductRating from "../ProductRating/ProductRating";
import ReviewTestimonial from "./../ReviewTestimonial";
import ReviewOnePage from "../ReviewOnePage";
import WidgetLoading from "../../../components/Widget/WidgetLoading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppBridge } from "@shopify/app-bridge-react";
const CustomizePage = () => {
    const shopify = useAppBridge();
    const { t } = useTranslation(["widget", "translation"]);
    const [reviewDisplayTab, setReviewDisplayTab] = useState({
        tab: 0,
        show: false,
    });
    const shopInfo = useSelector((state) => state.Auth.shopInfo);
    const params = new URLSearchParams(window.location.search);
    const [saveState, setSaveState] = useState({
        status: false,
        saveFunc: null,
        loading: false,
    });
    const [viewport, setViewport] = useState("desktop");

    const sectionMarkup = [
        <ReviewDisplay
            t={t}
            reviewDisplayTab={reviewDisplayTab.tab}
            shopInfo={shopInfo}
            viewport={viewport}
            setSaveState={setSaveState}
        />,
        <Suspense fallback={<WidgetLoading />}>
            <ProductRating
                t={t}
                shopInfo={shopInfo}
                viewport={viewport}
                setSaveState={setSaveState}
            />
        </Suspense>,
        <Suspense fallback={<WidgetLoading />}>
            <ReviewTestimonial
                t={t}
                shopInfo={shopInfo}
                setSaveState={setSaveState}
                viewport={viewport}
            />
        </Suspense>,
        <Suspense fallback={<WidgetLoading />}>
            <ReviewOnePage
                t={t}
                shopInfo={shopInfo}
                viewport={viewport}
                setSaveState={setSaveState}
            />
        </Suspense>,
    ];

    const page = [
        t("Review Display"),
        t("Product Rating"),
        t("Testimonial"),
        t("Review Page"),
    ];

    const reviewDisplaySection = [
        {
            content: (
                <>
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                width: "13px",
                                height: "13px",
                            }}
                        >
                            <rect
                                x="1"
                                y="1"
                                width="14"
                                height="14"
                                rx="2"
                                stroke="#4A4A4A"
                                strokeWidth="1.5"
                            />
                            <g clipPath="url(#clip0_24492_61281)">
                                <rect
                                    x="3.5"
                                    y="3.5"
                                    width="3.85714"
                                    height="3.85714"
                                    rx="0.642857"
                                    fill="#4A4A4A"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.36508 4.18214C5.39052 4.1221 5.47522 4.12227 5.50043 4.1824L5.62875 4.4885C5.63908 4.51314 5.66183 4.53025 5.68826 4.53324L6.0094 4.56959C6.07161 4.57663 6.09715 4.65364 6.05159 4.6968L5.8062 4.92933C5.78774 4.94682 5.77955 4.97263 5.78453 4.99762L5.8516 5.3343C5.86418 5.39745 5.79598 5.44544 5.74128 5.41192L5.4689 5.24501C5.4455 5.23067 5.41611 5.23061 5.39265 5.24485L5.11961 5.41065C5.06478 5.44394 4.99678 5.3957 5.0096 5.3326L5.07794 4.99611C5.08302 4.97114 5.07493 4.94529 5.05655 4.92774L4.81204 4.69421C4.76665 4.65085 4.7925 4.57394 4.85474 4.56716L5.17593 4.53216C5.20237 4.52928 5.2252 4.51226 5.23563 4.48766L5.36508 4.18214Z"
                                    fill="#E3E3E3"
                                />
                                <rect
                                    x="4.14062"
                                    y="6.0752"
                                    width="2.57143"
                                    height="0.642857"
                                    rx="0.321429"
                                    fill="#E3E3E3"
                                />
                                <g clipPath="url(#clip1_24492_61281)">
                                    <rect
                                        x="8"
                                        y="3.5"
                                        width="4.5"
                                        height="9"
                                        rx="0.642857"
                                        fill="#4A4A4A"
                                    />
                                    <rect
                                        x="8.5"
                                        y="4.52832"
                                        width="3.63673"
                                        height="0.991837"
                                        rx="0.495918"
                                        fill="#E3E3E3"
                                    />
                                    <rect
                                        x="8.5"
                                        y="6.51221"
                                        width="3.63673"
                                        height="0.991837"
                                        rx="0.495918"
                                        fill="#E3E3E3"
                                    />
                                    <rect
                                        x="8.5"
                                        y="8.49561"
                                        width="3.63673"
                                        height="0.991837"
                                        rx="0.495918"
                                        fill="#E3E3E3"
                                    />
                                    <rect
                                        x="8.5"
                                        y="10.4795"
                                        width="3.63673"
                                        height="0.991837"
                                        rx="0.495918"
                                        fill="#E3E3E3"
                                    />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_24492_61281">
                                    <rect
                                        x="3.5"
                                        y="3.5"
                                        width="9"
                                        height="9"
                                        rx="0.642857"
                                        fill="white"
                                    />
                                </clipPath>
                                <clipPath id="clip1_24492_61281">
                                    <rect
                                        x="8"
                                        y="3.5"
                                        width="4.5"
                                        height="9"
                                        rx="0.642857"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <Text>
                        {t("review_display.tabs.default", {
                            ns: "widget",
                        })}
                    </Text>
                </>
            ),
        },
        {
            content: (
                <>
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                width: "13px",
                                height: "13px",
                            }}
                        >
                            <rect
                                x="1"
                                y="1"
                                width="14"
                                height="14"
                                rx="1"
                                stroke="#4A4A4A"
                                strokeWidth="1.5"
                                strokeDasharray="2 2"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.68807 5.20971C7.80679 4.92952 8.20206 4.93028 8.31971 5.21092L8.91854 6.63939C8.96673 6.75435 9.07292 6.83418 9.19623 6.84814L10.6949 7.01777C10.9852 7.05062 11.1044 7.41001 10.8918 7.61145L9.74662 8.69658C9.66049 8.77818 9.62228 8.89864 9.64554 9.01528L9.95853 10.5864C10.0172 10.8812 9.69894 11.1051 9.44368 10.9486L8.17257 10.1697C8.06337 10.1028 7.92621 10.1025 7.81674 10.169L6.54254 10.9427C6.28667 11.0981 5.96934 10.8729 6.02915 10.5785L6.34812 9.00821C6.37179 8.89167 6.33406 8.77107 6.24828 8.68917L5.10721 7.59933C4.89539 7.39702 5.01603 7.03808 5.30649 7.00643L6.80537 6.84312C6.92877 6.82968 7.03533 6.75024 7.08398 6.63543L7.68807 5.20971Z"
                                stroke="#4A4A4A"
                            />
                        </svg>
                    </div>

                    <Text>
                        {t("review_display.tabs.no_reviews", {
                            ns: "widget",
                        })}
                    </Text>
                </>
            ),
        },
        {
            content: (
                <>
                    <Box>
                        <Icon source={ImageIcon} />
                    </Box>
                    <Text>
                        {t("review_display.tabs.modal.all_medias", {
                            ns: "widget",
                        })}
                    </Text>
                </>
            ),
        },
        {
            content: (
                <>
                    <Box>
                        <Icon source={SlideshowIcon} />
                    </Box>
                    <Text>
                        {t("review_display.tabs.modal.review_detail", {
                            ns: "widget",
                        })}
                    </Text>
                </>
            ),
        },
        {
            content: (
                <>
                    <Box>
                        <Icon source={NoteIcon} />
                    </Box>
                    <Text>
                        {t("review_display.tabs.modal.form_write_review", {
                            ns: "widget",
                        })}
                    </Text>
                </>
            ),
        },
    ];
    const otherPage = (
        <>
            <InlineStack gap={100} wrap={false} blockAlign="center">
                <Text variant="headingMd">{page[params.get("section")]}</Text>
            </InlineStack>
        </>
    );

    const reviewPage = (
        <div
            style={{
                position: "relative",
            }}
        >
            <div
                onClick={() =>
                    setReviewDisplayTab({
                        ...reviewDisplayTab,
                        show: !reviewDisplayTab.show,
                    })
                }
                style={{
                    cursor: "pointer",
                }}
            >
                <InlineStack gap={100} wrap={false} blockAlign="center">
                    {reviewDisplaySection[reviewDisplayTab.tab].content}
                    <Icon source={ChevronDownIcon} />
                </InlineStack>
            </div>
            <div
                style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "250%",
                    backgroundColor: "white",
                    boxShadow: "0px 0 6px 0px rgba(0, 0, 0, 0.2)",
                    zIndex: 100,
                    borderRadius: "8px",
                    padding: "6px",
                }}
                className={`widget-review-display__tab-selected ${
                    reviewDisplayTab.show ? "show" : ""
                }`}
            >
                {reviewDisplaySection.map((item, index) => (
                    <div
                        key={`widget-review-display--${index}`}
                        className="widget-review-display__tab-item"
                        onClick={() => {
                            setReviewDisplayTab((prev) => ({
                                tab: index,
                                show: !prev.show,
                            }));
                        }}
                    >
                        <InlineStack
                            align="space-between"
                            gap={100}
                            wrap={false}
                        >
                            <Box>
                                <InlineStack gap={100} blockAlign="center">
                                    {item.content}
                                </InlineStack>
                            </Box>
                            {index === reviewDisplayTab.tab && (
                                <Box>
                                    {" "}
                                    <Icon source={CheckIcon} />
                                </Box>
                            )}
                        </InlineStack>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <>
            <div
                style={{
                    width: "100%",
                    position: "relative",
                }}
                className="setting-widget--container"
            >
                <div
                    className="setting-widget--body"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "98vh",
                        overflow: "hidden",
                    }}
                >
                    <div
                        style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 100,
                            backgroundColor: "white",
                            boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.05)",
                        }}
                    >
                        <Box paddingBlock={300} paddingInline={400}>
                            <InlineStack
                                align="space-between"
                                blockAlign="center"
                                gap={400}
                                wrap={true}
                            >
                                <Button
                                    icon={ExitIcon}
                                    variant="monochromePlain"
                                    onClick={() =>
                                        shopify.modal.hide(
                                            "widget-setting--modal"
                                        )
                                    }
                                >
                                    Exit
                                </Button>
                                <Box>
                                    {" "}
                                    <InlineStack
                                        gap={100}
                                        wrap={false}
                                        blockAlign="center"
                                    >
                                        {Number(params.get("section")) !== 0
                                            ? otherPage
                                            : reviewPage}
                                    </InlineStack>
                                </Box>
                                <Box>
                                    <InlineStack blockAlign="center" gap={150}>
                                        <ButtonGroup variant="segmented">
                                            <Button
                                                icon={DesktopIcon}
                                                onClick={() =>
                                                    setViewport("desktop")
                                                }
                                                pressed={viewport === "desktop"}
                                            />
                                            <Button
                                                icon={MobileIcon}
                                                onClick={() =>
                                                    setViewport("mobile")
                                                }
                                                pressed={viewport === "mobile"}
                                            />
                                            <Button
                                                icon={ViewportWideIcon}
                                                onClick={() =>
                                                    setViewport("max")
                                                }
                                                pressed={viewport === "max"}
                                            />
                                        </ButtonGroup>
                                        <Button
                                            variant="primary"
                                            disabled={!saveState.status}
                                            onClick={() => saveState.saveFunc()}
                                            loading={saveState.loading}
                                        >
                                            Save
                                        </Button>
                                    </InlineStack>
                                </Box>
                            </InlineStack>
                        </Box>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            height: "calc(100% - 52px)",
                        }}
                    >
                        <div
                            style={{
                                height: "100%",
                            }}
                        >
                            {sectionMarkup[params.get("section")]}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CustomizePage;
