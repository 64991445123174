import { Text, Box } from "@shopify/polaris";
import { SkeletonDisplayText } from "@shopify/polaris";
const StatisticsBlock = ({
    label,
    oldValue,
    value,
    minWidth,
    loading,
    item,
}) => {
    // return (
    //     <>
    //         {oldValue !== undefined ? (
    //             <Box minWidth={minWidth}>
    //                 <Text variant="bodyLg" tone="subdued">
    //                     {label}
    //                 </Text>
    //                 {loading ? (
    //                    <div style={{height: "24px"}}>
    //                    <SkeletonDisplayText size="small" />
    //                </div>
    //                 ) : (
    //                     <InlineStack gap={100} blockAlign="center">
    //                         <Text variant="headingLg">{value}</Text>
    //                         <InlineStack wrap={false} blockAlign="center">
    //                             {value > oldValue ? (
    //                                 <>
    //                                     <Icon
    //                                         source={ArrowUpMinor}
    //                                         tone="success"
    //                                     />
    //                                     <Text tone="subdued" variant="bodyLg">
    //                                         {oldValue > 0
    //                                             ? (
    //                                                   ((value - oldValue) /
    //                                                       oldValue) *
    //                                                   100
    //                                               ).toFixed(1)
    //                                             : 100}
    //                                         %
    //                                     </Text>
    //                                 </>
    //                             ) : value === oldValue ? (
    //                                 <>
    //                                     <Icon
    //                                         source={MinusMajor}
    //                                         tone="caution"
    //                                     />
    //                                     <Text tone="subdued" variant="bodyLg">
    //                                         0 %
    //                                     </Text>
    //                                 </>
    //                             ) : (
    //                                 <>
    //                                     <Icon
    //                                         source={ArrowDownMinor}
    //                                         tone="critical"
    //                                     />
    //                                     <Text tone="subdued" variant="bodyLg">
    //                                         {oldValue > 0
    //                                             ? (
    //                                                   ((oldValue - value) /
    //                                                       oldValue) *
    //                                                   100
    //                                               ).toFixed(1)
    //                                             : (oldValue - value) * 100}
    //                                         %
    //                                     </Text>
    //                                 </>
    //                             )}
    //                         </InlineStack>
    //                     </InlineStack>
    //                 )}
    //             </Box>
    //         ) : (
    //             <>
    //                 <Box minWidth={minWidth}>
    //                     <Text variant="bodyLg" tone="subdued">
    //                         {label}
    //                     </Text>
    //                     {loading ? (
    //                         <div style={{height: "24px"}}>
    //                             <SkeletonDisplayText size="small" />
    //                         </div>
    //                     ) : (
    //                         <Text variant="headingLg">
    //                             {Number(value).toFixed(1)}
    //                         </Text>
    //                     )}
    //                 </Box>
    //             </>
    //         )}
    //     </>
    // );
    return (
        <>
            <Box minWidth={minWidth}>
                <Text variant="bodyLg" tone="subdued">
                    {label}
                </Text>
                {loading ? (
                    <div style={{ height: "24px" }}>
                        <SkeletonDisplayText size="small" />
                    </div>
                ) : item === "avg" ? (
                    <Text variant="headingLg">
                        {Number(value).toFixed(1)}
                    </Text>
                ) : (
                    <Text variant="headingLg">{value}</Text>
                )}
            </Box>
        </>
    );
};

export default StatisticsBlock;
