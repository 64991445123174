import {
    Text,
    Box,
    Button,
    ButtonGroup,
    BlockStack,
    InlineStack,
    Card,
} from "@shopify/polaris";
import { XIcon } from "@shopify/polaris-icons";
import Default from "./Default/Default";
import { useState } from "react";
import { Trans } from "react-i18next";
import listFunction from "../../../../config/pricingListFunction";
import WidgetLimitNotice from "../../../../components/Pricing/WidgetLimitNotice";
const SettingOption = ({
    t,
    open,
    setOpen,
    multiDisplaySetting,
    displaySetting,
    contentValue,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleShowHideReviewFilter,
    handleMultiDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
    listTabFilter,
    handleQueueFilter,
    handleDragEnd,
    handleDisplaySetting,
    setMultiDisplaySetting,
    initReviewDisplay,
    setSessionColor,
    tabs,
    productSelectList,
    setProductSelectList,
    shopInfo,
}) => {
    const section = [
        <Default
            t={t}
            open={open}
            setOpen={setOpen}
            handleToggle={handleToggle}
            reviewDisplay={reviewDisplay}
            setReviewDisplay={setReviewDisplay}
            displaySetting={displaySetting}
            handleShowHideInfo={handleShowHideInfo}
            handleShowHideReviewFilter={handleShowHideReviewFilter}
            multiDisplaySetting={multiDisplaySetting}
            handleMultiDisplaySetting={handleMultiDisplaySetting}
            listTabFilter={listTabFilter}
            handleQueueFilter={handleQueueFilter}
            handleDragEnd={handleDragEnd}
            setMultiDisplaySetting={setMultiDisplaySetting}
            contentValue={contentValue}
            handleInputChange={handleInputChange}
            handleDisplaySetting={handleDisplaySetting}
            initReviewDisplay={initReviewDisplay}
            setSessionColor={setSessionColor}
            productSelectList={productSelectList}
            setProductSelectList={setProductSelectList}
            disabled={!listFunction?.review_page[shopInfo?.current_plan]}
        />,
    ];

    const [openGuild, setOpenGuild] = useState(false);

    return (
        <>
            {shopInfo?.current_plan === "free" && (
                <Box paddingInline={400}>
                    <WidgetLimitNotice
                        content={t("limit_function.widget_limit", {
                            ns: "widget",
                        })}
                        btnContent={t("upgrade_plan", {
                            ns: "translation",
                        })}
                    />
                </Box>
            )}
            <Box as="div" paddingBlockEnd={400}>
                <div
                    className={`setting-widget-body--option ${
                        !listFunction?.review_page[shopInfo?.current_plan]
                            ? "limit-function"
                            : ""
                    }`}
                >
                    <Box paddingBlockStart={400} paddingInline={400}>
                        <Button
                            variant="primary"
                            onClick={() => setOpenGuild(true)}
                            fullWidth
                            disabled={
                                !listFunction?.review_page[
                                    shopInfo?.current_plan
                                ]
                            }
                        >
                            {t("add_widget", { ns: "translation" })}
                        </Button>
                    </Box>
                    <Box paddingInline={400}>
                        <BlockStack>
                            {section[0]}
                        </BlockStack>
                    </Box>
                </div>
            </Box>
            {openGuild && (
                <div id="add-app-block--guild">
                    <Card padding={0}>
                        <Box padding={400} background="bg">
                            <InlineStack
                                align="space-between"
                                blockAlign="center"
                                gap={400}
                            >
                                <Text variant="headingMd">
                                    {t(
                                        `product_rating.modal_add_rating_manually.onepage_title`,
                                        { ns: "widget" }
                                    )}
                                </Text>
                                <Button
                                    icon={XIcon}
                                    variant="plain"
                                    onClick={() => setOpenGuild(false)}
                                ></Button>
                            </InlineStack>
                        </Box>
                        <Box padding={400}>
                            <BlockStack gap={200}>
                                <Text>
                                    <Trans
                                        i18nKey={`product_rating.modal_add_rating_manually.guild_onepage`}
                                        ns="widget"
                                        components={{
                                            1: (
                                                <a
                                                    href="https://help.trustshop.io/en/article/how-to-create-a-reviews-page-n0xffi/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    here
                                                </a>
                                            ),
                                        }}
                                    />
                                </Text>
                            </BlockStack>
                        </Box>
                        <Box
                            padding={400}
                            borderBlockStartWidth="0165"
                            borderColor="border"
                        >
                            <InlineStack
                                gap={200}
                                blockAlign="center"
                                align="end"
                            >
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        window.open(
                                            "https://help.trustshop.io/en/article/how-to-create-a-reviews-page-n0xffi/",
                                            "_blank"
                                        )
                                    }
                                >
                                    {t("view_guide", { ns: "translation" })}
                                </Button>
                            </InlineStack>
                        </Box>
                    </Card>
                </div>
            )}
        </>
    );
};

export default SettingOption;
