import {
    Box,
    InlineGrid,
    Spinner,
    InlineStack,
    Icon,
    Text,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import Demo from "./Demo";
import Setting from "./Setting";
import useEffectSettingProductRating from "./useEffectSettingProductRating";
import { useEffect, useState } from "react";

const ProductRating = ({ t, shopInfo, setSaveState, viewport }) => {
    const {
        initProductRating,
        productRating,
        setProductRating,
        handleSaveProductRating,
        handleChangeInput,
        validateInput,
        isLoading,
    } = useEffectSettingProductRating();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    useEffect(() => {
        if (
            JSON.stringify(productRating) !== JSON.stringify(initProductRating)
        ) {
            setSaveState((prev) => ({
                ...prev,
                status: true,
                saveFunc: handleSaveProductRating,
            }));
        } else {
            setSaveState((prev) => ({
                ...prev,
                status: false,
                saveFunc: handleSaveProductRating,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productRating, initProductRating]);

    useEffect(() => {
        setSaveState((prev) => ({
            ...prev,
            loading: isLoading,
        }));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <>
            {!isLoading.productRating ? (
                <div className="product-rating--container">
                    <InlineGrid
                        columns={
                            viewport === "max"
                                ? 1
                                : {
                                      xs: "1fr",
                                      sm: "1fr",
                                      lg: "2fr 6fr",
                                      xl: "2fr 6fr",
                                  }
                        }
                    >
                        <div
                            className={`product-rating-setting--field ${
                                showMobileMenu ? "hide" : ""
                            }`}
                            style={{
                                display: viewport !== "max" ? "block" : "none",
                                borderTop: "1px solid #dfe3e8",
                            }}
                        >
                            <div
                                onClick={() =>
                                    setShowMobileMenu(!showMobileMenu)
                                }
                                className="desktop-hidden"
                                style={{
                                    cursor: "pointer",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 999,
                                    left: 0,
                                    right: 0,
                                    borderBottom: "1px solid #e5e5e5",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 4px 6px 0px rgba(0,0,0,.1)",
                                }}
                            >
                                <Box paddingBlock={400} paddingInline={400}>
                                    <InlineStack
                                        align="space-between"
                                        blockAlign="center"
                                        gap={400}
                                    >
                                        <Text as="h5" variant="headingMd">
                                            {t("review_display.widget_area", {
                                                ns: "widget",
                                            })}
                                        </Text>
                                        <Box>
                                            <Icon
                                                source={
                                                    !showMobileMenu
                                                        ? ChevronDownIcon
                                                        : ChevronUpIcon
                                                }
                                                tone="base"
                                            />
                                        </Box>
                                    </InlineStack>
                                </Box>
                            </div>

                            <div
                                className={`setting-widget-body--container ${
                                    !showMobileMenu ? "hide" : ""
                                }`}
                            >
                                <Setting
                                    productRating={productRating}
                                    setProductRating={setProductRating}
                                    t={t}
                                    handleChangeInput={handleChangeInput}
                                    validateInput={validateInput}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                height: "100%",
                                overflow: "hidden",
                                padding: "16px 12px",
                                backgroundColor:
                                    "var(--p-color-input-bg-surface-active)",
                            }}
                        >
                            <div className="product-rating-demo--field">
                                <Demo
                                    productRating={productRating}
                                    t={t}
                                    shopInfo={shopInfo}
                                />
                            </div>
                        </div>
                    </InlineGrid>
                </div>
            ) : (
                <div
                    style={{
                        width: "100%",
                        height: "80vh",
                        maxHeight: "1080px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner />
                </div>
            )}
        </>
    );
};

export default ProductRating;
