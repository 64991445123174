import { Box, Divider, Text, BlockStack, TextField } from "@shopify/polaris";

const ContentReview = ({
    t,
    contentValue,
    displaySetting,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
}) => {
    return (
        <div className="setting-component">
            <BlockStack gap={300}>
                <Text as="h4" fontWeight="semibold">
                    {t("review_display.content.content_review.title", {
                        ns: "widget",
                    })}
                </Text>
                <Divider borderColor="border-subdued" />
            </BlockStack>
            <Box paddingBlockStart={100} paddingInlineEnd={0}>
                <BlockStack gap={300}>

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text Anonymous(When the reviewer anonymously)
                            </Text>
                        }
                        name="anonymous"
                        value={reviewDisplay?.label_content?.anonymous}
                        onChange={(e) => handleInputChange(e, "anonymous")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text review on
                            </Text>
                        }
                        name="text_review_on"
                        value={reviewDisplay?.label_content?.text_review_on}
                        onChange={(e) => handleInputChange(e, "text_review_on")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text people found this helpful
                            </Text>
                        }
                        name="text_people_found_helpful"
                        value={reviewDisplay?.label_content?.text_people_found_helpful}
                        onChange={(e) => handleInputChange(e, "text_people_found_helpful")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text helpful
                            </Text>
                        }
                        name="text_helpful"
                        value={reviewDisplay?.label_content?.text_helpful}
                        onChange={(e) => handleInputChange(e, "text_helpful")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text report
                            </Text>
                        }
                        name="text_report"
                        value={reviewDisplay?.label_content?.text_report}
                        onChange={(e) => handleInputChange(e, "text_report")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text reply from 
                            </Text>
                        }
                        name="text_reply_from"
                        value={reviewDisplay?.label_content?.text_reply_from}
                        onChange={(e) => handleInputChange(e, "text_reply_from")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Button loadmore review text
                            </Text>
                        }
                        name="btn_load_more_review"
                        value={
                            reviewDisplay?.label_content?.btn_load_more_review
                        }
                        onChange={(e) =>
                            handleInputChange(e, "btn_load_more_review")
                        }
                    />
                </BlockStack>
            </Box>
        </div>
    );
};

export default ContentReview;
