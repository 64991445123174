import { Text, Icon, InlineStack, Link, Tooltip } from "@shopify/polaris";
import { AlertCircleIcon } from "@shopify/polaris-icons";
import { lazy, Suspense, useState } from "react";
import { Trans } from "react-i18next";
const PricingModal = lazy(() => import("./PricingPage"));
const BadgeLimit = ({ shopInfo, t }) => {
    const [showPricing, setShowPricing] = useState(false);
    return (
        <>
            <div
                style={{
                    color: "var(--p-color-text-emphasis)",
                    display: "flex",
                    flexWrap: "nowrap",
                    backgroundColor: "#E4EEFC",
                    padding: "2px 8px 2px 3px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    position: "relative",
                }}
                className="badge-limit"
            >
                <Icon source={AlertCircleIcon} />
                <span tone="emphasis">
                    {t("limit_function.setting_function_limit.badge", {
                        ns: "widget",
                        plan: t("plan.basic", {ns: "pricing"})
                    })}
                </span>

                <div
                    className="badge-limit--content"
                    style={{
                        position: "absolute",
                        backgroundColor: "var(--p-color-bg-surface)",
                        color: "var(--p-color-text)",
                        wordBreak: "break-word",
                        borderRadius: "4px",
                        padding: "4px 8px",
                        boxShadow: "var(--p-shadow-300)",
                        top: "calc(100% + 0.4rem)",
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}
                >
                    <svg
                        className="Polaris-Tooltip-TooltipOverlay__Tail"
                        width="19"
                        height="11"
                        fill="none"
                        style={{
                            position: "absolute",
                            top: "calc(var(--p-space-200)*-1 + 0.05rem)",
                            zIndex: 2,
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <path
                            d="M18.829 8.171 11.862.921A3 3 0 0 0 7.619.838L0 8.171h1.442l6.87-6.612a2 2 0 0 1 2.83.055l6.3 6.557h1.387Z"
                            fill="#E3E3E3"
                        ></path>
                        <path
                            d="M17.442 10.171h-16v-2l6.87-6.612a2 2 0 0 1 2.83.055l6.3 6.557v2Z"
                            fill="var(--p-color-bg-surface)"
                        ></path>
                    </svg>
                    <Text as="span">
                        {t("limit_function.setting_function_limit.content", {
                            ns: "widget",
                        })}
                    </Text>
                    <span
                        style={{
                            color: "var(--p-color-text-emphasis)",
                            cursor: "pointer"
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowPricing(true);
                        }}
                        className="badge-limit--link"
                    >
                        {t("upgrade_plan", {
                            ns: "translation",
                        })}
                    </span>
                </div>
            </div>
            {showPricing && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        backgroundColor: "#00000080",
                        zIndex: 999,
                        cursor: "default",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        style={{
                            width: "fix-content",
                            maxWidth: "90%",
                            height: "90%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            flexDirection: "column",
                            borderRadius: "12px",
                        }}
                    >
                        <Suspense fallback="loading">
                            <PricingModal
                                shopInfo={shopInfo}
                                open={showPricing}
                                setShowPricing={setShowPricing}
                                isModal={true}
                            />
                        </Suspense>
                    </div>
                </div>
            )}
        </>
    );
};
export default BadgeLimit;
