import {
    Box,
    Icon,
    Collapsible,
    RadioButton,
    InlineGrid,
    BlockStack,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import LayoutSkeleton from "../../../../../components/SkeletonComponent/Layout";
import SummaryBoxSkeleton from "../../../../../components/SkeletonComponent/SummaryBox";
import BadgeLimit from "../../../../../components/Pricing/BadgeLimit";
import listFunction from "../../../../../config/pricingListFunction";
const DisplayStyle = ({
    t,
    open,
    setOpen,
    reviewDisplay,
    setReviewDisplay,
    handleToggle,
    shopInfo,
}) => {
    const handleSkeletonClick = (type, option) => {
        if (type === "layout_box") {
            option === "list"
                ? document.getElementById("list").click()
                : document.getElementById("grid").click();
        } else {
            option === "left"
                ? document.getElementById("left").click()
                : document.getElementById("top").click();
        }
    };

    const handleLayoutChange = (_, newValue) => {
        setReviewDisplay({
            ...reviewDisplay,
            layout_box: newValue,
        });
    };

    const handleSummaryChange = (_, newValue) => {
        setReviewDisplay({
            ...reviewDisplay,
            layout_summary_box: newValue,
        });
    };

    return (
        <>
            <InlineGrid
                align="space-between"
                columns={{ xs: "1fr", sm: "5fr 5fr", lg: "1fr" }}
                gap={{
                    xs: 0,
                    sm: "400",
                    lg: 0,
                }}
            >
                <Box padding={0}>
                    <div
                        className="widget-setting-collape--title"
                        onClick={() => handleToggle("layout")}
                    >
                        <h5 className="setting-group--title">
                            {t("review_display.choose_layout.title", {
                                ns: "widget",
                            })}
                        </h5>
                        {!listFunction?.customize_widget?.detail?.widgetBox
                            .choose_layout[shopInfo?.current_plan] && (
                            <BadgeLimit shopInfo={shopInfo} t={t} />
                        )}
                        {open.layout ? (
                            <Icon source={ChevronDownIcon} />
                        ) : (
                            <Icon source={ChevronRightIcon} />
                        )}
                    </div>
                    <Collapsible
                        open={open.layout}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                    >
                        <Box paddingBlockStart={400}>
                            <BlockStack gap={200} columns={2}>
                                <div
                                    className={`setting-layout--item ${
                                        !listFunction?.customize_widget?.detail
                                            ?.widgetBox.choose_layout[
                                            shopInfo?.current_plan
                                        ]
                                            ? "limit-function"
                                            : ""
                                    }`}
                                >
                                    <div
                                        className="skeleton-field"
                                        onClick={() => {
                                            if (
                                                !listFunction?.customize_widget
                                                    ?.detail?.widgetBox
                                                    .choose_layout[
                                                    shopInfo?.current_plan
                                                ]
                                            )
                                                return;
                                            handleSkeletonClick(
                                                "layout_box",
                                                "list"
                                            );
                                        }}
                                    >
                                        <div
                                            id="setting-widget-layout--list"
                                            className={
                                                reviewDisplay.layout_box ===
                                                "list"
                                                    ? "layout-enable"
                                                    : ""
                                            }
                                        >
                                            <LayoutSkeleton />
                                            <LayoutSkeleton />
                                        </div>
                                    </div>
                                    <RadioButton
                                        name="choose-layout"
                                        id="list"
                                        onChange={handleLayoutChange}
                                        label={t(
                                            "review_display.choose_layout.list",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                        checked={
                                            reviewDisplay.layout_box === "list"
                                        }
                                        disabled={
                                            !listFunction?.customize_widget
                                                ?.detail?.widgetBox
                                                .choose_layout[
                                                shopInfo?.current_plan
                                            ]
                                        }
                                    />
                                </div>
                                <div
                                    className={`setting-layout--item ${
                                        !listFunction?.customize_widget?.detail
                                            ?.widgetBox.choose_layout[
                                            shopInfo?.current_plan
                                        ]
                                            ? "limit-function"
                                            : ""
                                    }`}
                                >
                                    <div
                                        className="skeleton-field"
                                        onClick={() => {
                                            if (
                                                !listFunction?.customize_widget
                                                    ?.detail?.widgetBox
                                                    .choose_layout[
                                                    shopInfo?.current_plan
                                                ]
                                            )
                                                return;
                                            handleSkeletonClick(
                                                "layout_box",
                                                "grid"
                                            );
                                        }}
                                    >
                                        <div
                                            id="setting-widget-layout--grid"
                                            className={
                                                reviewDisplay.layout_box ===
                                                "grid"
                                                    ? "layout-enable"
                                                    : ""
                                            }
                                        >
                                            <LayoutSkeleton />
                                            <LayoutSkeleton />
                                        </div>
                                    </div>
                                    <RadioButton
                                        name="choose-layout"
                                        id="grid"
                                        onChange={handleLayoutChange}
                                        label={t(
                                            "review_display.choose_layout.grid",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                        checked={
                                            reviewDisplay.layout_box === "grid"
                                        }
                                        disabled={!listFunction?.customize_widget?.detail
                                            ?.widgetBox.choose_layout[
                                            shopInfo?.current_plan
                                        ]}
                                    />
                                </div>
                            </BlockStack>
                        </Box>
                    </Collapsible>
                </Box>
                <Box padding={0}>
                    <div
                        className="widget-setting-collape--title"
                        onClick={() => handleToggle("summary")}
                    >
                        <h5 className="setting-group--title">
                            {t("review_display.summary_box.title", {
                                ns: "widget",
                            })}
                        </h5>
                        {!listFunction?.customize_widget?.detail?.widgetBox
                            .summary_box[shopInfo?.current_plan] && (
                            <BadgeLimit shopInfo={shopInfo} t={t} />
                        )}
                        {open.summary ? (
                            <Icon source={ChevronDownIcon} />
                        ) : (
                            <Icon source={ChevronRightIcon} />
                        )}
                    </div>
                    <Collapsible
                        open={open.summary}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                    >
                        <Box paddingBlockStart={400}>
                            <BlockStack gap={200} columns={2}>
                                <div
                                    className={`setting-layout--item ${
                                        !listFunction?.customize_widget?.detail
                                            ?.widgetBox.summary_box[
                                            shopInfo?.current_plan
                                        ]
                                            ? "limit-function"
                                            : ""
                                    }`}
                                >
                                    <div
                                        className="skeleton-field"
                                        onClick={() => {
                                            if (
                                                !listFunction?.customize_widget
                                                    ?.detail?.widgetBox
                                                    .summary_box[
                                                    shopInfo?.current_plan
                                                ]
                                            )
                                                return;
                                            handleSkeletonClick(
                                                "layout_summary_box",
                                                "left"
                                            );
                                        }}
                                    >
                                        <div
                                            id="setting-widget-summary--left"
                                            className={
                                                reviewDisplay.layout_summary_box ===
                                                "left"
                                                    ? "box-enable"
                                                    : ""
                                            }
                                        >
                                            <SummaryBoxSkeleton />
                                        </div>
                                    </div>
                                    <RadioButton
                                        name="choose-summary"
                                        id="left"
                                        onChange={handleSummaryChange}
                                        label={t(
                                            "review_display.summary_box.left",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                        checked={
                                            reviewDisplay.layout_summary_box ===
                                            "left"
                                        }
                                        disabled={
                                            !listFunction?.customize_widget
                                                ?.detail?.widgetBox.summary_box[
                                                shopInfo?.current_plan
                                            ]
                                        }
                                    />
                                </div>
                                <div
                                    className={`setting-layout--item ${
                                        !listFunction?.customize_widget?.detail
                                            ?.widgetBox.summary_box[
                                            shopInfo?.current_plan
                                        ]
                                            ? "limit-function"
                                            : ""
                                    }`}
                                >
                                    <div
                                        className="skeleton-field"
                                        onClick={() => {
                                            if (
                                                !listFunction?.customize_widget
                                                    ?.detail?.widgetBox
                                                    .summary_box[
                                                    shopInfo?.current_plan
                                                ]
                                            )
                                                return;
                                            handleSkeletonClick(
                                                "layout_summary_box",
                                                "top"
                                            );
                                        }}
                                    >
                                        <div
                                            id="setting-widget-summary--top"
                                            className={
                                                reviewDisplay.layout_summary_box ===
                                                "top"
                                                    ? "box-enable"
                                                    : ""
                                            }
                                        >
                                            <SummaryBoxSkeleton />
                                        </div>
                                    </div>
                                    <RadioButton
                                        name="choose-summary"
                                        id="top"
                                        onChange={handleSummaryChange}
                                        label={t(
                                            "review_display.summary_box.top",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                        checked={
                                            reviewDisplay.layout_summary_box ===
                                            "top"
                                        }
                                        disabled={
                                            !listFunction?.customize_widget
                                                ?.detail?.widgetBox.summary_box[
                                                shopInfo?.current_plan
                                            ]
                                        }
                                    />
                                </div>
                            </BlockStack>
                        </Box>
                    </Collapsible>
                </Box>
            </InlineGrid>
        </>
    );
};

export default DisplayStyle;
