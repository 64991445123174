import { Box, Divider, Text, BlockStack, TextField } from "@shopify/polaris";
const ModalImageLabel = ({
    t,
    contentValue,
    displaySetting,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
}) => {
    return (
        <div className="setting-component">
            <BlockStack gap={300}>
                <Text as="h4" fontWeight="semibold">
                    {t("review_display.content.modal_image.title", {
                        ns: "widget",
                    })}
                </Text>
                <Divider borderColor="border" />
            </BlockStack>
            <Box paddingBlockStart={100}>
                <BlockStack gap={300}>
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text image review for
                            </Text>
                        }
                        name="modal_image_review_for"
                        value={
                            reviewDisplay?.label_content?.modal_image_review_for
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_image_review_for")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text image from this review
                            </Text>
                        }
                        name="modal_image_from_this_review"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_image_from_this_review
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_image_from_this_review")
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text all media
                            </Text>
                        }
                        name="modal_view_image_text_all_media"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_view_image_text_all_media
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_view_image_text_all_media"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Label tab all
                            </Text>
                        }
                        name="text_all"
                        value={reviewDisplay?.label_content?.text_all}
                        onChange={(e) => handleInputChange(e, "text_all")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Label tab image
                            </Text>
                        }
                        name="text_image"
                        value={reviewDisplay?.label_content?.text_image}
                        onChange={(e) => handleInputChange(e, "text_image")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Label tab video
                            </Text>
                        }
                        name="text_video"
                        value={reviewDisplay?.label_content?.text_video}
                        onChange={(e) => handleInputChange(e, "text_video")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text no medias uploaded
                            </Text>
                        }
                        name="no_media"
                        value={reviewDisplay?.label_content?.no_media}
                        onChange={(e) => handleInputChange(e, "no_media")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text no images uploaded
                            </Text>
                        }
                        name="no_image"
                        value={reviewDisplay?.label_content?.no_image}
                        onChange={(e) => handleInputChange(e, "no_image")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text no videos uploaded
                            </Text>
                        }
                        name="no_video"
                        value={reviewDisplay?.label_content?.no_video}
                        onChange={(e) => handleInputChange(e, "no_video")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text purchased item
                            </Text>
                        }
                        name="purchased_item"
                        value={reviewDisplay?.label_content?.purchased_item}
                        onChange={(e) => handleInputChange(e, "purchased_item")}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text view product
                            </Text>
                        }
                        name="view_product"
                        value={reviewDisplay?.label_content?.view_product}
                        onChange={(e) => handleInputChange(e, "view_product")}
                    />
                </BlockStack>
            </Box>
        </div>
    );
};

export default ModalImageLabel;
