import { InlineStack } from "@shopify/polaris";

const FontSettingComponent = ({ style, onChange }) => {
    return (
        <InlineStack gap={100} blockAlign="center" wrap={false} align="center">
            <p
                style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    padding: "3px",
                    borderRadius: "2px",
                    backgroundColor:
                        style.font_weight === "bold"
                            ? "var(--p-color-bg-fill)"
                            : "",
                    width: "16px",
                    height: "16px",
                    lineHeight: "100%",
                    cursor: "pointer",
                    textAlign: "center",
                }}
                onClick={() => {
                    onChange(
                        "font_weight",
                        style.font_weight === "bold" ? "normal" : "bold"
                    );
                }}
            >
                B
            </p>
            <p
                style={{
                    fontSize: "10px",
                    fontStyle: "italic",
                    padding: "3px",
                    borderRadius: "2px",
                    backgroundColor:
                        style.font_style === "italic"
                            ? "var(--p-color-bg-fill)"
                            : "",
                    width: "16px",
                    height: "16px",
                    lineHeight: "100%",
                    cursor: "pointer",
                    textAlign: "center",
                }}
                onClick={() => {
                    onChange(
                        "font_style",
                        style.font_style === "italic" ? "normal" : "italic"
                    );
                }}
            >
                I
            </p>
            <p
                style={{
                    fontSize: "10px",
                    textDecoration: "underline",
                    padding: "3px",
                    borderRadius: "2px",
                    backgroundColor:
                        style.text_decoration === "underline"
                            ? "var(--p-color-bg-fill)"
                            : "",
                    width: "16px",
                    height: "16px",
                    lineHeight: "100%",
                    cursor: "pointer",
                    textAlign: "center",
                }}
                onClick={() => {
                    onChange(
                        "text_decoration",
                        style.text_decoration === "underline"
                            ? "unset"
                            : "underline"
                    );
                }}
                className={
                    style.text_decoration === "underline"
                        ? "font-setting-item--active"
                        : ""
                }
            >
                U
            </p>
        </InlineStack>
    );
};

export default FontSettingComponent;
