import { BlockStack } from "@shopify/polaris";
import ColorSchema from "./ColorPicker/ColorSchema";
import DisplayStyle from "./DisplayStyle";
import InformationDisplay from "./InformationDisplay/InformationDisplay";
import Content from "./Content";

const Default = ({
    t,
    open,
    setOpen,
    handleToggle,
    reviewDisplay,
    setReviewDisplay,
    displaySetting,
    handleShowHideInfo,
    handleShowHideReviewFilter,
    multiDisplaySetting,
    handleMultiDisplaySetting,
    listTabFilter,
    handleQueueFilter,
    handleDragEnd,
    setMultiDisplaySetting,
    contentValue,
    handleInputChange,
    handleDisplaySetting,
    initReviewDisplay,
    setSessionColor,
    shopInfo
}) => {
    return (
        <>
            <BlockStack>
                <DisplayStyle
                    t={t}
                    open={open}
                    setOpen={setOpen}
                    handleToggle={handleToggle}
                    reviewDisplay={reviewDisplay}
                    setReviewDisplay={setReviewDisplay}
                    shopInfo={shopInfo}
                />
                <ColorSchema
                    t={t}
                    open={open}
                    handleToggle={handleToggle}
                    reviewDisplay={reviewDisplay}
                    setReviewDisplay={setReviewDisplay}
                    initReviewDisplay={initReviewDisplay}
                    setSessionColor={setSessionColor}
                />
                <InformationDisplay
                    t={t}
                    open={open}
                    setOpen={setOpen}
                    handleToggle={handleToggle}
                    displaySetting={displaySetting}
                    handleShowHideInfo={handleShowHideInfo}
                    handleShowHideReviewFilter={handleShowHideReviewFilter}
                    multiDisplaySetting={multiDisplaySetting}
                    handleMultiDisplaySetting={handleMultiDisplaySetting}
                    reviewDisplay={reviewDisplay}
                    setReviewDisplay={setReviewDisplay}
                    listTabFilter={listTabFilter}
                    handleQueueFilter={handleQueueFilter}
                    handleDragEnd={handleDragEnd}
                    setMultiDisplaySetting={setMultiDisplaySetting}
                />
                <Content
                    t={t}
                    open={open}
                    setOpen={setOpen}
                    contentValue={contentValue}
                    displaySetting={displaySetting}
                    handleToggle={handleToggle}
                    handleInputChange={handleInputChange}
                    handleShowHideInfo={handleShowHideInfo}
                    handleDisplaySetting={handleDisplaySetting}
                    reviewDisplay={reviewDisplay}
                    setReviewDisplay={setReviewDisplay}
                />
            </BlockStack>
        </>
    );
};

export default Default;
