import { useEffect, useState, lazy, Suspense } from "react";
import { Box, Icon, Card, Divider, InlineStack } from "@shopify/polaris";
import { DesktopIcon, MobileIcon } from "@shopify/polaris-icons";
import Header from "./Header";
import ListReview from "./ListReview";
import convert from "color-convert";
import { listIconStyle } from "./../../../../config/listIconStyle";

const PageNoReview = lazy(() => import("./PageNoReview"));
const AllMediasDemo = lazy(() => import("./Modal/AllMedias"));
const DetailDemo = lazy(() => import("./Modal/DetailReview"));
const FormWriteReviewDemo = lazy(() => import("./Modal/FormWriteReview"));
const WidgetReview = ({
    viewport,
    reviewDisplay,
    listTabFilter,
    isLoading,
    tabs,
    shopInfo,
}) => {
    const [rootCssVariable, setRootCssVariable] = useState({});

    const [mediaQuery, setMediaQuery] = useState(
        window.matchMedia("(max-width: 599px)").matches
    );

    useEffect(() => {
        if (mediaQuery) {
            // setDevice("mobile");
        }
    }, []);

    useEffect(() => {
        let variable = {};
        Object.keys(reviewDisplay.color_customize).map((item, index) => {
            const color = convert.hex.rgb([
                reviewDisplay.color_customize[item],
            ]);

            variable = {
                ...variable,
                [`--${item}`]: `rgb(${color[0]}, ${color[1]},${color[2]})`,
            };
        });
        if(shopInfo?.button_corner_radius){
            variable = {
                ...variable,
                "--button-corner-radius": `${shopInfo?.button_corner_radius}px`,
            };
        }
        setRootCssVariable(variable);
    }, [reviewDisplay]);

    const section = [
        <div
            className={`demo-field ${viewport}`}
            style={{
                backgroundColor: "var(--bg_base_color)",
            }}
        >
            <p className={`title `}>
                {reviewDisplay.label_content.text_customer_reviews}
            </p>
            <div
                className={
                    reviewDisplay.layout_summary_box === "left"
                        ? "trustshop-layout-box--left"
                        : ""
                }
            >
                {" "}
                <Header
                    device={viewport}
                    reviewDisplay={reviewDisplay}
                    starIcon={
                        listIconStyle[shopInfo?.star_icon - 1] ||
                        listIconStyle[0]
                    }
                    shopInfo={shopInfo}
                />
                <ListReview
                    device={viewport}
                    reviewDisplay={reviewDisplay}
                    listTabFilter={listTabFilter}
                    starIcon={
                        listIconStyle[shopInfo?.star_icon - 1] ||
                        listIconStyle[0]
                    }
                    shopInfo={shopInfo}
                />
            </div>
        </div>,
        <Suspense fallback={<div>Loading...</div>}>
            <PageNoReview reviewDisplay={reviewDisplay} device={viewport} shopInfo={shopInfo} />
        </Suspense>,
        <Suspense fallback={<div>Loading...</div>}>
            <AllMediasDemo
                device={viewport}
                reviewDisplay={reviewDisplay}
                starIcon={
                    listIconStyle[shopInfo?.star_icon - 1] || listIconStyle[0]
                }
                shopInfo={shopInfo}
            />
        </Suspense>,
        <Suspense fallback={<div>Loading...</div>}>
            <DetailDemo
                device={viewport}
                reviewDisplay={reviewDisplay}
                starIcon={
                    listIconStyle[shopInfo?.star_icon - 1] || listIconStyle[0]
                }
                shopInfo={shopInfo}
            />
        </Suspense>,
        <Suspense fallback={<div>Loading...</div>}>
            <FormWriteReviewDemo
                device={viewport}
                reviewDisplay={reviewDisplay}
                starIcon={
                    listIconStyle[shopInfo?.star_icon - 1] || listIconStyle[0]
                }
                shopInfo={shopInfo}
            />
        </Suspense>,
    ];
    return (
        <>
            <Box
                paddingBlock={400}
                paddingInline={300}
                background="bg-fill-active"
            >
                <Card padding={0}>
                    <div className="demo-container" style={rootCssVariable}>
                        <div className="setting-widget-body-right--body">
                            <Box as="div" padding={0}>
                                {section[tabs]}
                            </Box>
                        </div>
                    </div>
                </Card>
            </Box>
        </>
    );
};

export default WidgetReview;
