import { useEffect, useState, useCallback, useContext } from "react";
import * as Config from "../../../api/Config";
import Http from "../../../layouts/Http";
import { useTranslation } from "react-i18next";

const useEffectOnePageSetting = () => {
    const [isLoading, setIsLoading] = useState({
        pageLoading: false,
        saveLoading: false,
    });
    const [onePageSessionColor, setOnePageSessionColor] = useState({
        light: {
            page_background_color: "#FFFFFF",
            primary_color: "#3D4153",
            secondary_color: "#FFFFFF",
            title_text_color: "#3D4153",
            all_photos_button_color: "#005BD3",
            primary_text_color: "#1A1A1A",
            secondary_text_color: "#74788D",
            star_icon_color: "#FFB400",
            line_color: "#A7ABC3",
            filter_sort_text: "#3D4153",
            filter_sort_background: "#F2F2F6",
            rating_process_color: "#FFB400",
            rating_process_empty_color: "#E7E7E7",
            user_name_color: "#303030",
            load_more_color: "#3D4153",
            product_background_color: "#F3F3F3",
            product_text_color: "#303030",
        },
        dark: {
            page_background_color: "#2E2E2E",
            primary_color: "#F4F4F4",
            secondary_color: "#2E2E2E",
            title_text_color: "#F8F8F8",
            all_photos_button_color: "#3FA0E5",
            primary_text_color: "#E7E7E7",
            secondary_text_color: "#A7ABC3",
            star_icon_color: "#FFB400",
            line_color: "#E3E3E3",
            filter_sort_background: "#E7E7E7",
            filter_sort_text: "#3D4153",
            rating_process_color: "#D8D8D8",
            rating_process_empty_color: "#4F4F4F",
            user_name_color: "#E7E7E7",
            load_more_color: "#EBEBEB",
            product_background_color: "#F3F3F3",
            product_text_color: "#303030",
            bg_base_color: "#2E2E2E",
        },
        custom: {
            page_background_color: "#FFFFFF",
            primary_color: "#3D4153",
            secondary_color: "#FFFFFF",
            title_text_color: "#3D4153",
            all_photos_button_color: "#005BD3",
            primary_text_color: "#1A1A1A",
            secondary_text_color: "#74788D",
            star_icon_color: "#FFB400",
            line_color: "#A7ABC3",
            filter_sort_text: "#3D4153",
            filter_sort_background: "#F2F2F6",
            rating_process_color: "#FFB400",
            rating_process_empty_color: "#E7E7E7",
            user_name_color: "#303030",
            load_more_color: "#3D4153",
            product_background_color: "#F3F3F3",
            product_text_color: "#303030",
        },
    });

    const [initOnePageSetting, setInitOnePageSetting] = useState({
        layout_box: "grid",
        layout_summary_box: "center",
        show_all_reviews: true,
        review_of_products: [], //list ids của products
        color_mode: "light",
        color_customize: {
            ...onePageSessionColor.light,
        },
        section_show: {
            summary_box: true,
            product: true,
            customer_name: true,
            customer_profile_picture: true,
            review_date: true,
            button_helpful: true,
            button_report: true,
            summary_star_icon: true,
            summary_number_of_reviews: true,
            summary_border: true,
            image_galley_star_rating: true,
            show_review_media: true,
            number_of_reviews: true,
            title: {
                status: true,
                content: "Our customers love us",
                size: 32,
                text_style: [],
            },
            sub_title: {
                status: true,
                content: "See what the community is saying about us",
                size: 16,
                text_style: [],
            },
        },
        sort_filter: [
            "all",
            "most_helpful",
            "highest_rating",
            "lowest_rating",
            "oldest",
            "newest",
            "review_with_photos",
            "stars_filter",
        ],
        review_per_page_desktop: 20,
        review_per_page_mobile: 10,
        country_language: "en",
        label_content: {
            text_customer_reviews: "Customer Reviews",
            text_reviews: "Reviews",
            text_review: "Review",
            text_review_this_product: "Review this product",
            text_share_your_thoughts:
                "Share your thoughts with other customers",
            btn_write_a_review: "Write a review",
            text_images_from_reviews: "Images from reviews",
            text_review_with_comments: "Review with comments",
            all_photos: "All photos",
            label_filter_all: "All",
            label_filter_most_helpful: "Most helpful",
            label_filter_highest_rating: "Highest rating",
            label_filter_lowest_rating: "Lowest rating",
            label_filter_oldest: "Oldest",
            label_filter_newest: "Newest",
            label_filter_review_with_photos: "Review with photos",
            label_filter_stars_filter: "Star filter(1-5)",
            text_review_on: "Review on",
            text_people_found_helpful: "people found this helpful",
            text_helpful: "Helpful",
            text_report: "Report",
            text_reply_from: "Reply from",
            anonymous: "Anonymous",
            btn_load_more_review: "Load more reviews",
            modal_all_media_title: "Reviews gallery",
            modal_view_image_text_all_media: "All photos",
            text_all: "All",
            text_image: "Images",
            text_video: "Videos",
            no_media: "No medias uploaded yet!",
            no_image: "No images uploaded yet!",
            no_video: "No videos uploaded yet!",
            purchased_item: "Purchased item",
            view_product: "View product",
            modal_report_title: "Report this review",
            modal_report_subtitle:
                "Thank you for taking the time to help {{ shopname }}.Your report is anonymous.",
            modal_report_option_1: "Review contains private information.",
            modal_report_option_2: "Review contains objectionable language.",
            modal_report_option_3: "Review contains advertising or spam.",
            modal_report_option_4:
                "Review contains violent and disgusting content.",
            modal_report_option_5: "Other violations.",
            modal_report_btn: "Report this review",
            modal_image_review_for: "Review for",
            modal_image_from_this_review: "Images from this review",
        },
    });

    const [onePageSetting, setOnePageSetting] = useState(initOnePageSetting);
    const { t } = useTranslation("message");
    const [saveState, setSaveState] = useState(true);
    const [productSelectList, setProductSelectList] = useState([]);
    const [open, setOpen] = useState({
        layout: false,
        summary: false,
        reviewSource: false,
        onePageHeader: false,
        colorSchema: false,
        information: false,
        content: false,
        textTabFilter: false,
        others: false,
    });
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const [multiDisplaySetting, setMultiDisplaySetting] = useState({
        information: true,
        filter: true,
    });

    const [displaySetting, setDisplaySetting] = useState({
        contentTitle: true,
        contentReviewSubmit: true,
        writeReviewBtn: true,
    });
    const [contentValue, setContentValue] = useState({
        title: "Customer Review",
        reviewSubmit: "Product image/video",
        writeReviewBtn: "Write a review",
    });

    const [listTabFilter, setListTabFilter] = useState([
        { all: "All" },
        { most_helpful: "Most Helpful" },
        { highest_rating: "Highest Rating" },
        { lowest_rating: "Lowest Rating" },
        { oldest: "Oldest" },
        { newest: "Newest" },
        { review_with_photos: "Review with Photo/Video" },
        { stars_filter: "Star filter(1-5)" },
    ]);

    const handleDisplaySetting = (name) => {
        setDisplaySetting({
            ...displaySetting,
            [name]: !displaySetting[name],
        });
    };

    const handleSaveState = () => {
        let flag = true;
        Object.keys(initOnePageSetting).map((item, index) => {
            if (
                JSON.stringify(initOnePageSetting[item]) !==
                JSON.stringify(onePageSetting[item])
            ) {
                flag = false;
            }
            return flag;
        });
        setSaveState(flag);
    };

    const handleToggle = (settingOption) => {
        setOpen({
            ...open,
            [settingOption]: !open[settingOption],
        });
    };

    const handleInputChange = (value, name) => {
        setOnePageSetting({
            ...onePageSetting,
            label_content: {
                ...onePageSetting.label_content,
                [name]: value,
            },
        });
    };

    const handleShowHideInfo = (name, type) => {
        document.getElementById(name).click();
    };

    const handleShowHideReviewFilter = (value, name) => {
        !onePageSetting.sort_filter.includes(name) && value
            ? setOnePageSetting({
                  ...onePageSetting,
                  sort_filter: [...onePageSetting.sort_filter, name],
              })
            : setOnePageSetting({
                  ...onePageSetting,
                  sort_filter: onePageSetting.sort_filter.filter(
                      (item) => item !== name
                  ),
              });
    };

    const handleMultiDisplaySetting = (value, name) => {
        if (name === "information") {
            setOnePageSetting({
                ...onePageSetting,
                section_show: {
                    ...onePageSetting.section_show,
                    customer_name: value,
                    customer_profile_picture: value,
                },
            });
        } else {
            if (!value) {
                setOnePageSetting({
                    ...onePageSetting,
                    sort_filter: [],
                });
            } else {
                setOnePageSetting({
                    ...onePageSetting,
                    sort_filter: [
                        "all",
                        "most_helpful",
                        "highest_rating",
                        "lowest_rating",
                        "oldest",
                        "newest",
                        "review_with_photos",
                        "stars_filter",
                    ],
                });
            }
        }
        setMultiDisplaySetting({
            ...multiDisplaySetting,
            [name]: value,
        });
    };

    // handle drag filter tab
    const handleListTabFilter = () => {
        const sortedData = listTabFilter.sort((a, b) => {
            const indexA = initOnePageSetting.sort_filter.indexOf(
                Object.keys(a)[0]
            );
            const indexB = initOnePageSetting.sort_filter.indexOf(
                Object.keys(b)[0]
            );
            return indexA - indexB;
        });
        setListTabFilter(sortedData);
    };

    const handleQueueFilter = () => {
        let currentList = [];
        listTabFilter.forEach((item) => {
            if (onePageSetting.sort_filter.includes(Object.keys(item)[0])) {
                currentList.push(Object.keys(item)[0]);
            }
        });

        return setOnePageSetting({
            ...onePageSetting,
            sort_filter: currentList,
        });
    };

    const handleDragEnd = useCallback(({ source, destination }) => {
        if (destination) {
            setListTabFilter((oldItems) => {
                const newItems = oldItems.slice(); // Duplicate
                const [temp] = newItems.splice(source.index, 1);
                newItems.splice(destination.index, 0, temp);
                return newItems;
            });
        }
    }, []);

    const handleNullContentValue = (res) => {
        const data = {
            ...initOnePageSetting.label_content,
            ...res,
        };
        const label_content = initOnePageSetting.label_content;

        Object.keys(label_content).forEach((item) => {
            label_content[item] = data[item] ? data[item] : "";
        });
        return label_content;
    };

    // sort list filter for first time load page
    const initSortListFilter = (arrayOrder) => {
        const sortedTabs = [...listTabFilter].sort((a, b) => {
            const keyA = Object.keys(a)[0];
            const keyB = Object.keys(b)[0];

            const indexA = arrayOrder.indexOf(keyA);
            const indexB = arrayOrder.indexOf(keyB);

            // If key not in arrayOrder, push to the end
            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            // Sort according to the order of the array
            return indexA - indexB;
        });

        setListTabFilter(sortedTabs);
    };

    //init get product list
    const initResouceFetching = async (listProduct) => {
        if (!listProduct) return;
        if (listProduct.length === 0) return;
        const res = await fetch("shopify:admin/api/graphql.json", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${await window.shopify.idToken()}`,
            },
            body: JSON.stringify({
                query: `
                {
                    nodes(ids: ${JSON.stringify(listProduct)}) {
                        ... on Product {
                        title
                        id
                        description
                        vendor
                        createdAt
                        productType
                        images(first:1) {
                            nodes{
                                originalSrc
                            }
                        }
                    }
                }
            }
              `,
            }),
        });
        const { data } = await res.json();
        let listProd = [];
        data?.nodes?.forEach((product) => {
            if (product) {
                listProd.push({
                    id: product.id,
                    title: product.title,
                    images: [
                        {
                            id: product.images.nodes[0].id,
                            originalSrc: product.images.nodes[0].originalSrc,
                            altText: product.images.nodes[0].altText,
                        },
                    ],
                });
            }
        });
        setProductSelectList(listProd);
    };

    const getonePageSetting = () => {
        setIsLoading({
            ...isLoading,
            pageLoading: true,
        });
        Http.get(`${Config.API_URL}/settings/widget/review-page`)
            .then((response) => {
                if (response.data.data) {
                    const data = {
                        ...initOnePageSetting,
                        ...response.data.data,
                        label_content: handleNullContentValue(
                            response.data.data.label_content
                        ),
                        color_customize: {
                            ...initOnePageSetting.color_customize,
                            ...response.data.data.color_customize,
                        },
                        section_show: {
                            ...initOnePageSetting.section_show,
                            ...response.data.data.section_show,
                        },
                        review_per_page_desktop: response.data.data
                            .review_per_page_desktop
                            ? response.data.data.review_per_page_desktop
                            : 6,
                        review_per_page_mobile: response.data.data
                            .review_per_page_mobile
                            ? response.data.data.review_per_page_mobile
                            : 6,
                        country_language: response.data.data.country_language
                            ? response.data.data.country_language
                            : "en",
                        color_mode: response.data.data.color_mode || "custom",
                    };
                    initSortListFilter(data.sort_filter);
                    setInitOnePageSetting(data);
                    setOnePageSetting(data);
                    setOnePageSessionColor((prevSessionColor) => ({
                        ...prevSessionColor,
                        [response.data.data.color_mode || "light"]: {
                            ...prevSessionColor.custom,
                            ...response.data.data.color_customize,
                        },
                    }));
                    initResouceFetching(response.data.data.review_of_products);
                } else {
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                }
            })
            .catch((e) => {
                window.shopify.toast.show(
                    t("toast.server_error", { ns: "message" }),
                    { duration: 3000 }
                );
            })
            .finally(() => {
                setIsLoading({
                    ...isLoading,
                    pageLoading: false,
                });
            });
    };

    const handleSaveOnePageSetting = () => {
        setIsLoading({
            ...isLoading,
            saveLoading: true,
        });
        Http.post(
            `${Config.API_URL}/settings/widget/review-page`,
            onePageSetting
        )
            .then((response) => {
                if (response.data.status) {
                    setInitOnePageSetting(onePageSetting);
                    setShowMobileMenu(false);
                    window.shopify.toast.show(
                        t("toast.saved", { ns: "message" }),
                        { duration: 3000 }
                    );
                } else {
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                }
            })
            .catch((e) => {
                window.shopify.toast.show(
                    t("toast.server_error", { ns: "message" }),
                    { duration: 3000 }
                );
            })
            .finally(() => {
                setIsLoading({
                    ...isLoading,
                    saveLoading: false,
                });
            });
    };

    useEffect(() => {
        getonePageSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleSaveState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onePageSetting, initOnePageSetting]);

    useEffect(() => {
        handleQueueFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiDisplaySetting.filterList]);

    useEffect(() => {
        handleListTabFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initOnePageSetting.sort_filter]);

    useEffect(() => {
        let labelFilter = listTabFilter;
        Object.keys(onePageSetting.label_content).forEach((item) => {
            if (item.split("label_filter_")[1]) {
                const index = labelFilter.findIndex(
                    (obj) =>
                        Object.keys(obj)[0] === item.split("label_filter_")[1]
                );
                labelFilter[index] = {
                    [item.split("label_filter_")[1]]:
                        onePageSetting.label_content[item],
                };
            }
        });
        setListTabFilter(labelFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onePageSetting.label_content]);

    // change color mode
    useEffect(() => {
        setOnePageSetting({
            ...onePageSetting,
            color_customize: {
                ...onePageSetting.color_customize,
                ...onePageSessionColor[onePageSetting.color_mode],
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onePageSetting.color_mode]);

    return {
        isLoading,
        open,
        setOpen,
        saveState,
        multiDisplaySetting,
        setMultiDisplaySetting,
        displaySetting,
        setDisplaySetting,
        contentValue,
        setContentValue,
        handleToggle,
        handleInputChange,
        handleShowHideInfo,
        handleShowHideReviewFilter,
        handleMultiDisplaySetting,
        onePageSetting,
        setOnePageSetting,
        setInitOnePageSetting,
        initOnePageSetting,
        handleQueueFilter,
        handleDragEnd,
        listTabFilter,
        handleSaveOnePageSetting,
        showMobileMenu,
        setShowMobileMenu,
        handleDisplaySetting,
        handleListTabFilter,
        setOnePageSessionColor,
        onePageSessionColor,
        productSelectList,
        setProductSelectList,
    };
};

export default useEffectOnePageSetting;
