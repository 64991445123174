import { InlineStack } from "@shopify/polaris";
const ColorModeOptionSkeleton = ({
    backgroundColor,
    primaryColor,
    secondaryColor,
}) => {
    return (
        <div
            className="color-mode-option-skeleton--container"
            style={{ backgroundColor: backgroundColor }}
        >
            <InlineStack gap={200} wrap={false}>
                <div
                    className="color-mode-option-skeleton-item small"
                    style={{ backgroundColor: secondaryColor }}
                ></div>
                <div
                    className="color-mode-option-skeleton-item medium-1"
                    style={{ backgroundColor: primaryColor }}
                ></div>
            </InlineStack>
            <InlineStack gap={200} wrap={false}>
                <div
                    className="color-mode-option-skeleton-item medium-2"
                    style={{ backgroundColor: primaryColor }}
                ></div>
                <div
                    className="color-mode-option-skeleton-item small"
                    style={{ backgroundColor: secondaryColor }}
                ></div>
            </InlineStack>
            <div
                className="color-mode-option-skeleton-item large"
                style={{ backgroundColor: primaryColor }}
            ></div>
        </div>
    );
};

export default ColorModeOptionSkeleton;
