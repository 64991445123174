import { Box, Divider, Text, BlockStack, TextField } from "@shopify/polaris";
const nameDisplayList = [
    { option_name_display_format_1: "Option name display format 1" },
    { option_name_display_format_2: "Option name display format 2" },
    { option_name_display_format_3: "Option name display format 3" },
    { option_name_display_format_4: "Option name display format 4" },
];
const ModalWriteReviewLabel = ({
    t,
    contentValue,
    displaySetting,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
}) => {
    return (
        <div className="setting-component">
            <BlockStack gap={300}>
                <Text as="h4" fontWeight="semibold">
                    {t("review_display.content.modal_write_review.title", {
                        ns: "widget",
                    })}
                </Text>
                <Divider borderColor="border" />
            </BlockStack>
            <Box paddingBlockStart={100}>
                <BlockStack gap={300}>
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal write review title
                            </Text>
                        }
                        name="modal_write_title"
                        value={reviewDisplay?.label_content?.modal_write_title}
                        onChange={(e) =>
                            handleInputChange(e, "modal_write_title")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Title when there is a review
                            </Text>
                        }
                        name="modal_write_title_had_review"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_write_title_had_review
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_write_title_had_review")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text write reviews
                            </Text>
                        }
                        name="modal_write_text_reviews"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_write_text_reviews
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_write_text_reviews")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text click to rate
                            </Text>
                        }
                        name="modal_review_text_click_to_rate"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_text_click_to_rate
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_text_click_to_rate"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text add photo or video
                            </Text>
                        }
                        name="modal_review_add_photo_or_video"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_add_photo_or_video
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_add_photo_or_video"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Label customer name
                            </Text>
                        }
                        name="modal_review_label_customer_name"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_label_customer_name
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_label_customer_name"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Placeholder customer name
                            </Text>
                        }
                        name="modal_review_placeholder_customer_name"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_placeholder_customer_name
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_placeholder_customer_name"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text display name format
                            </Text>
                        }
                        name="name_display_format"
                        value={
                            reviewDisplay?.label_content?.name_display_format
                        }
                        onChange={(e) =>
                            handleInputChange(e, "name_display_format")
                        }
                    />

                    {nameDisplayList.map((nameDisplay, index) => (
                        <TextField
                            key={Object.keys(nameDisplay)[0]}
                            label={
                                <Text as="h4" fontWeight="medium">
                                    {Object.values(nameDisplay)[0]}
                                </Text>
                            }
                            name={nameDisplay}
                            value={
                                reviewDisplay?.label_content[Object.keys(nameDisplay)[0]]
                            }
                            onChange={(e) =>
                                handleInputChange(e, Object.keys(nameDisplay)[0])
                            }
                        />
                    ))}

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Label customer email
                            </Text>
                        }
                        name="modal_review_label_customer_email"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_label_customer_email
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_label_customer_email"
                            )
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Placeholder customer email
                            </Text>
                        }
                        name="modal_review_placeholder_customer_email"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_placeholder_customer_email
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_placeholder_customer_email"
                            )
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Label review title
                            </Text>
                        }
                        name="modal_review_label_review_title"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_label_review_title
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_label_review_title"
                            )
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Placeholder review title
                            </Text>
                        }
                        name="modal_review_placeholder_review_title"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_placeholder_review_title
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_placeholder_review_title"
                            )
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Label review content
                            </Text>
                        }
                        name="modal_review_label_review_content"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_label_review_content
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_label_review_content"
                            )
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Placeholder review content
                            </Text>
                        }
                        name="modal_review_placeholder_review_content"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_placeholder_review_content
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_placeholder_review_content"
                            )
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Label characters remaining
                            </Text>
                        }
                        name="modal_review_label_characters_remaining"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_label_characters_remaining
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_label_characters_remaining"
                            )
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text add photo
                            </Text>
                        }
                        name="modal_review_btn_add_photo"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_btn_add_photo
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_review_btn_add_photo")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Text add video
                            </Text>
                        }
                        name="modal_review_btn_add_video"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_btn_add_video
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_review_btn_add_video")
                        }
                    />
                    {/* <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Button back
                            </Text>
                        }
                        name="modal_review_btn_back"
                        value={
                            reviewDisplay?.label_content?.modal_review_btn_back
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_review_btn_back")
                        }
                    /> */}
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Button submit
                            </Text>
                        }
                        name="modal_review_btn_submit_review"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_btn_submit_review
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_btn_submit_review"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Button submit when there is a review
                            </Text>
                        }
                        name="modal_review_btn_submit_review_has_review"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_review_btn_submit_review_has_review
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_review_btn_submit_review_has_review"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal review success title
                            </Text>
                        }
                        name="modal_success_review_title"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_success_review_title
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_success_review_title")
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal review success subtitle
                            </Text>
                        }
                        name="modal_success_review_subtitle"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_success_review_subtitle
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_success_review_subtitle"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal review success button
                            </Text>
                        }
                        name="modal_success_review_button"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_success_review_button
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_success_review_button")
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal review pending title
                            </Text>
                        }
                        name="modal_pending_review_title"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_pending_review_title
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_pending_review_title")
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal review pending subtitle
                            </Text>
                        }
                        name="modal_pending_review_subtitle"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_pending_review_subtitle
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "modal_pending_review_subtitle"
                            )
                        }
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal review pending button
                            </Text>
                        }
                        name="modal_pending_review_button"
                        value={
                            reviewDisplay?.label_content
                                ?.modal_pending_review_button
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_pending_review_button")
                        }
                    />
                </BlockStack>
            </Box>
        </div>
    );
};

export default ModalWriteReviewLabel;
