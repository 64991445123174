import { useEffect, useState } from "react";
import { Card } from "@shopify/polaris";
import convert from "color-convert";
import { listIconStyle } from "../../../../config/listIconStyle";

const listReview = [
    {
        id: 1,
        content:
            "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. Generate your own Lorem Ipsum with a dictionary of over 200 Latin words and",
        reviewer: "John S.",
        date: "2021-09-01",
        star: "⭐⭐⭐⭐⭐",
    },
    {
        id: 2,
        content:
            "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. Generate your own Lorem Ipsum with a dictionary of over 200 Latin words and",
        reviewer: "John S.",
        date: "2021-09-01",
        star: "⭐⭐⭐⭐⭐",
    },
    {
        id: 3,
        content:
            "Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. Generate your own Lorem Ipsum with a dictionary of over 200 Latin words and",
        reviewer: "John S.",
        date: "2021-09-01",
        star: "⭐⭐⭐⭐⭐",
    },
];

const DemoCarousel = ({
    carouselDisplay,
    setCarouselDisplay,
    shopInfo,
    viewport,
}) => {
    const [rootCssVariable, setRootCssVariable] = useState({});

    useEffect(() => {
        let variable = {};
        Object.keys(carouselDisplay.color_customize).map((item, index) => {
            const color = convert.hex.rgb([
                carouselDisplay.color_customize[item],
            ]);

            variable = {
                ...variable,
                [`--${item}`]: `rgb(${color[0]}, ${color[1]},${color[2]})`,
            };
        });
        setRootCssVariable(variable);
    }, [carouselDisplay]);

    return (
        <div
            style={{
                ...rootCssVariable,
                overflow: "hidden",
            }}
        >
            <Card padding={0}>
                <div
                    className="demo-container"
                    style={{
                        backgroundColor:
                            "var(--p-color-input-bg-surface-active)",
                    }}
                >
                    <div className={`review-carousel-demo--wrap ${viewport}`}>
                        <div className={`review-carousel-demo`}>
                            <div className="review-carousel-demo--content--heading">
                                <p
                                    style={{
                                        display:
                                            carouselDisplay.title_label
                                                .is_show === false
                                                ? "none"
                                                : "block",
                                        fontSize:
                                            carouselDisplay.title_label.style
                                                .font_size + "px",
                                        fontWeight:
                                            carouselDisplay.title_label.style
                                                .font_weight,
                                        fontStyle:
                                            carouselDisplay.title_label.style
                                                .font_style,
                                        textDecoration:
                                            carouselDisplay.title_label.style
                                                .text_decoration,
                                    }}
                                >
                                    {carouselDisplay.title_label.label}
                                </p>

                                <p
                                    style={{
                                        display:
                                            carouselDisplay.subtitle_label
                                                .is_show === false
                                                ? "none"
                                                : "block",
                                        fontSize:
                                            carouselDisplay.subtitle_label.style
                                                .font_size + "px",
                                        fontWeight:
                                            carouselDisplay.subtitle_label.style
                                                .font_weight,
                                        fontStyle:
                                            carouselDisplay.subtitle_label.style
                                                .font_style,
                                        textDecoration:
                                            carouselDisplay.subtitle_label.style
                                                .text_decoration,
                                    }}
                                >
                                    {carouselDisplay.subtitle_label.label}
                                </p>
                            </div>
                            <div className="review-carousel-demo--section">
                                {carouselDisplay.show_navigator_button && (
                                    <>
                                        <button
                                            className="carousel-button left"
                                            disabled
                                        >
                                            <span></span>
                                            <svg
                                                width="8"
                                                height="15"
                                                viewBox="0 0 8 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7 13.5801L1 7.58008L7 1.58008"
                                                    stroke="var(--navigator_arrow)"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            className="carousel-button right"
                                            disabled
                                        >
                                            <span></span>
                                            <svg
                                                transform="rotate(180)"
                                                width="8"
                                                height="15"
                                                viewBox="0 0 8 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7 13.58L1 7.57996L7 1.57996"
                                                    stroke="var(--navigator_arrow)"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </button>
                                    </>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            maxWidth: "unset",
                                            overflow: "hidden",
                                            margin: "auto",
                                        }}
                                    >
                                        <div className="review-carousel-demo--content">
                                            {listReview.map((review, index) => (
                                                <div
                                                    className="review-carousel-demo--card"
                                                    key={index}
                                                >
                                                    <div
                                                        className={`card  ${
                                                            shopInfo?.element_corners ===
                                                            "square"
                                                                ? "element_corners--square"
                                                                : ""
                                                        }`}
                                                    >
                                                        <div className="content">
                                                            <svg
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 18 18"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M3.14612 4.58407C4.1581 3.48701 5.68941 2.93091 7.69678 2.93091H8.41808V4.96419L7.83815 5.08031C6.84998 5.27794 6.16258 5.66671 5.79472 6.23724C5.60278 6.5446 5.49392 6.89652 5.47879 7.25857H7.69678C7.88808 7.25857 8.07154 7.33456 8.20681 7.46982C8.34208 7.60509 8.41808 7.78855 8.41808 7.97984V13.0288C8.41808 13.8243 7.77107 14.4713 6.97548 14.4713H2.64771C2.45641 14.4713 2.27294 14.3953 2.13767 14.2601C2.0024 14.1248 1.92641 13.9413 1.92641 13.7501V10.1437L1.92857 8.03827C1.92208 7.9582 1.78504 6.06125 3.14612 4.58407ZM14.9097 14.4713H10.582C10.3907 14.4713 10.2072 14.3953 10.0719 14.2601C9.93666 14.1248 9.86067 13.9413 9.86067 13.7501V10.1437L9.86283 8.03827C9.85634 7.9582 9.71929 6.06125 11.0804 4.58407C12.0924 3.48701 13.6237 2.93091 15.631 2.93091H16.3523V4.96419L15.7724 5.08031C14.7842 5.27794 14.0968 5.66671 13.729 6.23724C13.537 6.5446 13.4282 6.89652 13.4131 7.25857H15.631C15.8223 7.25857 16.0058 7.33456 16.1411 7.46982C16.2763 7.60509 16.3523 7.78855 16.3523 7.97984V13.0288C16.3523 13.8243 15.7053 14.4713 14.9097 14.4713Z" />
                                                            </svg>
                                                            <p>
                                                                "
                                                                {review.content}
                                                                "
                                                            </p>
                                                        </div>
                                                        <div className="user">
                                                            <h3
                                                                className={`customer-name ${
                                                                    carouselDisplay.display_star_rating ===
                                                                    false
                                                                        ? "name-without-stars"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {
                                                                    review.reviewer
                                                                }
                                                            </h3>
                                                            <div
                                                                className={
                                                                    carouselDisplay.display_star_rating ===
                                                                    false
                                                                        ? "hidden-star stars"
                                                                        : "stars visible"
                                                                }
                                                            >
                                                                {[
                                                                    5, 4, 3, 2,
                                                                    1,
                                                                ].map(
                                                                    (item) => (
                                                                        <span
                                                                            key={`testimonial-rating-${index}--${item}`}
                                                                            className="star-icon--box star-testimonial-fill"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    listIconStyle[
                                                                                        shopInfo?.star_icon -
                                                                                            1
                                                                                    ] ||
                                                                                    listIconStyle[0],
                                                                            }}
                                                                            style={{
                                                                                marginRight:
                                                                                    "2px",
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "center",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        ></span>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default DemoCarousel;
