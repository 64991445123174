export const formatDate = (type="dd/mm/yyyy") => {
    const date = new Date();
    const pad = (num) => (num < 10 ? `0${num}` : num);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() is zero-based
    const day = pad(date.getDate());
    const shortYear = year.toString().slice(-2);
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const formats = {
        "yyyy-mm-dd": `${year}-${month}-${day}`,
        "mm/dd/yyyy": `${month}/${day}/${year}`,
        "dd/mm/yyyy": `${day}/${month}/${year}`,
        "dd/mm/yy": `${day}/${month}/${shortYear}`,
        "yyyy/mm/dd": `${year}/${month}/${day}`,
        "mmmm dd, yyyy": `${monthNames[date.getMonth()]} ${day}, ${year}`,
        "mmm dd, yyyy": `${monthShortNames[date.getMonth()]} ${day}, ${year}`,
    };

    return formats[type];
};
