import {
    BlockStack,
    InlineStack,
    Divider,
    Box,
    Checkbox,
    Select,
} from "@shopify/polaris";

const CustomerInfo = ({
    t,
    multiDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
}) => {
    return (
        <>
            <div className="setting-component">
                <BlockStack gap={300}>
                    <InlineStack align="start" gap={100}>
                        <p className={`component-title `}>
                            {t(
                                "review_display.information_display.customer_information.title",
                                {
                                    ns: "widget",
                                }
                            )}
                        </p>
                    </InlineStack>
                    <Divider />
                </BlockStack>

                <BlockStack gap={400}>
                    <Box paddingBlockStart={200}>
                        <InlineStack
                            align="start"
                            gap={100}
                            blockAlign="center"
                        >
                            <Checkbox
                                type="checkbox"
                                id={"customer_profile_picture"}
                                checked={
                                    reviewDisplay.section_show
                                        .customer_profile_picture
                                }
                                onChange={(e) =>
                                    setReviewDisplay({
                                        ...reviewDisplay,
                                        section_show: {
                                            ...reviewDisplay.section_show,
                                            customer_profile_picture:
                                                !reviewDisplay.section_show
                                                    .customer_profile_picture,
                                        },
                                    })
                                }
                                label={
                                    <p
                                        className={`component-item-label ${
                                            reviewDisplay.section_show
                                                .customer_profile_picture
                                                ? ""
                                                : "subdued-text"
                                        }`}
                                    >
                                        {t(
                                            "review_display.information_display.customer_information.customer_profile_picture",
                                            {
                                                ns: "widget",
                                            }
                                        )}
                                    </p>
                                }
                            />
                        </InlineStack>
                    </Box>
                </BlockStack>
            </div>
        </>
    );
};
export default CustomerInfo;
