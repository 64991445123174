const LayoutSkeleton = () => {
    return (
        <div className="review-skeleton">
            <div className="review-customer">
                <div className="customer-avt">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                    >
                        <rect width="15" height="15" rx="7.5" fill="#E4E5E7" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.49986 7.55859C8.7425 7.55859 9.74986 6.55123 9.74986 5.30859C9.74986 4.06595 8.7425 3.05859 7.49986 3.05859C6.25721 3.05859 5.24986 4.06595 5.24986 5.30859C5.24986 6.55123 6.25721 7.55859 7.49986 7.55859ZM7.49985 13.5586C9.39044 13.5586 11.0797 12.6795 12.1803 11.3086C11.0797 9.93773 9.3904 9.05859 7.49976 9.05859C5.60917 9.05859 3.91993 9.93768 2.81934 11.3085C3.91993 12.6795 5.60921 13.5586 7.49985 13.5586Z"
                            fill="#8C9196"
                        />
                    </svg>
                </div>
                <div className="customer-info">
                    <div className="customer-name"></div>
                    <div className="verify-buyer"></div>
                </div>
            </div>
            <div className="review-body">
                <div className="review-rating">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.67973 0.434215C2.74896 0.271725 2.97943 0.272167 3.04803 0.434921L3.64592 1.85343C3.67402 1.92011 3.73595 1.9664 3.80785 1.9745L5.29242 2.14162C5.46168 2.16067 5.53117 2.36909 5.40721 2.48591L4.28221 3.54613C4.232 3.59345 4.20973 3.66331 4.22328 3.73096L4.53226 5.27365C4.56649 5.44457 4.38092 5.57443 4.23207 5.48371L2.95962 4.7082C2.89594 4.66939 2.81597 4.66923 2.75214 4.70778L1.47665 5.47806C1.32745 5.56816 1.14242 5.4376 1.1773 5.26684L1.49216 3.72514C1.50596 3.65755 1.48396 3.58763 1.43395 3.54012L0.313002 2.47529C0.189492 2.35797 0.259838 2.14981 0.4292 2.13145L1.9141 1.97054C1.98606 1.96274 2.04818 1.91668 2.07655 1.85009L2.67973 0.434215Z"
                            fill="#E4E5E7"
                        />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.67973 0.434215C2.74896 0.271725 2.97943 0.272167 3.04803 0.434921L3.64592 1.85343C3.67402 1.92011 3.73595 1.9664 3.80785 1.9745L5.29242 2.14162C5.46168 2.16067 5.53117 2.36909 5.40721 2.48591L4.28221 3.54613C4.232 3.59345 4.20973 3.66331 4.22328 3.73096L4.53226 5.27365C4.56649 5.44457 4.38092 5.57443 4.23207 5.48371L2.95962 4.7082C2.89594 4.66939 2.81597 4.66923 2.75214 4.70778L1.47665 5.47806C1.32745 5.56816 1.14242 5.4376 1.1773 5.26684L1.49216 3.72514C1.50596 3.65755 1.48396 3.58763 1.43395 3.54012L0.313002 2.47529C0.189492 2.35797 0.259838 2.14981 0.4292 2.13145L1.9141 1.97054C1.98606 1.96274 2.04818 1.91668 2.07655 1.85009L2.67973 0.434215Z"
                            fill="#E4E5E7"
                        />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.67973 0.434215C2.74896 0.271725 2.97943 0.272167 3.04803 0.434921L3.64592 1.85343C3.67402 1.92011 3.73595 1.9664 3.80785 1.9745L5.29242 2.14162C5.46168 2.16067 5.53117 2.36909 5.40721 2.48591L4.28221 3.54613C4.232 3.59345 4.20973 3.66331 4.22328 3.73096L4.53226 5.27365C4.56649 5.44457 4.38092 5.57443 4.23207 5.48371L2.95962 4.7082C2.89594 4.66939 2.81597 4.66923 2.75214 4.70778L1.47665 5.47806C1.32745 5.56816 1.14242 5.4376 1.1773 5.26684L1.49216 3.72514C1.50596 3.65755 1.48396 3.58763 1.43395 3.54012L0.313002 2.47529C0.189492 2.35797 0.259838 2.14981 0.4292 2.13145L1.9141 1.97054C1.98606 1.96274 2.04818 1.91668 2.07655 1.85009L2.67973 0.434215Z"
                            fill="#E4E5E7"
                        />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.67973 0.434215C2.74896 0.271725 2.97943 0.272167 3.04803 0.434921L3.64592 1.85343C3.67402 1.92011 3.73595 1.9664 3.80785 1.9745L5.29242 2.14162C5.46168 2.16067 5.53117 2.36909 5.40721 2.48591L4.28221 3.54613C4.232 3.59345 4.20973 3.66331 4.22328 3.73096L4.53226 5.27365C4.56649 5.44457 4.38092 5.57443 4.23207 5.48371L2.95962 4.7082C2.89594 4.66939 2.81597 4.66923 2.75214 4.70778L1.47665 5.47806C1.32745 5.56816 1.14242 5.4376 1.1773 5.26684L1.49216 3.72514C1.50596 3.65755 1.48396 3.58763 1.43395 3.54012L0.313002 2.47529C0.189492 2.35797 0.259838 2.14981 0.4292 2.13145L1.9141 1.97054C1.98606 1.96274 2.04818 1.91668 2.07655 1.85009L2.67973 0.434215Z"
                            fill="#E4E5E7"
                        />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.67973 0.434215C2.74896 0.271725 2.97943 0.272167 3.04803 0.434921L3.64592 1.85343C3.67402 1.92011 3.73595 1.9664 3.80785 1.9745L5.29242 2.14162C5.46168 2.16067 5.53117 2.36909 5.40721 2.48591L4.28221 3.54613C4.232 3.59345 4.20973 3.66331 4.22328 3.73096L4.53226 5.27365C4.56649 5.44457 4.38092 5.57443 4.23207 5.48371L2.95962 4.7082C2.89594 4.66939 2.81597 4.66923 2.75214 4.70778L1.47665 5.47806C1.32745 5.56816 1.14242 5.4376 1.1773 5.26684L1.49216 3.72514C1.50596 3.65755 1.48396 3.58763 1.43395 3.54012L0.313002 2.47529C0.189492 2.35797 0.259838 2.14981 0.4292 2.13145L1.9141 1.97054C1.98606 1.96274 2.04818 1.91668 2.07655 1.85009L2.67973 0.434215Z"
                            fill="#E4E5E7"
                        />
                    </svg>
                </div>
                <div className="review-content review-content-100"></div>
                <div className="review-content review-content-50"></div>
                <div className="review-content review-content-20"></div>
            </div>
        </div>
    );
};

export default LayoutSkeleton;
