import {
    BlockStack,
    InlineStack,
    Checkbox,
    Text,
    Select,
    Box,
} from "@shopify/polaris";
const optionsGalley = [
    {
        label: "50%",
        value: 50,
    },
    {
        label: "75%",
        value: 75,
    },
    {
        label: "100%(110px)",
        value: 100,
    },
    {
        label: "125%",
        value: 125,
    },
    {
        label: "150%",
        value: 150
    },
    {
        label: "175%",
        value: 175,
    },
    {
        label: "200%",
        value: 200,
    },
];

const optionsReview = [
    {
        label: "50%",
        value: 50,
    },
    {
        label: "75%",
        value: 75
    },
    {
        label: "100%(80px)",
        value: 100,
    },
    {
        label: "125%",
        value: 125,
    },
    {
        label: "150%",
        value: 150
    },
    {
        label: "175%",
        value: 175,
    },
    {
        label: "200%",
        value: 200,
    },
];
const Summary = ({ t, reviewDisplay, setReviewDisplay }) => {
    return (
        <div className="setting-component">
            <BlockStack gap={300}>
                <BlockStack gap={200}>
                    <p className={`component-title `}>
                        {t("review_display.information_display.summary", {
                            ns: "widget",
                        })}
                    </p>

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="summary_star_icon"
                            checked={
                                reviewDisplay.section_show.summary_star_icon
                            }
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_star_icon: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .summary_star_icon
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.color_schema.star_icon",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    </InlineStack>

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="number_of_review"
                            checked={
                                reviewDisplay.section_show
                                    .summary_number_of_reviews
                            }
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_number_of_reviews: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .summary_number_of_reviews
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.number_of_review",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    </InlineStack>

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="write_a_review_btn"
                            checked={
                                reviewDisplay.section_show.write_a_review_button
                            }
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        write_a_review_button: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .write_a_review_button
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.write_a_reivew_button",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    </InlineStack>

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="summary_divider"
                            checked={reviewDisplay.section_show.summary_divider}
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_divider: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .summary_divider
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.divider",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    </InlineStack>

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="summary_text_1"
                            checked={reviewDisplay.section_show.summary_text_1}
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_text_1: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .summary_text_1
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.text_1",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    </InlineStack>

                    <InlineStack align="start" gap={100} blockAlign="center">
                        <Checkbox
                            type="checkbox"
                            id="summary_text_2"
                            checked={reviewDisplay.section_show.summary_text_2}
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        summary_text_2: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .summary_text_2
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.text_2",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    </InlineStack>
                </BlockStack>
                <BlockStack gap={200}>
                    <p className={`component-title `}>
                        {t("review_display.information_display.image_gallery", {
                            ns: "widget",
                        })}
                    </p>

                    <Checkbox
                        type="checkbox"
                        id="show_review_media"
                        checked={reviewDisplay.section_show.show_review_media}
                        onChange={(value) => {
                            setReviewDisplay({
                                ...reviewDisplay,
                                section_show: {
                                    ...reviewDisplay.section_show,
                                    show_review_media: value,
                                },
                            });
                        }}
                        label={
                            <p
                                className={`component-item-label  ${
                                    reviewDisplay.section_show.show_review_media
                                        ? ""
                                        : "subdued-text"
                                }`}
                            >
                                {t(
                                    "review_display.information_display.show_review_media",
                                    {
                                        ns: "widget",
                                    }
                                )}
                            </p>
                        }
                    />

                    {reviewDisplay.section_show.show_review_media && (
                        <Checkbox
                            type="checkbox"
                            id="image_galley_star_rating"
                            checked={
                                reviewDisplay.section_show
                                    .image_galley_star_rating
                            }
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        image_galley_star_rating: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label  ${
                                        reviewDisplay.section_show
                                            .image_galley_star_rating
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.star_rating",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    )}

                    <InlineStack wrap={false} align="space-between" blockAlign="center"gap={200}>
                        <Text>Media size on galley carousel</Text>
                        <Box>
                            <Select options={optionsGalley} value={parseInt(reviewDisplay.section_show.media_size_on_galley)} onChange={(value)=>{
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show:{
                                        ...reviewDisplay.section_show,
                                        media_size_on_galley: value
                                    }
                                })
                            }} />
                        </Box>
                    </InlineStack>
                    <InlineStack wrap={false} align="space-between" blockAlign="center"gap={200}>
                        <Text>Media size on reviews</Text>
                        <Box>
                            <Select options={optionsReview} value={parseInt(reviewDisplay.section_show.media_size_on_review)} onChange={(value)=>{
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show:{
                                        ...reviewDisplay.section_show,
                                        media_size_on_review: value
                                    }
                                })
                            }} />
                        </Box>
                    </InlineStack>
                </BlockStack>
            </BlockStack>
        </div>
    );
};

export default Summary;
