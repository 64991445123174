import { useEffect, useState } from "react";
import { Box, Card } from "@shopify/polaris";
import Header from "./Header";
import ListReview from "./ListReview";
import convert from "color-convert";
import { listIconStyle } from "../../../../config/listIconStyle";
const WidgetReview = ({
    reviewDisplay,
    listTabFilter,
    shopInfo,
    viewport
}) => {
    const [rootCssVariable, setRootCssVariable] = useState({});

    useEffect(() => {
        let variable = {};
        Object.keys(reviewDisplay.color_customize).map((item, index) => {
            const color = convert.hex.rgb([
                reviewDisplay.color_customize[item],
            ]);

            variable = {
                ...variable,
                [`--${item}`]: `rgb(${color[0]}, ${color[1]},${color[2]})`,
            };
        });
        setRootCssVariable(variable);
    }, [reviewDisplay]);

    return (
        <>
            <Box
                paddingBlock={400}
                paddingInline={300}
                background="bg-fill-active"
            >
                <Card padding={0}>
                    <div className="demo-container" style={rootCssVariable}>
                        <div className="setting-widget-body-right--body">
                            <Box as="div" padding={0}>
                                {
                                    <div
                                        className={`demo-field ${viewport}`}
                                        style={{
                                            backgroundColor:
                                                reviewDisplay.color_customize
                                                    .page_background_color,
                                        }}
                                    >
                                        {(reviewDisplay.section_show.title
                                            .status ||
                                            reviewDisplay.section_show.sub_title
                                                .status) && (
                                            <div
                                                className="trustshop-one-page--header"
                                                style={{
                                                    padding: "20px 0 32px",
                                                }}
                                            >
                                                {reviewDisplay.section_show
                                                    .title.status && (
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight:
                                                                "133.4%",
                                                            fontSize: `${reviewDisplay.section_show.title.size}px`,
                                                            color: "var(--primary_color)",
                                                            textAlign: "center",
                                                            fontStyle:
                                                                reviewDisplay.section_show.title.text_style.includes(
                                                                    "italic"
                                                                )
                                                                    ? "italic"
                                                                    : "normal",
                                                            fontWeight:
                                                                reviewDisplay.section_show.title.text_style.includes(
                                                                    "bold"
                                                                )
                                                                    ? "bold"
                                                                    : "normal",
                                                            textDecoration:
                                                                reviewDisplay.section_show.title.text_style.includes(
                                                                    "underline"
                                                                )
                                                                    ? "underline"
                                                                    : "none",
                                                        }}
                                                    >
                                                        {
                                                            reviewDisplay
                                                                .section_show
                                                                .title.content
                                                        }
                                                    </p>
                                                )}
                                                {reviewDisplay.section_show
                                                    .sub_title.status && (
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            lineHeight:
                                                                "133.4%",
                                                            fontSize: `${reviewDisplay.section_show.sub_title.size}px`,
                                                            color: "var(--primary_color)",
                                                            textAlign: "center",
                                                            fontStyle:
                                                                reviewDisplay.section_show.sub_title.text_style.includes(
                                                                    "italic"
                                                                )
                                                                    ? "italic"
                                                                    : "normal",
                                                            fontWeight:
                                                                reviewDisplay.section_show.sub_title.text_style.includes(
                                                                    "bold"
                                                                )
                                                                    ? "bold"
                                                                    : "normal",
                                                            textDecoration:
                                                                reviewDisplay.section_show.sub_title.text_style.includes(
                                                                    "underline"
                                                                )
                                                                    ? "underline"
                                                                    : "none",
                                                        }}
                                                    >
                                                        {
                                                            reviewDisplay
                                                                .section_show
                                                                .sub_title
                                                                .content
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                        <div>
                                            {" "}
                                            {reviewDisplay.section_show
                                                .summary_box && (
                                                <Header
                                                    device={viewport}
                                                    reviewDisplay={
                                                        reviewDisplay
                                                    }
                                                    starIcon={
                                                        listIconStyle[shopInfo?.star_icon - 1] ||
                                                        listIconStyle[0]
                                                    }
                                                    shopInfo={shopInfo}
                                                />
                                            )}
                                            <ListReview
                                                device={viewport}
                                                reviewDisplay={reviewDisplay}
                                                listTabFilter={listTabFilter}
                                                starIcon={
                                                    listIconStyle[shopInfo?.star_icon - 1] ||
                                                    listIconStyle[0]
                                                }
                                                shopInfo={shopInfo}
                                            />
                                        </div>
                                    </div>
                                }
                            </Box>
                        </div>
                    </div>
                </Card>
            </Box>
        </>
    );
};

export default WidgetReview;
