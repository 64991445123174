import ContentReview from "./ContentReview";
import FilterLabel from "./FilterReview";
import HeaderLabel from "./Header";
import ModalImageLabel from "./ModalImage";
import {
    Box,
    Icon,
    Collapsible,
    BlockStack,
    InlineStack,
    Text,
    Select,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import ModalReport from "./ModalReport";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomModalMessage from './../../../../../../components/CustomModalMessage';
const Content = ({
    t,
    open,
    setOpen,
    contentValue,
    displaySetting,
    handleToggle,
    handleInputChange,
    handleDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
    handleShowHideInfo,
    disabled
}) => {
    const { i18n } = useTranslation("widget-translate");
    const listCountries = [
        { label: "English", value: "en" }, //
        { label: "Arabic", value: "ae" },
        { label: "Chinese", value: "cn" }, //
        { label: "Densk", value: "dk" }, //
        { label: "Español", value: "es" }, //
        { label: "French", value: "fr" }, //
        { label: "Gaeilge", value: "ie" }, //
        { label: "German", value: "de" }, //
        { label: "Hindi", value: "in" }, //
        { label: "Italiano", value: "it" }, //
        { label: "Japanese", value: "jp" }, //
        { label: "Māori", value: "nz" }, //
        { label: "Nederlands", value: "nl" }, //
        { label: "Portuguese", value: "pt" }, //
        { label: "Svenska", value: "se" }, //
        { label: "Vietnamese", value: "vi" }, //
    ];
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState("en");
    const handleChangeCountries = async (value) => {
        setShowModal(true);
        setSelected(value);
        await i18n.loadLanguages([value]);
    };

    const handleCancel = () => {
        setSelected(reviewDisplay.country_language);
        setShowModal(false);
    };

    const handleUpdate = () => {
        setReviewDisplay({
            ...reviewDisplay,
            country_language: selected,
            label_content: i18n.getResourceBundle(selected, "widget-translate")
                .content,
        });
        setShowModal(false);
    };
    useEffect(() => {
        if (reviewDisplay.country_language !== selected) {
            setSelected(reviewDisplay.country_language);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewDisplay.country_language]);
    return (
        <>
            <Box padding={0}>
                <div
                    className="widget-setting-collape--title no-border"
                    onClick={() => handleToggle("content")}
                >
                    <h5 className="setting-group--title">
                        {t("review_display.content.title", { ns: "widget" })}
                    </h5>
                    {open.content ? (
                        <Icon source={ChevronDownIcon} />
                    ) : (
                        <Icon source={ChevronRightIcon} />
                    )}
                </div>
                <Collapsible
                    open={open.content}
                    id="basic-collapsible"
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                >
                    <Box
                        paddingBlockEnd={400}
                        borderBlockEndWidth="0165"
                        borderColor="border-hover"
                    >
                        <InlineStack
                            gap={200}
                            align="space-between"
                            wrap={true}
                            blockAlign="center"
                        >
                            <Text variant="bodyMd">
                                {t("review_display.content.widget_language", {
                                    ns: "widget",
                                })}
                            </Text>
                            <div style={{ flex: 1, maxWidth: "500px" }}>
                                <Select
                                    options={listCountries}
                                    onChange={handleChangeCountries}
                                    value={selected}
                                    disabled={disabled}
                                />
                            </div>
                        </InlineStack>
                    </Box>
                    <Box paddingBlockStart={400} paddingBlockEnd={200}>
                        <BlockStack gap={400}>
                            <HeaderLabel
                                t={t}
                                contentValue={contentValue}
                                displaySetting={displaySetting}
                                handleToggle={handleToggle}
                                handleInputChange={handleInputChange}
                                handleShowHideInfo={handleShowHideInfo}
                                handleDisplaySetting={handleDisplaySetting}
                                reviewDisplay={reviewDisplay}
                                setReviewDisplay={setReviewDisplay}
                                disabled={disabled}
                            />
                            <FilterLabel
                                t={t}
                                contentValue={contentValue}
                                displaySetting={displaySetting}
                                handleToggle={handleToggle}
                                handleInputChange={handleInputChange}
                                handleShowHideInfo={handleShowHideInfo}
                                handleDisplaySetting={handleDisplaySetting}
                                reviewDisplay={reviewDisplay}
                                setReviewDisplay={setReviewDisplay}
                                disabled={disabled}
                            />
                            <ContentReview
                                t={t}
                                contentValue={contentValue}
                                displaySetting={displaySetting}
                                handleToggle={handleToggle}
                                handleInputChange={handleInputChange}
                                handleShowHideInfo={handleShowHideInfo}
                                handleDisplaySetting={handleDisplaySetting}
                                reviewDisplay={reviewDisplay}
                                setReviewDisplay={setReviewDisplay}
                                disabled={disabled}
                            />
                            <ModalReport
                                t={t}
                                contentValue={contentValue}
                                displaySetting={displaySetting}
                                handleToggle={handleToggle}
                                handleInputChange={handleInputChange}
                                handleShowHideInfo={handleShowHideInfo}
                                handleDisplaySetting={handleDisplaySetting}
                                reviewDisplay={reviewDisplay}
                                setReviewDisplay={setReviewDisplay}
                                disabled={disabled}
                            />
                            <ModalImageLabel
                                t={t}
                                contentValue={contentValue}
                                displaySetting={displaySetting}
                                handleToggle={handleToggle}
                                handleInputChange={handleInputChange}
                                handleShowHideInfo={handleShowHideInfo}
                                handleDisplaySetting={handleDisplaySetting}
                                reviewDisplay={reviewDisplay}
                                setReviewDisplay={setReviewDisplay}
                                disabled={disabled}
                            />
                        </BlockStack>
                    </Box>
                </Collapsible>
            </Box>
            {showModal && (
                <CustomModalMessage
                    t={t}
                    closeModalAction={handleCancel}
                    primaryButton={{
                        content: t("update", { ns: "translation" }),
                        action: handleUpdate,
                    }}
                    secondaryButton={{
                        content: t("cancel", { ns: "translation" }),
                        action: handleCancel,
                    }}
                    title={t("warning", { ns: "translation" })}
                    content={t("are_you_sure", { ns: "translation" })}
                />
            )}
        </>
    );
};

export default Content;
