// retro/identicon/monsterid/wavatar/robohash
export const gvtarList = {
    mystery_person: [
        "https://asset.trustshop.io/global-setting/gvtar/mystery-person/gvtar-mystery.png",
        "https://asset.trustshop.io/global-setting/gvtar/mystery-person/gvtar-mystery.png",
        "https://asset.trustshop.io/global-setting/gvtar/mystery-person/gvtar-mystery.png",
        "https://asset.trustshop.io/global-setting/gvtar/mystery-person/gvtar-mystery.png",
    ],
    retro: [
        "https://asset.trustshop.io/global-setting/gvtar/retro/gvatar-retro-1.png",
        "https://asset.trustshop.io/global-setting/gvtar/retro/gvatar-retro-2.png",
        "https://asset.trustshop.io/global-setting/gvtar/retro/gvatar-retro-3.png",
        "https://asset.trustshop.io/global-setting/gvtar/retro/gvatar-retro-4.png",
    ],
    identicon: [
        "https://asset.trustshop.io/global-setting/gvtar/identicon/gvatar-identicon-1.png",
        "https://asset.trustshop.io/global-setting/gvtar/identicon/gvatar-identicon-2.png",
        "https://asset.trustshop.io/global-setting/gvtar/identicon/gvatar-identicon-3.png",
        "https://asset.trustshop.io/global-setting/gvtar/identicon/gvatar-identicon-4.png",
    ],
    monsterid: [
        "https://asset.trustshop.io/global-setting/gvtar/monsterid/gvatar-monsterid-1.png",
        "https://asset.trustshop.io/global-setting/gvtar/monsterid/gvatar-monsterid-2.png",
        "https://asset.trustshop.io/global-setting/gvtar/monsterid/gvatar-monsterid-3.png",
        "https://asset.trustshop.io/global-setting/gvtar/monsterid/gvatar-monsterid-4.png",
    ],
    wavatar: [
        "https://asset.trustshop.io/global-setting/gvtar/wavatar/gvatar-wavatar-1.png",
        "https://asset.trustshop.io/global-setting/gvtar/wavatar/gvatar-wavatar-2.png",
        "https://asset.trustshop.io/global-setting/gvtar/wavatar/gvatar-wavatar-3.png",
        "https://asset.trustshop.io/global-setting/gvtar/wavatar/gvatar-wavatar-4.png",
    ],
    robohash: [
        "https://asset.trustshop.io/global-setting/gvtar/robohash/gvatar-robohash-1.png",
        "https://asset.trustshop.io/global-setting/gvtar/robohash/gvatar-robohash-2.png",
        "https://asset.trustshop.io/global-setting/gvtar/robohash/gvatar-robohash-3.png",
        "https://asset.trustshop.io/global-setting/gvtar/robohash/gvatar-robohash-4.png",
    ],
};
