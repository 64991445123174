import { Box, Divider, Text, BlockStack, TextField } from "@shopify/polaris";

const ModalReport = ({
    t,
    contentValue,
    displaySetting,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
    disabled
}) => {
    return (
        <div className="setting-component">
            <BlockStack gap={300}>
                <Text as="h4" fontWeight="semibold">
                    {t("review_display.content.modal_report.title", {
                        ns: "widget",
                    })}
                </Text>
                <Divider borderColor="border-subdued" />
            </BlockStack>
            <Box paddingBlockStart={100} paddingInlineEnd={0}>
                <BlockStack gap={300}>
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal title
                            </Text>
                        }
                        name="modal_report_title"
                        value={reviewDisplay?.label_content?.modal_report_title}
                        onChange={(e) =>
                            handleInputChange(e, "modal_report_title")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Modal subtitle
                            </Text>
                        }
                        name="modal_report_subtitle"
                        value={
                            reviewDisplay?.label_content?.modal_report_subtitle
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_report_subtitle")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Reason 1: Review contains private information.
                            </Text>
                        }
                        name="modal_report_option_1"
                        value={
                            reviewDisplay?.label_content?.modal_report_option_1
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_report_option_1")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Reason 2: Review contains objectionable
                                language.
                            </Text>
                        }
                        name="modal_report_option_2"
                        value={
                            reviewDisplay?.label_content?.modal_report_option_2
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_report_option_2")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Reason 3: Review contains advertising or spam.
                            </Text>
                        }
                        name="modal_report_option_3"
                        value={
                            reviewDisplay?.label_content?.modal_report_option_3
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_report_option_3")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Reason 4: Review contains violent and disgusting
                                content.
                            </Text>
                        }
                        name="modal_report_option_4"
                        value={
                            reviewDisplay?.label_content?.modal_report_option_4
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_report_option_4")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Other reason
                            </Text>
                        }
                        name="modal_report_option_5"
                        value={
                            reviewDisplay?.label_content?.modal_report_option_5
                        }
                        onChange={(e) =>
                            handleInputChange(e, "modal_report_option_5")
                        }
                        disabled={disabled}
                    />

                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Report button text
                            </Text>
                        }
                        name="modal_report_btn"
                        value={reviewDisplay?.label_content?.modal_report_btn}
                        onChange={(e) =>
                            handleInputChange(e, "modal_report_btn")
                        }
                        disabled={disabled}
                    />
                </BlockStack>
            </Box>
        </div>
    );
};

export default ModalReport;
