import {
    Card,
    InlineGrid,
    Icon,
    InlineStack,
    Text,
    Box,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import SettingOption from "./SettingOption";
import WidgetReview from "./Demo";
import useEffectOnePageSetting from "./useEffectReviewOnePage";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import WidgetLoading from "../../../components/Widget/WidgetLoading";
const ReviewDisplay = ({
    reviewDisplayTab,
    shopInfo,
    viewport,
    setSaveState,
}) => {
    const { t } = useTranslation("widget");
    const {
        open,
        setOpen,
        multiDisplaySetting,
        setMultiDisplaySetting,
        displaySetting,
        setDisplaySetting,
        contentValue,
        setContentValue,
        handleToggle,
        handleInputChange,
        handleShowHideInfo,
        handleShowHideReviewFilter,
        handleMultiDisplaySetting,
        popoverActive,
        onePageSetting,
        setOnePageSetting,
        listTabFilter,
        handleQueueFilter,
        handleDragEnd,
        saveState,
        showMobileMenu,
        setShowMobileMenu,
        handleSaveOnePageSetting,
        handleDisplaySetting,
        isLoading,
        initOnePageSetting,
        setOnePageSessionColor,
        productSelectList,
        setProductSelectList,
    } = useEffectOnePageSetting();

    useEffect(() => {
        if (
            JSON.stringify(onePageSetting) !==
            JSON.stringify(initOnePageSetting)
        ) {
            setSaveState((prev) => ({
                ...prev,
                status: true,
                saveFunc: handleSaveOnePageSetting,
            }));
        } else {
            setSaveState((prev) => ({
                ...prev,
                status: false,
                saveFunc: handleSaveOnePageSetting,
            }));
        }
    }, [onePageSetting, initOnePageSetting]);

    useEffect(() => {
        setSaveState((prev) => ({
            ...prev,
            loading: isLoading.saveLoading,
        }));
    }, [isLoading.saveLoading]);
    return (
        <>
            {!isLoading.pageLoading ? (
                <>
                    <div
                        className={`review-display--container `}
                        style={{
                            height: "100%",
                        }}
                    >
                        <InlineGrid
                            columns={
                                viewport === "max"
                                    ? 1
                                    : {
                                          xs: "1fr",
                                          sm: "1fr",
                                          lg: "2fr 6fr",
                                          xl: "2fr 6fr",
                                      }
                            }
                        >
                            <div
                                className={`review-display-setting--field ${
                                    showMobileMenu ? "hide" : ""
                                }`}
                            >
                                {viewport !== "max" && (
                                    <div
                                        className={`review-display-setting--field ${
                                            showMobileMenu ? "hide" : ""
                                        }`}
                                    >
                                        <div
                                            onClick={() =>
                                                setShowMobileMenu(
                                                    !showMobileMenu
                                                )
                                            }
                                            className="desktop-hidden"
                                            style={{
                                                cursor: "pointer",
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 999,
                                                left: 0,
                                                right: 0,
                                                borderBottom:
                                                    "1px solid #e5e5e5",
                                                backgroundColor: "#fff",
                                                boxShadow:
                                                    "0px 4px 6px 0px rgba(0,0,0,.1)",
                                            }}
                                        >
                                            <Box
                                                paddingBlock={400}
                                                paddingInline={400}
                                            >
                                                <InlineStack
                                                    align="space-between"
                                                    blockAlign="center"
                                                    gap={400}
                                                >
                                                    <Text
                                                        as="h5"
                                                        variant="headingMd"
                                                    >
                                                        {t(
                                                            "review_display.widget_area",
                                                            {
                                                                ns: "widget",
                                                            }
                                                        )}
                                                    </Text>
                                                    <Icon
                                                        source={
                                                            !showMobileMenu
                                                                ? ChevronDownIcon
                                                                : ChevronUpIcon
                                                        }
                                                        tone="base"
                                                    />
                                                </InlineStack>
                                            </Box>
                                        </div>
                                        <div
                                            className={`setting-widget-body--container ${
                                                showMobileMenu ? "hide" : ""
                                            }`}
                                        >
                                            <SettingOption
                                                t={t}
                                                open={open}
                                                setOpen={setOpen}
                                                multiDisplaySetting={
                                                    multiDisplaySetting
                                                }
                                                setMultiDisplaySetting={
                                                    setMultiDisplaySetting
                                                }
                                                displaySetting={displaySetting}
                                                setDisplaySetting={
                                                    setDisplaySetting
                                                }
                                                contentValue={contentValue}
                                                setContentValue={
                                                    setContentValue
                                                }
                                                handleToggle={handleToggle}
                                                handleInputChange={
                                                    handleInputChange
                                                }
                                                handleShowHideInfo={
                                                    handleShowHideInfo
                                                }
                                                handleShowHideReviewFilter={
                                                    handleShowHideReviewFilter
                                                }
                                                handleMultiDisplaySetting={
                                                    handleMultiDisplaySetting
                                                }
                                                popoverActive={popoverActive}
                                                reviewDisplay={onePageSetting}
                                                setReviewDisplay={
                                                    setOnePageSetting
                                                }
                                                listTabFilter={listTabFilter}
                                                handleQueueFilter={
                                                    handleQueueFilter
                                                }
                                                handleDragEnd={handleDragEnd}
                                                saveState={saveState}
                                                showMobileMenu={showMobileMenu}
                                                setShowMobileMenu={
                                                    setShowMobileMenu
                                                }
                                                handleSaveOnePageSetting={
                                                    handleSaveOnePageSetting
                                                }
                                                handleDisplaySetting={
                                                    handleDisplaySetting
                                                }
                                                initReviewDisplay={
                                                    initOnePageSetting
                                                }
                                                setSessionColor={
                                                    setOnePageSessionColor
                                                }
                                                productSelectList={
                                                    productSelectList
                                                }
                                                setProductSelectList={
                                                    setProductSelectList
                                                }
                                                shopInfo={shopInfo}
                                                viewport={viewport}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="review-display-demo--field">
                                <WidgetReview
                                    reviewDisplay={onePageSetting}
                                    listTabFilter={listTabFilter}
                                    shopInfo={shopInfo}
                                    viewport={viewport}
                                />
                            </div>
                        </InlineGrid>
                    </div>
                </>
            ) : (
                <WidgetLoading />
            )}
        </>
    );
};

export default ReviewDisplay;
