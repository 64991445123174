import { Fragment, useEffect, useState } from "react";
import GenerateAvt from "../../../../components/Widget/GenerateAvtDemo";
import { gvtarList } from "../../../../config/listGvatarDemo";
import { formatDate } from "../../../../config/formatDate";
const ListReview = ({
    device,
    reviewDisplay,
    listTabFilter,
    starIcon,
    shopInfo,
}) => {
    const tabFilter = listTabFilter.filter((item) =>
        reviewDisplay.sort_filter.includes(Object.keys(item)[0])
    );
    const starFilter = [
        { star_5: 4000 },
        { star_4: 7500 },
        { star_3: 13 },
        { star_2: 0 },
        { star_1: 540 },
    ];
    const listImageFromReview = [
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/7b095992-6296-4741-78a8-bc5ed14c7d00/100`,
            star: 2,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/910555ea-1dc2-4eb1-6733-6c0753994700/100`,
            star: 3,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/15e7ba98-abe9-4e3d-36d7-145ec9912b00/100`,
            star: 1,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/283c7d14-083c-4f04-424a-dcd1ce597900/100`,
            star: 5,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/f79277b2-6771-4b9b-58b8-3b4936707300/100`,
            star: 4,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/c8c06c40-d1a7-4899-5c5f-11dc69ca9e00/100`,
            star: 1,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/660f3189-5445-4e66-1e97-3cdcc57f9b00/100`,
            star: 1,
        },
        //
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/f3a8b8e0-1ad1-4a43-fe19-feabec082500/100`,
            star: 2,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/910555ea-1dc2-4eb1-6733-6c0753994700/100`,
            star: 5,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/15e7ba98-abe9-4e3d-36d7-145ec9912b00/100`,
            star: 3,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/283c7d14-083c-4f04-424a-dcd1ce597900/100`,
            star: 1,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/f79277b2-6771-4b9b-58b8-3b4936707300/100`,
            star: 5,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/c8c06c40-d1a7-4899-5c5f-11dc69ca9e00/100`,
            star: 5,
        },
        {
            data: `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/660f3189-5445-4e66-1e97-3cdcc57f9b00/100`,
            star: 1,
        },
    ];
    const reviewMedia = [
        `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/f3a8b8e0-1ad1-4a43-fe19-feabec082500/100`,
        `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/910555ea-1dc2-4eb1-6733-6c0753994700/100`,
        `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/15e7ba98-abe9-4e3d-36d7-145ec9912b00/100`,
        `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/283c7d14-083c-4f04-424a-dcd1ce597900/100`,
    ];
    const [nameHidden, setNameHidden] = useState("");
    const handleNameHidden = (name = "Customer Name") => {
        let d = "";
        // eslint-disable-next-line array-callback-return
        name.split(" ").map((item) => {
            d +=
                " " +
                item
                    .split("")
                    .map((item2, index) => (index === 0 ? item2 : "*"));
        });
        setNameHidden(d.replace(/,/g, "").trim());
    };

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        handleNameHidden();
    }, [reviewDisplay.section_show.customer_name]);

    const handleStyleGrid = () => {
        const allItems = document.getElementsByClassName("review-item");
        for (let i = 0; i < allItems.length; i++) {
            const grid = document.querySelector(".list-review");
            const rowHeight = parseInt(
                window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
            );

            const rowGap = parseInt(
                window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
            );
            const rowSpan = Math.ceil(
                (allItems[i]
                    .querySelector(".review-wrap")
                    .getBoundingClientRect().height +
                    rowGap) /
                    (rowHeight + rowGap)
            );
            allItems[i].style.gridRowEnd = "span " + rowSpan;
        }
    };

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (reviewDisplay.layout_box === "grid") {
            handleStyleGrid();
        }
    }, [reviewDisplay, device, dimensions]);

    return (
        <div
            className={`widget-body body-${device} style-${reviewDisplay.layout_box}`}
            key={"widget-body"}
        >
            {reviewDisplay.section_show.show_review_media && (
                <div className="image-from-review--container">
                    <p className={`title`}>
                        {reviewDisplay.label_content.text_images_from_reviews}
                        <span
                            style={{
                                color: "var(--all_photos_button_color)",
                                fontSize: "13px",
                                fontWeight: 450,
                                lineHeight: "20px",
                                marginLeft: "5px",
                            }}
                        >
                            {reviewDisplay.label_content.all_photos}
                        </span>
                    </p>
                    <div className="image-from-review--wrap">
                        <button className="button-slide button-left" disabled>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 1L14.4 2.5L7.00002 10L14.4 17.5L13 19L4.00002 10L13 1Z"
                                    fill="var(--secondary_color)"
                                />
                            </svg>
                        </button>
                        <div className="image-from-review--list">
                            {listImageFromReview.map((item, index) => (
                                <div
                                    className={`image-from-review--item  ${
                                        shopInfo?.element_corners === "square"
                                            ? "element_corners--square"
                                            : ""
                                    }`}
                                    key={`widget-image-from-review--${index}`}
                                >
                                    <img
                                        src={item.data}
                                        alt={`item-${index + 1}`}
                                        width={92}
                                        height={91}
                                        loading="lazy"
                                    />
                                    {reviewDisplay.section_show
                                        .image_galley_star_rating && (
                                        <div className="overlay image-from-review--overlay">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M7.36392 1.51975C7.6062 0.951039 8.41286 0.952584 8.65296 1.52222L10.164 5.1073C10.2624 5.34066 10.4791 5.5027 10.7308 5.53102L14.4989 5.9552C15.0913 6.02189 15.3345 6.75135 14.9007 7.16023L12.0323 9.86346C11.8565 10.0291 11.7785 10.2736 11.826 10.5104L12.6117 14.4332C12.7315 15.0314 12.082 15.4859 11.561 15.1684L8.34963 13.2112C8.12677 13.0754 7.84687 13.0748 7.62346 13.2097L4.40434 15.1538C3.88217 15.4691 3.23457 15.0122 3.35663 14.4145L4.15731 10.494C4.20562 10.2575 4.12862 10.0127 3.95357 9.84643L1.09551 7.13146C0.66322 6.72082 0.909431 5.99226 1.5022 5.92802L5.27093 5.51962C5.52278 5.49233 5.74023 5.3311 5.83952 5.09805L7.36392 1.51975Z"
                                                    fill="white"
                                                />
                                            </svg>
                                            <span>{item.star}</span>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <button className="button-slide button-right" disabled>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.99998 1L5.59998 2.5L13 10L5.59998 17.5L6.99998 19L16 10L6.99998 1Z"
                                    fill="var(--secondary_color)"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
            <div>
                <p className="title">
                    {reviewDisplay.label_content.text_review_with_comments}
                </p>
                {reviewDisplay.sort_filter.length > 0 && (
                    <div className="filter-container">
                        <div className="filter-tab--list">
                            {tabFilter.map((item, index) => {
                                return (
                                    <Fragment key={`tab-item-${index}`}>
                                        {Object.keys(item)[0] !==
                                        "stars_filter" ? (
                                            <button
                                                className={`tab-item ${
                                                    Object.values(item)[0] ===
                                                    "All"
                                                        ? "different"
                                                        : ""
                                                } ${
                                                    shopInfo?.button_corner_radius
                                                        ? "button_corner_radius"
                                                        : ""
                                                }`}
                                                disabled
                                                key={`tab-item--${index}`}
                                            >
                                                {Object.values(item)}
                                            </button>
                                        ) : (
                                            <>
                                                {starFilter.map(
                                                    (item2, index) => (
                                                        <button
                                                            className={`tab-item ${
                                                                shopInfo?.button_corner_radius
                                                                    ? "button_corner_radius"
                                                                    : ""
                                                            }`}
                                                            disabled
                                                            key={`tab-item-star-${
                                                                tabFilter.length +
                                                                index
                                                            }`}
                                                        >
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: starIcon,
                                                                }}
                                                                className="star-icon--box"
                                                            ></span>
                                                            <span
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {
                                                                    Object.keys(
                                                                        item2
                                                                    )[0].split(
                                                                        "_"
                                                                    )[1]
                                                                }
                                                            </span>
                                                        </button>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>

            <div className="list-review">
                <div className="review-item">
                    <div className="review-wrap">
                        <div className="review-item--left review-item--header">
                            <GenerateAvt
                                reviewerAvtType={shopInfo.reviewer_avt_type}
                                showAvt={
                                    reviewDisplay.section_show
                                        .customer_profile_picture
                                }
                                shortName="CN"
                                singleColor={shopInfo.single_color}
                                imgSrc={
                                    gvtarList[
                                        shopInfo.gravatar_type ||
                                            "mystery_person"
                                    ][2]
                                }
                            />
                            <div className="customer-info">
                                <p className="customer-name">
                                    {" "}
                                    {reviewDisplay.section_show.customer_name
                                        ? "Customer Name"
                                        : nameHidden}
                                </p>
                                <div>
                                    <p className="verify"></p>
                                    {reviewDisplay.section_show.review_date && (
                                        <p>
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="4"
                                                    height="4"
                                                    viewBox="0 0 4 4"
                                                    fill="none"
                                                >
                                                    <g clipPath="url(#clip0_7598_23655)">
                                                        <path
                                                            d="M1.88013 3.23203L3.35928 1.75288C3.11042 1.64898 2.88441 1.49717 2.6941 1.3061C2.50294 1.11575 2.35106 0.889667 2.24712 0.640721L0.767972 2.11987C0.652576 2.23526 0.594778 2.29306 0.54518 2.35666C0.486603 2.43171 0.436375 2.51292 0.395385 2.59885C0.360786 2.67165 0.334987 2.74925 0.283389 2.90404L0.0109987 3.72061C-0.0015381 3.758 -0.00339812 3.79814 0.00562766 3.83653C0.0146534 3.87492 0.0342073 3.91003 0.0620912 3.93791C0.0899751 3.96579 0.125084 3.98535 0.163471 3.99437C0.201858 4.0034 0.242001 4.00154 0.279389 3.989L1.09596 3.71661C1.25095 3.66501 1.32835 3.63921 1.40115 3.60462C1.48715 3.56362 1.56834 3.51342 1.64334 3.45482C1.70694 3.40522 1.76474 3.34742 1.88013 3.23203ZM3.76966 1.3425C3.91715 1.19501 4 0.994986 4 0.786416C4 0.577845 3.91715 0.377818 3.76966 0.230336C3.62218 0.0828543 3.42215 1.55397e-09 3.21358 0C3.00501 -1.55397e-09 2.80499 0.0828543 2.6575 0.230336L2.48011 0.40773L2.48771 0.429929C2.57513 0.680063 2.71818 0.907092 2.9061 1.0939C3.09849 1.28744 3.33347 1.43331 3.59227 1.51989L3.76966 1.3425Z"
                                                            fill="#A7ABC3"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_7598_23655">
                                                            <rect
                                                                width="4"
                                                                height="4"
                                                                fill="white"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span className="review-time">
                                                {shopInfo.review_date_format ? (
                                                    <>
                                                        {formatDate(
                                                            shopInfo.review_date_format
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            reviewDisplay
                                                                .label_content
                                                                .text_review_on
                                                        }{" "}
                                                        27, 2022
                                                    </>
                                                )}
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="review-item--right review-item--body">
                            <div className="star-rating">
                                <div className="star">
                                    {[1, 2, 3, 4].map((item, index) => (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: starIcon,
                                            }}
                                            className="star-icon star-icon--box"
                                            key={`one-page-rating-1-${index}`}
                                        ></span>
                                    ))}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: starIcon,
                                        }}
                                        className="star-icon--empty star-icon--box"
                                    ></span>
                                    <p className="review-title">
                                        This was nice is buy
                                    </p>
                                </div>
                            </div>

                            <p className="review-content">
                                This is 💯 one hundred percent the best lip mask
                                duo ever !!! The scent is delicious and it's so
                                smooth from the scrub & mask ~ This is
                                perfection~ Smells just like honey 🍯 & the
                                packaging is so adorable ~ I'm so very happy
                                with this product 🐻 🍯 ~
                            </p>
                            {reviewDisplay.section_show.product && (
                                <div
                                    style={{
                                        padding: "6px",
                                        borderRadius: "8px",
                                        backgroundColor:
                                            "var(--product_background_color)",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "4px",
                                        width: "fit-content",
                                        margin: "8px 0",
                                    }}
                                    className={` ${
                                        shopInfo?.element_corners === "square"
                                            ? "element_corners--square"
                                            : ""
                                    }`}
                                >
                                    <div
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "8px",
                                            overflow: "hidden",
                                        }}
                                        className={` ${
                                            shopInfo?.element_corners ===
                                            "square"
                                                ? "element_corners--square"
                                                : ""
                                        }`}
                                    >
                                        <img
                                            src={listImageFromReview[2].data}
                                            alt="product"
                                            width={20}
                                            height={20}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                objectFit: "cover",
                                                aspectRatio: "1/1",
                                            }}
                                        />
                                    </div>
                                    <span
                                        style={{
                                            fontSize: "13px",
                                            fontWeight: 550,
                                            lineHeight: "20px",
                                            color: "var(--product_text_color)",
                                        }}
                                    >
                                        Benjamin Bookcase
                                    </span>
                                </div>
                            )}
                            <div className="review-item--footer">
                                <div className="helpful-container">
                                    {reviewDisplay.section_show
                                        .button_helpful && (
                                        <span
                                            className={`${
                                                shopInfo?.button_corner_radius
                                                    ? "button_corner_radius"
                                                    : ""
                                            }`}
                                        >
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12.8422 7.34052C13.1047 6.99364 13.25 6.56864 13.25 6.12645C13.25 5.42489 12.8578 4.76083 12.2266 4.39051C12.0641 4.29519 11.879 4.24503 11.6906 4.2452H7.94375L8.0375 2.32489C8.05938 1.86083 7.89531 1.4202 7.57656 1.08426C7.42014 0.918683 7.23141 0.786941 7.02206 0.697179C6.8127 0.607417 6.58716 0.561542 6.35938 0.562389C5.54688 0.562389 4.82813 1.10926 4.6125 1.89208L3.27031 6.75145H3.26563V13.439H10.6453C10.7891 13.439 10.9297 13.4108 11.0594 13.3546C11.8031 13.0374 12.2828 12.3108 12.2828 11.5046C12.2828 11.3077 12.2547 11.114 12.1984 10.9265C12.4609 10.5796 12.6063 10.1546 12.6063 9.71239C12.6063 9.51552 12.5781 9.32176 12.5219 9.13426C12.7844 8.78739 12.9297 8.36239 12.9297 7.9202C12.9266 7.72333 12.8984 7.52802 12.8422 7.34052ZM0.75 7.25145V12.939C0.75 13.2155 0.973438 13.439 1.25 13.439H2.26563V6.75145H1.25C0.973438 6.75145 0.75 6.97489 0.75 7.25145Z"
                                                    fill="var(--primary_text_color)"
                                                />
                                            </svg>
                                            (30)
                                        </span>
                                    )}
                                    {reviewDisplay.section_show
                                        .button_helpful &&
                                        reviewDisplay.section_show
                                            .button_report && (
                                            <div className="divider"></div>
                                        )}
                                    {reviewDisplay.section_show
                                        .button_report && (
                                        <span
                                            className={`report-btn ${
                                                shopInfo?.button_corner_radius
                                                    ? "button_corner_radius"
                                                    : ""
                                            }`}
                                        >
                                            {
                                                reviewDisplay?.label_content
                                                    ?.text_report
                                            }
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="review-item">
                    <div className="review-wrap">
                        <div className="review-item--left review-item--header">
                            <GenerateAvt
                                reviewerAvtType={shopInfo.reviewer_avt_type}
                                showAvt={
                                    reviewDisplay.section_show
                                        .customer_profile_picture
                                }
                                shortName="CN"
                                singleColor={shopInfo.single_color}
                                imgSrc={
                                    gvtarList[
                                        shopInfo.gravatar_type ||
                                            "mystery_person"
                                    ][1]
                                }
                            />
                            <div className="customer-info">
                                <p className="customer-name">
                                    {reviewDisplay.section_show.customer_name
                                        ? "Customer Name"
                                        : nameHidden}
                                </p>
                                <div>
                                    <p className="verify"></p>
                                    {reviewDisplay.section_show.review_date && (
                                        <p>
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="4"
                                                    height="4"
                                                    viewBox="0 0 4 4"
                                                    fill="none"
                                                >
                                                    <g clipPath="url(#clip0_7598_23655)">
                                                        <path
                                                            d="M1.88013 3.23203L3.35928 1.75288C3.11042 1.64898 2.88441 1.49717 2.6941 1.3061C2.50294 1.11575 2.35106 0.889667 2.24712 0.640721L0.767972 2.11987C0.652576 2.23526 0.594778 2.29306 0.54518 2.35666C0.486603 2.43171 0.436375 2.51292 0.395385 2.59885C0.360786 2.67165 0.334987 2.74925 0.283389 2.90404L0.0109987 3.72061C-0.0015381 3.758 -0.00339812 3.79814 0.00562766 3.83653C0.0146534 3.87492 0.0342073 3.91003 0.0620912 3.93791C0.0899751 3.96579 0.125084 3.98535 0.163471 3.99437C0.201858 4.0034 0.242001 4.00154 0.279389 3.989L1.09596 3.71661C1.25095 3.66501 1.32835 3.63921 1.40115 3.60462C1.48715 3.56362 1.56834 3.51342 1.64334 3.45482C1.70694 3.40522 1.76474 3.34742 1.88013 3.23203ZM3.76966 1.3425C3.91715 1.19501 4 0.994986 4 0.786416C4 0.577845 3.91715 0.377818 3.76966 0.230336C3.62218 0.0828543 3.42215 1.55397e-09 3.21358 0C3.00501 -1.55397e-09 2.80499 0.0828543 2.6575 0.230336L2.48011 0.40773L2.48771 0.429929C2.57513 0.680063 2.71818 0.907092 2.9061 1.0939C3.09849 1.28744 3.33347 1.43331 3.59227 1.51989L3.76966 1.3425Z"
                                                            fill="#A7ABC3"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_7598_23655">
                                                            <rect
                                                                width="4"
                                                                height="4"
                                                                fill="white"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span className="review-time">
                                                {shopInfo.review_date_format ? (
                                                    <>
                                                        {formatDate(
                                                            shopInfo.review_date_format
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            reviewDisplay
                                                                .label_content
                                                                .text_review_on
                                                        }{" "}
                                                        27, 2022
                                                    </>
                                                )}
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="review-item--right review-item--body">
                            <div className="star-rating">
                                <div className="star">
                                    {[1, 2, 3, 4].map((item, index) => (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: starIcon,
                                            }}
                                            className="star-icon star-icon--box"
                                            key={`one-page-rating-2-${index}`}
                                        ></span>
                                    ))}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: starIcon,
                                        }}
                                        className="star-icon--empty star-icon--box"
                                    ></span>
                                    <p className="review-title">
                                        This was nice is buy
                                    </p>
                                </div>
                            </div>

                            <p className="review-content">
                                This is 💯 one hundred percent the best lip mask
                                duo ever !!! The scent is delicious and it's so
                                smooth from the scrub & mask ~ This is
                                perfection~ Smells just like honey 🍯 & the
                                packaging is so adorable ~ I'm so very happy
                                with this product 🐻 🍯 ~
                            </p>

                            {reviewDisplay.section_show.show_review_media && (
                                <div className="review-media">
                                    {reviewMedia.map((item, index) =>
                                        index === 0 ? (
                                            <div
                                                className={`media-item  ${
                                                    shopInfo?.element_corners ===
                                                    "square"
                                                        ? "element_corners--square"
                                                        : ""
                                                }`}
                                                key={`media-1-${index}`}
                                            >
                                                <img
                                                    src={item}
                                                    alt="media item"
                                                    width={72}
                                                    height={72}
                                                    loading="lazy"
                                                />
                                                <div className="overlay video-overlay">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="11"
                                                        height="7"
                                                        viewBox="0 0 11 7"
                                                        fill="none"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M1.65 0C1.21239 0 0.792709 0.158035 0.483274 0.43934C0.173839 0.720644 0 1.10218 0 1.5V5.5C0 5.89782 0.173839 6.27936 0.483274 6.56066C0.792709 6.84196 1.21239 7 1.65 7H7.15C7.58761 7 8.00729 6.84196 8.31673 6.56066C8.62616 6.27936 8.8 5.89782 8.8 5.5V4.707L10.0611 5.8535C10.1381 5.92341 10.2361 5.97101 10.3427 5.99029C10.4494 6.00958 10.56 5.99967 10.6605 5.96184C10.7609 5.924 10.8468 5.85993 10.9073 5.77773C10.9677 5.69552 11 5.59888 11 5.5V1.5C11 1.40112 10.9677 1.30448 10.9073 1.22227C10.8468 1.14007 10.7609 1.076 10.6605 1.03816C10.56 1.00033 10.4494 0.990424 10.3427 1.00971C10.2361 1.02899 10.1381 1.0766 10.0611 1.1465L8.8 2.293V1.5C8.8 1.10218 8.62616 0.720644 8.31673 0.43934C8.00729 0.158035 7.58761 0 7.15 0H1.65Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                    <p className="duration">
                                                        0:25
                                                    </p>
                                                </div>
                                            </div>
                                        ) : index === 3 ? (
                                            <div
                                                className={`media-item  ${
                                                    shopInfo?.element_corners ===
                                                    "square"
                                                        ? "element_corners--square"
                                                        : ""
                                                }`}
                                                key={`media-1-${index}`}
                                            >
                                                <img
                                                    src={item}
                                                    alt="media item"
                                                    width={72}
                                                    height={72}
                                                    loading="lazy"
                                                />
                                                <div className="overlay loadmore-overlay">
                                                    +2
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className={`media-item  ${
                                                    shopInfo?.element_corners ===
                                                    "square"
                                                        ? "element_corners--square"
                                                        : ""
                                                }`}
                                                key={`media-1-${index}`}
                                            >
                                                <img
                                                    src={item}
                                                    alt="media item"
                                                    width={72}
                                                    height={72}
                                                    loading="lazy"
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            )}

                            {reviewDisplay.section_show.product && (
                                <div
                                    style={{
                                        padding: "6px",
                                        borderRadius: "8px",
                                        backgroundColor:
                                            "var(--product_background_color)",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "4px",
                                        width: "fit-content",
                                        margin: "8px 0",
                                    }}
                                    className={` ${
                                        shopInfo?.element_corners === "square"
                                            ? "element_corners--square"
                                            : ""
                                    }`}
                                >
                                    <div
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "8px",
                                            overflow: "hidden",
                                        }}
                                        className={` ${
                                            shopInfo?.element_corners ===
                                            "square"
                                                ? "element_corners--square"
                                                : ""
                                        }`}
                                    >
                                        <img
                                            src={listImageFromReview[2].data}
                                            alt="product"
                                            width={20}
                                            height={20}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                objectFit: "cover",
                                                aspectRatio: "1/1",
                                            }}
                                        />
                                    </div>
                                    <span
                                        style={{
                                            fontSize: "13px",
                                            fontWeight: 550,
                                            lineHeight: "20px",
                                            color: "var(--product_text_color)",
                                        }}
                                    >
                                        Benjamin Bookcase
                                    </span>
                                </div>
                            )}

                            <div className="review-item--footer">
                                <div className="helpful-container">
                                    {reviewDisplay.section_show
                                        .button_helpful && (
                                        <span
                                            className={`${
                                                shopInfo?.button_corner_radius
                                                    ? "button_corner_radius"
                                                    : ""
                                            }`}
                                        >
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12.8422 7.34052C13.1047 6.99364 13.25 6.56864 13.25 6.12645C13.25 5.42489 12.8578 4.76083 12.2266 4.39051C12.0641 4.29519 11.879 4.24503 11.6906 4.2452H7.94375L8.0375 2.32489C8.05938 1.86083 7.89531 1.4202 7.57656 1.08426C7.42014 0.918683 7.23141 0.786941 7.02206 0.697179C6.8127 0.607417 6.58716 0.561542 6.35938 0.562389C5.54688 0.562389 4.82813 1.10926 4.6125 1.89208L3.27031 6.75145H3.26563V13.439H10.6453C10.7891 13.439 10.9297 13.4108 11.0594 13.3546C11.8031 13.0374 12.2828 12.3108 12.2828 11.5046C12.2828 11.3077 12.2547 11.114 12.1984 10.9265C12.4609 10.5796 12.6063 10.1546 12.6063 9.71239C12.6063 9.51552 12.5781 9.32176 12.5219 9.13426C12.7844 8.78739 12.9297 8.36239 12.9297 7.9202C12.9266 7.72333 12.8984 7.52802 12.8422 7.34052ZM0.75 7.25145V12.939C0.75 13.2155 0.973438 13.439 1.25 13.439H2.26563V6.75145H1.25C0.973438 6.75145 0.75 6.97489 0.75 7.25145Z"
                                                    fill="var(--primary_text_color)"
                                                />
                                            </svg>
                                        </span>
                                    )}
                                    {reviewDisplay.section_show
                                        .button_helpful &&
                                        reviewDisplay.section_show
                                            .button_report && (
                                            <div className="divider"></div>
                                        )}
                                    {reviewDisplay.section_show
                                        .button_report && (
                                        <span
                                            className={`report-btn ${
                                                shopInfo?.button_corner_radius
                                                    ? "button_corner_radius"
                                                    : ""
                                            }`}
                                        >
                                            {
                                                reviewDisplay?.label_content
                                                    ?.text_report
                                            }
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {reviewDisplay.layout_box === "grid" ? (
                    <>
                        <div className="review-item">
                            <div className="review-wrap">
                                <div className="review-item--left review-item--header">
                                    <GenerateAvt
                                        reviewerAvtType={
                                            shopInfo.reviewer_avt_type
                                        }
                                        showAvt={
                                            reviewDisplay.section_show
                                                .customer_profile_picture
                                        }
                                        shortName="CN"
                                        singleColor={shopInfo.single_color}
                                        imgSrc={
                                            gvtarList[
                                                shopInfo.gravatar_type ||
                                                    "mystery_person"
                                            ][0]
                                        }
                                    />
                                    <div className="customer-info">
                                        <p className="customer-name">
                                            {" "}
                                            {reviewDisplay.section_show
                                                .customer_name
                                                ? "Customer Name"
                                                : nameHidden}
                                        </p>
                                        <div>
                                            <p className="verify"></p>
                                            {reviewDisplay.section_show
                                                .review_date && (
                                                <p>
                                                    <span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="4"
                                                            height="4"
                                                            viewBox="0 0 4 4"
                                                            fill="none"
                                                        >
                                                            <g clipPath="url(#clip0_7598_23655)">
                                                                <path
                                                                    d="M1.88013 3.23203L3.35928 1.75288C3.11042 1.64898 2.88441 1.49717 2.6941 1.3061C2.50294 1.11575 2.35106 0.889667 2.24712 0.640721L0.767972 2.11987C0.652576 2.23526 0.594778 2.29306 0.54518 2.35666C0.486603 2.43171 0.436375 2.51292 0.395385 2.59885C0.360786 2.67165 0.334987 2.74925 0.283389 2.90404L0.0109987 3.72061C-0.0015381 3.758 -0.00339812 3.79814 0.00562766 3.83653C0.0146534 3.87492 0.0342073 3.91003 0.0620912 3.93791C0.0899751 3.96579 0.125084 3.98535 0.163471 3.99437C0.201858 4.0034 0.242001 4.00154 0.279389 3.989L1.09596 3.71661C1.25095 3.66501 1.32835 3.63921 1.40115 3.60462C1.48715 3.56362 1.56834 3.51342 1.64334 3.45482C1.70694 3.40522 1.76474 3.34742 1.88013 3.23203ZM3.76966 1.3425C3.91715 1.19501 4 0.994986 4 0.786416C4 0.577845 3.91715 0.377818 3.76966 0.230336C3.62218 0.0828543 3.42215 1.55397e-09 3.21358 0C3.00501 -1.55397e-09 2.80499 0.0828543 2.6575 0.230336L2.48011 0.40773L2.48771 0.429929C2.57513 0.680063 2.71818 0.907092 2.9061 1.0939C3.09849 1.28744 3.33347 1.43331 3.59227 1.51989L3.76966 1.3425Z"
                                                                    fill="#A7ABC3"
                                                                />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_7598_23655">
                                                                    <rect
                                                                        width="4"
                                                                        height="4"
                                                                        fill="white"
                                                                    />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                    <span className="review-time">
                                                        {shopInfo.review_date_format ? (
                                                            <>
                                                                {formatDate(
                                                                    shopInfo.review_date_format
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    reviewDisplay
                                                                        .label_content
                                                                        .text_review_on
                                                                }{" "}
                                                                27, 2022
                                                            </>
                                                        )}
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="review-item--right review-item--body">
                                    <div className="star-rating">
                                        <div className="star">
                                            {[1, 2, 3, 4].map((item, index) => (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: starIcon,
                                                    }}
                                                    className="star-icon star-icon--box"
                                                    key={`one-page-rating-3-${index}`}
                                                ></span>
                                            ))}
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: starIcon,
                                                }}
                                                className="star-icon--empty star-icon--box"
                                            ></span>
                                        </div>
                                    </div>
                                    <p className="review-title">
                                        This was nice is buy
                                    </p>
                                    <p className="review-content">
                                        This is 💯 one hundred percent the best
                                        lip mask duo ever !!! The scent is
                                        delicious and it's so smooth from the
                                        scrub & mask ~ This is perfection~
                                        Smells just like honey 🍯 & the
                                        packaging is so adorable ~ I'm so very
                                        happy with this product 🐻 🍯 ~
                                    </p>

                                    {reviewDisplay.section_show.product && (
                                        <div
                                            style={{
                                                padding: "6px",
                                                borderRadius: "8px",
                                                backgroundColor:
                                                    "var(--product_background_color)",

                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: "4px",
                                                width: "fit-content",
                                                margin: "8px 0",
                                            }}
                                            className={` ${
                                                shopInfo?.element_corners ===
                                                "square"
                                                    ? "element_corners--square"
                                                    : ""
                                            }`}
                                        >
                                            <div
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                }}
                                                className={` ${
                                                    shopInfo?.element_corners ===
                                                    "square"
                                                        ? "element_corners--square"
                                                        : ""
                                                }`}
                                            >
                                                <img
                                                    src={
                                                        listImageFromReview[2]
                                                            .data
                                                    }
                                                    alt="product"
                                                    width={20}
                                                    height={20}
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        objectFit: "cover",
                                                        aspectRatio: "1/1",
                                                    }}
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    fontSize: "13px",
                                                    fontWeight: 550,
                                                    lineHeight: "20px",
                                                    color: "var(--product_text_color)",
                                                }}
                                            >
                                                Benjamin Bookcase
                                            </span>
                                        </div>
                                    )}

                                    <div className="review-item--footer">
                                        <div className="helpful-container">
                                            {reviewDisplay.section_show
                                                .button_helpful && (
                                                <span
                                                    className={`${
                                                        shopInfo?.button_corner_radius
                                                            ? "button_corner_radius"
                                                            : ""
                                                    }`}
                                                >
                                                    <svg
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 14 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M12.8422 7.34052C13.1047 6.99364 13.25 6.56864 13.25 6.12645C13.25 5.42489 12.8578 4.76083 12.2266 4.39051C12.0641 4.29519 11.879 4.24503 11.6906 4.2452H7.94375L8.0375 2.32489C8.05938 1.86083 7.89531 1.4202 7.57656 1.08426C7.42014 0.918683 7.23141 0.786941 7.02206 0.697179C6.8127 0.607417 6.58716 0.561542 6.35938 0.562389C5.54688 0.562389 4.82813 1.10926 4.6125 1.89208L3.27031 6.75145H3.26563V13.439H10.6453C10.7891 13.439 10.9297 13.4108 11.0594 13.3546C11.8031 13.0374 12.2828 12.3108 12.2828 11.5046C12.2828 11.3077 12.2547 11.114 12.1984 10.9265C12.4609 10.5796 12.6063 10.1546 12.6063 9.71239C12.6063 9.51552 12.5781 9.32176 12.5219 9.13426C12.7844 8.78739 12.9297 8.36239 12.9297 7.9202C12.9266 7.72333 12.8984 7.52802 12.8422 7.34052ZM0.75 7.25145V12.939C0.75 13.2155 0.973438 13.439 1.25 13.439H2.26563V6.75145H1.25C0.973438 6.75145 0.75 6.97489 0.75 7.25145Z"
                                                            fill="var(--primary_text_color)"
                                                        />
                                                    </svg>
                                                    (100)
                                                </span>
                                            )}
                                            {reviewDisplay.section_show
                                                .button_helpful &&
                                                reviewDisplay.section_show
                                                    .button_report && (
                                                    <div className="divider"></div>
                                                )}
                                            {reviewDisplay.section_show
                                                .button_report && (
                                                <span
                                                    className={`report-btn ${
                                                        shopInfo?.button_corner_radius
                                                            ? "button_corner_radius"
                                                            : ""
                                                    }`}
                                                >
                                                    {
                                                        reviewDisplay
                                                            ?.label_content
                                                            ?.text_report
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {reviewDisplay.layout_summary_box === "left" && (
                            <div className="review-item">
                                <div className="review-wrap">
                                    <div className="review-item--left review-item--header">
                                        <GenerateAvt
                                            reviewerAvtType={
                                                shopInfo.reviewer_avt_type
                                            }
                                            showAvt={
                                                reviewDisplay.section_show
                                                    .customer_profile_picture
                                            }
                                            shortName="CN"
                                            singleColor={shopInfo.single_color}
                                            imgSrc={
                                                gvtarList[
                                                    shopInfo.gravatar_type ||
                                                        "mystery_person"
                                                ][3]
                                            }
                                        />
                                        <div className="customer-info">
                                            <p className="customer-name">
                                                {" "}
                                                {reviewDisplay.section_show
                                                    .customer_name
                                                    ? "Customer Name"
                                                    : nameHidden}
                                            </p>
                                            <div>
                                                <p className="verify"></p>
                                                {reviewDisplay.section_show
                                                    .review_date && (
                                                    <p>
                                                        <span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="4"
                                                                height="4"
                                                                viewBox="0 0 4 4"
                                                                fill="none"
                                                            >
                                                                <g clipPath="url(#clip0_7598_23655)">
                                                                    <path
                                                                        d="M1.88013 3.23203L3.35928 1.75288C3.11042 1.64898 2.88441 1.49717 2.6941 1.3061C2.50294 1.11575 2.35106 0.889667 2.24712 0.640721L0.767972 2.11987C0.652576 2.23526 0.594778 2.29306 0.54518 2.35666C0.486603 2.43171 0.436375 2.51292 0.395385 2.59885C0.360786 2.67165 0.334987 2.74925 0.283389 2.90404L0.0109987 3.72061C-0.0015381 3.758 -0.00339812 3.79814 0.00562766 3.83653C0.0146534 3.87492 0.0342073 3.91003 0.0620912 3.93791C0.0899751 3.96579 0.125084 3.98535 0.163471 3.99437C0.201858 4.0034 0.242001 4.00154 0.279389 3.989L1.09596 3.71661C1.25095 3.66501 1.32835 3.63921 1.40115 3.60462C1.48715 3.56362 1.56834 3.51342 1.64334 3.45482C1.70694 3.40522 1.76474 3.34742 1.88013 3.23203ZM3.76966 1.3425C3.91715 1.19501 4 0.994986 4 0.786416C4 0.577845 3.91715 0.377818 3.76966 0.230336C3.62218 0.0828543 3.42215 1.55397e-09 3.21358 0C3.00501 -1.55397e-09 2.80499 0.0828543 2.6575 0.230336L2.48011 0.40773L2.48771 0.429929C2.57513 0.680063 2.71818 0.907092 2.9061 1.0939C3.09849 1.28744 3.33347 1.43331 3.59227 1.51989L3.76966 1.3425Z"
                                                                        fill="#A7ABC3"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_7598_23655">
                                                                        <rect
                                                                            width="4"
                                                                            height="4"
                                                                            fill="white"
                                                                        />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span className="review-time">
                                                            {shopInfo.review_date_format ? (
                                                                <>
                                                                    {formatDate(
                                                                        shopInfo.review_date_format
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        reviewDisplay
                                                                            .label_content
                                                                            .text_review_on
                                                                    }{" "}
                                                                    27, 2022
                                                                </>
                                                            )}
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review-item--right review-item--body">
                                        <div className="star-rating">
                                            <div className="star">
                                                {[1, 2, 3, 4].map(
                                                    (item, index) => (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: starIcon,
                                                            }}
                                                            className="star-icon star-icon--box"
                                                            key={`one-page-rating-4-${index}`}
                                                        ></span>
                                                    )
                                                )}
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: starIcon,
                                                    }}
                                                    className="star-icon--empty star-icon--box"
                                                ></span>
                                            </div>
                                        </div>
                                        <p className="review-title">
                                            This was nice is buy
                                        </p>
                                        <p className="review-content">
                                            This is 💯 one hundred percent the
                                            best lip mask duo ever !!! The scent
                                            is delicious and it's so smooth from
                                            the scrub & mask ~ This is
                                            perfection~ Smells just like honey
                                            🍯 & the packaging is so adorable ~
                                            I'm so very happy with this product
                                            🐻 🍯 ~
                                        </p>

                                        {reviewDisplay.section_show.product && (
                                            <div
                                                style={{
                                                    padding: "6px",
                                                    borderRadius: "8px",
                                                    backgroundColor:
                                                        "var(--product_background_color)",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "4px",
                                                    width: "fit-content",
                                                    margin: "8px 0",
                                                }}
                                                className={` ${
                                                    shopInfo?.element_corners ===
                                                    "square"
                                                        ? "element_corners--square"
                                                        : ""
                                                }`}
                                            >
                                                <div
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "8px",
                                                        overflow: "hidden",
                                                    }}
                                                    className={` ${
                                                        shopInfo?.element_corners ===
                                                        "square"
                                                            ? "element_corners--square"
                                                            : ""
                                                    }`}
                                                >
                                                    <img
                                                        src={
                                                            listImageFromReview[2]
                                                                .data
                                                        }
                                                        alt="product"
                                                        width={20}
                                                        height={20}
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            objectFit: "cover",
                                                            aspectRatio: "1/1",
                                                        }}
                                                    />
                                                </div>
                                                <span
                                                    style={{
                                                        fontSize: "13px",
                                                        fontWeight: 550,
                                                        lineHeight: "20px",
                                                        color: "var(--product_text_color)",
                                                    }}
                                                >
                                                    Benjamin Bookcase
                                                </span>
                                            </div>
                                        )}

                                        <div className="review-item--footer">
                                            <div className="helpful-container">
                                                {reviewDisplay.section_show
                                                    .button_helpful && (
                                                    <span
                                                        className={`${
                                                            shopInfo?.button_corner_radius
                                                                ? "button_corner_radius"
                                                                : ""
                                                        }`}
                                                    >
                                                        <svg
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 14 14"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12.8422 7.34052C13.1047 6.99364 13.25 6.56864 13.25 6.12645C13.25 5.42489 12.8578 4.76083 12.2266 4.39051C12.0641 4.29519 11.879 4.24503 11.6906 4.2452H7.94375L8.0375 2.32489C8.05938 1.86083 7.89531 1.4202 7.57656 1.08426C7.42014 0.918683 7.23141 0.786941 7.02206 0.697179C6.8127 0.607417 6.58716 0.561542 6.35938 0.562389C5.54688 0.562389 4.82813 1.10926 4.6125 1.89208L3.27031 6.75145H3.26563V13.439H10.6453C10.7891 13.439 10.9297 13.4108 11.0594 13.3546C11.8031 13.0374 12.2828 12.3108 12.2828 11.5046C12.2828 11.3077 12.2547 11.114 12.1984 10.9265C12.4609 10.5796 12.6063 10.1546 12.6063 9.71239C12.6063 9.51552 12.5781 9.32176 12.5219 9.13426C12.7844 8.78739 12.9297 8.36239 12.9297 7.9202C12.9266 7.72333 12.8984 7.52802 12.8422 7.34052ZM0.75 7.25145V12.939C0.75 13.2155 0.973438 13.439 1.25 13.439H2.26563V6.75145H1.25C0.973438 6.75145 0.75 6.97489 0.75 7.25145Z"
                                                                fill="var(--primary_text_color)"
                                                            />
                                                        </svg>
                                                        (5)
                                                    </span>
                                                )}
                                                {reviewDisplay.section_show
                                                    .button_helpful &&
                                                    reviewDisplay.section_show
                                                        .button_report && (
                                                        <div className="divider"></div>
                                                    )}
                                                {reviewDisplay.section_show
                                                    .button_report && (
                                                    <span
                                                        className={`report-btn ${
                                                            shopInfo?.button_corner_radius
                                                                ? "button_corner_radius"
                                                                : ""
                                                        }`}
                                                    >
                                                        {
                                                            reviewDisplay
                                                                ?.label_content
                                                                ?.text_report
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    ""
                )}
            </div>
            <button
                className={`loadmore-review-btn ${
                    shopInfo?.button_corner_radius ? "button_corner_radius" : ""
                }`}
                disabled
            >
                {reviewDisplay.label_content.btn_load_more_review}
            </button>
        </div>
    );
};

export default ListReview;
