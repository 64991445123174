import {
    BlockStack,
    Icon,
    Box,
    CalloutCard,
    Card,
    Text,
    Button,
    Grid,
    InlineStack,
    Page,
} from "@shopify/polaris";
import { ChevronDownIcon, CheckIcon } from "@shopify/polaris-icons";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect, lazy, Suspense } from "react";
import StatisticsBlock from "../../components/Dashboard/Statistics";
import useEffectDashboard from "./useEffectDashboard";
import { Trans, useTranslation } from "react-i18next";
import AddWidgetPanel from "../../components/AddWidgetPanel";
import ModalNotGood from "./ModalNotGood";
const PricingTable = lazy(() => import("../../components/Pricing/PricingPage"));

const Dashboard = () => {
    const { t, i18n } = useTranslation(["dashboard", "onboarding"]);
    const [selectedLng, setSelectedLng] = useState(
        i18n.resolvedLanguage || "en"
    );
    const navigate = useNavigate();
    const {
        shopInfo,
        loading,
        dashboard,
        dashboardAction,
        setDashboardAction,
        addScriptButton,
        handleAddScript,
        handleRecheckByTabVisible,
        handleAddBlockV1,
        handleAddBlockManual,
    } = useEffectDashboard();
    const handleNavigate = (page, tab) => {
        if (page === "email") {
            navigate("/email-review/campaign");
        } else {
            if (tab) {
                navigate(`/email/discount-voucher`);
            } else {
                navigate("/widget");
            }
        }
    };
    const lngs = {
        en: { nativeName: "English" },
        de: { nativeName: "Deutsch" },
        fr: { nativeName: "Français" },
        dk: { nativeName: "Dansk" },
        jp: { nativeName: "日本語" },
        cn: { nativeName: "中文" },
        nl: { nativeName: "Nederlands" },
        in: { nativeName: "हिन्दी" },
        it: { nativeName: "Italiano" },
        nz: { nativeName: "Māori" },
        ie: { nativeName: "Gaeilge" },
        se: { nativeName: "Svenska" },
        es: { nativeName: "Español" },
        pt: { nativeName: "Português" },
        vi: { nativeName: "Tiếng Việt" },
    };
    const handleChooseLanguage = (lng) => {
        setSelectedLng(lng);
        i18n.changeLanguage(lng);
    };

    const [open, setOpen] = useState(false);

    const [openModalNotGood, setOpenModalNotGood] = useState(false);

    const [showPricing, setShowPricing] = useState(false);

    const handleAction = () => {
        setOpen(true);
    };

    const handleShowModalNotGood = (value) => {
        setOpenModalNotGood(value);
    };

    useEffect(() => {
        setOpen(false);
    }, [shopInfo?.widget?.app_embed, shopInfo?.widget?.widget_box]);
    useEffect(() => {
        setShowPricing(shopInfo?.show_pricing);
    }, [shopInfo]);
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between",
                    position: "relative",
                }}
            >
                <Page>
                    <div className="dashboard-container">
                        <div className="dashboard-wrap">
                            <BlockStack gap={400} inlineAlign="center">
                                <div className="select-language--container">
                                    <div className="select-language">
                                        <p className="selected-lng">
                                            {lngs[selectedLng]?.nativeName}{" "}
                                            <Icon source={ChevronDownIcon} />
                                        </p>
                                        <div className="list-language--wrap">
                                            <div className="list-language">
                                                {Object.keys(lngs).map(
                                                    (lng) => (
                                                        <div
                                                            key={lng}
                                                            className={`language-item ${
                                                                i18n.resolvedLanguage ===
                                                                lng
                                                                    ? "selected-language"
                                                                    : ""
                                                            }`}
                                                            onClick={() =>
                                                                handleChooseLanguage(
                                                                    lng
                                                                )
                                                            }
                                                        >
                                                            {
                                                                lngs[lng]
                                                                    .nativeName
                                                            }
                                                            {i18n.resolvedLanguage ===
                                                                lng && (
                                                                <Icon
                                                                    source={
                                                                        CheckIcon
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {shopInfo?.widget && shopInfo?.widget?.app_embed === false && (
                                    <AddWidgetPanel
                                        title={t("add_app_embedded", {
                                            ns: "translation",
                                        })}
                                        buttonText={t("add_app_embedded", {
                                            ns: "translation",
                                        })}
                                        subButtonText={t("learn_more", {
                                            ns: "translation",
                                        })}
                                        content={t("enable_app_embed_desc", {
                                            ns: "translation",
                                        })}
                                        action={handleAction}
                                        t={t}
                                        data={addScriptButton.app_embed}
                                        handleRecheckByTabVisible={
                                            handleRecheckByTabVisible
                                        }
                                        open={open}
                                        setOpen={setOpen}
                                        handleAdd={handleAddScript}
                                        handleAddBlockManual={
                                            handleAddBlockManual
                                        }
                                    />
                                )}
                                {!shopInfo?.widget?.widget_box && shopInfo?.widget?.app_embed && (
                                        <AddWidgetPanel
                                            title={t("enable_widget", {
                                                ns: "translation",
                                            })}
                                            content={t("enable_widget_desc", {
                                                ns: "translation",
                                            })}
                                            buttonText={t("add_widget", {
                                                ns: "translation",
                                            })}
                                            action={handleAction}
                                            t={t}
                                            data={addScriptButton.widget_box}
                                            handleRecheckByTabVisible={
                                                handleRecheckByTabVisible
                                            }
                                            open={open}
                                            setOpen={setOpen}
                                            handleAddBlockV1={handleAddBlockV1}
                                            handleAdd={handleAddScript}
                                            currentTheme={{
                                                version: shopInfo?.theme_version || "2.0",
                                            }}
                                            handleAddBlockManual={
                                                handleAddBlockManual
                                            }
                                        />
                                    )}

                                {!dashboardAction.close_vote && (
                                    <div className="dashboard-header">
                                        <CalloutCard
                                            title={t("popup.title")}
                                            primaryAction={{
                                                content: `${t("good", {
                                                    ns: "translation",
                                                })} 👍`,
                                                url: "https://apps.shopify.com/trustshop#modal-show=WriteReviewModal",
                                                target: "_blank",
                                            }}
                                            secondaryAction={{
                                                content: `${t("not_good", {
                                                    ns: "translation",
                                                })} 👎`,
                                                onClick: () =>
                                                    handleShowModalNotGood(
                                                        true
                                                    ),
                                                variant: "secondary",
                                            }}
                                            onDismiss={() => {
                                                window.localStorage.setItem(
                                                    "trustshop_rate_us_popup",
                                                    true
                                                );
                                                setDashboardAction({
                                                    ...dashboardAction,
                                                    close_vote: true,
                                                });
                                            }}
                                            illustration={`https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/2714be7a-39cd-4fa1-0ff5-62cd71210d00/100`}
                                        >
                                            <p>{t("popup.content")}</p>
                                        </CalloutCard>
                                    </div>
                                )}

                                <div className="dashboard-middle">
                                    <Card>
                                        <Box>
                                            <BlockStack gap={200}>
                                                <InlineStack
                                                    align="space-between"
                                                    blockAlign="center"
                                                    wrap
                                                    gap={300}
                                                >
                                                    <Text variant="headingLg">
                                                        {t("statistics.title")}
                                                    </Text>
                                                    {/* <Box minWidth="135px">
                                                    <Select
                                                        options={options}
                                                        onChange={
                                                            handleChooseDate
                                                        }
                                                        value={
                                                            dashboardAction.date
                                                        }
                                                        tone="success"
                                                    />
                                                </Box> */}
                                                </InlineStack>
                                                <Box>
                                                    <BlockStack gap={200}>
                                                        <InlineStack
                                                            gap={400}
                                                            wrap={false}
                                                        >
                                                            <Text variant="headingMd">
                                                                {t("review", {
                                                                    ns: "translation",
                                                                })}
                                                            </Text>
                                                            <NavLink
                                                                to="/review-management"
                                                                style={{
                                                                    color: "#2C6ECB",
                                                                }}
                                                            >
                                                                <Text variant="bodyLg">
                                                                    {t(
                                                                        "details",
                                                                        {
                                                                            ns: "translation",
                                                                        }
                                                                    )}
                                                                </Text>
                                                            </NavLink>
                                                        </InlineStack>
                                                        <InlineStack
                                                            gap={400}
                                                            align="stretch"
                                                        >
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.total_review
                                                                }
                                                                label={t(
                                                                    "statistics.all_review"
                                                                )}
                                                            />
                                                            <div className="statistic-divider divider-1"></div>
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.avg_review
                                                                }
                                                                label={t(
                                                                    "statistics.avg_rating"
                                                                )}
                                                                item={"avg"}
                                                            />
                                                            <div className="statistic-divider divider-2"></div>
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.pending_review
                                                                }
                                                                label={t(
                                                                    "pending",
                                                                    {
                                                                        ns: "translation",
                                                                    }
                                                                )}
                                                            />
                                                            <div className="statistic-divider divider-3"></div>
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.approved_review
                                                                }
                                                                label={t(
                                                                    "approved",
                                                                    {
                                                                        ns: "translation",
                                                                    }
                                                                )}
                                                            />
                                                            <div className="statistic-divider divider-4"></div>
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.rejected_review
                                                                }
                                                                label={t(
                                                                    "rejected",
                                                                    {
                                                                        ns: "translation",
                                                                    }
                                                                )}
                                                            />
                                                        </InlineStack>
                                                    </BlockStack>
                                                </Box>
                                                <Box>
                                                    <BlockStack gap={200}>
                                                        <InlineStack
                                                            gap={400}
                                                            wrap={false}
                                                        >
                                                            <Text variant="headingMd">
                                                                {t(
                                                                    "request_mail",
                                                                    {
                                                                        ns: "translation",
                                                                    }
                                                                )}
                                                            </Text>
                                                            <NavLink
                                                                to="/email-review"
                                                                style={{
                                                                    color: "#2C6ECB",
                                                                }}
                                                            >
                                                                <Text variant="bodyLg">
                                                                    {t(
                                                                        "details",
                                                                        {
                                                                            ns: "translation",
                                                                        }
                                                                    )}
                                                                </Text>
                                                            </NavLink>
                                                        </InlineStack>
                                                        <InlineStack
                                                            gap={400}
                                                            align="stretch"
                                                        >
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.total_mail
                                                                }
                                                                label={t(
                                                                    "statistics.all_mail"
                                                                )}
                                                            />
                                                            <div className="statistic-divider divider-1"></div>
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.success_mail
                                                                }
                                                                label={t(
                                                                    "sent",
                                                                    {
                                                                        ns: "translation",
                                                                    }
                                                                )}
                                                            />
                                                            <div className="statistic-divider divider-2"></div>
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.scheduled_mail
                                                                }
                                                                label={t(
                                                                    "scheduled",
                                                                    {
                                                                        ns: "translation",
                                                                    }
                                                                )}
                                                            />
                                                            <div className="statistic-divider divider-3"></div>
                                                            <StatisticsBlock
                                                                loading={
                                                                    loading
                                                                }
                                                                minWidth="150px"
                                                                value={
                                                                    dashboard.canceled_mail
                                                                }
                                                                label={t(
                                                                    "canceled",
                                                                    {
                                                                        ns: "translation",
                                                                    }
                                                                )}
                                                            />
                                                        </InlineStack>
                                                    </BlockStack>
                                                </Box>
                                            </BlockStack>
                                        </Box>
                                    </Card>
                                </div>
                                <div className="dashboard-footer">
                                    <Box paddingBlockEnd={400}>
                                        <Text variant="headingLg">
                                            {t("setup_guide.title")}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Grid
                                            gap={500}
                                            columns={{
                                                xl: 3,
                                                lg: 3,
                                                md: 2,
                                                sm: 1,
                                                xs: 1,
                                            }}
                                        >
                                            <Card>
                                                <BlockStack gap={100}>
                                                    <Text variant="headingMd">
                                                        {t(
                                                            "setup_guide.card_2.title"
                                                        )}
                                                    </Text>
                                                    <Box minHeight="38px">
                                                        <Text
                                                            variant="bodyMd"
                                                            tone="subdued"
                                                        >
                                                            {t(
                                                                "setup_guide.card_2.content"
                                                            )}
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        <Button
                                                            onClick={() =>
                                                                handleNavigate(
                                                                    "email"
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                "setup_guide.card_2.button"
                                                            )}
                                                        </Button>
                                                    </Box>
                                                </BlockStack>
                                            </Card>
                                            <Card>
                                                <BlockStack gap={100}>
                                                    <Text variant="headingMd">
                                                        {t(
                                                            "setup_guide.card_3.title"
                                                        )}
                                                    </Text>
                                                    <Box minHeight="38px">
                                                        <Text
                                                            variant="bodyMd"
                                                            tone="subdued"
                                                        >
                                                            {t(
                                                                "setup_guide.card_3.content"
                                                            )}
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        <Button
                                                            onClick={() =>
                                                                handleNavigate(
                                                                    "widget"
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                "setup_guide.card_3.button"
                                                            )}
                                                        </Button>
                                                    </Box>
                                                </BlockStack>
                                            </Card>
                                            <Card>
                                                <BlockStack gap={100}>
                                                    <Text variant="headingMd">
                                                        {t(
                                                            "setup_guide.card_4.title"
                                                        )}
                                                    </Text>
                                                    <Box minHeight="38px">
                                                        <Text
                                                            variant="bodyMd"
                                                            tone="subdued"
                                                        >
                                                            {t(
                                                                "setup_guide.card_4.content"
                                                            )}
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        <Button
                                                            onClick={() =>
                                                                handleNavigate(
                                                                    "widget",
                                                                    2
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                "setup_guide.card_4.button"
                                                            )}
                                                        </Button>
                                                    </Box>
                                                </BlockStack>
                                            </Card>
                                        </Grid>
                                    </Box>
                                </div>
                            </BlockStack>
                        </div>
                    </div>
                    <InlineStack align="center">
                        <Text variant="headingMd" fontWeight="regular">
                            <Trans
                                ns="translation"
                                i18nKey={"learn_more_about"}
                            >
                                Learn more about
                                <a
                                    href="https://trustshopio.notion.site/TrustShop-Help-Docs-a61d15b7148d40d993fb4ce90326c9a1?pvs=74"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        color: "#004de6",
                                    }}
                                >
                                    Trustshop
                                </a>
                            </Trans>
                        </Text>
                    </InlineStack>
                </Page>

                {openModalNotGood && (
                    <ModalNotGood
                        t={t}
                        handleShowModalNotGood={handleShowModalNotGood}
                    />
                )}

                {showPricing && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed",
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            backgroundColor: "#00000080",
                            zIndex: 999,
                        }}
                    >
                        <div
                            style={{
                                width: "fix-content",
                                maxWidth: "90%",
                                height: "90%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                                flexDirection: "column",
                                borderRadius: "12px",
                            }}
                        >
                            <Suspense fallback="loading">
                                <PricingTable
                                    shopInfo={shopInfo}
                                    open={open}
                                    setShowPricing={setShowPricing}
                                    isModal={true}
                                    isDashboard={true}
                                />
                            </Suspense>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Dashboard;
