import { BlockStack, Box, Divider, InlineStack } from "@shopify/polaris";
import { Fragment } from "react";
import { listIconStyle } from "../../../../config/listIconStyle";
const listProduct = [
    {
        id: 1,
        img: "https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/5f86ed99-4ae4-4eb6-275c-e6b4bf571a00/100",
        title: "Elite Gourmet EGC115M Easy Egg Cooker Electric 7-Egg Capacity",
        price: "302.379 VND",
        total: 28,
        rating: 4.3,
        width: 150,
        height: 127.5,
    },
    {
        id: 2,
        img: "https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/c25e08ec-e003-4a6f-67e6-fb419590e300/100",
        title: "Keurig K-Compact Coffee Maker, Single Serve K-Cup Pod Coffee Brewer, Turquoise",
        price: "1.260.713 VND",
        comparePrice: "2.521.677 VND",
        total: 1,
        rating: 5,
        width: 150,
        height: 157.89,
    },
    {
        id: 3,
        img: "https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/8dc6de33-3607-47cf-ed5b-45649b526900/100",
        title: "Paris Hilton Ceramic Nonstick Cookware Set, Cast Aluminum with Dual Layer Nonstick Coating",
        price: "2.833.388 VND",
        comparePrice: "3.026.063 VND",
        total: 1,
        rating: 5,
        width: 150,
        height: 129,
    },
    {
        id: 4,
        img: "https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/5a0083e6-a376-42a7-b151-c1dcf5455c00/100",
        title: "Ultra Personal Blender NB50500, 32 oz, Gray",
        price: "3.278.256 VND",
        comparePrice: "3.782.642 VND",
        total: 4,
        rating: 5,
        width: 150,
        height: 156.25,
    },
];
const Demo = ({ productRating, t, shopInfo }) => {
    return (
        <div
            style={{
                "--product-rating-star-size":
                    productRating.font_size.product_page.star_size + "px",
                "--collection-rating-star-size":
                    productRating.font_size.collection_page.star_size + "px",
                "--rating-icon--color":
                    productRating.rating_icon_color || "#FFB400",
            }}
        >
            <BlockStack gap={800}>
                <Box padding={0}>
                    <p className="preview--title">
                        {t("product_rating.product_review", {
                            ns: "widget",
                        })}
                    </p>
                    <div className="preview--wrap">
                        <div className="preview-content--left">
                            <img
                                src="https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/6187115e-9e1f-4ea3-5696-5e4c4e542600/100"
                                alt="product-rating-left"
                                width="295"
                                height={296}
                            />
                        </div>
                        <div className="preview-content--right">
                            <div className="rating--container">
                                <InlineStack
                                    wrap={false}
                                    gap={300}
                                    blockAlign="center"
                                >
                                    <InlineStack
                                        wrap={false}
                                        gap={100}
                                        blockAlign="center"
                                    >
                                        {productRating.display_rating ===
                                            "before" && (
                                            <p
                                                className="avg-rating"
                                                style={{
                                                    color: `${productRating.rating_text_color}`,
                                                    fontSize: `${productRating.font_size.product_page.text_star}px`,
                                                }}
                                            >
                                                4.8
                                            </p>
                                        )}
                                        {[1, 2, 3, 4].map((item) => (
                                            <Fragment key={item}>
                                                <span
                                                    className="star-icon--box star-fill"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            listIconStyle[
                                                                shopInfo?.star_icon -
                                                                    1
                                                            ] ||
                                                            listIconStyle[0],
                                                    }}
                                                ></span>
                                            </Fragment>
                                        ))}
                                        <span
                                            className="star-icon--box star-empty"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    listIconStyle[
                                                        shopInfo?.star_icon - 1
                                                    ] || listIconStyle[0],
                                            }}
                                        ></span>
                                        {productRating.display_rating ===
                                            "after" && (
                                            <p
                                                className="avg-rating"
                                                style={{
                                                    color: `${productRating.rating_text_color}`,
                                                    fontSize: `${productRating.font_size.product_page.text_star}px`,
                                                }}
                                            >
                                                4.8
                                            </p>
                                        )}
                                    </InlineStack>
                                    {productRating.display_number && (
                                        <p
                                            className="total-ratings"
                                            style={{
                                                color: `${productRating.number_text_color}`,
                                                fontSize: `${productRating.font_size.product_page.text_rating}px`,
                                            }}
                                        >
                                            2.345 {productRating.label_ratings}
                                        </p>
                                    )}
                                </InlineStack>
                            </div>
                            <div className="demo-product">
                                <img
                                    src="https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/16e1dbf9-cf4c-4de0-bf12-173870294500/150"
                                    alt="product-rating-right"
                                    width={344.5}
                                    height={252.63}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
                <Divider />
                <Box padding={0}>
                    <p className="preview--title">
                        {t("product_rating.collection_page", {
                            ns: "widget",
                        })}
                    </p>
                    <div className="preview--wrap" style={{ flexWrap: true }}>
                        <div className="collection-container">
                            <h2
                                style={{
                                    fontWeight: 400,
                                    fontSize: "13px",
                                    lineHeight: "17px",
                                    marginBottom: "5px",
                                    filter: "blur(1.5px)",
                                }}
                            >
                                Featured product
                            </h2>
                            <div className="product-list">
                                {listProduct.map((item) => (
                                    <div
                                        className="collection-product--item"
                                        key={item.id}
                                    >
                                        <img
                                            src={item.img}
                                            alt={item.title}
                                            width={item.width}
                                            height={item.height}
                                        />
                                        <p className="collection-product--title">
                                            {item.title}
                                        </p>
                                        <InlineStack
                                            gap={100}
                                            wrap={false}
                                            blockAlign="center"
                                        >
                                            {productRating.display_rating_collection ===
                                                "before" && (
                                                <>
                                                    <span
                                                        style={{
                                                            fontSize: `${productRating.font_size.collection_page.text_star}px`,
                                                            fontWeight: 500,
                                                            lineHeight: "100%",
                                                            color: `${productRating.number_text_color}`,
                                                        }}
                                                    >
                                                        ({item.total})
                                                    </span>
                                                </>
                                            )}
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <Fragment key={item.id + star}>
                                                    <span
                                                        className="star-icon--box star-fill"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                listIconStyle[
                                                                    shopInfo?.star_icon -
                                                                        1
                                                                ] ||
                                                                listIconStyle[0],
                                                        }}
                                                    ></span>
                                                </Fragment>
                                            ))}
                                            {productRating.display_rating_collection ===
                                                "after" && (
                                                <>
                                                    <span
                                                        style={{
                                                            fontSize: `${productRating.font_size.collection_page.text_star}px`,
                                                            fontWeight: 500,
                                                            lineHeight: "100%",
                                                            color: `${productRating.number_text_color}`,
                                                        }}
                                                    >
                                                        ({item.total})
                                                    </span>
                                                </>
                                            )}
                                        </InlineStack>
                                        <div className="collection-product--price">
                                            {item.comparePrice && (
                                                <span className="collection-product-compare-price">
                                                    {item.comparePrice}
                                                </span>
                                            )}
                                            <span className="collection-product--price">
                                                {item.price}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Box>
            </BlockStack>
        </div>
    );
};
export default Demo;
