import React, { useCallback, useState } from "react";
import {
    Box,
    Card,
    Button,
    InlineStack,
    Text,
    BlockStack,
    TextField,
    Popover,
    ActionList,
} from "@shopify/polaris";
import { CheckIcon, FileIcon, XIcon } from "@shopify/polaris-icons";
import { Trans } from "react-i18next";
import * as Config from "../../api/Config";
import { useSelector } from "react-redux";

const AddAppBlockSnippet = ({ t, block, collection, disabled }) => {
    const shopInfo = useSelector((state) => state.Auth.shopInfo);
    const [coppySuccess, setCoppySuccess] = useState(false);
    const [open, setOpen] = useState({
        popoverActive: false,
        modalGuild: false,
        collection: false,
    });

    const toggleOpen = useCallback(
        (key) =>
            setOpen((prev) => {
                return {
                    ...prev,
                    [key]: !open[key],
                };
            }),
        [open]
    );

    const addWidgetButton = (
        <Button
            variant="primary"
            onClick={() => toggleOpen("popoverActive")}
            disclosure
            disabled={disabled}
        >
            {block === "rating"
                ? t("add_rating_star", { ns: "translation" })
                : t("add_widget", { ns: "translation" })}
        </Button>
    );

    const listCodeEmbed = {
        widget: {
            theme2: `https://${shopInfo.domain}/admin/themes/current/editor?template=product&addAppBlockId=${Config.THEME_APP_EXTENSION_ID}/widget-box&target=newAppsSection`,
            theme1: `https://${shopInfo.domain}/admin/themes/current/?key=templates%2Fproduct.liquid`,
            code: `<div class="trustshop trustshop-review-wrap" id="trustshop-review"></div>`,
        },
        rating: {
            theme2: `https://${shopInfo.domain}/admin/themes/current/editor?template=product&addAppBlockId=${Config.THEME_APP_EXTENSION_ID}/widget-product-rating&target=mainSection`,
            theme1: `https://${shopInfo.domain}/admin/themes/current/?key=templates%2Fproduct.liquid`,
            code: `<div class="trustshop-review--summary" id="trustshop-review--summary"></div>`,
        },
        testimonial: {
            theme2: `https://${shopInfo.domain}/admin/themes/current/editor?template=index&addAppBlockId=${Config.THEME_APP_EXTENSION_ID}/review-testimonial&target=newAppsSection`,
            theme1: `https://${shopInfo.domain}/admin/themes/current/?key=templates%2Fproduct.liquid`,
            code: `<div id="trustshop-testimonial" class="trustshop-testimonial--container"></div>`,
        },
        one_page: {
            code: `<div id="trustshop-review-one--page" class="trustshop-review-one-page_container"></div>`
        }
    };

    const handleAddStarRating = () => {
        window.open(listCodeEmbed[block].theme2, "_blank");
    };

    const handleEditCode = () => {
        window.open(listCodeEmbed[block].theme1, "_blank");
    };

    const handleCoppyCode = () => {
        const input = document.getElementById("star-rating-embbed--code");
        document.getSelection().removeAllRanges();
        const range = document.createRange();
        range.selectNode(input);
        window.getSelection().addRange(range);
        window.navigator.clipboard.writeText(input.value);
        document.getSelection().removeAllRanges();
        setCoppySuccess(true);
        setTimeout(() => {
            setCoppySuccess(false);
        }, 2000);
        window.shopify.toast.show(t("copied", { ns: "translation" }), {
            duration: 2000,
        });
    };
    return (
        <>
            <div className="add-app-block-guild--popover">
                <Popover
                    activator={addWidgetButton}
                    active={open.popoverActive}
                    onClose={() => toggleOpen("popoverActive")}
                >
                    <ActionList
                        actionRole="menuitem"
                        items={[
                            {
                                content: t("theme_v2", {
                                    ns: "translation",
                                }),
                                onAction: handleAddStarRating,
                            },
                            {
                                content: t("theme_v1", {
                                    ns: "translation",
                                }),
                                onAction: () => toggleOpen("modalGuild"),
                            },
                            collection && {
                                content: t("collection", {
                                    ns: "translation",
                                }),
                                onAction: () => toggleOpen("collection"),
                            },
                        ]}
                    />
                </Popover>
            </div>
            {open.modalGuild && (
                <div id="add-app-block--guild">
                    <Card padding={0}>
                        <Box padding={400} background="bg">
                            <InlineStack
                                align="space-between"
                                blockAlign="center"
                                gap={400}
                            >
                                <Text variant="headingMd">
                                    {t(
                                        `product_rating.modal_add_rating_manually.${block}_title`,
                                        { ns: "widget" }
                                    )}
                                </Text>
                                <Button
                                    icon={XIcon}
                                    variant="plain"
                                    onClick={() => toggleOpen("modalGuild")}
                                ></Button>
                            </InlineStack>
                        </Box>
                        <Box padding={400}>
                            <BlockStack gap={200}>
                                <Text>
                                    {block === "rating" ? (
                                        <Trans
                                            i18nKey={`product_rating.modal_add_rating_manually.guild_rating`}
                                            ns="widget"
                                        >
                                            Copy the code below and paste it on
                                            the specific sections that you want
                                            to show the rating star.
                                            <a
                                                href="https://www.youtube.com/watch?v=BBE-hRvhp08"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Learn more
                                            </a>
                                        </Trans>
                                    ) : (
                                        <Trans
                                            i18nKey={`product_rating.modal_add_rating_manually.guild_widget`}
                                            ns="widget"
                                        >
                                            Copy the code below and paste it on
                                            the specific sections that you want
                                            to show the widget.
                                            <a
                                                href="https://www.youtube.com/watch?v=IPajBWLP08k"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Learn more
                                            </a>
                                        </Trans>
                                    )}
                                </Text>
                                <div className="embbed-code--textfield">
                                    <TextField
                                        value={listCodeEmbed[block].code}
                                        readOnly
                                        id="star-rating-embbed--code"
                                    />
                                    <Button
                                        icon={
                                            coppySuccess ? CheckIcon : FileIcon
                                        }
                                        onClick={() => {
                                            handleCoppyCode();
                                        }}
                                        id="embbed-code--btn"
                                    >
                                        {coppySuccess
                                            ? t("copied", {
                                                  ns: "translation",
                                              })
                                            : ""}
                                    </Button>
                                </div>
                            </BlockStack>
                        </Box>
                        <Box
                            padding={400}
                            borderBlockStartWidth="0165"
                            borderColor="border"
                        >
                            <InlineStack
                                gap={200}
                                blockAlign="center"
                                align="end"
                            >
                                <Button
                                    variant="primary"
                                    onClick={handleEditCode}
                                >
                                    {t("edit_code", { ns: "translation" })}
                                </Button>
                            </InlineStack>
                        </Box>
                    </Card>
                </div>
            )}

            {open.collection && (
                <div id="add-app-block--guild">
                    <Card padding={0}>
                        <Box padding={400} background="bg">
                            <InlineStack
                                align="space-between"
                                blockAlign="center"
                                gap={400}
                            >
                                <Text variant="headingMd">
                                    {t(
                                        `product_rating.modal_add_rating_manually.collection_title`,
                                        { ns: "widget" }
                                    )}
                                </Text>
                                <Button
                                    icon={XIcon}
                                    variant="plain"
                                    onClick={() => toggleOpen("collection")}
                                ></Button>
                            </InlineStack>
                        </Box>
                        <Box padding={400}>
                            <BlockStack gap={200}>
                                <Text>
                                    <Trans
                                        i18nKey={`product_rating.modal_add_rating_manually.guild_collection`}
                                        ns="widget"
                                    >
                                        Copy the code below and paste it on the
                                        specific sections that you want to show
                                        the collection rating.
                                        {/* <a href="#" rel="noreferrer">
                                            Learn more
                                        </a> */}
                                    </Trans>
                                </Text>
                                <div className="embbed-code--textfield">
                                    <TextField
                                        value={`<div class="trustshop-collection-rating--item"\nrating-value="{{ card_product.metafields.trustshop_reviews.stars_review.value.average_review }}" \nrating-count="{{ card_product.metafields.trustshop_reviews.stars_review.value.total_review }}" >\n</div>`}
                                        readOnly
                                        multiline="3"
                                        id="star-rating-embbed--code"
                                    />
                                    <Button
                                        icon={
                                            coppySuccess ? CheckIcon : FileIcon
                                        }
                                        onClick={() => {
                                            handleCoppyCode();
                                        }}
                                        id="embbed-code--btn"
                                    >
                                        {coppySuccess
                                            ? t("copied", {
                                                  ns: "translation",
                                              })
                                            : ""}
                                    </Button>
                                </div>
                            </BlockStack>
                        </Box>
                        <Box
                            padding={400}
                            borderBlockStartWidth="0165"
                            borderColor="border"
                        >
                            <InlineStack
                                gap={200}
                                blockAlign="center"
                                align="end"
                            >
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        window.open(
                                            `https://${shopInfo.domain}/admin/themes/current/?key=templates%2Fcard_product.liquid`,
                                            "_blank"
                                        )
                                    }
                                >
                                    {t("edit_code", { ns: "translation" })}
                                </Button>
                            </InlineStack>
                        </Box>
                    </Card>
                </div>
            )}
        </>
    );
};

export default AddAppBlockSnippet;
