import React, { useState, useEffect } from "react";
import { InlineStack, BlockStack, Box, Checkbox } from "@shopify/polaris";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import iconDrag from "../../../../../../assets/images/widget/icon-dragable.svg";

function ListItem({
    items,
    id,
    keyItem,
    index,
    title,
    displaySetting,
    reviewDisplay,
    handleFilter,
}) {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => {
                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={
                            snapshot.isDragging
                                ? {
                                      background: "white",
                                      ...provided.draggableProps.style,
                                  }
                                : provided.draggableProps.style
                        }
                        className="drag-item--container"
                    >
                        <Box>
                            <div {...provided.dragHandleProps}>
                                <InlineStack
                                    gap={100}
                                    blockAlign="center"
                                    wrap={false}
                                    align="space-between"
                                >
                                    <Checkbox
                                        type="checkbox"
                                        id={keyItem}
                                        checked={reviewDisplay.sort_filter.includes(
                                            keyItem
                                        )}
                                        onChange={(e) =>
                                            handleFilter(e, keyItem)
                                        }
                                        label={
                                            <InlineStack
                                                gap={200}
                                                blockAlign="center"
                                                align="space-between"
                                            >
                                                <p
                                                    className={`conponent-item--label
                                            ${
                                                reviewDisplay.sort_filter.includes(
                                                    keyItem
                                                )
                                                    ? ""
                                                    : "subdued-text"
                                            }`}
                                                >
                                                    {title}
                                                </p>
                                            </InlineStack>
                                        }
                                    />
                                    <img src={iconDrag} alt="drag-icon" />
                                </InlineStack>
                            </div>
                        </Box>
                    </div>
                );
            }}
        </Draggable>
    );
}

function DraggableReviewFilter({
    t,
    displaySetting,
    reviewDisplay,
    setReviewDisplay,
    handleShowHideReviewFilter,
    listTabFilter,
    handleQueueFilter,
    handleDragEnd,
}) {

    const handleFilter = (e, keyFilter) => {
        handleShowHideReviewFilter(e, keyFilter);
    };

    useEffect(() => {
        handleQueueFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listTabFilter]);

    const handleDragState = ({ source, destination }) => {
        handleDragEnd({ source, destination });
    };
    return (
        <>
            <Box paddingBlockStart={400}>
                <BlockStack gap={200}>
                    <DragDropContext onDragEnd={handleDragState}>
                        <Droppable droppableId="root">
                            {(provided) => {
                                return (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        <BlockStack gap={400}>
                                            {listTabFilter.map(
                                                (item, index) => (
                                                    <ListItem
                                                        key={index}
                                                        keyItem={
                                                            Object.keys(item)[0]
                                                        }
                                                        id={String(index)}
                                                        index={index}
                                                        title={
                                                            Object.values(
                                                                item
                                                            )[0]
                                                        }
                                                        displaySetting={
                                                            displaySetting
                                                        }
                                                        items={listTabFilter}
                                                        reviewDisplay={
                                                            reviewDisplay
                                                        }
                                                        handleFilter={
                                                            handleFilter
                                                        }
                                                    />
                                                )
                                            )}
                                        </BlockStack>
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </DragDropContext>
                    <Box
                        borderBlockStartWidth="0165"
                        borderColor="border"
                        paddingBlockStart={200}
                    >
                        <Checkbox
                            type="checkbox"
                            id="number_of_reviews"
                            checked={
                                reviewDisplay.section_show.number_of_reviews
                            }
                            onChange={(value) => {
                                setReviewDisplay({
                                    ...reviewDisplay,
                                    section_show: {
                                        ...reviewDisplay.section_show,
                                        number_of_reviews: value,
                                    },
                                });
                            }}
                            label={
                                <p
                                    className={`component-item-label ${
                                        reviewDisplay.section_show
                                            .number_of_reviews
                                            ? ""
                                            : "subdued-text"
                                    }`}
                                >
                                    {t(
                                        "review_display.information_display.number_of_reviews",
                                        {
                                            ns: "widget",
                                        }
                                    )}
                                </p>
                            }
                        />
                    </Box>
                </BlockStack>
            </Box>
        </>
    );
}

export default DraggableReviewFilter;
