import {
    Banner,
    Box,
    Text,
    BlockStack,
    Button,
    InlineStack,
} from "@shopify/polaris";
import ModalAddScript from "./ModalAddScript";
const AddWidgetPanel = ({
    action,
    content,
    title,
    buttonText,
    subButtonText,
    t,
    data,
    handleRecheckByTabVisible,
    open,
    setOpen,
    handleAddBlockV1,
    handleAdd,
    currentTheme,
    handleAddBlockManual,
}) => {
    const type =
        buttonText ===
        t("add_app_embedded", {
            ns: "translation",
        })
            ? "app-embed"
            : "widget-box";
    
    const handleLearnMore = () => {
        window.open(
            "https://trustshopio.notion.site/Enable-the-app-embed-in-your-theme-e2d49812e92344b5b2da210aa8e1ea89",
            "_blank"
        );
    };
    return (
        <div className="add-script--banner">
            <Banner title={title} tone="warning">
                <Box paddingInline={200} paddingBlockStart={100}>
                    <BlockStack gap={200}>
                        <Text variant="bodySm">{content}</Text>
                        <InlineStack gap={200}>
                            <Button
                                variant="primary"
                                onClick={() => action()}
                                size="large"
                            >
                                {buttonText}
                            </Button>
                            <Button
                                onClick={() =>
                                    handleAddBlockManual(
                                        type,
                                        type === "app-embed"? "app_embed": "widget_box"
                                    )
                                }
                            >
                                {t("it_was_added", {
                                    ns: "translation",
                                })}
                            </Button>
                            {subButtonText && (
                                <Button
                                    variant="plain"
                                    onClick={() => handleLearnMore()}
                                >
                                    {subButtonText}
                                </Button>
                            )}
                        </InlineStack>
                    </BlockStack>
                </Box>
            </Banner>
            {open && (
                <ModalAddScript
                    t={t}
                    data={data}
                    handleRecheckByTabVisible={handleRecheckByTabVisible}
                    block={buttonText}
                    type={type}
                    handleAdd={handleAdd}
                    handleAddBlockV1={handleAddBlockV1}
                    currentTheme={currentTheme}
                    setOpen={setOpen}
                />
            )}
        </div>
    );
};
export default AddWidgetPanel;
