const OnePageSkeleton = ({ type, active }) => {
    return (
        <>
            {type === "left" ? (
                <svg
                    width="90"
                    height="50"
                    viewBox="0 0 90 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                        <filter id="centerActive">
                        <feColorMatrix
                                type="matrix"
                                values={
                                    active
                                        ? `0 0 0 0 0
                                    0 0 0 0 0
                                    0 0 1 0 0
                                    0 0 0 1 0`
                                        : `0.33 0.33 0.33 0 0
                                            0.33 0.33 0.33 0 0
                                            0.33 0.33 0.33 0 0
                                            0 0 0 1 0`
                                }
                            />
                        </filter>
                    </defs>
                    <g filter="url(#centerActive)">
                        <rect
                            x="0.5"
                            y="0.5"
                            width="89"
                            height="49"
                            rx="3.5"
                            stroke="#D3D3D3"
                        />
                        <rect
                            x="24"
                            y="4"
                            width="42"
                            height="4"
                            rx="2"
                            fill="#2C6ECB"
                            fillOpacity="0.05"
                        />
                        <rect
                            x="11.25"
                            y="10.25"
                            width="31.5"
                            height="13.5"
                            rx="1.75"
                            stroke="#CCCCCC"
                            strokeWidth="0.5"
                        />
                        <rect
                            x="14"
                            y="14"
                            width="6"
                            height="6"
                            rx="3"
                            fill="#B5B5B5"
                        />
                        <rect
                            x="22"
                            y="13"
                            width="18"
                            height="1"
                            rx="0.5"
                            fill="#B5B5B5"
                        />
                        <rect
                            x="22"
                            y="15"
                            width="18"
                            height="1"
                            rx="0.5"
                            fill="#B5B5B5"
                        />
                        <rect
                            x="22"
                            y="17"
                            width="18"
                            height="1"
                            rx="0.5"
                            fill="#B5B5B5"
                        />
                        <rect
                            x="22"
                            y="19"
                            width="18"
                            height="1"
                            rx="0.5"
                            fill="#B5B5B5"
                        />
                        <g clipPath="url(#clip0_20912_66528)">
                        <rect
                                x="11"
                                y="27"
                                width="21"
                                height="13"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="34"
                                y="27"
                                width="21"
                                height="6"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="57"
                                y="27"
                                width="22"
                                height="8"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="11"
                                y="42"
                                width="21"
                                height="10"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="34"
                                y="35"
                                width="21"
                                height="11"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="57"
                                y="37"
                                width="22"
                                height="15"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_20912_66528">
                            <rect
                                width="68"
                                height="19"
                                fill="white"
                                transform="translate(11 27)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            ): (
                <svg
                    width="90"
                    height="50"
                    viewBox="0 0 90 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                        <filter id="leftActive">
                            <feColorMatrix
                                type="matrix"
                                values={
                                    active
                                        ? `0 0 0 0 0
                                    0 0 0 0 0
                                    0 0 1 0 0
                                    0 0 0 1 0`
                                        : `0.33 0.33 0.33 0 0
                                            0.33 0.33 0.33 0 0
                                            0.33 0.33 0.33 0 0
                                            0 0 0 1 0`
                                }
                            />
                        </filter>
                    </defs>
                    <g filter="url(#leftActive)">
                        <rect
                            x="0.5"
                            y="0.5"
                            width="89"
                            height="49"
                            rx="3.5"
                            stroke="#D3D3D3"
                        />
                        <rect
                            x="24"
                            y="4"
                            width="42"
                            height="4"
                            rx="2"
                            fill="#2C6ECB"
                            fillOpacity="0.15"
                        />
                        <rect
                            x="11.25"
                            y="10.25"
                            width="67.5"
                            height="13.5"
                            rx="1.75"
                            stroke="#CCCCCC"
                            strokeWidth="0.5"
                        />
                        <rect
                            x="32"
                            y="15"
                            width="6"
                            height="6"
                            rx="3"
                            fill="#B5B5B5"
                        />
                        <rect
                            x="40"
                            y="14"
                            width="18"
                            height="1"
                            rx="0.5"
                            fill="#B5B5B5"
                        />
                        <rect
                            x="40"
                            y="16"
                            width="18"
                            height="1"
                            rx="0.5"
                            fill="#B5B5B5"
                        />
                        <rect
                            x="40"
                            y="18"
                            width="18"
                            height="1"
                            rx="0.5"
                            fill="#B5B5B5"
                        />
                        <rect
                            x="40"
                            y="20"
                            width="18"
                            height="1"
                            rx="0.5"
                            fill="#B5B5B5"
                        />
                        <g clipPath="url(#clip0_20912_66525)">
                            <rect
                                x="11"
                                y="27"
                                width="21"
                                height="13"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="34"
                                y="27"
                                width="21"
                                height="6"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="57"
                                y="27"
                                width="22"
                                height="8"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="11"
                                y="42"
                                width="21"
                                height="10"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="34"
                                y="35"
                                width="21"
                                height="11"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                            <rect
                                x="57"
                                y="37"
                                width="22"
                                height="15"
                                rx="2"
                                fill="#2C6ECB"
                                fillOpacity="0.15"
                            />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_20912_66525">
                            <rect
                                width="68"
                                height="19"
                                fill="white"
                                transform="translate(11 27)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </>
    );
};

export default OnePageSkeleton;
