import { AlertCircleIcon } from "@shopify/polaris-icons";
import { Icon, BlockStack, Divider } from "@shopify/polaris";
const ReviewPerPage = ({
    t,
    reviewDisplay,
    handleChangeReviewPerPage,
    error,
    disabled
}) => {
    return (
        <div className="setting-component">
            <BlockStack gap={200}>
                <p className={`component-title `}>
                    {t("review_display.information_display.review_per_page", {
                        ns: "widget",
                    })}
                </p>
                <Divider />
                <BlockStack gap={100}>
                    <label
                        className="Polaris-Label__Text"
                        htmlFor="review_per_page_mobile"
                    >{`${t("mobile", {
                        ns: "translation",
                    })}(1-10)`}</label>
                    <input
                        id="review_per_page_mobile"
                        className="review_per_page_input"
                        min={1}
                        max={10}
                        type="number"
                        value={reviewDisplay.review_per_page_mobile}
                        onChange={(value) =>
                            handleChangeReviewPerPage(
                                value,
                                "review_per_page_mobile"
                            )
                        }
                        disabled={disabled}
                    />
                    {error.review_per_page_mobile &&
                        error.review_per_page_mobile && (
                            <span
                                className="Polaris-InlineError"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2px",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                }}
                            >
                                <Icon source={AlertCircleIcon} />
                                {error.review_per_page_mobile}
                            </span>
                        )}
                </BlockStack>
                <BlockStack gap={100}>
                    <label
                        className="Polaris-Label__Text"
                        htmlFor="review_per_page_desktop"
                    >{`${t("desktop", {
                        ns: "translation",
                    })}(1-10)`}</label>
                    <input
                        id="review_per_page_desktop"
                        className="review_per_page_input"
                        min={1}
                        max={10}
                        type="number"
                        value={reviewDisplay.review_per_page_desktop}
                        onChange={(value) =>
                            handleChangeReviewPerPage(
                                value,
                                "review_per_page_desktop"
                            )
                        }
                        disabled={disabled}
                    />
                    {error.review_per_page_desktop &&
                        error.review_per_page_desktop && (
                            <span
                                className="Polaris-InlineError"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2px",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                }}
                            >
                                <Icon source={AlertCircleIcon} />
                                {error.review_per_page_desktop}
                            </span>
                        )}
                </BlockStack>
            </BlockStack>
        </div>
    );
};

export default ReviewPerPage;
