import {
    InlineGrid,
    Box,
    Icon,
    Collapsible,
    Text,
    InlineStack,
    Checkbox,
    TextField,
    BlockStack,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import OnePageFontSettingComponent from "../../../../../../components/Widget/OnePageFontSettingComponent";
const onePageTitle = ["title", "sub_title"];
const OnePageHeader = ({
    t,
    open,
    handleToggle,
    reviewDisplay,
    setReviewDisplay,
    disabled
}) => {
    return (
        <>
            <Box padding={0}>
                <div
                    className="widget-setting-collape--title"
                    onClick={() => handleToggle("onePageHeader")}
                >
                    <h5 className="setting-group--title">
                        {t("review_onepage.one_page_header.title", {
                            ns: "widget",
                        })}
                    </h5>
                    {open.onePageHeader ? (
                        <Icon source={ChevronDownIcon} />
                    ) : (
                        <Icon source={ChevronRightIcon} />
                    )}
                </div>
                <Collapsible
                    open={open.onePageHeader}
                    id="basic-collapsible"
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                >
                    <Box paddingBlock={400}>
                        <InlineGrid gap={400}>
                            {onePageTitle.map((item) => (
                                <Box key={`one-page-${item}`}>
                                    <BlockStack gap={100}>
                                        <Checkbox
                                            label={t(item.split("_").join(""), {
                                                ns: "translation",
                                            })}
                                            checked={
                                                reviewDisplay?.section_show[
                                                    item
                                                ]?.status
                                            }
                                            onChange={(value) =>
                                                setReviewDisplay({
                                                    ...reviewDisplay,
                                                    section_show: {
                                                        ...reviewDisplay.section_show,
                                                        [item]: {
                                                            ...reviewDisplay
                                                                .section_show[
                                                                item
                                                            ],
                                                            status: value,
                                                        },
                                                    },
                                                })
                                            }
                                            disabled={disabled}
                                        />
                                        <BlockStack gap={0}>
                                            <InlineStack
                                                wrap={false}
                                                gap={200}
                                                align="space-between"
                                                blockAlign="center"
                                            >
                                                <Text>
                                                    {t("font_size", {
                                                        ns: "translation",
                                                    })}{" "}
                                                    (
                                                    {t("pixels", {
                                                        ns: "translation",
                                                    })}
                                                    )
                                                </Text>
                                                <Box width="80px">
                                                    <TextField
                                                        onChange={(value) =>
                                                            setReviewDisplay({
                                                                ...reviewDisplay,
                                                                section_show: {
                                                                    ...reviewDisplay.section_show,
                                                                    [item]: {
                                                                        ...reviewDisplay
                                                                            .section_show[
                                                                            item
                                                                        ],
                                                                        size: value,
                                                                    },
                                                                },
                                                            })
                                                        }
                                                        type="number"
                                                        value={
                                                            reviewDisplay
                                                                ?.section_show[
                                                                item
                                                            ]?.size
                                                        }
                                                        disabled={disabled}
                                                    />
                                                </Box>
                                            </InlineStack>
                                            <TextField
                                                label={
                                                    <InlineStack
                                                        gap={200}
                                                        wrap={false}
                                                    >
                                                        <Text>
                                                            {t(
                                                                item
                                                                    .split("_")
                                                                    .join(""),
                                                                {
                                                                    ns: "translation",
                                                                }
                                                            )}
                                                        </Text>
                                                        <Box
                                                            borderRadius="050"
                                                            background="bg-surface-active"
                                                            padding={100}
                                                        >
                                                            <OnePageFontSettingComponent
                                                                style={
                                                                    reviewDisplay
                                                                        .section_show[
                                                                        item
                                                                    ].text_style
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    if(disabled) return;
                                                                    setReviewDisplay(
                                                                        {
                                                                            ...reviewDisplay,
                                                                            section_show:
                                                                                {
                                                                                    ...reviewDisplay.section_show,
                                                                                    [item]: {
                                                                                        ...reviewDisplay
                                                                                            .section_show[
                                                                                            item
                                                                                        ],
                                                                                        text_style:
                                                                                            reviewDisplay.section_show[
                                                                                                item
                                                                                            ].text_style.includes(
                                                                                                value
                                                                                            )
                                                                                                ? reviewDisplay.section_show[
                                                                                                      item
                                                                                                  ].text_style.filter(
                                                                                                      (
                                                                                                          i
                                                                                                      ) =>
                                                                                                          i !==
                                                                                                          value
                                                                                                  )
                                                                                                : [
                                                                                                      ...reviewDisplay
                                                                                                          .section_show[
                                                                                                          item
                                                                                                      ]
                                                                                                          .text_style,
                                                                                                      value,
                                                                                                  ],
                                                                                    },
                                                                                },
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </InlineStack>
                                                }
                                                value={
                                                    reviewDisplay?.section_show[
                                                        item
                                                    ]?.content
                                                }
                                                onChange={(value) =>
                                                    setReviewDisplay({
                                                        ...reviewDisplay,
                                                        section_show: {
                                                            ...reviewDisplay.section_show,
                                                            [item]: {
                                                                ...reviewDisplay
                                                                    .section_show[
                                                                    item
                                                                ],
                                                                content: value,
                                                            },
                                                        },
                                                    })
                                                }
                                                disabled={disabled}
                                            />
                                        </BlockStack>
                                    </BlockStack>
                                </Box>
                            ))}{" "}
                        </InlineGrid>
                    </Box>
                </Collapsible>
            </Box>
        </>
    );
};

export default OnePageHeader;
