import { Box, Card, BlockStack, Text, Button } from "@shopify/polaris";
import { useState, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
const PricingModal = lazy(()=>import("./PricingPage"));
const WidgetLimitNotice = ({ content, btnContent }) => {
    const shopInfo = useSelector(state => state?.Auth?.shopInfo)
    const [showPricing, setShowPricing] = useState(false)
    return (
        <>
            <Box paddingBlockStart={400}>
                <Card>
                    <BlockStack gap={200}>
                        <Text>
                            {content}
                        </Text>
                        <Box>
                            <Button
                                variant="primary"
                                onClick={() => setShowPricing(true)}
                            >
                                {btnContent}
                            </Button>
                        </Box>
                    </BlockStack>
                </Card>
            </Box>
            {showPricing && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        backgroundColor: "#00000080",
                        zIndex: 999,
                    }}
                >
                    <div
                        style={{
                            width: "fix-content",
                            maxWidth: "90%",
                            height: "90%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            flexDirection: "column",
                            borderRadius: "12px",
                        }}
                    >
                        <Suspense fallback="loading">
                            <PricingModal
                                shopInfo={shopInfo}
                                open={showPricing}
                                setShowPricing={setShowPricing}
                                isModal={true}
                            />
                        </Suspense>
                    </div>
                </div>
            )}
        </>
    );
};

export default WidgetLimitNotice;
