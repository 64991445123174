import {
    Frame,
    Modal,
    Checkbox,
    Avatar,
    BlockStack,
    Badge,
    Box,
    InlineStack,
    Text,
    Pagination
} from '@shopify/polaris';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Filter from "./Filter";

const ModalChooseManual = ({
    t,
    active,
    handleCloseModal,
    reviews,
    carouselDisplay,
    setCarouselDisplay,
    filter,
    setFilter,
    isLoadReview,
}) => {
    const [selected, setSelected] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const listVariant = [
        '100',
        '150',
        '450',
        '50',
        '500',
        '600',
        '70',
        'public'
    ]
    const[pickedReviews, setPickedReviews] = useState([])
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const isToday = date.toDateString() === now.toDateString();
        const timeString = date.toLocaleTimeString("en-US", {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });

        return isToday ? `Today at ${timeString}` : `${date.toLocaleDateString()} at ${timeString}`;
    };

    const handleItemCheck = (id, checked) => {
        if (checked) {
            setPickedReviews(prev => {
                if (prev.length < 15) {
                    return [...prev, id];
                } else {
                    return prev;
                }
            });
        } else {
            setPickedReviews(prev => prev.filter(itemId => itemId !== id));
        }
    };

    const handleSelectAllChange = (checked) => {
        setSelectAll(checked);
        if (checked) {
            setPickedReviews(reviews.listReviews.map(review => review.id));
        } else {
            setPickedReviews([]);
        }
    };

    const rowMarkup = reviews.listReviews && reviews.listReviews.map(
        ({ id, product_title, product_featured_image, title, content, created_at, updated_at, review_star, customer, avatar, medias }, index) => (
            <div className='row' key={id}>
                <BlockStack>
                    <div className="header">
                        <Checkbox
                            value={id}
                            checked={pickedReviews.includes(id)}
                            onChange={(checked) => handleItemCheck(id, checked)}
                        />
                        <Badge size="small" border='none'>
                            <div className='rating'>
                                <p className='rating-number'>
                                    {review_star}
                                </p>
                                {[...Array(5)].map((_, i) => (
                                    <span key={i} className="rating-star">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="13"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.17966 1.17107C7.52577 0.358624 8.67815 0.360835 9.02114 1.17461L10.478 4.63101C10.6185 4.96438 10.9281 5.19586 11.2876 5.23633L14.9473 5.6483C15.7936 5.74357 16.1411 6.78565 15.5213 7.36977L12.7138 10.0156C12.4628 10.2522 12.3514 10.6015 12.4192 10.9397L13.1847 14.7617C13.3558 15.6163 12.428 16.2656 11.6837 15.812L8.59522 13.9297C8.27685 13.7356 7.87698 13.7348 7.55784 13.9276L4.46176 15.7973C3.7158 16.2478 2.79066 15.595 2.96503 14.7412L3.74519 10.9212C3.81421 10.5833 3.7042 10.2336 3.45414 9.99608L0.656806 7.33881C0.039253 6.75218 0.390985 5.71137 1.2378 5.61961L4.8978 5.22299C5.25758 5.184 5.56822 4.95368 5.71006 4.62074L7.17966 1.17107Z"
                                                fill={i < review_star ? "#FFD700" : "#E2E2E2"}
                                            />
                                        </svg>
                                    </span>
                                ))}
                            </div>
                        </Badge>
                    </div>
                    <div className="body">
                        <img
                            width={50}
                            height={50}
                            src={product_featured_image}
                            className="product-images"
                            alt={product_title}
                        />
                        <div className='product-info'>
                            <p className='title'>{product_title}</p>
                        </div>
                    </div>
                    <BlockStack>
                        <div style={{
                            padding: '10px 30px'
                        }}>
                            <Text variant='headingMd' as="h2">{title}</Text>
                            <p>{content}</p>
                        </div>
                    </BlockStack>
                    <div className="footer">
                        <div className='list-images'>
                            {medias.slice(0, 5).map((media, index) => (
                                <img
                                    key={index}
                                    src={media.url + `/${listVariant[0]}` }
                                    alt=''
                                    className='image'
                                />
                            ))}
                        </div>
                        <div className='customer-info' >
                            <p className='date'>{formatDate(created_at)}</p>
                            <div className='personal-info'>
                                <p className='name'>{customer.name}</p>
                                <p className='email'>{customer.email}</p>
                            </div>
                            <Avatar
                                id={customer.id}
                                email={customer.email}
                                name={customer.name}
                                last_ip={customer.last_ip}
                                country={customer.country}
                                customer_verify={customer.customer_verify}
                                shop_id={customer.shop_id}
                                source={avatar}
                            />
                        </div>

                    </div>
                </BlockStack>
            </div>
        ),
    );


    const handleSavePickedReviews = (pickedReviews) => {
        setCarouselDisplay({
            ...carouselDisplay,
            review_ids: pickedReviews,
            number_of_reviews: pickedReviews.length
        })
    }

    const modalContent = (
        <>
            <Modal
                padding={400}
                size="large"
                open={active}
                onClose={handleCloseModal}
                title={t('review_testimonial.choose_review.modal.title', {
                    ns: 'widget'
                })}
                primaryAction={{
                    content: 'Save',
                    onAction: () => {
                        handleSavePickedReviews(pickedReviews);
                        handleCloseModal();
                    },
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleCloseModal,
                    },
                ]}
            >
                <div className='modal-choose-review'>
                    <div className='modal-header'>
                        <div className='checkbox'>
                            <Box padding={200}
                            >
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                    label={
                                        `${pickedReviews.length} review${pickedReviews.length >= 2 ? 's' : ''} selected`
                                    }
                                />
                            </Box>
                        </div>
                        <div className='filters'>
                            <Filter
                                t={t}
                                filter={filter}
                                setFilter={setFilter}
                                selected={selected}
                                setSelected={setSelected}
                                isLoadReview={isLoadReview}
                            />
                        </div>
                    </div>
                    <div className='modal-body'>
                        {rowMarkup}
                    </div>
                    <Box>
                        <InlineStack align='center' blockAlign='center'>
                            <Pagination 
                            hasPrevious = {reviews.prev_cursor ? true : false}
                            onPrevious={() => {
                              setFilter(prev =>{
                                return {
                                    ...prev,
                                    cursor: reviews.prev_cursor
                                  }
                              })
                            }}
                            hasNext = {reviews.next_cursor ? true: false}
                            onNext={() => {
                                setFilter(prev =>{
                                    return {
                                        ...prev,
                                        cursor: reviews.next_cursor
                                      }
                                  })
                            }}/>
                        </InlineStack>
                    </Box>
                </div>
            </Modal>
        </>
    );

    useEffect(()=>{
        setPickedReviews(carouselDisplay.review_ids)
    },[])

    return active ? ReactDOM.createPortal(modalContent, document.body) : null;
};

export default ModalChooseManual;
