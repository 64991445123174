import { Spinner } from "@shopify/polaris";
const WidgetLoading = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "80vh",
                maxHeight: "1080px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spinner size="small" />
        </div>
    );
};

export default WidgetLoading;
