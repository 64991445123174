import {
    Box,
    Divider,
    Text,
    TextField,
    InlineGrid,
    BlockStack,
} from "@shopify/polaris";
const FilterLabel = ({
    t,
    contentValue,
    displaySetting,
    handleToggle,
    handleInputChange,
    handleShowHideInfo,
    handleDisplaySetting,
    reviewDisplay,
    setReviewDisplay,
}) => {
    return (
        <div className="setting-component">
            <BlockStack gap={300}>
                <Text as="h4" fontWeight="semibold">
                    {t("review_display.content.label_of_filter.title", {
                        ns: "widget",
                    })}
                </Text>
                <Divider borderColor="border-subdued" />
            </BlockStack>
            <Box paddingBlockStart={1}>
                <InlineGrid
                    columns={{ xl: 3, lg: 2, md: 6, sm: 3, xs: 2 }}
                    gap={300}
                >
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                All
                            </Text>
                        }
                        name="label_filter_all"
                        value={reviewDisplay?.label_content?.label_filter_all}
                        onChange={(e) =>
                            handleInputChange(e, "label_filter_all")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Most helpful
                            </Text>
                        }
                        name="label_filter_most_helpful"
                        value={
                            reviewDisplay?.label_content
                                ?.label_filter_most_helpful
                        }
                        onChange={(e) =>
                            handleInputChange(e, "label_filter_most_helpful")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Highest rating
                            </Text>
                        }
                        name="label_filter_highest_rating"
                        value={
                            reviewDisplay?.label_content
                                ?.label_filter_highest_rating
                        }
                        onChange={(e) =>
                            handleInputChange(e, "label_filter_highest_rating")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Lowest rating
                            </Text>
                        }
                        name="label_filter_lowest_rating"
                        value={
                            reviewDisplay?.label_content
                                ?.label_filter_lowest_rating
                        }
                        onChange={(e) =>
                            handleInputChange(e, "label_filter_lowest_rating")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Oldest
                            </Text>
                        }
                        name="label_filter_oldest"
                        value={
                            reviewDisplay?.label_content?.label_filter_oldest
                        }
                        onChange={(e) =>
                            handleInputChange(e, "label_filter_oldest")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Newest
                            </Text>
                        }
                        name="label_filter_newest"
                        value={
                            reviewDisplay?.label_content?.label_filter_newest
                        }
                        onChange={(e) =>
                            handleInputChange(e, "label_filter_newest")
                        }
                    />
                    <TextField
                        label={
                            <Text as="h4" fontWeight="medium">
                                Review with photos
                            </Text>
                        }
                        name="label_filter_review_with_photos"
                        value={
                            reviewDisplay?.label_content
                                ?.label_filter_review_with_photos
                        }
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                "label_filter_review_with_photos"
                            )
                        }
                    />
                </InlineGrid>
            </Box>
        </div>
    );
};

export default FilterLabel;
