import { useEffect } from "react";
import { Trans } from "react-i18next";
import { Icon, InlineStack, Text } from "@shopify/polaris";
import { XIcon } from "@shopify/polaris-icons";
import AddScriptGuild from "./AddScriptGuildComponent";

const ModalAddScript = ({
    t,
    data,
    handleRecheckByTabVisible,
    block,
    handleAdd,
    handleAddBlockV1,
    type,
    currentTheme,
    setOpen,
}) => {
    useEffect(() => {
        const handleCheckVisible = () => {
            if (document.visibilityState === "visible") {
                handleRecheckByTabVisible(type);
            }
        };
        if (typeof document.hidden !== "undefined" && !data.verify) {
            window.addEventListener("visibilitychange", handleCheckVisible);
        }
        return () => {
            window.removeEventListener("visibilitychange", handleCheckVisible);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.verify]);
    return (
        <div className="modal-add-script">
            <div className="modal-wrap">
                <div className="modal-add-script--header">
                    <InlineStack align="space-between" gap={100} wrap={false}>
                        {
                            <Text variant="headingMd">
                                {type === "app-embed"
                                    ? t("guide_to_enable_app_embed", {
                                          ns: "translation",
                                      })
                                    : t("guide_to_add_widget", {
                                          ns: "translation",
                                      })}
                            </Text>
                        }
                        <div
                            onClick={() => setOpen(false)}
                            style={{ cursor: "pointer" }}
                        >
                            <Icon source={XIcon} />
                        </div>
                    </InlineStack>
                </div>
                <div className="modal-add-script--content">
                        <AddScriptGuild
                            t={t}
                            data={data}
                            handleAddBlockV1={handleAddBlockV1}
                            handleAdd={handleAdd}
                            Trans={Trans}
                            type={type}
                            currentTheme={currentTheme}
                        />
                </div>
            </div>
        </div>
    );
};

export default ModalAddScript;
