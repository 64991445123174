import { useEffect, useState, useCallback, useContext } from "react";
import * as Config from "../../../api/Config";
import Http from "../../../layouts/Http";
import { useTranslation } from "react-i18next";

const modalAllMediasDefaultColorSchema = {
    modal_all_medias_headline_title: "#121212",
    modal_all_medias_filter_label: "#3D4153",
    modal_all_medias_filter_background: "#f2f2f6",
    modal_all_medias_selected_filter_label: "#FFFFFF",
    modal_all_medias_selected_filter_background: "#3D4153",
    modal_all_medias_star_icon: "#FFB400",
    modal_all_medias_rating_background: "#FAFAFA",
};

const modalDetailColorSchema = {
    modal_detail_customer_name: "#121212",
    modal_detail_review_time_title: "#74788D",
    modal_detail_review_star: "#FFB400",
    modal_detail_review_star_default: "#CCCCCC",
    modal_detail_review_star_field_background: "#F1F1F1",
    modal_detail_review_title: "#3D4153",
    modal_detail_review_content: "#303030",
    modal_detail_navigator_button: "#FFFFFF",
    modal_detail_navigator_arrow: "#303030",
    modal_detail_product_name: "#303030",
    modal_detail_product_price: "#303030",
    modal_detail_icon_title: "#74788D",
    modal_detail_selected_icon: "#1E4786",
    modal_detail_icon_background: "#F2F2F6",
    modal_detail_divider: "#F2F2F6",
    modal_detail_card_background: "#FFFFFF",
};

const useEffectReviewDisplay = () => {
    const [isLoading, setIsLoading] = useState({
        reviewDisplay: true,
        productRating: true,
        onePageSetting: true,
        carouselDisplay: true,
    });

    const [reviewDisplaySessionColor, setReviewDisplaySessionColor] = useState({
        light: {
            primary_color: {
                color: "#3D4153",
            },
            color_customize: {
                primary_color: "#3D4153", //
                secondary_color: "#FFFFFF", //
                title_text_color: "#3D4153", //
                all_photos_button_color: "#005BD3", //
                primary_text_color: "#1A1A1A", //
                secondary_text_color: "#74788D", //
                star_icon_color: "#FFB400", //
                line_color: "#A7ABC3", //
                filter_sort_background: "#F2F2F6", //
                filter_sort_text: "#3D4153", //
                rating_process_color: "#FFB400", //
                rating_process_empty_color: "#E7E7E7", //
                user_name_color: "#303030", //
                load_more_color: "#3D4153", //
                section_title_color: "#111111",
                page_no_review_star_color: "#FFB400",
                text_no_review_color: "#111111",
                call_to_action_color: "#3D4153",
                call_to_text_color: "#FFFFFF",
                border_color: "#CED6E2",
                bg_base_color: "#FFFFFF",
                bg_no_review_color: "#FFFFFF",
                reply_title_color: "#3D4153",
                reply_content_color: "#3D4153",
                reply_background_color: "#F6F6F6",
            },
        },
        dark: {
            primary_color: {
                color: "#F4F4F4",
            },
            color_customize: {
                primary_color: "#F4F4F4",
                secondary_color: "#2E2E2E",
                title_text_color: "#F8F8F8",
                all_photos_button_color: "#3FA0E5",
                primary_text_color: "#E7E7E7",
                secondary_text_color: "#A7ABC3",
                star_icon_color: "#FFB400",
                line_color: "#E3E3E3",
                filter_sort_background: "#E7E7E7",
                filter_sort_text: "#3D4153",
                rating_process_color: "#D8D8D8",
                rating_process_empty_color: "#4F4F4F",
                user_name_color: "#E7E7E7",
                load_more_color: "#EBEBEB",
                section_title_color: "#F8F8F8",
                page_no_review_star_color: "#FFB400",
                text_no_review_color: "#F8F8F8",
                call_to_action_color: "#F4F4F4",
                call_to_text_color: "#2E2E2E",
                border_color: "#E3E3E3",
                bg_base_color: "#2C2C2C",
                bg_no_review_color: "#2C2C2C",
                reply_title_color: "#FFFFFF",
                reply_content_color: "#FFFFFF",
                reply_background_color: "#3A3A3A",
            },
        },
        custom: {
            primary_color: {
                color: "#3D4153",
            },
            color_customize: {
                primary_color: "#3D4153",
                secondary_color: "#FFFFFF",
                title_text_color: "#3D4153",
                all_photos_button_color: "#005BD3",
                primary_text_color: "#1A1A1A",
                secondary_text_color: "#74788D",
                star_icon_color: "#FFB400",
                line_color: "#A7ABC3",
                filter_sort_background: "#F2F2F6",
                filter_sort_text: "#3D4153",
                rating_process_color: "#FFB400",
                rating_process_empty_color: "#E7E7E7",
                user_name_color: "#303030",
                load_more_color: "#3D4153",
                section_title_color: "#111111",
                page_no_review_star_color: "#FFB400",
                text_no_review_color: "#111111",
                call_to_action_color: "#3D4153",
                call_to_text_color: "#FFFFFF",
                border_color: "#CED6E2",
                bg_base_color: "#FFFFFF",
                bg_no_review_color: "#FFFFFF",
                reply_title_color: "#3D4153",
                reply_content_color: "#3D4153",
                reply_background_color: "#F6F6F6",
            },
        },
    });

    const [initReviewDisplay, setInitReviewDisplay] = useState({
        layout_box: "grid",
        layout_summary_box: "left",
        // primary_color: {
        //     color: "#3D4153",
        // },
        color_mode: "light",
        no_review_color_mode: "custom",
        modal_detail_color_mode: "light",
        modal_all_medias_color_mode: "light",
        modal_write_review_color_mode: "light",
        primary_color: {
            color: "#3D4153",
        },
        color_customize: {
            ...reviewDisplaySessionColor.light.color_customize,
            modal_all_medias_headline_title: "#121212",
            modal_all_medias_filter_label: "#3D4153",
            modal_all_medias_filter_background: "#f2f2f6",
            modal_all_medias_selected_filter_label: "#FFFFFF",
            modal_all_medias_selected_filter_background: "#3D4153",
            modal_all_medias_star_icon: "#FFB400",
            modal_all_medias_rating_background: "#FAFAFA",
            modal_detail_customer_name: "#121212",
            modal_detail_review_time_title: "#74788D",
            modal_detail_review_star: "#FFB400",
            modal_detail_review_star_default: "#CCCCCC",
            modal_detail_review_star_field_background: "#F1F1F1",
            modal_detail_review_title: "#3D4153",
            modal_detail_review_content: "#303030",
            modal_detail_navigator_button: "#FFFFFF",
            modal_detail_navigator_arrow: "#303030",
            modal_detail_product_name: "#303030",
            modal_detail_product_price: "#303030",
            modal_detail_icon_title: "#74788D",
            modal_detail_selected_icon: "#1E4786",
            modal_detail_icon_background: "#F2F2F6",
            modal_detail_divider: "#F2F2F6",
            modal_detail_card_background: "#FFFFFF",
            form_write_review_star_color: "#FFB400",
        },
        section_show: {
            write_a_review_button: true, //
            customer_name: true, //
            country: true,
            customer_profile_picture: true, //
            review_date: true, //
            button_helpful: true, //
            button_report: true, //
            no_review_border: true, //
            no_review_star_icon: true,
            summary_star_icon: true, //
            summary_number_of_reviews: true, //
            summary_divider: true, //
            summary_text_1: true, //
            summary_text_2: true, //
            image_galley_star_rating: true, //
            show_review_media: true,
            media_size_on_galley: 100,
            media_size_on_review: 100,
            number_of_reviews: true, //
            modal_all_medias: {
                filterList: {
                    All: true,
                    Images: true,
                    Videos: true,
                    "5_star": true,
                    "4_star": true,
                    "3_star": true,
                    "2_star": true,
                    "1_star": true,
                },
                show_star_rating: true,
                show_helpful_button: true,
            },
            detail: {
                show_review_media: true,
                show_review_time: true,
                show_purchase_items: true,
                star_size: 16,
            },
            form_write_review: {
                show_product_image: true,
                show_add_photo_btn: true,
                show_add_video_btn: true,
                required_review_title: true,
                star_size: 32,
            },
        },
        sort_filter: [
            "all",
            "most_helpful",
            "highest_rating",
            "lowest_rating",
            "oldest",
            "newest",
            "review_with_photos",
            "stars_filter",
        ],
        review_per_page_desktop: 6,
        review_per_page_mobile: 6,
        country_language: "en",
        label_content: {
            text_customer_reviews: "Customer Reviews",
            text_reviews: "Reviews",
            text_review: "Review",
            text_review_this_product: "Review this product",
            text_share_your_thoughts:
                "Share your thoughts with other customers",
            btn_write_a_review: "Write a review",
            text_images_from_reviews: "Images from reviews",
            text_review_with_comments: "Review with comments",
            all_photos: "All photos",
            label_filter_all: "All",
            label_filter_most_helpful: "Most helpful",
            label_filter_highest_rating: "Highest rating",
            label_filter_lowest_rating: "Lowest rating",
            label_filter_oldest: "Oldest",
            label_filter_newest: "Newest",
            label_filter_review_with_photos: "Review with photos",
            label_filter_stars_filter: "Star filter(1-5)",
            text_review_on: "Review on",
            text_people_found_helpful: "people found this helpful",
            text_helpful: "Helpful",
            text_report: "Report",
            text_reply_from: "Reply from",
            anonymous: "Anonymous",
            btn_load_more_review: "Load more reviews",
            no_review_text_no_review:
                "No reviews yet. Any feedback? Let us know",
            no_review_btn_write_first_review: "Write review",
            modal_write_title: "Ratings & Reviews",
            modal_write_title_had_review: "Thank you for leaving reviews",
            modal_write_text_reviews: "Reviews",
            modal_review_text_click_to_rate: "Click to rate",
            modal_review_add_photo_or_video: "Add photo or video",
            name_display_format: "Name display format",
            option_name_display_format_1: "John Smith",
            option_name_display_format_2: "John S.",
            option_name_display_format_3: "J.S.",
            option_name_display_format_4: "Anonymous",
            modal_review_label_customer_name: "Customer Name",
            modal_review_placeholder_customer_name: "Type your name here",
            modal_review_label_customer_email: "Customer Email",
            modal_review_placeholder_customer_email:
                "Your email is private and is used to send you discount vouchers",
            modal_review_label_review_title: "Review Title",
            modal_review_placeholder_review_title: "Example: Easy to use",
            modal_review_label_review_content: "Product Review",
            modal_review_placeholder_review_content:
                "Example: Since i bought this a month ago, it has been used a lot. What i like best this product is...",
            modal_review_label_characters_remaining: "characters remaining",
            modal_review_btn_add_photo: "Photo",
            modal_review_btn_add_video: "Video",
            modal_review_btn_back: "Back",
            modal_review_btn_submit_review: "Submit Review",
            modal_review_btn_submit_review_has_review: "Go Shoping",
            modal_pending_review_title: "Review in review",
            modal_pending_review_subtitle:
                "Your review has been sent successfully and will be reviewed to ensure it meets our guidelines and standards. Please allow us some time to thoroughly assess your review.",
            modal_pending_review_button: "Done",
            modal_success_review_title: "Review submitted successfully!",
            modal_success_review_subtitle:
                "Thank you for spending time sharing your experience and leaving feedback, it will be very beneficial for other customers.",
            modal_success_review_button: "Ok",
            modal_report_title: "Report this review",
            modal_report_subtitle:
                "Thank you for taking the time to help {{ shopname }}.Your report is anonymous.",
            modal_report_option_1: "Review contains private information.",
            modal_report_option_2: "Review contains objectionable language.",
            modal_report_option_3: "Review contains advertising or spam.",
            modal_report_option_4:
                "Review contains violent and disgusting content.",
            modal_report_option_5: "Other violations.",
            modal_report_btn: "Report this review",
            modal_view_image_text_all_media: "All photos",
            text_all: "All",
            text_image: "Images",
            text_video: "Videos",
            no_media: "No medias uploaded yet!",
            no_image: "No images uploaded yet!",
            no_video: "No videos uploaded yet!",
            purchased_item: "Purchased item",
            view_product: "View product",
            required_input: "*Please fill out this field",
            required_email: "*Please enter a valid email address!",
            media_rule_size: "*Media size must be smaller than 10MB",
            media_rule_type:
                "*Media type must be .bmp, .jpeg, .png, .mp4 or .mov",
            media_upload_fail:
                "*Unable to upload the image. Please try again later",
            media_upload_duplicate: "*You have already uploaded this image",
            modal_image_review_for: "Review for",
            modal_image_from_this_review: "Image from this review",
        },
    });

    const [reviewDisplay, setReviewDisplay] = useState(initReviewDisplay);

    const { t } = useTranslation("message");
    const [saveState, setSaveState] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [open, setOpen] = useState({
        layout: false,
        summary: false,
        colorSchema: false,
        information: false,
        content: false,
        textTabFilter: false,
        others: false,
    });
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const [multiDisplaySetting, setMultiDisplaySetting] = useState({
        information: true,
        filter: true,
    });

    const [displaySetting, setDisplaySetting] = useState({
        contentTitle: true,
        contentReviewSubmit: true,
        writeReviewBtn: true,
    });
    const [contentValue, setContentValue] = useState({
        title: "Customer Review",
        reviewSubmit: "Product image/video",
        writeReviewBtn: "Write a review",
    });

    const [listTabFilter, setListTabFilter] = useState([
        { all: "All" },
        { most_helpful: "Most Helpful" },
        { highest_rating: "Highest Rating" },
        { lowest_rating: "Lowest Rating" },
        { oldest: "Oldest" },
        { newest: "Newest" },
        { review_with_photos: "Review with Photo/Video" },
        { stars_filter: "Star filter(1-5)" },
    ]);

    const handleDisplaySetting = (name) => {
        setDisplaySetting({
            ...displaySetting,
            [name]: !displaySetting[name],
        });
    };


    const handleToggle = (settingOption) => {
        setOpen({
            ...open,
            [settingOption]: !open[settingOption],
        });
    };

    const handleInputChange = (value, name) => {
        setReviewDisplay({
            ...reviewDisplay,
            label_content: {
                ...reviewDisplay.label_content,
                [name]: value,
            },
        });
    };

    const handleShowHideInfo = (name, type) => {
        document.getElementById(name).click();
    };

    const handleShowHideReviewFilter = (value, name) => {
        !reviewDisplay.sort_filter.includes(name) && value
            ? setReviewDisplay({
                  ...reviewDisplay,
                  sort_filter: [...reviewDisplay.sort_filter, name],
              })
            : setReviewDisplay({
                  ...reviewDisplay,
                  sort_filter: reviewDisplay.sort_filter.filter(
                      (item) => item !== name
                  ),
              });
    };

    const handleMultiDisplaySetting = (value, name) => {
        if (name === "information") {
            setReviewDisplay({
                ...reviewDisplay,
                section_show: {
                    ...reviewDisplay.section_show,
                    customer_name: value,
                    customer_profile_picture: value,
                },
            });
        } else {
            if (!value) {
                setReviewDisplay({
                    ...reviewDisplay,
                    sort_filter: [],
                });
            } else {
                setReviewDisplay({
                    ...reviewDisplay,
                    sort_filter: [
                        "all",
                        "most_helpful",
                        "highest_rating",
                        "lowest_rating",
                        "oldest",
                        "newest",
                        "review_with_photos",
                        "stars_filter",
                    ],
                });
            }
        }
        setMultiDisplaySetting({
            ...multiDisplaySetting,
            [name]: value,
        });
    };

    // handle drag filter tab
    const handleListTabFilter = () => {
        const sortedData = listTabFilter.sort((a, b) => {
            const indexA = initReviewDisplay.sort_filter.indexOf(
                Object.keys(a)[0]
            );
            const indexB = initReviewDisplay.sort_filter.indexOf(
                Object.keys(b)[0]
            );
            return indexA - indexB;
        });
        setListTabFilter(sortedData);
    };

    const handleQueueFilter = () => {
        let currentList = [];
        listTabFilter.forEach((item) => {
            if (reviewDisplay.sort_filter.includes(Object.keys(item)[0])) {
                currentList.push(Object.keys(item)[0]);
            }
        });

        return setReviewDisplay({
            ...reviewDisplay,
            sort_filter: currentList,
        });
    };

    const handleDragEnd = useCallback(({ source, destination }) => {
        if (destination) {
            setListTabFilter((oldItems) => {
                const newItems = oldItems.slice(); // Duplicate
                const [temp] = newItems.splice(source.index, 1);
                newItems.splice(destination.index, 0, temp);
                return newItems;
            });
        }
    }, []);

    const handleNullContentValue = (res) => {
        const data = {
            ...initReviewDisplay.label_content,
            ...res,
        };
        const label_content = initReviewDisplay.label_content;

        Object.keys(label_content).forEach((item) => {
            label_content[item] = data[item] ? data[item] : "";
        });
        return label_content;
    };

        // sort list filter for first time load page
        const initSortListFilter = (arrayOrder) => {
            const sortedTabs = [...listTabFilter].sort((a, b) => {
                const keyA = Object.keys(a)[0];
                const keyB = Object.keys(b)[0];
    
                const indexA = arrayOrder.indexOf(keyA);
                const indexB = arrayOrder.indexOf(keyB);
    
                // If key not in arrayOrder, push to the end
                if (indexA === -1 && indexB === -1) return 0;
                if (indexA === -1) return 1;
                if (indexB === -1) return -1;
    
                // Sort according to the order of the array
                return indexA - indexB;
            });
    
            setListTabFilter(sortedTabs);
        };

    const getreviewDisplay = () => {
        Http.get(`${Config.API_URL}/settings/widget`)
            .then((response) => {
                if (response.data.data) {
                    const data = {
                        ...initReviewDisplay,
                        ...response.data.data,
                        label_content: handleNullContentValue(
                            response.data.data.label_content
                        ),
                        color_customize: {
                            ...modalAllMediasDefaultColorSchema,
                            ...modalDetailColorSchema,
                            ...initReviewDisplay.color_customize,
                            ...response.data.data.color_customize,
                        },
                        section_show: {
                            ...initReviewDisplay.section_show,
                            ...response.data.data.section_show,
                        },
                        review_per_page_desktop: response.data.data
                            .review_per_page_desktop
                            ? response.data.data.review_per_page_desktop
                            : 6,
                        review_per_page_mobile: response.data.data
                            .review_per_page_mobile
                            ? response.data.data.review_per_page_mobile
                            : 6,
                        country_language: response.data.data.country_language
                            ? response.data.data.country_language
                            : "en",
                        color_mode: response.data.data.color_mode || "custom",
                        no_review_color_mode:
                            response.data.data.no_review_color_mode ||
                            response.data.data.color_mode ||
                            "custom",
                    };
                    initSortListFilter(data.sort_filter);
                    setInitReviewDisplay(data);
                    setReviewDisplay(data);
                    setReviewDisplaySessionColor((prevSessionColor) => ({
                        ...prevSessionColor,
                        [response.data.data.color_mode || "custom"]: {
                            ...prevSessionColor.custom,
                            primary_color: response.data.data.primary_color,
                            color_customize: {
                                ...prevSessionColor.custom.color_customize,
                                ...response.data.data.color_customize,
                            },
                        },
                    }));
                } else {
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                }
            })
            .catch((e) => {
                window.shopify.toast.show(
                    t("toast.server_error", { ns: "message" }),
                    { duration: 3000 }
                );
            })
            .finally(() => {
                setIsLoading(prev => ({...prev, reviewDisplay: false}));
            });
    };

    const handleSaveReviewDisplay = () => {
        setSaveLoading(true);
        Http.post(`${Config.API_URL}/settings/widget`, reviewDisplay)
            .then((response) => {
                if (response.data.status) {
                    setInitReviewDisplay(reviewDisplay);
                    setShowMobileMenu(false);
                    window.shopify.toast.show(
                        t("toast.saved", { ns: "message" }),
                        { duration: 3000 }
                    );
                    setSaveState(false);
                } else {
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                }
            })
            .catch((e) => {
                window.shopify.toast.show(
                    t("toast.server_error", { ns: "message" }),
                    { duration: 3000 }
                );
                throw e;
            }).finally(()=>{
                setSaveLoading(false);
            });
    };

    useEffect(() => {
        getreviewDisplay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleQueueFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiDisplaySetting.filterList]);

    useEffect(() => {
        handleListTabFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initReviewDisplay.sort_filter]);

    useEffect(() => {
        let labelFilter = listTabFilter;
        Object.keys(reviewDisplay.label_content).forEach((item) => {
            if (item.split("label_filter_")[1]) {
                const index = labelFilter.findIndex(
                    (obj) =>
                        Object.keys(obj)[0] === item.split("label_filter_")[1]
                );
                labelFilter[index] = {
                    [item.split("label_filter_")[1]]:
                        reviewDisplay.label_content[item],
                };
            }
        });
        setListTabFilter(labelFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewDisplay.label_content]);

    // change color mode
    useEffect(() => {
        setReviewDisplay({
            ...reviewDisplay,
            primary_color:
                reviewDisplaySessionColor[reviewDisplay.color_mode]
                    .primary_color,

            color_customize: {
                ...reviewDisplay.color_customize,
                primary_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.primary_color,
                secondary_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.secondary_color,
                title_text_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.title_text_color,
                all_photos_button_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.all_photos_button_color,
                primary_text_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.primary_text_color,
                secondary_text_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.secondary_text_color,
                star_icon_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.star_icon_color,
                line_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.line_color,
                filter_sort_background:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.filter_sort_background,
                filter_sort_text:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.filter_sort_text,
                rating_process_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.rating_process_color,
                rating_process_empty_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.rating_process_empty_color,
                user_name_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.user_name_color,
                load_more_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.load_more_color,
                bg_base_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.bg_base_color,
                reply_title_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.reply_title_color,
                reply_content_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.reply_content_color,
                reply_background_color:
                    reviewDisplaySessionColor[reviewDisplay.color_mode]
                        .color_customize.reply_background_color,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewDisplay.color_mode]);

    useEffect(() => {
        setReviewDisplay({
            ...reviewDisplay,
            color_customize: {
                ...reviewDisplay.color_customize,
                section_title_color:
                    reviewDisplaySessionColor[
                        reviewDisplay.no_review_color_mode
                    ].color_customize.section_title_color,
                page_no_review_star_color:
                    reviewDisplaySessionColor[
                        reviewDisplay.no_review_color_mode
                    ].color_customize.page_no_review_star_color,
                text_no_review_color:
                    reviewDisplaySessionColor[
                        reviewDisplay.no_review_color_mode
                    ].color_customize.text_no_review_color,
                call_to_action_color:
                    reviewDisplaySessionColor[
                        reviewDisplay.no_review_color_mode
                    ].color_customize.call_to_action_color,
                call_to_text_color:
                    reviewDisplaySessionColor[
                        reviewDisplay.no_review_color_mode
                    ].color_customize.call_to_text_color,
                border_color:
                    reviewDisplaySessionColor[
                        reviewDisplay.no_review_color_mode
                    ].color_customize.border_color,
                bg_no_review_color:
                    reviewDisplaySessionColor[
                        reviewDisplay.no_review_color_mode
                    ].color_customize.bg_no_review_color,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewDisplay.no_review_color_mode]);

    return {
        isLoading,
        open,
        setOpen,
        saveState,
        saveLoading,
        multiDisplaySetting,
        setMultiDisplaySetting,
        displaySetting,
        setDisplaySetting,
        contentValue,
        setContentValue,
        handleToggle,
        handleInputChange,
        handleShowHideInfo,
        handleShowHideReviewFilter,
        handleMultiDisplaySetting,
        reviewDisplay,
        setReviewDisplay,
        setInitReviewDisplay,
        initReviewDisplay,
        handleQueueFilter,
        handleDragEnd,
        listTabFilter,
        handleSaveReviewDisplay,
        showMobileMenu,
        setShowMobileMenu,
        handleDisplaySetting,
        handleListTabFilter,
        setReviewDisplaySessionColor,
        reviewDisplaySessionColor,
    };
};

export default useEffectReviewDisplay;
