import React, { useEffect, useState } from "react";
import { Box, InlineGrid, InlineStack, Text, Icon } from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import SettingCarousel from "./Setting";
import DemoCarousel from "./Demo";
import useReviewTestimonial from "./useReviewTestimonial";
import { useTranslation } from "react-i18next";
import WidgetLoading from "../../../components/Widget/WidgetLoading";

const ReviewTestimonial = ({ shopInfo, setSaveState, viewport }) => {
    const { t } = useTranslation(["widget", "review-management"]);

    const {
        open,
        reviews,
        setOpen,
        handleToggle,
        carouselDisplay,
        setCarouselDisplay,
        initCarouselDisplay,
        setCarouselSessionColor,
        getListReview,
        carouselSessionColor,
        filterReviews,
        handleSaveCarouselDisplay,
        filter,
        setFilter,
        isLoadReview,
        isLoading,
    } = useReviewTestimonial();

    const [validTime, setValidTime] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const handleCheckValidTime = (value) => {
        if (value < 1 || value > 30) {
            setValidTime(true);
        } else {
            setValidTime(false);
        }
    };

    const handleSaveOnClick = (e) => {
        e.preventDefault();
        handleSaveCarouselDisplay();
    };

    const handleOnDiscard = () => {
        setCarouselDisplay(initCarouselDisplay);
        setCarouselSessionColor((prevState) => ({
            ...prevState,
            [initCarouselDisplay.color_mode]: {
                primary_color: initCarouselDisplay.primary_color,
                color_customize: initCarouselDisplay.color_customize,
            },
        }));
        document.getElementById("custom-save-bar").hide();
    };

    useEffect(() => {
        if (
            JSON.stringify(carouselDisplay) !==
            JSON.stringify(initCarouselDisplay)
        ) {
            setSaveState((prev) => ({
                ...prev,
                status: true,
                saveFunc: handleSaveCarouselDisplay,
            }));
        } else {
            setSaveState((prev) => ({
                ...prev,
                status: false,
                saveFunc: handleOnDiscard,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselDisplay, initCarouselDisplay]);

    useEffect(() => {
        setSaveState((prev) => ({
            ...prev,
            loading: isLoading,
        }));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
    return (
        <>
            {!isLoading.carouselDisplay ? (
                <>
                    <div
                        className="carousel"
                        style={{
                            height: "100%",
                        }}
                    >
                        <InlineGrid
                            columns={
                                viewport === "max"
                                    ? 1
                                    : {
                                          xs: "1fr",
                                          sm: "1fr",
                                          lg: "2fr 6fr",
                                          xl: "2fr 6fr",
                                      }
                            }
                        >
                            {viewport !== "max" && (
                                <div
                                    className={`review-testimonial-setting--field ${
                                        showMobileMenu ? "hide" : ""
                                    }`}
                                >
                                    <div
                                        onClick={() =>
                                            setShowMobileMenu(!showMobileMenu)
                                        }
                                        className="desktop-hidden"
                                        style={{
                                            cursor: "pointer",
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 999,
                                            left: 0,
                                            right: 0,
                                            borderBottom: "1px solid #e5e5e5",
                                            backgroundColor: "#fff",
                                            boxShadow:
                                                "0px 4px 6px 0px rgba(0,0,0,.1)",
                                        }}
                                    >
                                        <Box
                                            paddingBlock={400}
                                            paddingInline={400}
                                        >
                                            <InlineStack
                                                align="space-between"
                                                blockAlign="center"
                                                gap={400}
                                            >
                                                <Text
                                                    as="h5"
                                                    variant="headingMd"
                                                >
                                                    {t(
                                                        "review_display.widget_area",
                                                        {
                                                            ns: "widget",
                                                        }
                                                    )}
                                                </Text>
                                                <Box>
                                                    <Icon
                                                        source={
                                                            !showMobileMenu
                                                                ? ChevronDownIcon
                                                                : ChevronUpIcon
                                                        }
                                                        tone="base"
                                                    />
                                                </Box>
                                            </InlineStack>
                                        </Box>
                                    </div>
                                    <div
                                        className={`setting-widget-body--container ${
                                            showMobileMenu ? "hide" : ""
                                        }`}
                                    >
                                        <SettingCarousel
                                            t={t}
                                            reviews={reviews}
                                            carouselDisplay={carouselDisplay}
                                            setCarouselDisplay={
                                                setCarouselDisplay
                                            }
                                            getListReview={getListReview}
                                            open={open}
                                            setOpen={setOpen}
                                            handleToggle={handleToggle}
                                            sessionColor={carouselSessionColor}
                                            setSessionColor={
                                                setCarouselSessionColor
                                            }
                                            initCarouselDisplay={
                                                initCarouselDisplay
                                            }
                                            handleSaveCarouselDisplay={
                                                handleSaveCarouselDisplay
                                            }
                                            filterReviews={filterReviews}
                                            filter={filter}
                                            setFilter={setFilter}
                                            isLoadReview={isLoadReview}
                                            validTime={validTime}
                                            handleCheckValidTime={
                                                handleCheckValidTime
                                            }
                                            shopInfo={shopInfo}
                                            viewport={viewport}
                                        />
                                    </div>
                                </div>
                            )}
                            <div
                                className="review-carousel-demo--field Polaris-Box"
                                style={{
                                    "--pc-box-background":
                                        "var(--p-color-input-bg-surface-active)",
                                    "--pc-box-padding-block-start-xs":
                                        "var(--p-space-400)",
                                    "--pc-box-padding-block-end-xs":
                                        "var(--p-space-400)",
                                    "--pc-box-padding-inline-start-xs":
                                        "var(--p-space-300)",
                                    "--pc-box-padding-inline-end-xs":
                                        "var(--p-space-300)",
                                    overflow: "auto",
                                    scrollbarWidth: "none",
                                }}
                            >
                                <div style={{ padding: 0, margin: 0 }}>
                                    <DemoCarousel
                                        t={t}
                                        carouselDisplay={carouselDisplay}
                                        shopInfo={shopInfo}
                                        viewport={viewport}
                                    />
                                </div>
                            </div>
                        </InlineGrid>
                    </div>
                </>
            ) : (
                <WidgetLoading />
            )}
        </>
    );
};

export default ReviewTestimonial;
