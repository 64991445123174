import { useEffect, useState } from "react";
import * as Config from "../../../api/Config";
import Http from "../../../layouts/Http";
import { useTranslation } from "react-i18next";

const useReviewCarousel = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [carouselSessionColor, setCarouselSessionColor] = useState({
        light: {
            primary_color: {
                color: "#3D4153",
            },
            color_customize: {
                headline: "#3E4152",
                navigator_button: "#74788D",
                navigator_arrow: "#FFFFFF",
                page_indicator: "#3D4153",
                content: "#74788D",
                customer_name: "#292A32",
                verified_tag: "#A7ABC3",
                star: "#FFB400",
                divider: "#D3D5E1",
                stroke: "#D3D5E0",
                card_background: "#FFFFFF",
                widget_background: "#FFFFFF",
            },
        },
        dark: {
            primary_color: {
                color: "#F4F4F4",
            },
            color_customize: {
                headline: "#FFFFFF", //
                navigator_button: "#74788D",
                navigator_arrow: "#3E4152",
                page_indicator: "#3D4153",
                content: "#74788D",
                customer_name: "#292A32",
                verified_tag: "#A7ABC3",
                star: "#FFB400",
                divider: "#D3D5E1",
                stroke: "#D3D5E0",
                card_background: "#FFFFFF",
                widget_background: "#2C2C2C",
            },
        },
        custom: {
            primary_color: {
                color: "#3D4153",
            },
            color_customize: {
                headline: "#3E4152",
                navigator_button: "#74788D",
                navigator_arrow: "#FFFFFF",
                page_indicator: "#3D4153",
                content: "#74788D",
                customer_name: "#292A32",
                verified_tag: "#A7ABC3",
                star: "#FFB400",
                divider: "#D3D5E1",
                stroke: "#D3D5E0",
                card_background: "#FFFFFF",
                widget_background: "#FFFFFF",
            },
        },
    });

    const [initCarouselDisplay, setInitCarouselDisplay] = useState({
        color_mode: "light",
        color_customize: {
            ...carouselSessionColor.light.color_customize,
        },
        number_of_reviews: 20,
        mode: "auto",
        display_title: true,
        title_label: {
            is_show: true,
            label: "Our customer loves us",
            style: {
                font_size: 36,
                font_weight: "normal", // normal/bold
                text_decoration: "unset", // unset/underline,
                font_style: "normal", // normal/italic
            },
        },
        subtitle_label: {
            is_show: false,
            label: "Real feedback from our valued customers",
            style: {
                font_size: 16,
                font_weight: "normal", // normal/bold
                text_decoration: "unset", // unset/underline,
                font_style: "normal", // normal/italic
            },
        },
        display_star_rating: true,
        display_customer_avatar: false,
        slide_movement: "auto",
        time_between_slide: 4,
        show_navigator_button: true,
        pause_when_hover: true,
        country_language: "en",
        review_ids: [],
    });

    const [carouselDisplay, setCarouselDisplay] = useState(initCarouselDisplay);
    const { t } = useTranslation("message");
    const [reviews, setReviews] = useState({
        listReviews: [],
        next_cursor: null,
        prev_cursor: null,
    });

    const [open, setOpen] = useState({
        choose_review: false,
        title: false,
        card_content: false,
        movement: false,
        color_schemes: false,
    });

    const [filter, setFilter] = useState({
        review_type: "",
        status: "",
        product_ids: "",
        star: "",
        keyword: "",
        cursor: "",
        sort_by: "",
        order_by: "",
        source: "",
    });

    const handleToggle = (settingOption) => {
        setOpen({
            ...open,
            [settingOption]: !open[settingOption],
        });
    };

    const getCarouselDisplay = () => {
        Http.get(`${Config.API_URL}/settings/widget/carousel`)
            .then((response) => {
                if (response.data.status) {
                    if (typeof response.data.data.title_label === "string") {
                        const data = {
                            ...carouselDisplay,
                            ...response.data.data,
                            title_label: {
                                ...initCarouselDisplay.title_label,
                                label: response.data.data.title_label || "",
                            },
                            color_customize: {
                                ...carouselDisplay.color_customize,
                                ...response.data.data.color_customize,
                            },
                        };
                        setCarouselDisplay(data);
                        setInitCarouselDisplay(data);
                    } else if (!response.data.data.title_label) {
                        const data = {
                            ...carouselDisplay,
                            ...response.data.data,
                            title_label: {
                                ...initCarouselDisplay.title_label,
                                label:
                                    response.data.data.title_label ||
                                    "Our customer loves us",
                            },
                            color_customize: {
                                ...carouselDisplay.color_customize,
                                ...response.data.data.color_customize,
                            },
                        };
                        setCarouselDisplay(data);
                        setInitCarouselDisplay(data);
                    } else {
                        const data = {
                            ...carouselDisplay,
                            ...response.data.data,
                            title_label: {
                                ...initCarouselDisplay.title_label,
                                ...response.data.data.title_label,
                            },
                            color_customize: {
                                ...carouselDisplay.color_customize,
                                ...response.data.data.color_customize,
                            },
                        };
                        setCarouselDisplay(data);
                        setInitCarouselDisplay(data);
                    }
                    setIsLoading(false);

                    setCarouselSessionColor((prevSessionColor) => ({
                        ...prevSessionColor,
                        [response.data.data.color_mode || "custom"]: {
                            ...prevSessionColor.custom,
                            primary_color: response.data.data.primary_color,
                            color_customize: {
                                ...prevSessionColor.custom.color_customize,
                                ...response.data.data.color_customize,
                            },
                        },
                    }));
                } else {
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                }
            })
            .catch((e) => {
                window.shopify.toast.show(
                    t("toast.server_error", { ns: "message" }),
                    { duration: 3000 }
                );
            });
    };

    const handleSaveCarouselDisplay = () => {
        setIsLoading(true);
        Http.post(`${Config.API_URL}/settings/widget/carousel`, carouselDisplay)
            .then((response) => {
                if (response.data.status) {
                    setInitCarouselDisplay(carouselDisplay);
                    window.shopify.toast.show(
                        t("toast.saved", { ns: "message" }),
                        { duration: 3000 }
                    );
                } else {
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                }
            })
            .catch((e) => {
                window.shopify.toast.show(
                    t("toast.server_error", { ns: "message" }),
                    { duration: 3000 }
                );
            }).finally(()=>{
                setIsLoading(false);
            });
    };

    const filterReviews = (newFilter) => {
        setFilter({
            ...filter,
            ...newFilter,
        });
    };

    const getListReview = () => {
        Http.get(`${Config.API_URL}/reviews`, {
            params: {
                ...filter,
                star: filter.star.toString(),
                source: filter.source.toString(),
            },
        })
            .then((response) => {
                if (response.status === 200 && response.data.status !== false) {
                    setReviews({
                        listReviews: response.data.data,
                        next_cursor: response.data.next_cursor,
                        prev_cursor: response.data.prev_cursor,
                    });
                } else {
                    window.shopify.toast.show(
                        t("toast.server_error", { ns: "message" }),
                        { duration: 3000 }
                    );
                }
            })
            .catch((e) => {
                window.shopify.toast.show(
                    t("toast.server_error", { ns: "message" }),
                    { duration: 3000 }
                );
            });
    };

    useEffect(() => {
        getListReview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        getCarouselDisplay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //change color mode
    useEffect(() => {
        setCarouselDisplay({
            ...carouselDisplay,
            primary_color:
                carouselSessionColor[carouselDisplay.color_mode].primary_color,
            color_customize: {
                ...carouselDisplay.color_customize,
                headline:
                    carouselSessionColor[carouselDisplay.color_mode]
                        .color_customize.headline,
                navigator_button:
                    carouselSessionColor[carouselDisplay.color_mode]
                        .color_customize.navigator_button,
                navigator_arrow:
                    carouselSessionColor[carouselDisplay.color_mode]
                        .color_customize.navigator_arrow,
                content:
                    carouselSessionColor[carouselDisplay.color_mode]
                        .color_customize.content,
                customer_name:
                    carouselSessionColor[carouselDisplay.color_mode]
                        .color_customize.customer_name,
                star: carouselSessionColor[carouselDisplay.color_mode]
                    .color_customize.star,
                stroke: carouselSessionColor[carouselDisplay.color_mode]
                    .color_customize.stroke,
                card_background:
                    carouselSessionColor[carouselDisplay.color_mode]
                        .color_customize.card_background,
                widget_background:
                    carouselSessionColor[carouselDisplay.color_mode]
                        .color_customize.widget_background,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselDisplay.color_mode]);

    return {
        open,
        setOpen,
        reviews,
        isLoading,
        getListReview,
        carouselDisplay,
        setCarouselDisplay,
        initCarouselDisplay,
        setCarouselSessionColor,
        handleToggle,
        filterReviews,
        handleSaveCarouselDisplay,
        filter,
        setFilter,
    };
};

export default useReviewCarousel;
