import { useCallback, useState } from "react";
import {
    Text,
    Popover,
    ColorPicker,
    BlockStack,
    TextField,
} from "@shopify/polaris";
import convert from "color-convert";
const ColorPicking = ({
    label,
    color,
    item,
    handleSelectedColor,
    disabled,
    codeColor = "#616161",
    bgColor = "#FFFFFF",
}) => {
    const [checkState, setCheckState] = useState(false);
    const colorHextoRGB = color ? convert.hex.rgb(color) : [];

    const colorRGBtoHSV = convert.rgb.hsv(colorHextoRGB);
    const [popoverActive, setPopoverActive] = useState(false);

    const togglePopoverActive = useCallback(
        (e) => {
            if (disabled) return;
            setPopoverActive((popoverActive) => !popoverActive);
        },
        [disabled]
    );

    const colorPickerPopup = (
        <div style={{ position: "relative" }}>
            <BlockStack>
                {label && (
                    <Text as="h5" fontWeight="semibold" variant="bodySm">
                        {label}
                    </Text>
                )}
                <div
                    className={`color-picker--container`}
                    style={{
                        backgroundColor: bgColor || "#FFFFFF",
                    }}
                >
                    <div
                        className="color-picker--btn"
                        onClick={togglePopoverActive}
                        style={{
                            cursor: "pointer",
                            backgroundColor: `${bgColor}`,
                        }}
                    >
                        <div
                            className={`color-picker  color-picker-detail--item
                                    `}
                        >
                            <div
                                style={{
                                    cursor: `pointer`,
                                    minWidth: "27px",
                                    minHeight: "27px",
                                    height: "100%",
                                    border: "1px solid #e1e3e5",
                                    backgroundColor: item
                                        ? `rgba(
                                            ${colorHextoRGB[0]},
                                            ${colorHextoRGB[1]},
                                            ${colorHextoRGB[2]})
                            `
                                        : "",
                                    borderRadius: "4px",
                                }}
                            ></div>
                            <p
                                className="color-code"
                                style={{
                                    color: `${
                                        codeColor ? codeColor : "#616161"
                                    }`,
                                }}
                            >
                                {color ? convert.rgb.hex(colorHextoRGB) : ""}
                            </p>
                        </div>
                    </div>
                </div>
            </BlockStack>
        </div>
    );

    const handleChangeColor = (selectColor) => {
        const colorHSVtoRGB = convert.hsv.rgb([
            selectColor.hue,
            selectColor.saturation * 100,
            selectColor.brightness * 100,
        ]);

        let colorRGBtoHex = convert.rgb.hex(colorHSVtoRGB);
        colorRGBtoHex = colorRGBtoHex.includes("#")
            ? colorRGBtoHex
            : `#${colorRGBtoHex}`;
        handleSelectedColor(colorRGBtoHex, item);
    };

    const handleColorInputChange = (colorInput) => {
        colorInput = colorInput.includes("#") ? colorInput : `#${colorInput}`;
        handleSelectedColor(colorInput, item);
    };

    return (
        <>
            <BlockStack align="end">
                <Popover
                    active={popoverActive}
                    activator={colorPickerPopup}
                    autofocusTarget="first-node"
                    ariaHaspopup={false}
                    onClose={togglePopoverActive}
                    preferInputActivator={false}
                >
                    <Popover.Pane> 
                        <Popover.Section>
                            <BlockStack gap={200}>
                                <ColorPicker
                                    onChange={(selectedColor) => {
                                        handleChangeColor(selectedColor);
                                    }}
                                    color={
                                        color
                                            ? {
                                                  hue: colorRGBtoHSV[0],
                                                  saturation:
                                                      colorRGBtoHSV[1] / 100,
                                                  brightness:
                                                      colorRGBtoHSV[2] / 100,
                                              }
                                            : {}
                                    }
                                />
                                <TextField
                                    value={color ? color : ""}
                                    onChange={handleColorInputChange}
                                />
                            </BlockStack>
                        </Popover.Section>
                    </Popover.Pane>
                </Popover>
            </BlockStack>
        </>
    );
};

export default ColorPicking;
