import {
    Box,
    InlineGrid,
    InlineStack,
    RadioButton,
    BlockStack,
    TextField,
    Icon,
    Text,
    Select,
    Collapsible,
    Checkbox,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import ColorPicking from "../../../../components/Widget/ColorPicker";
import { AlertCircleIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AddAppBlockSnippet from "../../../../components/Widget/AddAppBlockSnippet";
import CustomModalMessage from "../../../../components/CustomModalMessage";

const Setting = ({
    t,
    productRating,
    setProductRating,
    handleChangeInput,
    validateInput,
}) => {
    const { i18n } = useTranslation("widget-translate");
    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState({
        product_review: false,
        collection_page: false,
        custom_color: false,
    });
    const [selected, setSelected] = useState("en");
    const listCountries = [
        { label: "English", value: "en" }, //
        { label: "Arabic", value: "ae" },
        { label: "Chinese", value: "cn" }, //
        { label: "Densk", value: "dk" }, //
        { label: "Español", value: "es" }, //
        { label: "French", value: "fr" }, //
        { label: "Gaeilge", value: "ie" }, //
        { label: "German", value: "de" }, //
        { label: "Hindi", value: "in" }, //
        { label: "Italiano", value: "it" }, //
        { label: "Japanese", value: "jp" }, //
        { label: "Māori", value: "nz" }, //
        { label: "Nederlands", value: "nl" }, //
        { label: "Portuguese", value: "pt" }, //
        { label: "Svenska", value: "se" }, //
        { label: "Vietnamese", value: "vi" }, //
    ];

    const handleChangeCountries = async (value) => {
        setShowModal(true);
        setSelected(value);
        await i18n.loadLanguages([value]);
    };
    const handleChange = (value, key) => {
        setProductRating({
            ...productRating,
            [key]: value,
        });
    };
    const handleSelectedColor = (color, item) => {
        setProductRating({
            ...productRating,
            [item]: color,
        });
    };

    const handleCancel = () => {
        setSelected(productRating.country_language);
        setShowModal(false);
    };

    const handleUpdate = () => {
        setProductRating({
            ...productRating,
            country_language: selected,
            ...i18n.getResourceBundle(selected, "widget-translate")
                .product_rating,
        });
        setShowModal(false);
    };

    const handleToggle = (name) => {
        setOpen({
            ...open,
            [name]: !open[name],
        });
    };

    const handleChangeFontSize = (value, ns, key) => {
        let val = parseInt(value);
        if (val < 1) {
            val = 1;
        }
        setProductRating({
            ...productRating,
            font_size: {
                ...productRating.font_size,
                [ns]: {
                    ...productRating.font_size[ns],
                    [key]: val,
                },
            },
        });
    };

    return (
        <>
            <Box
                paddingInline={400}
                borderInlineEndWidth="0165"
                borderInlineStartWidth="0165"
                borderColor="transparent"
            >
                <BlockStack gap={400}>
                    <AddAppBlockSnippet
                        t={t}
                        block={"rating"}
                        collection={true}
                    />
                    <div>
                        <div>
                            <div
                                className="widget-setting-collape--title"
                                onClick={() => handleToggle("product_review")}
                                style={{ paddingRight: 0 }}
                            >
                                <h5
                                    className="setting-group--title"
                                    style={{ fontWeight: 700 }}
                                >
                                    {t("product_rating.product_review", {
                                        ns: "widget",
                                    })}
                                </h5>
                                {!open.product_review ? (
                                    <Icon source={ChevronRightIcon} />
                                ) : (
                                    <Icon source={ChevronDownIcon} />
                                )}
                            </div>
                            <Collapsible
                                open={open.product_review}
                                id="basic-collapsible"
                                transition={{
                                    duration: "500ms",
                                    timingFunction: "ease-in-out",
                                }}
                                expandOnPrint
                            >
                                <div className="product-rating-setting--item">
                                    <div className="setting--option">
                                        <Checkbox
                                            checked={
                                                !productRating.show_rating_when_empty
                                            }
                                            onChange={(value) =>
                                                setProductRating({
                                                    ...productRating,
                                                    show_rating_when_empty:
                                                        !value,
                                                })
                                            }
                                            id="show_rating_when_empty"
                                            label={t(
                                                "product_rating.summary_of_rating_star.show_rating_when_empty",
                                                { ns: "widget" }
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="product-rating-setting--item">
                                    <div className="setting--option">
                                        <BlockStack gap={400}>
                                            <RadioButton
                                                label={
                                                    <>
                                                        <InlineStack
                                                            blockAlign="center"
                                                            gap={100}
                                                            wrap={false}
                                                            align="space-between"
                                                        >
                                                            <p className="setting-item-secondary--title">
                                                                {t(
                                                                    "product_rating.summary_of_rating_star.text_before",
                                                                    {
                                                                        ns: "widget",
                                                                    }
                                                                )}
                                                            </p>
                                                            <InlineStack
                                                                gap={100}
                                                                wrap={false}
                                                                blockAlign="center"
                                                            >
                                                                <p className="avg-rating">
                                                                    4.8
                                                                </p>
                                                                {[
                                                                    1, 2, 3, 4,
                                                                    5,
                                                                ].map(
                                                                    (item) => (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 18 18"
                                                                            fill="none"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M8.08786 2.17107C8.43398 1.35862 9.58635 1.36083 9.92935 2.17461L11.3862 5.63101C11.5267 5.96438 11.8363 6.19586 12.1958 6.23633L15.8555 6.6483C16.7019 6.74357 17.0493 7.78565 16.4295 8.36977L13.622 11.0156C13.371 11.2522 13.2596 11.6015 13.3274 11.9397L14.0929 15.7617C14.264 16.6163 13.3362 17.2656 12.5919 16.812L9.50342 14.9297C9.18505 14.7356 8.78519 14.7348 8.46604 14.9276L5.36996 16.7973C4.624 17.2478 3.69886 16.595 3.87324 15.7412L4.65339 11.9212C4.72241 11.5833 4.61241 11.2336 4.36234 10.9961L1.56501 8.33881C0.947456 7.75218 1.29919 6.71137 2.146 6.61961L5.806 6.22299C6.16578 6.184 6.47643 5.95368 6.61826 5.62074L8.08786 2.17107Z"
                                                                                fill="#FFB400"
                                                                            />
                                                                        </svg>
                                                                    )
                                                                )}
                                                            </InlineStack>
                                                        </InlineStack>
                                                    </>
                                                }
                                                id="before"
                                                name="display_rating"
                                                checked={
                                                    productRating.display_rating ===
                                                    "before"
                                                }
                                                onChange={() =>
                                                    handleChange(
                                                        "before",
                                                        "display_rating"
                                                    )
                                                }
                                            />
                                            <RadioButton
                                                label={
                                                    <>
                                                        <InlineStack
                                                            blockAlign="center"
                                                            gap={100}
                                                            align="space-between"
                                                            wrap={false}
                                                        >
                                                            <p className="setting-item-secondary--title">
                                                                {t(
                                                                    "product_rating.summary_of_rating_star.text_after",
                                                                    {
                                                                        ns: "widget",
                                                                    }
                                                                )}
                                                            </p>
                                                            <InlineStack
                                                                gap={100}
                                                                wrap={false}
                                                                blockAlign="center"
                                                            >
                                                                {[
                                                                    1, 2, 3, 4,
                                                                    5,
                                                                ].map(
                                                                    (item) => (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 18 18"
                                                                            fill="none"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M8.08786 2.17107C8.43398 1.35862 9.58635 1.36083 9.92935 2.17461L11.3862 5.63101C11.5267 5.96438 11.8363 6.19586 12.1958 6.23633L15.8555 6.6483C16.7019 6.74357 17.0493 7.78565 16.4295 8.36977L13.622 11.0156C13.371 11.2522 13.2596 11.6015 13.3274 11.9397L14.0929 15.7617C14.264 16.6163 13.3362 17.2656 12.5919 16.812L9.50342 14.9297C9.18505 14.7356 8.78519 14.7348 8.46604 14.9276L5.36996 16.7973C4.624 17.2478 3.69886 16.595 3.87324 15.7412L4.65339 11.9212C4.72241 11.5833 4.61241 11.2336 4.36234 10.9961L1.56501 8.33881C0.947456 7.75218 1.29919 6.71137 2.146 6.61961L5.806 6.22299C6.16578 6.184 6.47643 5.95368 6.61826 5.62074L8.08786 2.17107Z"
                                                                                fill="#FFB400"
                                                                            />
                                                                        </svg>
                                                                    )
                                                                )}
                                                                <p className="avg-rating">
                                                                    4.8
                                                                </p>
                                                            </InlineStack>
                                                        </InlineStack>
                                                    </>
                                                }
                                                id="after"
                                                name="display_rating"
                                                checked={
                                                    productRating.display_rating ===
                                                    "after"
                                                }
                                                onChange={() =>
                                                    handleChange(
                                                        "after",
                                                        "display_rating"
                                                    )
                                                }
                                            />
                                            <RadioButton
                                                label={
                                                    <>
                                                        <InlineStack
                                                            blockAlign="center"
                                                            gap={100}
                                                            align="space-between"
                                                            wrap={false}
                                                        >
                                                            <p className="setting-item-secondary--title">
                                                                {t(
                                                                    "product_rating.summary_of_rating_star.no_text",
                                                                    {
                                                                        ns: "widget",
                                                                    }
                                                                )}
                                                            </p>
                                                            <InlineStack
                                                                gap={100}
                                                                wrap={false}
                                                                blockAlign="center"
                                                            >
                                                                {[
                                                                    1, 2, 3, 4,
                                                                    5,
                                                                ].map(
                                                                    (item) => (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 18 18"
                                                                            fill="none"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M8.08786 2.17107C8.43398 1.35862 9.58635 1.36083 9.92935 2.17461L11.3862 5.63101C11.5267 5.96438 11.8363 6.19586 12.1958 6.23633L15.8555 6.6483C16.7019 6.74357 17.0493 7.78565 16.4295 8.36977L13.622 11.0156C13.371 11.2522 13.2596 11.6015 13.3274 11.9397L14.0929 15.7617C14.264 16.6163 13.3362 17.2656 12.5919 16.812L9.50342 14.9297C9.18505 14.7356 8.78519 14.7348 8.46604 14.9276L5.36996 16.7973C4.624 17.2478 3.69886 16.595 3.87324 15.7412L4.65339 11.9212C4.72241 11.5833 4.61241 11.2336 4.36234 10.9961L1.56501 8.33881C0.947456 7.75218 1.29919 6.71137 2.146 6.61961L5.806 6.22299C6.16578 6.184 6.47643 5.95368 6.61826 5.62074L8.08786 2.17107Z"
                                                                                fill="#FFB400"
                                                                            />
                                                                        </svg>
                                                                    )
                                                                )}
                                                            </InlineStack>
                                                        </InlineStack>
                                                    </>
                                                }
                                                id="none"
                                                name="display_rating"
                                                checked={
                                                    productRating.display_rating ===
                                                    "none"
                                                }
                                                onChange={() =>
                                                    handleChange(
                                                        "none",
                                                        "display_rating"
                                                    )
                                                }
                                            />
                                        </BlockStack>
                                    </div>
                                </div>

                                <div className="product-rating-setting--item">
                                    <BlockStack align="space-between" gap={300}>
                                        <p className="setting-item-primary--title">
                                            {t("font_size", {
                                                ns: "translation",
                                            })}
                                        </p>
                                        <InlineGrid
                                            columns={{
                                                xl: "1",
                                                lg: "1",
                                                md: "2",
                                                sm: "1",
                                                xs: "1",
                                            }}
                                            gap={300}
                                        >
                                            <InlineGrid
                                                gap={300}
                                                columns={[
                                                    "twoThirds",
                                                    "oneThird",
                                                ]}
                                                alignItems="center"
                                            >
                                                <p className="setting-item-secondary--title">
                                                    {t("star_size", {
                                                        ns: "translation",
                                                    })}{" "}
                                                    (
                                                    {t("pixels", {
                                                        ns: "translation",
                                                    })}
                                                    )
                                                </p>

                                                <InlineStack align="end">
                                                    {" "}
                                                    <Box maxWidth="100px">
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                productRating
                                                                    .font_size
                                                                    .product_page
                                                                    .star_size
                                                            }
                                                            onChange={(value) =>
                                                                handleChangeFontSize(
                                                                    value,
                                                                    "product_page",
                                                                    "star_size"
                                                                )
                                                            }
                                                            min={1}
                                                        />
                                                    </Box>
                                                </InlineStack>
                                            </InlineGrid>
                                            <InlineGrid
                                                gap={300}
                                                columns={[
                                                    "twoThirds",
                                                    "oneThird",
                                                ]}
                                                alignItems="center"
                                            >
                                                <p className="setting-item-secondary--title">
                                                    {t("text_star", {
                                                        ns: "translation",
                                                    })}{" "}
                                                    (
                                                    {t("pixels", {
                                                        ns: "translation",
                                                    })}
                                                    )
                                                </p>

                                                <InlineStack align="end">
                                                    {" "}
                                                    <Box maxWidth="100px">
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                productRating
                                                                    .font_size
                                                                    .product_page
                                                                    .text_star
                                                            }
                                                            onChange={(value) =>
                                                                handleChangeFontSize(
                                                                    value,
                                                                    "product_page",
                                                                    "text_star"
                                                                )
                                                            }
                                                            min={1}
                                                        />
                                                    </Box>
                                                </InlineStack>
                                            </InlineGrid>
                                            <InlineGrid
                                                gap={300}
                                                columns={[
                                                    "twoThirds",
                                                    "oneThird",
                                                ]}
                                                alignItems="center"
                                            >
                                                <p className="setting-item-secondary--title">
                                                    {t("text_ratings", {
                                                        ns: "translation",
                                                    })}{" "}
                                                    (
                                                    {t("pixels", {
                                                        ns: "translation",
                                                    })}
                                                    )
                                                </p>

                                                <InlineStack align="end">
                                                    {" "}
                                                    <Box maxWidth="100px">
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                productRating
                                                                    .font_size
                                                                    .product_page
                                                                    .text_rating
                                                            }
                                                            onChange={(value) =>
                                                                handleChangeFontSize(
                                                                    value,
                                                                    "product_page",
                                                                    "text_rating"
                                                                )
                                                            }
                                                            min={1}
                                                        />
                                                    </Box>
                                                </InlineStack>
                                            </InlineGrid>
                                        </InlineGrid>
                                    </BlockStack>
                                </div>

                                <div className="product-rating-setting--item">
                                    <BlockStack align="space-between" gap={300}>
                                        <p className="setting-item-primary--title">
                                            {t(
                                                "product_rating.display_number_of_review.title",
                                                {
                                                    ns: "widget",
                                                }
                                            )}
                                        </p>
                                        <div className="setting--option">
                                            <InlineStack
                                                wrap={false}
                                                blockAlign="center"
                                                gap={100}
                                            >
                                                <Checkbox
                                                    checked={
                                                        productRating.display_number
                                                    }
                                                    onChange={(value) =>
                                                        setProductRating({
                                                            ...productRating,
                                                            display_number:
                                                                value,
                                                        })
                                                    }
                                                    id="show-number-of--review"
                                                />
                                                <label htmlFor="show-number-of--review">
                                                    <p className="total-ratings">
                                                        {t(
                                                            "product_rating.display_number_of_review.show_number_of_review",
                                                            {
                                                                ns: "widget",
                                                            }
                                                        )}
                                                    </p>
                                                </label>
                                            </InlineStack>
                                        </div>
                                    </BlockStack>
                                </div>
                                <div className="product-rating-setting--item  no-border no-padding-bottom">
                                    <BlockStack gap={400}>
                                        <InlineStack
                                            gap={200}
                                            align="space-between"
                                            wrap={true}
                                            blockAlign="center"
                                        >
                                            <Text variant="bodyMd">
                                                {t("language", {
                                                    ns: "translation",
                                                })}
                                            </Text>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    maxWidth: "140px",
                                                }}
                                            >
                                                <Select
                                                    options={listCountries}
                                                    onChange={
                                                        handleChangeCountries
                                                    }
                                                    value={selected}
                                                />
                                            </div>
                                        </InlineStack>
                                        <InlineStack
                                            align="space-between"
                                            wrap={false}
                                            blockAlign="center"
                                            gap={200}
                                        >
                                            <p className="setting-item-secondary--title">
                                                {t(
                                                    "product_rating.rating_label",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </p>
                                            <BlockStack
                                                gap={100}
                                                inlineAlign="end"
                                            >
                                                <Box
                                                    maxWidth="140px"
                                                    minWidth="140px"
                                                >
                                                    <TextField
                                                        value={
                                                            productRating.label_rating
                                                        }
                                                        onChange={(value) =>
                                                            handleChangeInput(
                                                                value,
                                                                "label_rating"
                                                            )
                                                        }
                                                        min={1}
                                                    />
                                                </Box>
                                                {validateInput.label_rating !==
                                                    "" && (
                                                    <div className="error-message">
                                                        <span>
                                                            <Icon
                                                                source={
                                                                    AlertCircleIcon
                                                                }
                                                                tone="critical"
                                                            />
                                                        </span>
                                                        <span>
                                                            {
                                                                validateInput.label_rating
                                                            }
                                                        </span>
                                                    </div>
                                                )}
                                            </BlockStack>
                                        </InlineStack>

                                        <InlineStack
                                            align="space-between"
                                            wrap={false}
                                            blockAlign="center"
                                            gap={200}
                                        >
                                            <div className="setting-item-secondary--title">
                                                {t(
                                                    "product_rating.ratings_label",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </div>
                                            <BlockStack
                                                gap={100}
                                                inlineAlign="end"
                                            >
                                                <Box
                                                    maxWidth="140px"
                                                    minWidth="140px"
                                                >
                                                    <TextField
                                                        value={
                                                            productRating.label_ratings
                                                        }
                                                        onChange={(value) =>
                                                            handleChangeInput(
                                                                value,
                                                                "label_ratings"
                                                            )
                                                        }
                                                        min={1}
                                                    />
                                                </Box>
                                                {validateInput.label_ratings !==
                                                    "" && (
                                                    <div className="error-message">
                                                        <span>
                                                            <Icon
                                                                source={
                                                                    AlertCircleIcon
                                                                }
                                                                tone="critical"
                                                            />
                                                        </span>
                                                        <span>
                                                            {
                                                                validateInput.label_ratings
                                                            }
                                                        </span>
                                                    </div>
                                                )}
                                            </BlockStack>
                                        </InlineStack>
                                    </BlockStack>
                                </div>
                            </Collapsible>
                        </div>

                        <div>
                            <div
                                className="widget-setting-collape--title"
                                onClick={() => handleToggle("collection_page")}
                                style={{ paddingRight: 0 }}
                            >
                                <h5
                                    className="setting-group--title"
                                    style={{ fontWeight: 700 }}
                                >
                                    {t("product_rating.collection_page", {
                                        ns: "widget",
                                    })}
                                </h5>
                                {!open.collection_page ? (
                                    <Icon source={ChevronRightIcon} />
                                ) : (
                                    <Icon source={ChevronDownIcon} />
                                )}
                            </div>
                            <Collapsible
                                open={open.collection_page}
                                id="basic-collapsible"
                                transition={{
                                    duration: "500ms",
                                    timingFunction: "ease-in-out",
                                }}
                                expandOnPrint
                            >
                                <div className="product-rating-setting--item">
                                    <div className="setting--option">
                                        <Checkbox
                                            checked={
                                                !productRating.show_collection_rating_when_empty
                                            }
                                            onChange={(value) =>
                                                setProductRating({
                                                    ...productRating,
                                                    show_collection_rating_when_empty:
                                                        !value,
                                                })
                                            }
                                            id="show_collection_rating_when_empty"
                                            label={t(
                                                "product_rating.summary_of_rating_star.show_collection_rating_when_empty",
                                                { ns: "widget" }
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="product-rating-setting--item">
                                    <div className="setting--option">
                                        <BlockStack gap={400}>
                                            <RadioButton
                                                label={
                                                    <>
                                                        <InlineStack
                                                            blockAlign="center"
                                                            gap={100}
                                                            wrap={false}
                                                            align="space-between"
                                                        >
                                                            <p className="setting-item-secondary--title">
                                                                {t(
                                                                    "product_rating.summary_of_rating_star.text_before",
                                                                    {
                                                                        ns: "widget",
                                                                    }
                                                                )}
                                                            </p>
                                                            <InlineStack
                                                                gap={100}
                                                                wrap={false}
                                                                blockAlign="center"
                                                            >
                                                                <p className="avg-rating">
                                                                    (20)
                                                                </p>
                                                                {[
                                                                    1, 2, 3, 4,
                                                                    5,
                                                                ].map(
                                                                    (item) => (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 18 18"
                                                                            fill="none"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M8.08786 2.17107C8.43398 1.35862 9.58635 1.36083 9.92935 2.17461L11.3862 5.63101C11.5267 5.96438 11.8363 6.19586 12.1958 6.23633L15.8555 6.6483C16.7019 6.74357 17.0493 7.78565 16.4295 8.36977L13.622 11.0156C13.371 11.2522 13.2596 11.6015 13.3274 11.9397L14.0929 15.7617C14.264 16.6163 13.3362 17.2656 12.5919 16.812L9.50342 14.9297C9.18505 14.7356 8.78519 14.7348 8.46604 14.9276L5.36996 16.7973C4.624 17.2478 3.69886 16.595 3.87324 15.7412L4.65339 11.9212C4.72241 11.5833 4.61241 11.2336 4.36234 10.9961L1.56501 8.33881C0.947456 7.75218 1.29919 6.71137 2.146 6.61961L5.806 6.22299C6.16578 6.184 6.47643 5.95368 6.61826 5.62074L8.08786 2.17107Z"
                                                                                fill="#FFB400"
                                                                            />
                                                                        </svg>
                                                                    )
                                                                )}
                                                            </InlineStack>
                                                        </InlineStack>
                                                    </>
                                                }
                                                id="collection_before"
                                                name="display_rating_collection"
                                                checked={
                                                    productRating.display_rating_collection ===
                                                    "before"
                                                }
                                                onChange={() =>
                                                    handleChange(
                                                        "before",
                                                        "display_rating_collection"
                                                    )
                                                }
                                            />
                                            <RadioButton
                                                label={
                                                    <>
                                                        <InlineStack
                                                            blockAlign="center"
                                                            gap={100}
                                                            align="space-between"
                                                            wrap={false}
                                                        >
                                                            <p className="setting-item-secondary--title">
                                                                {t(
                                                                    "product_rating.summary_of_rating_star.text_after",
                                                                    {
                                                                        ns: "widget",
                                                                    }
                                                                )}
                                                            </p>
                                                            <InlineStack
                                                                gap={100}
                                                                wrap={false}
                                                                blockAlign="center"
                                                            >
                                                                {[
                                                                    1, 2, 3, 4,
                                                                    5,
                                                                ].map(
                                                                    (item) => (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 18 18"
                                                                            fill="none"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M8.08786 2.17107C8.43398 1.35862 9.58635 1.36083 9.92935 2.17461L11.3862 5.63101C11.5267 5.96438 11.8363 6.19586 12.1958 6.23633L15.8555 6.6483C16.7019 6.74357 17.0493 7.78565 16.4295 8.36977L13.622 11.0156C13.371 11.2522 13.2596 11.6015 13.3274 11.9397L14.0929 15.7617C14.264 16.6163 13.3362 17.2656 12.5919 16.812L9.50342 14.9297C9.18505 14.7356 8.78519 14.7348 8.46604 14.9276L5.36996 16.7973C4.624 17.2478 3.69886 16.595 3.87324 15.7412L4.65339 11.9212C4.72241 11.5833 4.61241 11.2336 4.36234 10.9961L1.56501 8.33881C0.947456 7.75218 1.29919 6.71137 2.146 6.61961L5.806 6.22299C6.16578 6.184 6.47643 5.95368 6.61826 5.62074L8.08786 2.17107Z"
                                                                                fill="#FFB400"
                                                                            />
                                                                        </svg>
                                                                    )
                                                                )}
                                                                <p className="avg-rating">
                                                                    (20)
                                                                </p>
                                                            </InlineStack>
                                                        </InlineStack>
                                                    </>
                                                }
                                                id="collection_after"
                                                name="display_rating_collection"
                                                checked={
                                                    productRating.display_rating_collection ===
                                                    "after"
                                                }
                                                onChange={() =>
                                                    handleChange(
                                                        "after",
                                                        "display_rating_collection"
                                                    )
                                                }
                                            />
                                            <RadioButton
                                                label={
                                                    <>
                                                        <InlineStack
                                                            blockAlign="center"
                                                            gap={100}
                                                            align="space-between"
                                                            wrap={false}
                                                        >
                                                            <p className="setting-item-secondary--title">
                                                                {t(
                                                                    "product_rating.summary_of_rating_star.no_text",
                                                                    {
                                                                        ns: "widget",
                                                                    }
                                                                )}
                                                            </p>
                                                            <InlineStack
                                                                gap={100}
                                                                wrap={false}
                                                                blockAlign="center"
                                                            >
                                                                {[
                                                                    1, 2, 3, 4,
                                                                    5,
                                                                ].map(
                                                                    (item) => (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 18 18"
                                                                            fill="none"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M8.08786 2.17107C8.43398 1.35862 9.58635 1.36083 9.92935 2.17461L11.3862 5.63101C11.5267 5.96438 11.8363 6.19586 12.1958 6.23633L15.8555 6.6483C16.7019 6.74357 17.0493 7.78565 16.4295 8.36977L13.622 11.0156C13.371 11.2522 13.2596 11.6015 13.3274 11.9397L14.0929 15.7617C14.264 16.6163 13.3362 17.2656 12.5919 16.812L9.50342 14.9297C9.18505 14.7356 8.78519 14.7348 8.46604 14.9276L5.36996 16.7973C4.624 17.2478 3.69886 16.595 3.87324 15.7412L4.65339 11.9212C4.72241 11.5833 4.61241 11.2336 4.36234 10.9961L1.56501 8.33881C0.947456 7.75218 1.29919 6.71137 2.146 6.61961L5.806 6.22299C6.16578 6.184 6.47643 5.95368 6.61826 5.62074L8.08786 2.17107Z"
                                                                                fill="#FFB400"
                                                                            />
                                                                        </svg>
                                                                    )
                                                                )}
                                                            </InlineStack>
                                                        </InlineStack>
                                                    </>
                                                }
                                                id="collection_none"
                                                name="display_rating_collection"
                                                checked={
                                                    productRating.display_rating_collection ===
                                                    "none"
                                                }
                                                onChange={() =>
                                                    handleChange(
                                                        "none",
                                                        "display_rating_collection"
                                                    )
                                                }
                                            />
                                        </BlockStack>
                                    </div>
                                </div>

                                <div className="product-rating-setting--item no-border no-padding-bottom">
                                    <BlockStack align="space-between" gap={300}>
                                        <p className="setting-item-primary--title">
                                            {t("font_size", {
                                                ns: "translation",
                                            })}
                                        </p>
                                        <InlineGrid
                                            columns={{
                                                xl: "1",
                                                lg: "1",
                                                md: "2",
                                                sm: "1",
                                                xs: "1",
                                            }}
                                            gap={300}
                                        >
                                            <InlineGrid
                                                gap={300}
                                                columns={[
                                                    "twoThirds",
                                                    "oneThird",
                                                ]}
                                                alignItems="center"
                                            >
                                                <p className="setting-item-secondary--title">
                                                    {t("star_size", {
                                                        ns: "translation",
                                                    })}{" "}
                                                    (
                                                    {t("pixels", {
                                                        ns: "translation",
                                                    })}
                                                    )
                                                </p>

                                                <InlineStack align="end">
                                                    {" "}
                                                    <Box maxWidth="100px">
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                productRating
                                                                    .font_size
                                                                    .collection_page
                                                                    .star_size
                                                            }
                                                            onChange={(value) =>
                                                                handleChangeFontSize(
                                                                    value,
                                                                    "collection_page",
                                                                    "star_size"
                                                                )
                                                            }
                                                            min={1}
                                                        />
                                                    </Box>
                                                </InlineStack>
                                            </InlineGrid>
                                            <InlineGrid
                                                gap={300}
                                                columns={[
                                                    "twoThirds",
                                                    "oneThird",
                                                ]}
                                                alignItems="center"
                                            >
                                                <p className="setting-item-secondary--title">
                                                    {t("text_star", {
                                                        ns: "translation",
                                                    })}{" "}
                                                    (
                                                    {t("pixels", {
                                                        ns: "translation",
                                                    })}
                                                    )
                                                </p>

                                                <InlineStack align="end">
                                                    {" "}
                                                    <Box maxWidth="100px">
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                productRating
                                                                    .font_size
                                                                    .collection_page
                                                                    .text_star
                                                            }
                                                            onChange={(value) =>
                                                                handleChangeFontSize(
                                                                    value,
                                                                    "collection_page",
                                                                    "text_star"
                                                                )
                                                            }
                                                            min={1}
                                                        />
                                                    </Box>
                                                </InlineStack>
                                            </InlineGrid>
                                        </InlineGrid>
                                    </BlockStack>
                                </div>
                            </Collapsible>
                        </div>

                        <div>
                            <div
                                className="widget-setting-collape--title"
                                onClick={() => handleToggle("custom_color")}
                                style={{ paddingRight: 0 }}
                            >
                                <h5
                                    className="setting-group--title"
                                    style={{ fontWeight: 700 }}
                                >
                                    {t("product_rating.custom_color.title", {
                                        ns: "widget",
                                    })}
                                </h5>
                                {!open.custom_color ? (
                                    <Icon source={ChevronRightIcon} />
                                ) : (
                                    <Icon source={ChevronDownIcon} />
                                )}
                            </div>
                            <Collapsible
                                open={open.custom_color}
                                id="basic-collapsible"
                                transition={{
                                    duration: "500ms",
                                    timingFunction: "ease-in-out",
                                }}
                                expandOnPrint
                            >
                                <div className="product-rating-setting--item no-border no-padding-bottom">
                                    <InlineGrid
                                        gap={300}
                                        columns={{
                                            xs: "1",
                                            sm: "3",
                                            md: "3",
                                            lg: "1",
                                            xl: "1",
                                        }}
                                    >
                                        <InlineStack
                                            blockAlign="center"
                                            align="space-between"
                                        >
                                            <p className="setting-item-secondary--title">
                                                {t(
                                                    "product_rating.custom_color.rating_icon_color",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </p>
                                            <div className="setting--option">
                                                <ColorPicking
                                                    item={"rating_icon_color"}
                                                    color={
                                                        productRating.rating_icon_color
                                                    }
                                                    handleSelectedColor={
                                                        handleSelectedColor
                                                    }
                                                />
                                            </div>
                                        </InlineStack>
                                        <InlineStack
                                            blockAlign="center"
                                            align="space-between"
                                        >
                                            <p className="setting-item-secondary--title">
                                                {t(
                                                    "product_rating.custom_color.review_rating_text",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </p>
                                            <div className="setting--option">
                                                <ColorPicking
                                                    item={"rating_text_color"}
                                                    color={
                                                        productRating.rating_text_color
                                                    }
                                                    handleSelectedColor={
                                                        handleSelectedColor
                                                    }
                                                />
                                            </div>
                                        </InlineStack>
                                        <InlineStack
                                            blockAlign="center"
                                            align="space-between"
                                        >
                                            <p className="setting-item-secondary--title">
                                                {t(
                                                    "product_rating.custom_color.number_of_review_text",
                                                    {
                                                        ns: "widget",
                                                    }
                                                )}
                                            </p>
                                            <div className="setting--option">
                                                <ColorPicking
                                                    item={"number_text_color"}
                                                    color={
                                                        productRating.number_text_color
                                                    }
                                                    handleSelectedColor={
                                                        handleSelectedColor
                                                    }
                                                />
                                            </div>
                                        </InlineStack>
                                    </InlineGrid>
                                </div>
                            </Collapsible>
                        </div>
                    </div>
                </BlockStack>
            </Box>
            {showModal && (
                <CustomModalMessage
                    closeModalAction={handleCancel}
                    t={t}
                    primaryButton={{
                        content: t("update", { ns: "translation" }),
                        action: handleUpdate,
                    }}
                    secondaryButton={{
                        content: t("cancel", { ns: "translation" }),
                        action: handleCancel,
                    }}
                    title={t("warning", { ns: "translation" })}
                    content={t("are_you_sure", { ns: "translation" })}
                />
            )}
        </>
    );
};

export default Setting;
