import React, { useEffect, useState } from "react";
import {
    Box,
    Spinner,
    Card,
    Text,
    TextField,
    Icon,
    Button,
    ButtonGroup,
} from "@shopify/polaris";
import { CheckIcon, ExternalIcon, FileIcon } from "@shopify/polaris-icons";
const AddScriptGuild = ({
    t,
    Trans,
    data,
    type,
    currentTheme,
    handleAdd,
    handleAddBlockV1,
}) => {
    const [step, setStep] = useState(
        type === "app-embed" ? "step_2" : "step_3"
    );

    const [coppySuccess, setCoppySuccess] = useState(false);

    const handleCoppyCode = () => {
        const copyText = document.getElementById("coppy-code--input");
        document.getSelection().removeAllRanges();
        const range = document.createRange();
        range.selectNode(copyText);
        window.getSelection().addRange(range);
        document.execCommand("copy");
        document.getSelection().removeAllRanges();
        setCoppySuccess(true);
        setTimeout(() => {
            setCoppySuccess(false);
        }, 2000);
    };

    const add_widgetIcon = () => {
        if (step === "step_2") {
            if (data?.value === t("add_app_embedded", { ns: "translation" })) {
                return <Icon source={ExternalIcon} />;
            }
        } else {
            if (data?.value === t("add_widget", { ns: "translation" })) {
                return <Icon source={ExternalIcon} />;
            }
        }
    };

    const handleChatWithSupport = () => {
        window.$crisp.push(["do", "chat:open"]);
        window.$crisp.push([
            "set",
            "message:text",
            [
                `I can not ${
                    type === "app-embed" ? "enable app embed" : "add widget"
                }. Please help me out!`,
            ],
        ]);
    };

    const guideV1 = (
        <>
            <Trans i18nKey={`step_3.v1.quick_guide.step_1`} ns="onboarding">
                Open
                <a
                    href="shopify://admin/themes/currents/editors?key=templates%2Fproduct.liquid"
                    target="_blank"
                    rel="noreferrer"
                >
                    templates/product.liquid
                </a>
                editor
            </Trans>

            <Trans
                i18nKey={`${step}.v1.quick_guide.step_2`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <div className="guide-list-no--style">
                <Box>
                    <TextField
                        connectedRight={
                            <Button
                                icon={coppySuccess ? CheckIcon : FileIcon}
                                onClick={() => {
                                    handleCoppyCode();
                                }}
                            >
                                {coppySuccess
                                    ? t("copied", { ns: "translation" })
                                    : ""}
                            </Button>
                        }
                        id="coppy-code--input"
                        value={`<div class="trustshop trustshop-review-wrap" id="trustshop-review"></div>`}
                    />
                </Box>
            </div>
            <Trans
                i18nKey={`${step}.v1.quick_guide.step_3`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <div className="guide-list-no--style">
                <ButtonGroup>
                    <Button
                        icon={ExternalIcon}
                        onClick={() => {
                            handleAddBlockV1();
                        }}
                        variant="primary"
                    >
                        {t("add_widget", {
                            ns: "translation",
                        })}
                    </Button>
                    <Button onClick={() => handleChatWithSupport()}>
                        {t("ask_for_help", { ns: "translation" })}
                    </Button>
                </ButtonGroup>
            </div>
        </>
    );

    const guildeV2 = (
        <>
            <Trans
                i18nKey={`${step}.v2.quick_guide.step_1`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <Trans
                i18nKey={`${step}.v2.quick_guide.step_2`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <Trans
                i18nKey={`${step}.v2.quick_guide.step_3`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <Trans
                i18nKey={`${step}.v2.quick_guide.step_4`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <div>
                <span
                    style={{
                        backgroundColor: "#FFF8DB",
                        borderRadius: "8px",
                        padding: "6px 12px",
                        display: `${data?.timeout === true ? "block" : "none"}`,
                        width: "fit-content",
                        marginBottom: "8px",
                    }}
                >
                    <span
                        style={{
                            fontWeight: "550",
                            lineHeight: "16px",
                            fontSize: "12px",
                        }}
                    >
                        {t(`${step}.quick_guide.not_detect_widget`, {
                            ns: "onboarding",
                        })}
                    </span>
                </span>

                <ButtonGroup>
                    <Button
                        size="large"
                        variant="primary"
                        disabled={
                            data?.verifyLoading ||
                            data?.value ===
                                t(`${step}.quick_guide.success_btn`, {
                                    ns: "onboarding",
                                })
                        }
                        loading={data?.loadLink || data?.verifyLoading}
                        tone={
                            data?.status
                                ? data?.value ===
                                  t(`${step}.quick_guide.success_btn`, {
                                      ns: "onboarding",
                                  })
                                    ? "success"
                                    : ""
                                : "critical"
                        }
                        icon={add_widgetIcon()}
                        onClick={() => handleAdd(step)}
                    >
                        {data?.value}
                    </Button>
                    <Button onClick={() => handleChatWithSupport()}>
                        {t("ask_for_help", { ns: "translation" })}
                    </Button>
                </ButtonGroup>
            </div>
        </>
    );

    useEffect(() => {
        setStep(type === "app-embed" ? "step_2" : "step_3");
    }, [type]);

    const guildeEmbed = (
        <>
            <Trans
                i18nKey={`${step}.quick_guide.step_1`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <Trans
                i18nKey={`${step}.quick_guide.step_2`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <Trans
                i18nKey={`${step}.quick_guide.step_3`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <Trans
                i18nKey={`${step}.quick_guide.step_4`}
                ns="onboarding"
                components={{
                    span: <span />,
                    paragraph: <p />,
                }}
            ></Trans>
            <p>
                <span
                    style={{
                        backgroundColor: "#FFF8DB",
                        borderRadius: "8px",
                        padding: "6px 12px",
                        display: `${data?.timeout === true ? "block" : "none"}`,
                        width: "fit-content",
                        marginBottom: "8px",
                    }}
                >
                    <span
                        style={{
                            fontWeight: "550",
                            lineHeight: "16px",
                            fontSize: "12px",
                        }}
                    >
                        {t(`${step}.quick_guide.not_detect_embedded`, {
                            ns: "onboarding",
                        })}
                    </span>
                </span>

                <ButtonGroup>
                    <Button
                        size="large"
                        variant="primary"
                        disabled={
                            data?.verifyLoading ||
                            data?.value ===
                                t(`${step}.quick_guide.success_btn`, {
                                    ns: "onboarding",
                                })
                        }
                        loading={data?.loadLink || data?.verifyLoading}
                        tone={
                            data?.status
                                ? data?.value ===
                                  t(`${step}.quick_guide.success_btn`, {
                                      ns: "onboarding",
                                  })
                                    ? "success"
                                    : ""
                                : "critical"
                        }
                        icon={add_widgetIcon()}
                        onClick={() => handleAdd(step)}
                    >
                        {data?.value}
                    </Button>
                    <Button onClick={() => handleChatWithSupport()}>
                        {t("ask_for_help", { ns: "translation" })}
                    </Button>
                </ButtonGroup>
            </p>
        </>
    );

    return (
        <div className="add-block-script--guide">
            <Box paddingBlockStart={1000}>
                <div
                    style={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "stretch",
                        justifyContent: "center",
                        flexWrap: "wrap",
                    }}
                >
                    {currentTheme?.loading && step === "step_3" ? (
                        <>
                            <Spinner />
                        </>
                    ) : (
                        <>
                            <div className="left">
                                <Card padding={0}>
                                    <Box
                                        padding={300}
                                        borderBlockEndWidth="0165"
                                        borderColor="border"
                                    >
                                        <Text
                                            variant="headingLg"
                                            alignment="center"
                                        >
                                            {t(`${step}.quick_guide.title`, {
                                                ns: "onboarding",
                                            })}
                                        </Text>
                                    </Box>
                                    <Box padding={300}>
                                        <div className="guide">
                                            <p>
                                                {t(
                                                    `${step}.quick_guide.sub_title`,
                                                    {
                                                        ns: "onboarding",
                                                    }
                                                )}
                                                 
                                            </p>
                                            <div className="guide-step">
                                                {step === "step_2"
                                                    ? guildeEmbed
                                                    : currentTheme?.version === "1.0"
                                                    ? guideV1
                                                    : guildeV2}
                                            </div>
                                        </div>
                                    </Box>
                                </Card>
                            </div>

                            <div
                                className="right"
                                style={{
                                    "--aspectRatio": `${
                                        currentTheme?.version === "1.0" ? "14/9" : "1/1"
                                    }`,
                                }}
                            >
                                <img
                                    src={
                                        type === "app-embed"
                                            ? `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/da90fc10-ca7d-4ca2-cb83-51cb65b7b800/600`
                                            : currentTheme?.version === "1.0"
                                            ? `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/ae7f10b0-0d62-4555-14ae-cf9f95ea1300/600`
                                            : `https://imagedelivery.net/JReHcjN6XOE1ppEd_a3QKg/5d859acc-4f68-45b7-0b72-0cec19a60a00/600`
                                    }
                                    alt="guide"
                                />
                            </div>
                        </>
                    )}
                </div>
            </Box>
        </div>
    );
};

export default AddScriptGuild;
